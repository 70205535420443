import { type ReactElement } from 'react'
import { type ShowcasePricingSummarySection } from './types'
import { ShowcasePricingSummaryComponentArea } from './AddToCartPricingSummaryComponent'
import { useCurrency } from '@stuller/shared/util/react-hooks'

interface ShowcasePricingSummarySectionAreaProps {
  section: ShowcasePricingSummarySection
  className?: string
}

function ShowcasePricingSummarySectionArea ({ section, className }: ShowcasePricingSummarySectionAreaProps): ReactElement | null {
  const [additionalCharge] = useCurrency(section.additionalCharge?.value, section.additionalCharge?.exchangeRate)
  const [baseTotal] = useCurrency(section.baseTotal?.value, section.baseTotal?.exchangeRate)
  const [markedUpTotal] = useCurrency(section.markedUpTotal?.value, section.markedUpTotal?.exchangeRate)

  return (
    <div className={className}>
      <div className='row'>
        <div className='col-12'>
          <img alt={`image for ${section.title}`} width='100' src={section.item?.image ?? ''} />
        </div>
        <div className='col-12'>
          <div className='row justify-content-end'>
            <div className='col-3 fw-bold text-end'>Your Cost</div>
            <div className='col-3 fw-bold text-end'>Marked Up</div>
          </div>
          {section.components?.map((component, index) => (
            <ShowcasePricingSummaryComponentArea key={index} className='mb-2 border-bottom border-dashed' component={component} />
          ))}
          <div className='row'>
            <div className='col-9'>Additional Charge</div>
            <div className='col-3 text-end'>{additionalCharge}</div>
          </div>
          <hr />
          <div className='row fw-bold'>
            <div className='col-6'>End Price</div>
            <div className='col-3 text-end'>{baseTotal}</div>
            <div className='col-3 text-end'>{markedUpTotal}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ShowcasePricingSummarySectionArea }
