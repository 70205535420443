import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Alert, AlertHeading, AlertIcon } from '@stuller/stullercom/ui'

/**
 * The message for the messages popover when there are no messages
 */
function MessagesEmpty (): ReactElement {
  return (
    <Alert transparent className='pb-0 mb-2'>
      <div className='row align-items-center justify-content-center'>
        <div className='col-auto'>
          <AlertIcon size='lg' className='text-green'>
            <FontAwesomeIcon icon={fas.faCircleCheck} />
          </AlertIcon>
        </div>
        <div className='col-auto ps-0'>
          <AlertHeading tag='h3' className='mb-0'>
            No updates to show
          </AlertHeading>
        </div>
        <div className='text-center mt-2'>
          Updates for shipments and delays, CAD/CAM project approval requests, past-due account balance notifications, and more will appear here based on the Message Center preferences you've set.
        </div>
      </div>
    </Alert>
  )
}

export {
  MessagesEmpty
}
