import { type ReactElement } from 'react'
import { Button } from '@stuller/stullercom/ui'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'
import {
  type CartLineMessageInfoFragment,
  useAddToCartMutation,
  GetCartItemCountDocument
} from '@stuller/stullercom/data-access/apollo-queries'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { getClientValidationErrors } from '@stuller/stullercom/data-access/apollo-client'
import { trackCartLineAddToCart } from '@stuller/stullercom/feat/google-tag-manager'
import { useAuth } from '@stuller/stullercom/feat/auth'

interface AddToCartStandardButtonProps {
  /**
   * Function to set the error state
   */
  onError: (errors: CartLineMessageInfoFragment[]) => void
  /**
   * Function to set the success state
   */
  onSuccess: () => void
  /**
   * Loading state of the AddToCartSection used to disable the button when refetching data
   */
  isLoading: boolean
}

/**
 * This is the Standard Add To Cart Button (that adds item(s) to the current user's cart)
 */
function AddToStandardCartButton ({ onError, onSuccess, isLoading }: AddToCartStandardButtonProps): ReactElement | null {
  const {
    options,
    itemId,
    itemType,
    trackingParams,
    customerNotes,
    salesType
  } = useAddToCartSectionContext()
  const {
    recommendationIndex,
    recommendationSource,
    recommendationId,
    recommendationType
  } = trackingParams ?? {}
  const numericRecommendationIndex = recommendationIndex != null ? parseInt(recommendationIndex) : undefined
  const numericRecommendationId = recommendationId != null ? parseInt(recommendationId) : undefined
  const auth = useAuth()

  const [addToCart, { loading: addToCartLoading }] = useAddToCartMutation({
    variables: {
      inputs: {
        itemId: {
          id: itemId,
          type: itemType
        },
        options: {
          isAnnealed: options?.isAnnealed ?? false,
          isForConsignment: options?.isForConsignment ?? false,
          isMatched: options?.isMatched ?? false,
          bagAndTagOptions: options?.bagAndTagOptions,
          customerNotes,
          specialInstructions: options?.specialInstructions,
          quantity: options?.quantity ?? 1,
          length: options?.length,
          width: options?.width,
          matchingCode: undefined, // TODO: add options?.matchingCode,
          consultantDiscount: undefined, // TODO: add options?.consultantDiscount,
          salesType
        },
        tracking: {
          index: numericRecommendationIndex,
          source: recommendationSource ?? '',
          sourceId: numericRecommendationId,
          type: recommendationType
        }
      }
    },
    onCompleted: (data) => {
      onSuccess()
      const items = data.addToCart?.cart?.items.filter((item) => data.addToCart?.addedCartLineIds.includes(item.id) === true) ?? []
      for (const item of items) {
        trackCartLineAddToCart(item, auth)
      }
    },
    onError: (error) => {
      logger.error('Could not add item to cart: ', { code: 'ADD_TO_CART_ERROR', message: error.message }, error)
      const validationErrors = getClientValidationErrors(error)

      if (validationErrors != null && validationErrors.length > 0) {
        onError(validationErrors.map((e) => {
          return { code: 'ADD_TO_CART_ERROR', message: e.message }
        }))
      } else {
        onError([{ code: 'ADD_TO_CART_ERROR', message: 'There was an error adding this item to the cart. Refresh the page and try again.' }])
      }
    },
    refetchQueries: [GetCartItemCountDocument]
  })

  async function handleAddToCart (): Promise<void> {
    await addToCart()
  }

  return (
    <Button
      loading={addToCartLoading}
      disabled={isLoading}
      color='success'
      onClick={handleAddToCart}
      data-gtm-click-section=''
    >
      Add To Cart
    </Button>
  )
}

export {
  AddToStandardCartButton
}
