import { Fragment, useCallback, useRef, type ReactElement } from 'react'
import clsx from 'clsx'
import { useOnClickOutside, useToggleKey } from '@stuller/shared/util/react-hooks'
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Loading, ShadowContainer } from '@stuller/stullercom/ui'
import { useGetConfigurationSummaryQuery, type LengthValueUom } from '@stuller/stullercom/data-access/apollo-queries'
import type {
  StoneSettingConfigurationDetail,
  MonogramConfigurationDetail,
  EngravingConfigurationDetail,
  ImprintingConfigurationDetail,
  StoneSettingLocationConfiguration,
  HeadConfigurationDetail
} from '../../shared/types'
import { CartItemConfigurationCard } from './CartItemConfigurationCard'
import { CartItemConfigurationProduct } from './CartItemConfigurationProduct'
import { CartItemConfigurationDetail } from './CartItemConfigurationDetail'
import { CartItemConfigurationEngraving } from './CartItemConfigurationEngraving'
import { CartItemConfigurationImprinting } from './CartItemConfigurationImprinting'
import { CartItemConfigurationMonogram } from './CartItemConfigurationMonogram'
import { CartItemConfigurationStoneSetting } from './CartItemConfigurationStoneSetting'
import { CartItemConfigurationHead } from './CartItemConfigurationHead'

/**
 * Component for the summary of the customizations in a cart item
 */
export interface CartItemConfigurationSummaryProps {
  /**
   * The groups of customization items
   */
  configurationId: string
}

type ConfigurationDetail =
  | StoneSettingConfigurationDetail
  | MonogramConfigurationDetail
  | ImprintingConfigurationDetail
  | EngravingConfigurationDetail

/**
 * Function to return the gemstone set string
 */
export function returnGemstoneSetString (stoneSettingConfigurationDetail: StoneSettingConfigurationDetail): string {
  let setCount: number = 0
  for (const stoneSetting of stoneSettingConfigurationDetail.stoneSettings) {
    if (stoneSetting.stone != null) {
      setCount += 1
    }
  }

  return `${setCount} of ${stoneSettingConfigurationDetail.stoneSettings.length} set`
}

/**
 * Function that consolidates the StoneSettingConfigurationDetail duplicate stones and sums their quantities
 */
export function consolidateStoneSettings (stoneSettingConfigurationDetail: StoneSettingConfigurationDetail): StoneSettingLocationConfiguration[] {
  const allStoneSettings = [...stoneSettingConfigurationDetail.stoneSettings]
  const consolidatedStoneSettings: StoneSettingLocationConfiguration[] = []

  function searchForStone (stoneId: string): number {
    return consolidatedStoneSettings.findIndex((stoneSetting) => stoneSetting.stone != null && stoneSetting.stone.__typename === 'Product' && stoneSetting.stone.id === stoneId)
  }

  for (const stoneSetting of allStoneSettings) {
    if (stoneSetting.stone != null && stoneSetting.stone.__typename === 'Product') {
      if (searchForStone(stoneSetting.stone.id) === -1) {
        const newStoneSetting = { ...stoneSetting }
        newStoneSetting.quantity = stoneSetting.quantity
        consolidatedStoneSettings.push(newStoneSetting)
      } else {
        const stoneIndex = searchForStone(stoneSetting.stone.id)
        consolidatedStoneSettings[stoneIndex].quantity += 1
      }
    }
    if (stoneSetting.stone != null && (stoneSetting.stone.__typename === 'CustomerStone' || stoneSetting.stone.__typename === 'SerializedProduct')) {
      consolidatedStoneSettings.push(stoneSetting)
    }
  }

  return consolidatedStoneSettings
}

/**
 * Function to build the label for the unit of measurement
 */
function buildUomStringLabel (uom: LengthValueUom): string {
  switch (uom) {
    case 'INCH':
      return 'Inches'
    case 'MILLIMETER':
      return 'Millimeters'
    default:
      return uom
  }
}

/**
 * Function to build the abbreviation for the unit of measurement
 */
function buildUomStringAbbr (uom: LengthValueUom): string {
  switch (uom) {
    case 'INCH':
      return 'in'
    case 'MILLIMETER':
      return 'mm'
    default:
      return uom
  }
}

/**
 * Render the configuration detail component
 */
function renderConfigurationDetailComponents (detail: ConfigurationDetail): ReactElement | null {
  switch (detail.__typename) {
    case 'StoneSettingConfigurationDetail':
      return <CartItemConfigurationStoneSetting stoneSettingData={detail as StoneSettingConfigurationDetail} />
    case 'MonogramConfigurationDetail':
      return <CartItemConfigurationMonogram monogramData={detail as MonogramConfigurationDetail} />
    case 'ImprintingConfigurationDetail':
      return <CartItemConfigurationImprinting imprintingData={detail as ImprintingConfigurationDetail} />
    case 'EngravingConfigurationDetail':
      return <CartItemConfigurationEngraving engravingData={detail as EngravingConfigurationDetail} />
  }
}

/**
 * Component for the summary of the customizations in a cart item
 */
function CartItemConfigurationSummary ({
  configurationId
}: CartItemConfigurationSummaryProps): ReactElement {
  const ref = useRef(null)
  const [open, handleToggle] = useToggleKey()
  const { data, loading } = useGetConfigurationSummaryQuery({
    variables: {
      configurationByIdId: configurationId
    },
    skip: open !== '1'
  })
  const classNames = clsx(
    'position-relative z-2',
    open === '1' && 'drop-shadow-sm'
  )

  /**
   * Close the configuration summary when the focus leaves the accordion
   */
  const handleOutsideClick = useCallback(() => {
    handleToggle('')
  }, [handleToggle])
  useOnClickOutside(ref, handleOutsideClick)

  /**
   * Give some extra margin for the scroll bar when there's overflow
   */
  const scrollRef = useRef<HTMLDivElement>(null)

  return (
    <Accordion open={open} onToggle={handleToggle} className={classNames} ref={ref}>
      <AccordionItem itemId='1' className='border-0'>
        <AccordionHeader size='sm' tag='h5' buttonClassName='py-0' data-test='customization-summary'>
          Customization Summary
        </AccordionHeader>
        <AccordionBody className='p-0' collapseClassName='w-100 position-absolute z-2'>
          <div className='bg-gray-100 rounded-bottom pb-5 px-4'>
            <ShadowContainer style={{ maxHeight: 350 }} ref={scrollRef}>
              <Loading loading={loading} contentClassName='d-flex flex-column gap-3'>
                {data?.configurationById?.product != null && (
                  <CartItemConfigurationCard
                    title={data?.configurationById?.product.type ?? ''}
                  >
                    <CartItemConfigurationProduct
                      image={data?.configurationById?.product.image}
                      title={data?.configurationById?.product.title}
                      itemNumber={data?.configurationById?.product.itemNumber}
                      id={data?.configurationById?.product.id}
                      isActive={data?.configurationById?.product.isWebActive}
                    />
                  </CartItemConfigurationCard>
                )}
                {data?.configurationById?.details != null && (
                  <>
                    {data?.configurationById?.details.map((detail, index) => (
                      <Fragment key={index}>
                        {detail.__typename === 'RingSizeConfigurationDetail' && (
                          <CartItemConfigurationDetail
                            title='Finger Size'
                            description={`${detail.ringSize.toFixed(2)} (exact size)`}
                          />
                        )}
                        {detail.__typename === 'ChainConfigurationDetail' && (
                          <CartItemConfigurationDetail
                            title={`Chain Length in ${buildUomStringLabel(detail.length.uom)}`}
                            description={`${detail.length.value.toFixed(2)} ${buildUomStringAbbr(detail.length.uom)}`}
                          />
                        )}
                        {detail.__typename === 'MatchingChainConfigurationDetail' && (
                          <CartItemConfigurationCard
                            title='Matching Chain'
                            description='Guaranteed to fit the chain passage'
                          >
                            <CartItemConfigurationProduct
                              image={detail.product.image}
                              title={detail.product.title}
                              itemNumber={detail.product.itemNumber}
                              id={detail.product.id}
                              isActive={detail.product.isWebActive}
                            />
                          </CartItemConfigurationCard>
                        )}
                        {detail.__typename === 'EarringConfigurationDetail' && (
                          <>
                            {detail.back != null && (
                              <CartItemConfigurationCard
                                title='Earring Back'
                              >
                                <CartItemConfigurationProduct
                                  image={detail.back.image}
                                  title={detail.back.title}
                                  itemNumber={detail.back.itemNumber}
                                  id={detail.back.id}
                                  quantity={detail.quantity}
                                  isActive={detail.back.isWebActive}
                                />
                              </CartItemConfigurationCard>
                            )}
                            {detail.post != null && (
                              <CartItemConfigurationCard
                                title='Earring Post'
                              >
                                <CartItemConfigurationProduct
                                  image={detail.post.image}
                                  title={detail.post.title}
                                  itemNumber={detail.post.itemNumber}
                                  id={detail.post.id}
                                  quantity={detail.quantity}
                                  isActive={detail.post.isWebActive}
                                />
                              </CartItemConfigurationCard>
                            )}
                          </>
                        )}
                        {detail.__typename === 'HeadConfigurationDetail' && (
                          <CartItemConfigurationHead headData={detail as HeadConfigurationDetail} />
                        )}
                        {detail.__typename === 'ChainConfigurationDetail' && (
                          <>
                            {detail.clasp != null && (
                              <CartItemConfigurationCard
                                title='Clasps'
                              >
                                <CartItemConfigurationProduct
                                  image={detail.clasp.image}
                                  title={detail.clasp.title}
                                  itemNumber={detail.clasp.itemNumber}
                                  id={detail.clasp.id}
                                  isActive={detail.clasp.isWebActive}
                                />
                              </CartItemConfigurationCard>
                            )}
                          </>
                        )}
                        {detail.__typename === 'SpecialFinishConfigurationDetail' && (
                          <>
                            {detail.finishes != null && (
                              <>
                                {detail.finishes.map((finish, index) => (
                                  <CartItemConfigurationCard
                                    title={`${finish.locationDescription} Special Finish`}
                                    key={index}
                                  >
                                    <CartItemConfigurationProduct
                                      key={index}
                                      image={finish.previewImage}
                                      title={`${finish.specialFinishName} Finish`}
                                      isActive={false}
                                    />
                                  </CartItemConfigurationCard>
                                ))}
                              </>
                            )}
                          </>
                        )}
                        {detail.__typename === 'KitConfigurationDetail' && (
                          <>
                            {detail.products != null && (
                              <CartItemConfigurationCard
                                title='Prototype Samples'
                              >
                                {detail.products.map((product, index) => (
                                  <CartItemConfigurationProduct
                                    key={index}
                                    image={product.image}
                                    title={product.title}
                                    itemNumber={product.itemNumber}
                                    id={product.id}
                                    isActive={product.isWebActive}
                                    paddingBottom={index !== detail.products.length - 1}
                                  />
                                ))}
                              </CartItemConfigurationCard>
                            )}
                          </>
                        )}
                        {renderConfigurationDetailComponents(detail as ConfigurationDetail)}
                      </Fragment>
                    ))}
                  </>
                )}
              </Loading>
            </ShadowContainer>
          </div>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  )
}

export {
  CartItemConfigurationSummary
}
