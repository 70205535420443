/**
 * Prefix used for Stuller events
 */
const STULLER_EVENT_PREFIX = 'stuller-'

/**
 * Stuller event names
 */
enum stullerEvents {
  ping,
  'react-mount',
  'form-builder-submission',
  'track-event',
  'track-universal-event',
  'live-chat-open',
  'cms-content-cleanup'
}

export {
  STULLER_EVENT_PREFIX,
  stullerEvents
}
