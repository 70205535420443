import { type ReactElement } from 'react'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import { useAuth } from '@stuller/stullercom/feat/auth'
import {
  type CartPriceInfoFragment,
  type DiscountCartPriceInfoFragment
} from '@stuller/stullercom/data-access/apollo-queries'
import { Zone } from '@stuller/stullercom/ui'

interface CartItemPriceProp {
  /**
   * The total price object of the cart item
   */
  totalPrice?: CartPriceInfoFragment
  /**
   * Quote price boolean
   */
  quotePrice?: CartPriceInfoFragment
  /**
   * Discount price object
   */
  discountedPrice?: DiscountCartPriceInfoFragment | null
  /**
   * Cart line options
   */
  isForConsignment?: boolean
  /**
   * Consultant mode discount
   */
  consultantDiscount?: number | null
}

/**
 * Component that will show the price of a cart item
 */
function CartItemPrice ({
  totalPrice,
  quotePrice,
  discountedPrice,
  isForConsignment = false,
  consultantDiscount = 0
}: CartItemPriceProp): ReactElement {
  const auth = useAuth()
  const { showcaseSettings, hasRole } = auth
  const hideUSDPrices = showcaseSettings?.hideUSDPrices ?? false
  const priceDecimal = totalPrice?.value
  const [price] = useCurrency(priceDecimal, totalPrice?.exchangeRate)
  const usdDecimalPrice = totalPrice?.valueInUsd
  const [usdPrice] = useCurrency(usdDecimalPrice)
  const currencyCode = totalPrice?.exchangeRate.code.toUpperCase() ?? 'USD'
  const showUsdConversionPrice = currencyCode !== 'USD' && usdDecimalPrice != null && !hideUSDPrices
  const includeQuotePrice = quotePrice != null
  const quotedPriceDecimal = quotePrice?.value
  const [quotedPrice] = useCurrency(quotedPriceDecimal, quotePrice?.exchangeRate)
  const usdDecimalQuotedPrice = quotePrice?.valueInUsd
  const [usdQuotedPrice] = useCurrency(usdDecimalQuotedPrice)
  const discountPriceDecimal = discountedPrice?.value.value
  const [discountPrice] = useCurrency(discountPriceDecimal, discountedPrice?.value.exchangeRate)
  const [originalPriceCurrency] = useCurrency(discountedPrice?.originalPrice?.value, discountedPrice?.originalPrice?.exchangeRate)
  const consultantDiscountDecimal = (priceDecimal ?? 0) * ((consultantDiscount ?? 0) / 100)
  const consultantDiscountPriceDecimal = (priceDecimal ?? 0) - consultantDiscountDecimal
  const [consultantDiscountPrice] = useCurrency(consultantDiscountPriceDecimal, totalPrice?.exchangeRate)

  return (
    <>
      {totalPrice != null && (
        <div className='col-12 mt-2'>
          {includeQuotePrice && (
            <>
              <p className='text-end fw-bold mb-0 text-green-300'>Quote</p>
              <p className='text-end h3 mb-0 text-green-300'>{quotedPrice}</p>
              {showUsdConversionPrice && (
                <div className='d-flex justify-content-end align-items-baseline fw-normal fst-italic fs-6 mt-0 text-gray-600'>
                  {usdQuotedPrice} (USD)
                </div>
              )}
            </>
          )}

          {discountedPrice != null
            ? (
              <>
                <p className='text-end fw mb-0 text'><s>{originalPriceCurrency}</s></p>
                <p className='text-end h3 mb-0 text-red-400'>{price}</p>
                <p className='text-end h5 mb-0 text-red-400'> Save {discountPrice} ({discountedPrice?.percent}% off) </p>
              </>
            )
            : (
              <div className='d-print-none-active-child' data-test='item-price'>
                {!isForConsignment && <p className='text-end h3 mb-0'>{price}</p>}
                {!isForConsignment && showUsdConversionPrice && (
                  <div className='d-flex justify-content-end align-items-baseline fw-normal fst-italic fs-6 mt-0 text-gray-600'>
                    {usdPrice} (USD)
                  </div>
                )}
                {consultantDiscountDecimal > 0 && hasRole('Consultant Mode') && (
                  <Zone color='consultant-mode'>
                    <p className='text-end h5 mb-0'>Discount Price: {consultantDiscountPrice}</p>
                  </Zone>
                )}
                {isForConsignment && (<p className='text-end fw-bold fs-6 mb-0'>For Review</p>)}
              </div>
            )}
        </div>
      )}
    </>
  )
}

export {
  CartItemPrice
}
