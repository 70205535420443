import { type ReactElement } from 'react'
import { type ShowcasePricingSummaryComponent } from './types'
import { useCurrency, useToggle } from '@stuller/shared/util/react-hooks'
import { Button, Collapse } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'

interface ShowcasePricingSummaryComponentAreaProps {
  component: ShowcasePricingSummaryComponent
  className?: string
}

function ShowcasePricingSummaryComponentArea ({ component, className }: ShowcasePricingSummaryComponentAreaProps): ReactElement | null {
  const [mathSectionOpen, toggleMathSectionOpen] = useToggle()

  const basePriceText = (component.quantity != null && component.quantity > 1)
    ? `Base Price (for ${component.quantity})`
    : 'Base Price'

  const [baseTotal] = useCurrency(component.baseTotal?.value, component?.baseTotal?.exchangeRate)
  const [markedUpTotal] = useCurrency(component.markedUpTotal?.value, component.markedUpTotal?.exchangeRate)

  return (
    <div className={className}>
      <div className='row align-items-center justify-content-end mb-1'>
        <div className='col-12 col-sm-6 ms-0'>
          <Button color='anchor-dark' className='fw-normal text-decoration-none' onClick={toggleMathSectionOpen}>
            <FontAwesomeIcon className='fs-4 text-primary me-2' icon={mathSectionOpen ? fas.faCircleMinus : fas.faCirclePlus} />
            {component.title}
          </Button>
        </div>
        <div className='col-3 text-end'>{baseTotal}</div>
        <div className='col-3 text-end'>{markedUpTotal}</div>
      </div>
      <Collapse isOpen={mathSectionOpen}>
        <div className='bg-gray-100 rounded-3 container p-4 mb-2'>
          <ShowcasePricingSummaryComponentMarkupRow
            title={basePriceText}
            value={component.basePrice?.value}
            exchangeRate={component.basePrice?.exchangeRate}
          />
          <div className='row'>
            <div className='col-8'>{component.markupDescription}</div>
            <div className='col-4 d-flex justify-content-between'>
              <div>x</div>
              <div>{component.markupMultiplier}</div>
            </div>
          </div>
          <div className='row justify-content-end'>
            <div className='col-4'>
              <hr />
            </div>
          </div>
          <ShowcasePricingSummaryComponentMarkupRow
            title='Base x Markup Price'
            value={component.markupPrice?.value}
            exchangeRate={component.markupPrice?.exchangeRate}
          />
          <ShowcasePricingSummaryComponentMarkupRow
            title='Minimum Price'
            value={component.minimumPrice?.value}
            exchangeRate={component.minimumPrice?.exchangeRate}
          />
          <ShowcasePricingSummaryComponentMarkupRow
            title='Flat Price'
            value={component.flatPrice?.value}
            exchangeRate={component.flatPrice?.exchangeRate}
          />
          <ShowcasePricingSummaryComponentMarkupRow
            title='Setting Charge'
            value={component.baseStoneSettingCharge?.value}
            exchangeRate={component.baseStoneSettingCharge?.exchangeRate}
          />
          <hr />
          <ShowcasePricingSummaryComponentMarkupRow
            className='fw-bold'
            title='Total'
            value={component.markedUpTotal?.value}
            exchangeRate={component.markedUpTotal?.exchangeRate}
          />
        </div>
      </Collapse>
    </div>
  )
}

interface ShowcasePricingSummaryComponentMarkupRowProps {
  title: string
  value: number | undefined
  exchangeRate: { code: string, culture: string } | undefined
  className?: string
}

function ShowcasePricingSummaryComponentMarkupRow ({ title, value, exchangeRate, className }: ShowcasePricingSummaryComponentMarkupRowProps): ReactElement | null {
  const [currencyValue] = useCurrency(value, exchangeRate)

  if (value == null || value === 0 || exchangeRate == null) {
    return null
  }

  const classes = clsx('row', className)

  return (
    <div className={classes}>
      <div className='col-8'>{title}</div>
      <div className='col-4'>
        <div className='text-end'>{currencyValue}</div>
      </div>
    </div>
  )
}

export { ShowcasePricingSummaryComponentArea }
