import { type Auth } from '@stuller/stullercom/feat/auth'
import { trackEvent } from '../track'
import type { CartLineTracking, CartTracking, GtmItemTracking } from './types'

/**
 * Check if the item is trackable
 */
function isTrackableItem (typename: string | undefined): boolean {
  return typename === 'Product' || typename === 'SerializedProduct' || typename === 'Configuration'
}

/**
 * Helper Method to map GraphQL cart data to ItemTracking object
 */
function cartLineTrackingMapper (cartLine: CartLineTracking, auth: Auth, quantityDiff?: number): GtmItemTracking | null {
  if (!isTrackableItem(cartLine.item.__typename)) {
    return null
  }
  const { __typename: typename } = cartLine.item
  const { isShowcase } = auth

  const price = isShowcase
    ? null
    : cartLine.pricePerUnit?.discount?.originalPrice.valueInUsd ??
      cartLine.pricePerUnit?.total.valueInUsd ??
      null
  const discount = price != null ? cartLine.pricePerUnit?.discount?.value.valueInUsd ?? 0 : null

  return {
    item_id: cartLine.productGroup?.code ?? cartLine.item.defaultProductGroup?.code ?? cartLine.item.series,
    item_variant: cartLine.item.itemNumber,
    item_name: cartLine.item.title,
    item_brand: cartLine.item.brand ?? null,
    item_category: cartLine.item.merchandiseHierarchy?.segment2 ?? null,
    item_category2: cartLine.item.merchandiseHierarchy?.segment3 ?? null,
    item_category3: cartLine.item.merchandiseHierarchy?.segment4 ?? null,
    item_category4: cartLine.item.merchandiseHierarchy?.segment5 ?? null,
    item_category5: cartLine.item.merchandiseHierarchy?.segment6 ?? null,
    currency: price != null ? 'USD' : null,
    discount,
    price,
    quantity: quantityDiff ?? cartLine.options.quantity,
    config_id: typename === 'Configuration' ? Number(cartLine.item.id) : null,
    serial_number: typename === 'SerializedProduct' ? Number(cartLine.item.id) : null,
    coupon: null,
    index: cartLine.trackingSource?.index ?? null,
    item_list_id: cartLine.trackingSource?.source ?? null,
    item_list_name: null
  }
}

/**
 * Track view cart event
 */
function trackCartView (cart: CartTracking | null | undefined, auth: Auth): void {
  const value = auth.isShowcase ? null : cart?.price?.subtotal.valueInUsd ?? null
  let cartLines: CartLineTracking[] = []

  if (cart != null) {
    if ('shipments' in cart) {
      cartLines = cart.shipments.map((shipment) => shipment.lines).flat()
    } else {
      cartLines = cart.items
    }
  }

  trackEvent('view_cart', {
    value,
    currency: value != null ? 'USD' : null,
    items: cartLines.map((x) => cartLineTrackingMapper(x, auth))
      .filter((i) => i != null) ?? []
  })
}

/**
 * Track remove all from cart event
 */
function trackCartRemoveAll (cart: CartTracking, auth: Auth): void {
  const value = auth.isShowcase ? null : cart.price?.subtotal.valueInUsd ?? null
  let cartLines: CartLineTracking[] = []

  if (cart != null) {
    if ('shipments' in cart) {
      cartLines = cart.shipments.map((shipment) => shipment.lines).flat()
    } else {
      cartLines = cart.items
    }
  }

  trackEvent('remove_from_cart', {
    value,
    currency: value != null ? 'USD' : null,
    items: cartLines.map((x) => cartLineTrackingMapper(x, auth))
      .filter((i) => i != null) ?? []
  })
}

/**
 * Track add all items from a cart list item to cart event
 */
function trackCartListItemAddToCart (cart: CartTracking, auth: Auth): void {
  const value = auth.isShowcase ? null : cart.price?.subtotal.valueInUsd ?? null
  let cartLines: CartLineTracking[] = []

  if (cart != null) {
    if ('shipments' in cart) {
      cartLines = cart.shipments.map((shipment) => shipment.lines).flat()
    } else {
      cartLines = cart.items
    }
  }

  trackEvent('add_to_cart', {
    value,
    currency: value != null ? 'USD' : null,
    items: cartLines.map((x) => cartLineTrackingMapper(x, auth))
      .filter((i) => i != null) ?? []
  })
}

/**
 * Track cart line add to cart event
 */
function trackCartLineAddToCart (cartLine: CartLineTracking, auth: Auth, quantityDiff?: number): void {
  const value = auth.isShowcase ? null : cartLine.price?.total.valueInUsd ?? null

  trackEvent('add_to_cart', {
    value,
    currency: value != null ? 'USD' : null,
    items: [cartLineTrackingMapper(cartLine, auth, quantityDiff)]
      .filter((i) => i != null)
  })
}

/**
 * Track cart line remove from cart event
 */
function trackCartLineRemoveFromCart (cartLine: CartLineTracking, auth: Auth, quantityDiff?: number): void {
  const value = auth.isShowcase ? null : cartLine.price?.total.valueInUsd ?? null

  trackEvent('remove_from_cart', {
    value: cartLine.price?.total.valueInUsd,
    currency: value != null ? 'USD' : null,
    items: [cartLineTrackingMapper(cartLine, auth, quantityDiff)]
      .filter((i) => i != null)
  })
}

/**
 * Track cart line add to wishlist event
*/
function trackCartLineAddToWishList (cartLine: CartLineTracking, auth: Auth): void {
  const item = cartLineTrackingMapper(cartLine, auth)
  trackEvent('add_to_wishlist', {
    items: [item]
      .filter((i) => i != null)
  })
}

export {
  cartLineTrackingMapper,
  trackCartView,
  trackCartRemoveAll,
  trackCartListItemAddToCart,
  trackCartLineAddToCart,
  trackCartLineRemoveFromCart,
  trackCartLineAddToWishList
}
