import { siteConfig } from '@stuller/stullercom/util/site-config'

/**
 * Given a jeweler showcase subdomain, return the URL for the jeweler showcase
 */
function getJewelerShowcaseUrl (jewelerShowcaseSubdomain: string | null | undefined): string | null {
  const jewelerShowcaseDemoUrl = siteConfig.NEXT_PUBLIC_JEWELER_SHOWCASE_HOST

  return jewelerShowcaseDemoUrl?.replace(':subdomain', jewelerShowcaseSubdomain ?? '') ?? null
}

export {
  getJewelerShowcaseUrl
}
