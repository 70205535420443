import { memo, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@stuller/stullercom/ui'
import {
  useGetAuthExpiredSignatureQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { useToggle } from '@stuller/shared/util/react-hooks'

const POLL_INTERVAL = 120 * 1000
const SET_TIMEOUT = 120 * 1000

/**
 * Polling for the authUser signature to keep user information secure
 */
const AuthExpired = memo(() => {
  const [modalOpen, toggleModalOpen] = useToggle()
  const [currentSignature, setCurrentSignature] = useState<string | null | undefined>(null)
  const { stopPolling } = useGetAuthExpiredSignatureQuery({
    pollInterval: POLL_INTERVAL,
    onCompleted (data) {
      if (currentSignature === null) {
        setCurrentSignature(data?.authUser?.signature)
      } else if (!modalOpen && currentSignature !== data?.authUser?.signature) {
        toggleModalOpen()
        stopPolling()
        setTimeout(() => {
          handleContinue()
        }, SET_TIMEOUT)
      }
    }
  })

  function handleContinue (): void {
    window.location.reload()
  }

  return (
    <Modal
      isOpen={modalOpen}
      onToggle={handleContinue}
      backdrop='static'
    >
      <ModalHeader close={false}>
        User Info Changed
      </ModalHeader>
      <ModalBody>
        Please refresh the page to continue.
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={handleContinue}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  )
})
AuthExpired.displayName = 'AuthExpired'

export {
  AuthExpired
}
