import { createContext } from 'react'
import type { CartArea, CartScheduledOrder } from './types'
import { type UseToggleKeyState } from '@stuller/shared/util/react-hooks'
import {
  useCartPageCartItemDeleteCartLineWithCartListItemMutation,
  type useCartPageCartItemDeleteCartLineWithShipmentsMutation,
  useCartPageCartItemUpdateCartLineWithCartListItemMutation,
  type useCartPageCartItemUpdateCartLineWithShipmentsMutation
} from '@stuller/stullercom/data-access/apollo-queries'

export interface CartContextType {
  /**
   * The cart area
   */
  cartArea: CartArea
  /**
   * The scheduled order info for the viewer
   */
  scheduledOrder?: CartScheduledOrder
  /**
   * Indicates which shipments are open
   */
  shipmentsOpen?: UseToggleKeyState[0]
  /**
   * Toggle the shipments open state
   */
  handleToggleShipments?: UseToggleKeyState[1]
  /**
   * Set the shipments open state
   */
  setOpenShipments?: UseToggleKeyState[2]
  /**
   * The mutation to use to update a cart line
   */
  useCartContextUpdateCartLineMutation: typeof useCartPageCartItemUpdateCartLineWithCartListItemMutation
    | typeof useCartPageCartItemUpdateCartLineWithShipmentsMutation
  /**
   * The mutation to use to delete a cart line
   */
  useCartContextDeleteCartLineMutation: typeof useCartPageCartItemDeleteCartLineWithCartListItemMutation
    | typeof useCartPageCartItemDeleteCartLineWithShipmentsMutation
}

const CartContext = createContext<CartContextType>({
  cartArea: 'cart-page',
  useCartContextUpdateCartLineMutation: useCartPageCartItemUpdateCartLineWithCartListItemMutation,
  useCartContextDeleteCartLineMutation: useCartPageCartItemDeleteCartLineWithCartListItemMutation
})

export {
  CartContext
}
