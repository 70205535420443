import { type ReactElement } from 'react'
import { Zone } from '@stuller/stullercom/ui'
import { isStringEmpty } from '@stuller/shared/util/core'
import { useCartContext } from '../shared/useCartContext'
import { type BagAndTagOptions } from '../shared/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

interface CartItemDOTDescriptionProps {
  /**
   * Description to be shown on Cart Item when in DOT cart mode
   */
  description?: string | null
  /**
   * Whether to show the description for all bag items
   */
  bagAndTagOptions?: BagAndTagOptions | null
}

/**
 * Description to be shown on Cart Item when in DOT cart mode
 */
function CartItemDOTDescription ({
  description,
  bagAndTagOptions
}: CartItemDOTDescriptionProps): ReactElement | null {
  const { hasRole } = useAuth()
  const { cartArea } = useCartContext()
  if ((cartArea !== 'dot-carts-page' &&
    cartArea !== 'cart-search-page' &&
    cartArea !== 'cart-page' &&
    cartArea !== 'saved-items-page') || isStringEmpty(description) || !hasRole('Consultant Mode')) {
    return null
  }

  return (
    <Zone color='consultant-mode' className='mt-3'>
      <div className='p-4'>
        <p className='fw-bold mb-0'>Full Description</p>
        <p className='mb-0'>{description}</p>
        {bagAndTagOptions?.tagBags != null && (
          <>
            <p className='fw-bold mb-0'>Tag All Bag Items</p>
            <p className='mb-0'>
              <span className='fw-bold'>Items Per Bag (Quantity): </span>
              {bagAndTagOptions.quantityPerBag}
            </p>
          </>
        )}
      </div>
    </Zone>
  )
}

export {
  CartItemDOTDescription
}
