import { useRef, useState, type ReactElement } from 'react'
import { Button } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fak, far, fas } from '@awesome.me/kit-3dbd93c064/icons'
import { type PdpImageCarouselVideo360InfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { useDrag } from '@use-gesture/react'
import { usePdpImageCarouselContext } from './context/usePdpImageCarouselContext'

interface PdpImageCarouselVideo360PlayerProps {
  /**
   * The video to render
   */
  video: PdpImageCarouselVideo360InfoFragment
  /**
   * Whether the PDP is in the modal or not
   */
  isInModal?: boolean
}

// Width of pdp videos
const pdpVideoSize = 720

/**
 * Helper to handle rewinding past the beginning of the video
 */
function getNewTime (currentTime: number, duration: number, step: number): number {
  const newTime = currentTime + step
  if (newTime < 0) {
    // If the new time is less than 0, return the duration minus the absolute value of the new time to rewind past the beginning
    return duration + newTime
  }

  return newTime
}

/**
 * Component to render a 360-degree video player
 */
function PdpImageCarouselVideo360Player ({ video, isInModal = false }: PdpImageCarouselVideo360PlayerProps): ReactElement {
  const [playPauseIcon, setPlayPauseIcon] = useState(fas.faPause)
  const videoRef = useRef<HTMLVideoElement>(null)
  const dragRef = useRef<HTMLDivElement>(null)

  const { onToggleModal } = usePdpImageCarouselContext()

  // Get the video sources with width of 720. This can be abstracted in a general component later if/when we use it elsewhere
  // Different width options are: 284, 382, 720
  const pdpSizeSources = video.sources?.filter((source) => ((source?.width ?? 0) === pdpVideoSize))

  /**
   * Pauses the video
   */
  function handlePauseVideo (): void {
    if (videoRef.current != null) {
      videoRef.current.pause()
      setPlayPauseIcon(fas.faPlay)
    }
  }

  /**
   * Plays the video
   */
  function handlePlayVideo (): void {
    if (videoRef.current != null) {
      void videoRef.current.play()
      setPlayPauseIcon(fas.faPause)
    }
  }

  /**
   * Handles toggling between playing and pausing the video
   */
  function handlePauseOrPlayVideo (): void {
    if (videoRef.current != null) {
      if (videoRef.current.paused) {
        handlePlayVideo()
      } else {
        handlePauseVideo()
      }
    }
  }

  /**
   * Pauses the video if it's not already paused
   */
  function pauseIfNotPaused (): void {
    if (videoRef.current != null && !videoRef.current.paused) {
      handlePauseVideo()
    }
  }

  /**
   * Resets the video to the start and pauses it if it's not already paused
   */
  function handleCenterVideo (): void {
    if (videoRef.current != null) {
      pauseIfNotPaused()
      videoRef.current.currentTime = 0 // Reset to start
    }
  }

  /**
   * Advances the video by two frames
   */
  function handleAdvanceOneFrame (): void {
    if (videoRef.current != null) {
      pauseIfNotPaused()
      const step = 2 * (1 / 30) // Advance by two frames (30 FPS)
      const newTime = getNewTime(videoRef.current.currentTime, videoRef.current.duration, step)
      videoRef.current.currentTime = newTime
    }
  }

  /**
   * Rewinds the video by two frames
   */
  function handleRewindOneFrame (): void {
    if (videoRef.current != null) {
      pauseIfNotPaused()
      const step = -2 * (1 / 30) // Rewind by two frames (30 FPS)
      const newTime = getNewTime(videoRef.current.currentTime, videoRef.current.duration, step)
      videoRef.current.currentTime = newTime
    }
  }

  /**
   * Toggles the modal
   */
  function handleToggleModal (): void {
    // Don't need to pause the video if its in the modal since it will be closed
    if (!isInModal) {
      pauseIfNotPaused()
    }
    onToggleModal?.()
  }

  /**
   * Handle scrubbing the video by dragging
   */
  useDrag(({ delta: [x] }) => {
    if (x != null && x !== 0 && videoRef.current != null) {
      pauseIfNotPaused()
      const step = -x / 100 // Divide by 100 to make the drag less sensitive
      const newTime = getNewTime(videoRef.current.currentTime, videoRef.current.duration, step)
      videoRef.current.currentTime = newTime
    }
  }, {
    target: dragRef,
    filterTaps: true,
    preventDefault: true,
    eventOptions: { capture: true, passive: false }
  })

  return (
    <div className='row gy-5'>
      <div className='col-12'>
        <div
          ref={dragRef}
          className='mx-auto cursor-ew-resize'
          style={{ maxWidth: `${pdpVideoSize}px`, touchAction: 'none' }}
        >
          <video
            className='w-100 rounded-3'
            ref={videoRef}
            autoPlay
            muted
            loop
            playsInline
            preload='auto'
          >
            {pdpSizeSources?.map((source) => source?.url != null && (
              <source src={source.url} type={source?.type ?? undefined} key={source.url} />
            ))}
          </video>
        </div>
      </div>
      <div className='col-12 text-center text-gray-500'>
        <FontAwesomeIcon icon={fak.fa360} className='me-2' />
        Click & Drag to Rotate
      </div>
      <div className='col-12'>
        <div className='d-flex gap-2'>
          <Button
            className='col'
            style={{ minWidth: 0, minHeight: '50px' }}
            onClick={handleCenterVideo}
          >
            <FontAwesomeIcon icon={far.faArrowsToLine} size='xl' rotation={90} />
            <div className='fs-6 lh-xs'>Center</div>
          </Button>
          <Button
            color='secondary'
            outline
            className='col'
            style={{ minWidth: 0, minHeight: '50px' }}
            onClick={handleRewindOneFrame}
          >
            <FontAwesomeIcon icon={fas.faBackwardStep} size='xl' />
          </Button>
          <Button
            color='secondary'
            outline
            className='col'
            style={{ minWidth: 0, minHeight: '50px' }}
            onClick={handlePauseOrPlayVideo}
          >
            <FontAwesomeIcon icon={playPauseIcon} size='xl' />
          </Button>
          <Button
            color='secondary'
            outline
            className='col'
            style={{ minWidth: 0, minHeight: '50px' }}
            onClick={handleAdvanceOneFrame}
          >
            <FontAwesomeIcon icon={fas.faForwardStep} size='xl' />
          </Button>
          <Button
            className='col'
            style={{ minWidth: 0, minHeight: '50px' }}
            onClick={handleToggleModal}
          >
            {isInModal
              ? (
                <>
                  <FontAwesomeIcon icon={fas.faClose} size='xl' />
                  <div className='fs-6 lh-xs'>Close</div>

                </>
              )
              : (
                <>
                  <FontAwesomeIcon icon={far.faMagnifyingGlassPlus} size='lg' />
                  <div className='fs-6 lh-xs'>Zoom</div>
                </>
              )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export {
  PdpImageCarouselVideo360Player
}
