import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if web user is authenticated or not
 */
function useAuthenticatedTargeting (targeting: TargetingMap['AUTHENTICATED']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const auth = useAuth()
  const { authUser } = auth
  const isAuthenticated = targetingPreviewer?.isAuthenticated ?? authUser != null

  return targeting.targetValue ? isAuthenticated : !isAuthenticated
}

export { useAuthenticatedTargeting }
