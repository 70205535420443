import { createContext } from 'react'
import {
  type GetHeaderUtilityNavMenuCartQueryHookResult,
  type GetCartItemCountQueryHookResult,
  type HeaderUtilityNavMenuCartCancelScheduledOrderMutationHookResult
} from '@stuller/stullercom/data-access/apollo-queries'

export interface CartPopoverContextType {
  /**
   * Result of the cart query
   */
  cart: GetHeaderUtilityNavMenuCartQueryHookResult
  /**
   * Result of the cart item count query
   */
  cartItemCount: GetCartItemCountQueryHookResult
  /**
   * Mutation function to cancel the scheduled order
   */
  cancelScheduledOrder: HeaderUtilityNavMenuCartCancelScheduledOrderMutationHookResult[0]
  /**
   * Result of the cancel scheduled order mutation
   */
  scheduledOrderCancel: HeaderUtilityNavMenuCartCancelScheduledOrderMutationHookResult[1]
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const CartPopoverContext = createContext<CartPopoverContextType>({} as CartPopoverContextType)

export {
  CartPopoverContext
}
