import { type ReactElement } from 'react'
import { type JewelerShowcaseCallbackRequestInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { Button, Card } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

interface JewelerShowcaseCustomerContactInformationProps {
  /**
   * The jeweler showcase callback request info to display
   */
  jewelerShowcaseCallbackRequestInfo: JewelerShowcaseCallbackRequestInfoFragment
  /**
   * Callback to print the quote
   */
  onPrint: (forCustomer: boolean) => void
}

/**
 * This component is a customer contact information section for the jeweler showcase
 */
function JewelerShowcaseCustomerContactInformation ({
  jewelerShowcaseCallbackRequestInfo,
  onPrint
}: JewelerShowcaseCustomerContactInformationProps): ReactElement {
  return (
    <Card className='p-5 mb-3 border-gray-300 rounded-3 d-print-none bg-gray-100'>
      <h3 className='fw-bold mb-2 mb-md-5'>Customer Contact Information</h3>
      <div className='row'>
        <div className='col-12 col-md-auto mb-3 mb-md-0'>
          <p className='mb-0 fw-bold d-inline d-md-block'>Name:</p>
          <p className='fs-5 d-inline d-md-block ms-2 ms-md-0 mb-0'>{jewelerShowcaseCallbackRequestInfo.firstName} {jewelerShowcaseCallbackRequestInfo.lastName}</p>
        </div>
        <div className='col-12 col-md-auto mb-3 mb-md-0'>
          <p className='mb-0 fw-bold d-inline d-md-block'>Phone:</p>
          <a className='fs-5 d-inline d-md-block ms-2 ms-md-0 mb-0' href={`tel:${jewelerShowcaseCallbackRequestInfo.phoneNumber}`}>
            {jewelerShowcaseCallbackRequestInfo.phoneNumber}
          </a>
        </div>
        <div className='col-12 col-md-auto mb-3 mb-md-0'>
          <p className='mb-0 fw-bold d-inline d-md-block'>Email:</p>
          <a className='fs-5 d-inline d-md-block ms-2 ms-md-0 mb-0' href={`mailto:${jewelerShowcaseCallbackRequestInfo.emailAddress}`}>
            {jewelerShowcaseCallbackRequestInfo.emailAddress}
          </a>
        </div>
        <div className='col-12 col-md-auto mb-3 mb-md-0'>
          <p className='mb-0 fw-bold d-inline d-md-block'>Message:</p>
          <p className='fs-5 d-inline d-md-block ms-2 ms-md-0 mb-0'>{jewelerShowcaseCallbackRequestInfo.messageToJeweler}</p>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-auto'>
          <Button color='secondary' outline='fill' onClick={() => { onPrint(false) }}>
            <FontAwesomeIcon icon={fas.faPrint} className='me-2' />
            Print Quote for Me
          </Button>
        </div>
        <div className='col-auto'>
          <Button color='secondary' outline='fill' onClick={() => { onPrint(true) }}>
            <FontAwesomeIcon icon={fas.faUser} className='me-2' />
            Print Quote for Customer
          </Button>
        </div>
      </div>
    </Card>
  )
}

export {
  JewelerShowcaseCustomerContactInformation
}
