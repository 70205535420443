import { type AppContext } from 'next/app'
import { type GetAuth } from '@stuller/stullercom/feat/auth'
import { type CustomAppProps } from './types'
import { isUnauthenticatedPath } from './isUnauthenticatedPath'
import type { StatusCodeProps } from '@stuller/stullercom/feat/layout-context'

const AUTHENTICATED_SHOWCASE_LOGIN_PATH = '/showcaselogin'

/**
 * Redirects to showcaselogin page if jeweler showcase site requiring auth and user is not authenticated
 */
function getAuthenticatedShowcaseRedirect (appContext: AppContext, auth: GetAuth, showcaseSettings?: CustomAppProps['showcaseSettings']): StatusCodeProps | null {
  const { ctx } = appContext
  const { isJewelerShowcase, isJewelerShowcasePunchout, authUser } = auth
  const isAuthenticatedShowcase = isJewelerShowcase && !isJewelerShowcasePunchout && showcaseSettings?.requiresAuthentication === true

  // If isAuthenticatedShowcase & no auth cookie & path should not be ignored
  if (isAuthenticatedShowcase && authUser == null && !isUnauthenticatedPath(ctx.asPath)) {
    return { statusCode: 302, location: AUTHENTICATED_SHOWCASE_LOGIN_PATH }
  }

  return null
}

export {
  getAuthenticatedShowcaseRedirect,
  AUTHENTICATED_SHOWCASE_LOGIN_PATH
}
