import { type ReactElement } from 'react'
import { AddToCartButton } from './AddToCartButton'
import {
  type AddToCartSectionInfoFragment,
  useGetCanAddToFavoritesQuery,
  type CartLineMessageInfoFragment,
  AddToCartType,
  useGetAddToCartUserShowcaseSettingsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { AddToCartMatch } from './AddToCartMatch'
import { AddToCartSpecialInstructions } from './AddToCartSpecialInstructions'
import { AddToCartCustomerNotes } from './AddToCartCustomerNotes'
import { AddToCartConsignmentCheckbox } from './AddToCartConsignmentCheckbox'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { AddToCartQuantityInput } from './AddToCartQuantityInput'
import { useToast } from '@stuller/stullercom/ui'
import { AddToCartDotSection } from './AddToCartDotSection'
import { AddToCartPricingSummary } from './pricing-summary/AddToCartPricingSummary'
import { AddToCartFavoriteButton } from './AddToCartFavoriteButton'
import { AddToCartSuccessToast } from './AddToCartSuccessToast'
import { AddToCartErrorToast } from './AddToCartErrorToast'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'

interface AddToCartSectionInnerProps {
  /**
   * Boolean to determine if the serialized product is on consignment
   */
  isOnConsignment: boolean
  /**
   * All of the data needed to produce the entire add to cart section
   */
  data: AddToCartSectionInfoFragment
  /**
   * Boolean to determine if the data is loading
   */
  dataLoading?: boolean
}

function AddToCartSectionInner ({
  data,
  isOnConsignment,
  dataLoading
}: AddToCartSectionInnerProps): ReactElement {
  const { authUser, hasRole, isShowcase } = useAuth()
  const { addToCartType, itemType } = useAddToCartSectionContext()
  const {
    canMatch,
    canOrderOnConsignment,
    soldByDescription,
    maximumQuantity,
    canAddToCart,
    image,
    title
  } = data
  const { data: canAddToFavoritesData } = useGetCanAddToFavoritesQuery()
  const toast = useToast()
  const canAddToFavorites = canAddToFavoritesData?.viewerPermissions.canAddToFavorites ?? false
  const { data: showcaseData } = useGetAddToCartUserShowcaseSettingsQuery()
  const showcaseSettings = showcaseData?.viewer?.showcaseSettings
  const showcaseSettingsSetup = showcaseSettings?.setup
  const showcaseSettingsComplete = showcaseSettingsSetup?.markupsComplete === true &&
    showcaseSettingsSetup.displayComplete
  const showShowcasePricingSummary = !isShowcase && showcaseSettingsComplete

  /**
   * function to handle showing the error toast when adding to favorites fails
   */
  function handleGenericCartError (error: CartLineMessageInfoFragment[]): void {
    for (const message of error) {
      toast(message.message, { color: 'danger' })
    }
  }

  /**
   * function to handle showing the error toast when adding to cart fails
   */
  function handleAddToCartError (errors: CartLineMessageInfoFragment[]): void {
    const errorMessages = errors.map((error) => error.message)
    toast(<AddToCartErrorToast messages={errorMessages} />, { color: 'danger' })
  }

  /**
   * function to handle showing the successful add to cart toast
   */
  function handleAddToCartSuccess (): void {
    toast(<AddToCartSuccessToast image={image} title={title} />, { color: 'success', icon: false })
  }

  // individual booleans for showing/hiding each area of the add to cart section
  const itemCanBeAddedToCart = canAddToCart ?? false
  const canShowQuantityInput = itemCanBeAddedToCart && addToCartType !== AddToCartType.QuoteId
  const canShowMatchCheckbox = itemCanBeAddedToCart && canMatch
  const canShowConsignmentCheckbox = itemCanBeAddedToCart && canOrderOnConsignment
  const canShowNotesInputs = itemCanBeAddedToCart
  const canShowAddToCartButton = itemCanBeAddedToCart
  const canShowPricingSummary = itemCanBeAddedToCart && showShowcasePricingSummary
  const canShowAddToFavorites = canAddToFavorites && (isOnConsignment || itemCanBeAddedToCart)

  return (
    <>
      {canShowQuantityInput && (
        <AddToCartQuantityInput
          soldByDescription={soldByDescription}
          disabled={maximumQuantity != null && maximumQuantity === 1}
        />
      )}
      {hasRole('Consultant Mode') && (
        <AddToCartDotSection addToCartSectionInfo={data} />
      )}
      {(canShowMatchCheckbox || canShowConsignmentCheckbox) && (
        <div className='d-flex gap-3 flex-column'>
          {canShowMatchCheckbox && <AddToCartMatch />}
          {canShowConsignmentCheckbox && <AddToCartConsignmentCheckbox />}
        </div>
      )}
      {canShowNotesInputs && (
        <div className='d-flex gap-3 flex-column'>
          <AddToCartSpecialInstructions />
          <AddToCartCustomerNotes />
        </div>
      )}
      {canShowAddToCartButton && (
        <AddToCartButton
          onAddToCartError={handleAddToCartError}
          onQuoteIdError={handleGenericCartError}
          onSuccess={handleAddToCartSuccess}
          isLoading={dataLoading ?? false}
          addToCartSectionInfo={data}
        />
      )}
      {canShowPricingSummary && (
        <AddToCartPricingSummary
          id={data.id}
          itemType={itemType}
          shipToAcountNumber={authUser?.user?.defaultActiveShipToAccount?.id}
        />
      )}
      {canShowAddToFavorites && (
        <>
          <hr className='m-0' />
          <AddToCartFavoriteButton data={data} onError={handleGenericCartError} />
        </>
      )}
    </>
  )
}

export {
  AddToCartSectionInner
}
