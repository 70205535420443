import {
  type FormEvent,
  type KeyboardEvent,
  type ReactElement,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { type SelectInstance, type ActionMeta, type InputActionMeta, type OptionProps } from 'react-select'
import {
  Button,
  Form,
  InputGroup,
  SelectDropdown,
  selectDropdownComponents,
  Spinner
} from '@stuller/stullercom/ui'
import {
  useGetHeaderSearchNavSearchSuggestionsQuery,
  type GetHeaderSearchNavSearchSuggestionsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { trackEvent } from '@stuller/stullercom/feat/google-tag-manager'
import { useDebounce } from '@stuller/shared/util/react-hooks'

type SearchSuggestion = GetHeaderSearchNavSearchSuggestionsQuery['searchSuggestions'][number]

// A fake id to use for the input search suggestion so we can filter it out
const INPUT_SEARCH_SUGGESTION_ID = '__input_search_suggestion_id__'

/**
 * Search suggestion option
 */
function Option ({ children, ...props }: OptionProps<SearchSuggestion>): ReactElement | null {
  const description = useMemo(() => {
    return props.data.description.replace(new RegExp(`(${props.selectProps.inputValue})`, 'gi'), '<span class=\'fw-bold\'>$1</span>')
  }, [props.data.description, props.selectProps.inputValue])

  if (props.data.id === INPUT_SEARCH_SUGGESTION_ID) {
    return null
  }

  return (
    <selectDropdownComponents.Option {...props} key={props.data.id}>
      <div className='row align-items-center'>
        {props.data.imageUrl != null && (
          <div className='col-auto'>
            <img
              src={`${props.data.imageUrl}&fmt=smart-alpha`}
              className='border'
              alt={props.data.description}
            />
          </div>
        )}
        <div className='col' dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </selectDropdownComponents.Option>
  )
}

/**
 * Search component
 */
function Search (): ReactElement {
  const { hasRole, isShowcase, isEmbeddedJewelerShowcase } = useAuth()
  const ref = useRef<SelectInstance>(null)
  const [input, setInput] = useState('')
  const [value, setValue] = useState<SearchSuggestion | null>(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [redirecting, setRedirecting] = useState(false)
  const inputDebounce = useDebounce(input, 500)
  const { previousData, data = previousData } = useGetHeaderSearchNavSearchSuggestionsQuery({
    skip: inputDebounce.length < 3 || redirecting,
    variables: { term: inputDebounce },
    fetchPolicy: 'cache-and-network'
  })
  const options = useMemo<SearchSuggestion[]>(() => {
    const suggestions = [...(data?.searchSuggestions ?? [])]

    // Add the input as a suggestion
    if (suggestions.length > 0) {
      suggestions.unshift({ id: INPUT_SEARCH_SUGGESTION_ID, description: input })
    }

    return suggestions
  }, [input, data])
  const router = useRouter()

  useHotkeys('/', (event) => {
    event.preventDefault()
    ref.current?.focusInput()
  }, { enabled: hasRole('Consultant Mode') })

  /**
   * Reset search when route changes if loading
   */
  useEffect(() => {
    setRedirecting(false)
    setInput('')
    setValue(null)
  }, [router.asPath])

  /**
   * Performs the search
   */
  async function handleSearch (term: string | SearchSuggestion): Promise<void> {
    if (redirecting) {
      return
    }

    // Handle if term is a string or search suggestion
    setRedirecting(true)
    if (typeof term === 'string') {
      await router.push(`/search/results?query=${encodeURIComponent(term)}`)
    } else {
      if (term.clickLoggingUrl != null) {
        trackEvent('track_url', { track_url: term.clickLoggingUrl })
      }
      const url = term.groupId != null
        ? `/products/details?gid=${term.groupId}&fromRAC=true&recommendationSource=SiteSearchRAC`
        : `/search/results?query=${encodeURIComponent(term.description)}`
      await router.push(url)
    }
  }

  /**
   * Handle search change and set term when needed
   */
  function handleInputChange (value: string, actionMeta: InputActionMeta): void {
    if (actionMeta.action === 'input-change') {
      setMenuIsOpen(true)
      setInput(value)
    }
  }

  /**
   * Closes the menu on blur
   */
  function handleBlur (): void {
    setMenuIsOpen(false)
  }

  /**
   * Prevent filtering options if they don't match the input
   */
  function handleFilterOption (): boolean {
    return true
  }

  /**
   * Prevents space from selecting the first option
   */
  function handleKeyDown (event: KeyboardEvent<HTMLDivElement>): void {
    if (event.code === 'Space' && input === '') {
      event.preventDefault()
    }
  }

  /**
   * Opens the menu on focus
   */
  function handleFocus (): void {
    if (!redirecting) {
      setMenuIsOpen(true)
    }
  }

  /**
   * Handle search form submit
   */
  async function handleSubmit (event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    await handleSearch(value ?? input)
  }

  /**
   * Handles when a value is chosen, we submit the search here
   */
  async function handleValueChange (option: SearchSuggestion, actionMeta: ActionMeta<SearchSuggestion>): Promise<void> {
    setMenuIsOpen(false)
    setValue(option)
    setInput(option.description)
    await handleSearch(option)
  }

  return (
    <Form onSubmit={handleSubmit} data-gtm-click-section=''>
      <InputGroup size='lg' pill>
        <SelectDropdown
          placeholder='Search...'
          className='border-white'
          classNames={{
            control: () => isEmbeddedJewelerShowcase ? '' : 'border-white',
            menu: () => 'border-white shadow-sm'
          }}
          hideDropdownIndicator
          value={value}
          onChange={handleValueChange}
          options={options}
          inputValue={input}
          onInputChange={handleInputChange}
          filterOption={handleFilterOption}
          components={{ Option }}
          menuIsOpen={menuIsOpen && options.length > 0}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          blurInputOnSelect
          closeMenuOnSelect={false}
          readOnly={redirecting}
          ref={ref}
        />
        <Button
          type='submit'
          color={isShowcase ? 'dark' : 'primary'}
          className='ms-0'
          disabled={redirecting}
          data-test='execute-search'
        >
          {redirecting
            ? <Spinner size='xs' color='inherit' />
            : <FontAwesomeIcon icon={fas.faMagnifyingGlass} />}
        </Button>
      </InputGroup>
    </Form>
  )
}

export {
  Search
}
