import { useEffect, useMemo, useState, type ReactElement } from 'react'
import { type ActionMeta } from 'react-select'
import { FormGroup, SelectDropdown } from '@stuller/stullercom/ui'
import { ShipCompleteMethod, type CartLineMessageInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { isStringEmpty } from '@stuller/shared/util/core'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { useCartContext } from '../shared/useCartContext'
import { useCartShipmentContext } from '../shared/useCartShipmentContext'
import type { CartLine } from '../shared/types'

interface OptionType {
  /**
   * The value of the option as ship set code
   */
  value: string
  /**
   * The label of the option as shipment number
   */
  label: string
}

interface CartItemShipmentSelectProps {
  /**
   * The cart line object
   */
  cartLine: CartLine
  /**
   * Function to handle loading state
   */
  onLoading: (loading: boolean) => void
  /**
   * Function to handle errors
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * Function to handle success
   */
  onSuccess: (success: boolean) => void
  /**
   * Whether the input is disabled
   */
  disabled?: boolean
}

/**
 * Shows the shipment select for a cart item when
 */
function CartItemShipmentSelect ({
  cartLine,
  onLoading,
  onError,
  onSuccess,
  disabled = false
}: CartItemShipmentSelectProps): ReactElement | null {
  const { scheduledOrder, useCartContextUpdateCartLineMutation } = useCartContext()
  const { shipment, shipments } = useCartShipmentContext()
  const options = useMemo(() => {
    const newOptions = (shipments ?? [])
      .map((shipment, index) => ({
        value: shipment.shipSetCode,
        label: `Shipment #${index + 1}`
      }))

    // Add the create new shipment only if the item is in a shipment with multiple items
    if (shipment?.lines.length != null && shipment.lines.length > 1) {
      newOptions.unshift({ value: '', label: 'Create New Shipment' })
    }

    return newOptions
  }, [shipment, shipments])
  const [value, setValue] = useState<OptionType>()

  /**
   * Mutation to update the ship set code of a cart item
   */
  const [handleUpdateCartLine, { loading }] = useCartContextUpdateCartLineMutation({
    onError: (error: Error) => {
      onError([{ code: 'CART_ERROR', message: !isStringEmpty(error.message) ? error.message : 'There was an issue updating the shipment' }])
      logger.error('Error updating ship set code', {}, error)
    },
    onCompleted: () => {
      onError([])
      onSuccess(true)
    }
  })

  /**
   * Handler for when the shipment select is changed
   */
  async function handleOnChange (option: OptionType, actionMeta: ActionMeta<OptionType>): Promise<void> {
    try {
      setValue(option)
      await handleUpdateCartLine({
        variables: {
          input: {
            cartLineId: cartLine.id,
            fieldsToUpdate: ['SHIP_SET_CODE'],
            options: {
              shipSetCode: isStringEmpty(option.value) ? null : option.value
            }
          }
        }
      })
    } catch (error) {
      logger.error('Error updating cart item ship set code', {}, error)
    }
  }

  /**
   * Handle updating parent loading state
   */
  useEffect(() => {
    onLoading(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  /**
   * Updates the value when the shipment changes
   */
  useEffect(() => {
    setValue(options.find((option) => option.value === shipment?.shipSetCode))
  }, [shipment, options])

  if (scheduledOrder?.shipCompleteMethod !== ShipCompleteMethod.LineLevel || value == null) {
    return null
  }

  return (
    <div className='row'>
      <div className='col-12 col-sm-9 col-md-12 col-lg-10 col-xl-9'>
        <FormGroup className='mb-4 mt-2 mt-lg-0'>
          <SelectDropdown
            options={options}
            value={value}
            onChange={handleOnChange}
            isSearchable={false}
            disabled={disabled}
          />
        </FormGroup>
      </div>
    </div>
  )
}

export {
  CartItemShipmentSelect
}
