import { type ReactElement } from 'react'
import { StoneRebateGraph } from '../rebate-graph/StoneRebateGraph'
import { StoneRebateType } from '@stuller/stullercom/data-access/apollo-queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { DiamondGradingReportDisclaimerModal } from './DiamondGradingReportsDisclaimerModal'

interface NaturalSerializedDiamondSellingInfoProps {
  /**
   * Whether the user can view the diamond rebate
   */
  canViewDiamondRebate?: boolean
}

interface SellingInfoItemProps {
  /**
   * The icon to display
   */
  icon: IconProp
  /**
   * The title of the selling info item
   */
  title: string
  /**
   * The description of the selling info item
   */
  description: string
}

/**
 * A selling information item for natural serialized diamonds
 */
function SellingInfoItem ({ icon, title, description }: SellingInfoItemProps): ReactElement {
  return (
    <div className='col-12 col-md-6 mb-5'>
      <div className='row'>
        <div className='col-auto'>
          <div className='rounded-circle bg-white d-flex align-items-center justify-content-center shadow-sm' style={{ width: 50, height: 50 }}>
            <FontAwesomeIcon icon={icon} size='2xl' className='text-blue-400' />
          </div>
        </div>
        <div className='col'>
          <p className='mb-0 fw-bold fs-3'>{title}</p>
          <p className='fs-4 mb-0' dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
  )
}

/**
 * The selling information for natural serialized diamonds
 */
function NaturalSerializedDiamondSellingInfo ({ canViewDiamondRebate }: NaturalSerializedDiamondSellingInfoProps): ReactElement {
  const [disclaimerOpen, toggleDisclaimerOpen] = useToggle(false)

  return (
    <div className='py-6 py-md-8' style={{ background: 'var(--Gradients-EEE-FFF-, linear-gradient(0deg, #EEE 0%, #FFF 100%))' }}>
      <div className='container mw-xxl px-4 px-lg-3'>
        <div className='row'>
          <div className='col-12 order-2 order-lg-1'>
            <h1 className='text-start text-lg-center fw-light mb-5'>Every diamond with a grading report comes with</h1>
          </div>
          <div className='col-12 col-lg-2 order-1 order-lg-2'>
            <img src='https://meteor.stullercloud.com/das/72341272?trimTransparent=true&hei=250&fmt=png' className='w-100' style={{ maxWidth: 250 }} />
          </div>
          <div className='col-12 col-sm order-3'>
            <div className='row'>
              <SellingInfoItem
                icon={fas.faLock}
                title='ID Number Security'
                description='Laser-inscribed number offers the ultimate safeguard.'
              />
              <SellingInfoItem
                icon={fas.faCalendarDays}
                title='Easy Reviews & Returns'
                description='Qualified accounts enjoy 5-day reviews and prepaid returns.'
              />
              <SellingInfoItem
                icon={fas.faShieldCheck}
                title='Loss Protection for One Year'
                description='Give consumers even more confidence to buy from you.'
              />
              <SellingInfoItem
                icon={fas.faHeart}
                title='Personalized Inscription'
                description='Engrave a personal message for as little as $25.00. Call <a href="tel:+18008777777">800-877-7777</a>, and press 5, for details.'
              />
              <SellingInfoItem
                icon={fas.faHeart}
                title='Lifetime Trade Up'
                description='Offer full value credit to your customers when they trade up a mined diamond for another mined diamond.'
              />
              <SellingInfoItem
                icon={fas.faCheck}
                title='Buy with Confidence'
                description='All diamonds are hand selected and individually graded.'
              />
            </div>
          </div>
          {canViewDiamondRebate != null && canViewDiamondRebate && (
            <div className='col-12 col-md-auto order-4 mt-3 mt-md-0'>
              <div className='d-flex justify-content-center'>
                <StoneRebateGraph stoneType={StoneRebateType.Diamond} />
              </div>
            </div>
          )}
        </div>
        <div className='row mt-5 mt-md-0'>
          <div className='col-12 col-lg-10 offset-lg-2'>
            <p className='text-center text-md-start mb-0'>
              <a
                className='d-inline'
                onClick={toggleDisclaimerOpen}
              >
                View Disclaimer
              </a> and limitation of liability regarding diamond laboratory grading reports.
            </p>
          </div>
        </div>
      </div>
      <DiamondGradingReportDisclaimerModal isOpen={disclaimerOpen} toggleIsOpen={toggleDisclaimerOpen} />
    </div>
  )
}

export {
  NaturalSerializedDiamondSellingInfo
}
