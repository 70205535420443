import { type GetAuth } from '@stuller/stullercom/feat/auth'
import { type CustomAppProps } from './types'
import type { StatusCodeProps } from '@stuller/stullercom/feat/layout-context'

/**
 * Throws a 403 error if host appears to be a jeweler showcase site but has missing or incomplete showcase settings
 */
function getInvalidShowcaseForbidden (auth: GetAuth, showcaseSettings?: CustomAppProps['showcaseSettings']): StatusCodeProps | null {
  const { isJewelerShowcase } = auth

  // If isJewelerShowcase & not markupsComplete return false
  return (isJewelerShowcase && showcaseSettings == null) ? { statusCode: 403 } : null
}

export {
  getInvalidShowcaseForbidden
}
