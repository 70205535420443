import { type ReactElement } from 'react'
import { CartPageLoadError } from '../shared/CartPageLoadError'

/**
 * Shows the top level cart page error
 */
function CartPageError (): ReactElement {
  return (
    <div className='bg-gray-100'>
      <div className='container-xxl mw-xxl px-4'>
        <div className='row'>
          <div className='col'>
            <CartPageLoadError cartName='Cart' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  CartPageError
}
