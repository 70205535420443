import { type ReactElement, useContext } from 'react'
import { Loading } from '@stuller/stullercom/ui'
import { MessagesPopoverContext } from './MessagesPopoverContext'
import { MessagesList } from './MessagesList'
import { MessagesError } from './MessagesError'
import { MessagesFilters } from './MessagesFilters'
import Link from 'next/link'

/**
 * The inner content of the messages popover that handles query results
 */
function MessagesPopoverInner (): ReactElement {
  const { feed, unreadCount, feedReadUpdate } = useContext(MessagesPopoverContext)

  if (!unreadCount.loading && !feed.loading &&
    (unreadCount.error != null || feed.error != null || feed.data?.viewer?.notificationsFeed == null)) {
    return <MessagesError />
  }

  return (
    <Loading
      loading={unreadCount.loading || feed.loading || feedReadUpdate.loading || feed.data?.viewer?.notificationsFeed == null}
      className='p-5'
    >
      {(feed.previousData?.viewer?.notificationsFeed.notifications != null || feed.data?.viewer?.notificationsFeed.notifications != null) && (
        <>
          <MessagesFilters />
          <MessagesList />
          <div className='text-center'>
            <Link href='/myaccount/accountsettings/preferencecenter'>
              Communication Preferences
            </Link>
          </div>
        </>
      )}
    </Loading>
  )
}

export {
  MessagesPopoverInner
}
