import { useContext } from 'react'
import { PdpImageCarouselContext, type PdpImageCarouselContextType } from './PdpImageCarouselContext'

/**
 * Hook to fetch PDP carousel context information
 */
function usePdpImageCarouselContext (): PdpImageCarouselContextType {
  return useContext(PdpImageCarouselContext)
}

export {
  usePdpImageCarouselContext
}
