import { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaginationItem } from './PaginationItem'
import { PaginationLink } from './PaginationLink'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'

export interface PaginationEllipsisProps {
  /**
   * ID of the element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Size of Ellipsis
   */
  size?: 'sm' | 'lg'

}

const PaginationEllipsis = forwardRef<HTMLLIElement, PaginationEllipsisProps>(({
  id,
  className,
  size,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'page-item ellipsis',
    className
  )

  return (
    <PaginationItem id={id} className={classNames} ref={ref} {...otherAttributes}>
      <PaginationLink>
        <FontAwesomeIcon icon={fas.faEllipsis} size={size === 'sm' ? 'sm' : 'xl'} />
      </PaginationLink>
    </PaginationItem>
  )
})
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
  PaginationEllipsis
}
