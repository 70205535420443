import Cookies from 'js-cookie'
import { trackEvent } from '@stuller/stullercom/feat/google-tag-manager'

/**
 * Interface for pilot cookie
 */
interface PilotCookieProps {
  /**
   * Name of the cookie
   */
  cookieName: string
  /**
   * Serialized/interfaced value of the cookie
   */
  cookieValue: PilotCookieValue
  /**
   * Value of the cookie
   */
  rawCookieValue: string
}

/**
 * Interface for pilot cookie
 */
interface PilotCookieValue {
  /**
   * Internal expiration of the pilot cookie (used by GTM piloting tag)
   */
  expiration: number
  /**
   * PageKey for the cookie value
   */
  pilotingPage: string
  /**
   * Currently targeted segment
   */
  segment: string
  /**
   * User RequestNumber
   */
  userRequestNumber: number
}

// Update existing 'pilot-{cookieName}-segment' cookie to a 'toggle-{targetValue}' cookie value so that GTM can handle updating to toggled pilot segment
function togglePilotSegment (pilotName: string): void {
  trackEvent('piloting_toggle', { pilot: pilotName })
}

// Get the value of a pilot cookie, including the serialized expiration, pageKey, and segment values.
// Will be useful for the "go back to original" version button.
function getPilotCookie (pilotingPage: string): PilotCookieValue | undefined {
  const cookieName = `pilot-${pilotingPage}-segment`
  const cookieValue = Cookies.get(cookieName) ?? ''

  return trySerializePilotCookie(pilotingPage, decodeURIComponent(cookieValue))
}

function trySerializePilotCookie (pilotingPage: string, cookieValue: string): PilotCookieValue | undefined {
  const regex = /^(\d+)&(\d+)&(\w+)$/
  const match = regex.exec(cookieValue)
  if (match === null) {
    console.warn(`Invalid cookie value format: "${cookieValue}"`)

    return undefined
  }

  const [, userRequestNumber, expiration, segment] = match ?? []

  return {
    expiration: parseInt(expiration, 10),
    pilotingPage,
    segment,
    userRequestNumber: parseInt(userRequestNumber) // Assuming userRequestNumber is not part of the cookie value and needs to be set separately
  }
}

function getPageKeyFromCookieName (cookieName: string): string {
  return cookieName.replace('pilot-', '').replace('-segment', '')
}

/**
 * Get all pilot cookies
 */
function getPilotCookies (): PilotCookieProps[] {
  const allCookies = Cookies.get()
  const matchingCookies = Object.entries(allCookies).filter(([name]) => name.trim().startsWith('pilot-'))

  return matchingCookies
    .map(([cookieName, cookieValue]) => {
      const pilotCookieValue = trySerializePilotCookie(getPageKeyFromCookieName(cookieName), cookieValue)
      if (pilotCookieValue === undefined) {
        return undefined
      }

      return {
        cookieName,
        cookieValue: pilotCookieValue,
        rawCookieValue: cookieValue
      }
    })
    .filter((cookie): cookie is PilotCookieProps => cookie !== undefined)
    .sort((a, b) => a.cookieName.localeCompare(b.cookieName))
}

/**
 * Remove all pilot cookies
 */
function removeAllPilotCookies (): void {
  const allCookies = Cookies.get()
  const matchingCookies = Object.entries(allCookies).filter(([name]) => name.trim().startsWith('pilot-'))
  matchingCookies.forEach((cookie) => {
    Cookies.set(cookie[0], '', { expires: 0 })
  })
}

export {
  removeAllPilotCookies,
  getPilotCookies,
  getPilotCookie,
  togglePilotSegment,
  type PilotCookieProps,
  type PilotCookieValue
}
