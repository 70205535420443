import { Alert } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'
import { type CartLineMessageInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface CartItemAlertProps {
  /**
   * The type of alert
   */
  type: 'danger' | 'warning'
  /**
   * The message of the alert
   */
  notes?: CartLineMessageInfoFragment[]
  /**
   * The message of the errors
   */
  errors?: CartLineMessageInfoFragment[]
}

/**
 * Component for the alerts in the cart item
 */
function CartItemAlert ({
  type,
  notes,
  errors
}: CartItemAlertProps): ReactElement {
  return (
    <Alert color={type} className='py-3 mb-0' icon>
      {notes != null && notes.length > 0 && (
        <>
          <p className='mb-0 fw-bold'>Important Notes</p>
          <ul className='mb-0'>
            {notes.map((note, index) => (
              <li key={index} className='mb-0 pb-0'>{note.message}</li>
            ))}
          </ul>
        </>
      )}
      {errors != null && errors.length > 0 && (
        <>
          {errors.map((error, index) => (
            <p key={index} className='mb-0 pb-0'>{error.message}</p>
          ))}
        </>
      )}
    </Alert>
  )
}

export {
  CartItemAlert
}
