import { type CartLineInfoFragment, CartLineInStockStatusCode } from '@stuller/stullercom/data-access/apollo-queries'
import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

interface CartItemInStockStatusProps {
  /**
   * The in stock status of the cart item
   */
  inStockStatus?: CartLineInfoFragment['inStockStatus']
}

/**
 * Component that will show the in stock status of a cart item
 */
function CartItemInStockStatus ({ inStockStatus }: CartItemInStockStatusProps): ReactElement | null {
  if (inStockStatus?.status == null || inStockStatus.statusCode == null) {
    return null
  }

  return (
    <div className='col-12'>
      <div className='d-flex justify-content-end'>
        <div>
          {inStockStatus.statusCode === CartLineInStockStatusCode.InStock && (
            <FontAwesomeIcon icon={fas.faCheck} className='me-2' size='lg' />
          )}
          {inStockStatus.status}
        </div>
      </div>
    </div>
  )
}

export {
  CartItemInStockStatus
}
