import { type ReactElement, useState } from 'react'
import type { SerializedDiamond } from '../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { Button, Popover, PopoverBody } from '@stuller/stullercom/ui'
import { ItemShareButton } from '@stuller/stullercom/feat/item-share-button'

interface SerializedDiamondPageHeaderProps {
  /**
   * The serialized diamond to display
   */
  serializedDiamond: SerializedDiamond
  /**
   * The title of the page
   */
  title: string
}

function SerializedDiamondPageHeader ({ serializedDiamond, title }: SerializedDiamondPageHeaderProps): ReactElement {
  const [showSuccess, setShowSuccess] = useState(false)
  const [reference, setReference] = useState<HTMLElement | null>(null)

  async function handleCopySerialNumber (): Promise<void> {
    try {
      await navigator.clipboard.writeText(serializedDiamond.serialNumber ?? '')
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 3000)
    } catch (e) {
      logger.error('Error copying serial number to clipboard', {}, e)
    }
  }

  return (
    <div className='row my-3'>
      <div className='col-12'>
        <div className='d-flex align-items-center mb-2'>
          {serializedDiamond?.stoneOrigin === 'Lab-Grown' && (
            <img src='http://meteor.stullercloud.com/das/37618011?hei=45&fmt=smart-alpha' className='me-3' />
          )}
          <h1 className='mb-0'>{title}</h1>
        </div>
      </div>
      {serializedDiamond.report != null && (
        <div className='col-12'>
          <p className='mb-2 fs-3 text-blue-400 fw-bold'>With Report</p>
        </div>
      )}
      <div className='col-12'>
        <div className='d-flex align-items-center w-100 justify-content-between justify-content-md-start'>
          <Popover open={showSuccess} reference={reference}>
            <PopoverBody>
              <p className='mb-0'>Copied to clipboard</p>
            </PopoverBody>
          </Popover>
          <Button color='anchor-dark' onClick={handleCopySerialNumber} ref={setReference} className='fw-normal me-4'>
            <FontAwesomeIcon icon={fas.faFiles} className='me-2 text-gray-600' />
            Serial #{serializedDiamond.serialNumber}
          </Button>
          <ItemShareButton
            itemId={serializedDiamond.itemId.id}
            itemType={serializedDiamond.itemId.type}
            image={serializedDiamond.image}
          />
        </div>
      </div>
    </div>
  )
}

export {
  SerializedDiamondPageHeader
}
