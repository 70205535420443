import React, { type ReactElement } from 'react'
import { IMAGE_COMING_SOON } from '@stuller/shared/util/constants'
import Link from 'next/link'
import clsx from 'clsx'
import { isStringEmpty } from '@stuller/shared/util/core'

interface CartItemConfigurationProductProps {
  /**
   * The image of the product
   */
  image?: string | null
  /**
   * The title of the product
   */
  title: string
  /**
   * The itemNumber of the product
   */
  itemNumber?: string
  /**
   * The serialNumber of the product
   */
  serialNumber?: string
  /**
   * The description of the product
   */
  description?: string
  /**
   * The id of the product
   */
  id?: string
  /**
   * If the product is active
   */
  isActive?: boolean
  /**
   * The quantity of the product
   */
  quantity?: number
  /**
   * Whether to add padding to the bottom of the component
   */
  paddingBottom?: boolean
}

/**
 * Component for the product item in the cart item configuration
 * to be used within the CartItemConfigurationProduct component
 */
function CartItemConfigurationProduct ({
  image,
  title,
  description,
  itemNumber,
  serialNumber,
  id,
  quantity,
  isActive = false,
  paddingBottom = false
}: CartItemConfigurationProductProps): ReactElement {
  const classNames = clsx(
    'row',
    paddingBottom && 'pb-5'
  )

  return (
    <div className={classNames}>
      <div className='col-2 col-sm-auto d-flex align-items-center'>
        <img src={image ?? IMAGE_COMING_SOON} className='w-100' style={{ maxWidth: 65 }} />
      </div>
      <div className='col d-flex flex-column justify-content-center'>
        <p className='mb-0 fw-bold lh-sm'>{title}</p>
        {!isStringEmpty(description) && (
          <p className='mb-0'>{description}</p>
        )}
        {itemNumber != null && (
          <>
            {isActive
              ? <Link href={`/products/details/?iid=${id}`} className='lh-sm'>{itemNumber}</Link>
              : <p className='lh-sm mb-0'>{itemNumber}</p>}
          </>
        )}
        {serialNumber != null && (
          <p className='lh-sm mb-0'>Serial Number: <Link href={`/serializedproduct/${serialNumber}`}>{serialNumber}</Link></p>
        )}
      </div>
      {quantity != null && (
        <div className='col-auto d-flex align-items-center'>
          <p className='mb-0'>Qty: {quantity}</p>
        </div>
      )}
    </div>
  )
}

export {
  CartItemConfigurationProduct
}
