import { type ReactElement } from 'react'
import Link from 'next/link'
import { Modal, ModalHeader, ModalBody } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far } from '@awesome.me/kit-3dbd93c064/icons'
import {
  EDGE_ICON,
  SAFARI_ICON,
  CHROME_ICON,
  FIREFOX_ICON
} from '@stuller/shared/util/constants'

interface UnsupportedBrowserModalProps {
  /**
   * Indicates the modal is open
   */
  isOpen?: boolean
  /**
   * Handle called when modal is toggled
   */
  onToggle?: () => void
  /**
   * Hides Edge
   */
  hideEdge?: boolean
  /**
   * Hides Safari
   */
  hideSafari?: boolean
  /**
   * Hides Chrome
   */
  hideChrome?: boolean
  /**
   * Hides Firefox
   */
  hideFirefox?: boolean
}

interface RecommendedBrowserProps {
  /**
   * Icon to display
   */
  image: string
  /**
   * Name of browser
   */
  name: string
  /**
   * Description of browser
   */
  description: string
  /**
   * URL to download browser
   */
  url: string
}

/**
 * Recommended browser link
 */
function RecommendedBrowser ({ image, name, description, url }: RecommendedBrowserProps): ReactElement {
  return (
    <div className='row align-items-center bg-gray-100 rounded p-3 border border-transparent border-gray-300-hover cursor-pointer position-relative mb-3'>
      <div className='col-auto'>
        <img className='img-fluid' src={image} style={{ height: 50 }} alt='Browser Icon' />
      </div>
      <div className='col'>
        <div>
          {name}
        </div>
        <div>
          {description}
        </div>
      </div>
      <div className='col-auto'>
        <Link href={url} target='_blank' className='stretched-link link-dark text-decoration-none' rel='noreferrer'>
          <FontAwesomeIcon icon={far.faAngleRight} size='2x' />
        </Link>
      </div>
    </div>
  )
}

/**
 * Modal for unsupported browsers
 */
function UnsupportedBrowserModal ({
  isOpen,
  onToggle,
  hideEdge = false,
  hideSafari = false,
  hideChrome = false,
  hideFirefox = false
}: UnsupportedBrowserModalProps): ReactElement {
  return (
    <Modal isOpen={isOpen} onToggle={onToggle}>
      <ModalHeader onToggle={onToggle}>
        Upgrade Your Browser
      </ModalHeader>
      <ModalBody>
        <div className='mb-3'>
          <div className='mb-3'>
            Download a modern browser from the options below:
          </div>
          {!hideEdge && (
            <RecommendedBrowser
              image={EDGE_ICON}
              name='Microsoft Edge'
              description='www.microsoft.com/edge'
              url='https://www.microsoft.com/edge'
            />
          )}
          {!hideSafari && (
            <RecommendedBrowser
              image={SAFARI_ICON}
              name='Safari'
              description='www.apple.com/safari'
              url='https://support.apple.com/en-us/HT204416'
            />
          )}
          {!hideChrome && (
            <RecommendedBrowser
              image={CHROME_ICON}
              name='Chrome'
              description='www.google.com/chrome'
              url='https://www.google.com/chrome/browser/desktop'
            />
          )}
          {!hideFirefox && (
            <RecommendedBrowser
              image={FIREFOX_ICON}
              name='Mozilla Firefox'
              description='www.mozilla.org/firefox'
              url='https://www.mozilla.org/firefox/new'
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export {
  UnsupportedBrowserModal
}
