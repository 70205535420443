import type { NextPage } from 'next'
import NextError, { type ErrorProps as NextErrorProps } from 'next/error'
import { datadogRum } from '@datadog/browser-rum'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { Error403 } from './Error403'
import { Error404 } from './Error404'
import { Error500 } from './Error500'
import { ErrorSerialized404 } from './ErrorSerialized404'
import { useEffect } from 'react'

export interface ErrorProps extends NextErrorProps {
  /**
   * The error thrown passed
   */
  err?: Error
  /**
   * Template to render for the error. Uses regular error page if not set
   */
  errorTemplate?: 'serialized404'
}

const Error: NextPage<ErrorProps> = ({ statusCode, err, errorTemplate }) => {
  // Send client-side error to Datadog
  useEffect(() => {
    if (err != null) {
      datadogRum.addError(err)
      logger.error('Error: NextPage', { statusCode }, err)
    } else if (statusCode >= 500) {
      logger.error('Error: NextPage', { statusCode }, 'Internal Server Error')
    }
  }, [statusCode, err])

  if (errorTemplate === 'serialized404') {
    return <ErrorSerialized404 />
  }

  if (statusCode === 404) {
    return <Error404 />
  }
  if (statusCode === 403) {
    return <Error403 />
  }

  return <Error500 />
}

Error.getInitialProps = async (context) => {
  return await NextError.getInitialProps(context)
}

export {
  Error
}
