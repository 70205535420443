import { type ElementType, forwardRef, type ReactNode } from 'react'
import clsx from 'clsx'
import Link from 'next/link'

export interface BreadcrumbItemProps {
  /**
   * Additional class name(s) for item elements
  */
  className?: string
  /**
   * The breadcrumb text label
  */
  children?: ReactNode
  /**
   * The url (href) for the breadcrumb item
   */
  href?: string
  /**
   * Active boolean for last element
   */
  active?: boolean
}

/**
 * Individual breadcrumb item for `Breadcrumb`
 */
const BreadcrumbItem = forwardRef<HTMLLIElement, BreadcrumbItemProps>(({
  className,
  href,
  active = false,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'breadcrumb-item',
    active && 'active',
    className
  )
  const Tag = href != null ? Link : 'span' as ElementType

  return (
    <li className={classNames} ref={ref} {...otherAttributes}>
      <Tag href={href} className='breadcrumb-item-link'>
        {children}
      </Tag>
    </li>
  )
})
BreadcrumbItem.displayName = 'BreadcrumbItem'

export {
  BreadcrumbItem
}
