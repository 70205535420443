import { type AppContext } from 'next/app'
import { type GetAuth } from '@stuller/stullercom/feat/auth'
import type { StatusCodeProps } from '@stuller/stullercom/feat/layout-context'
import { isUnauthenticatedPath } from './isUnauthenticatedPath'

const STERLING_LOGIN_PATH = '/login/?ReturnUrl='

/**
 * Redirects to Sterling login page if user is not authenticated
 */
function getSterlingRedirect (appContext: AppContext, auth: GetAuth): StatusCodeProps | null {
  const { ctx } = appContext
  const { isSterling, authUser } = auth
  const returnPath = ctx.asPath

  // If isSterling & no auth cookie then redirect to login
  if (isSterling && authUser == null && returnPath != null && !isUnauthenticatedPath(ctx.asPath)) {
    const location = `${STERLING_LOGIN_PATH}${encodeURIComponent(returnPath)}`

    return { statusCode: 302, location }
  }

  return null
}

export {
  getSterlingRedirect,
  STERLING_LOGIN_PATH
}
