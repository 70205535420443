import { type ReactElement, Fragment, useMemo } from 'react'
import { type EngravingConfigurationDetail, type EngravingText } from '../../shared/types'
import { CartItemConfigurationCard } from './CartItemConfigurationCard'
import { CartItemConfigurationDetail } from './CartItemConfigurationDetail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { IMAGE_COMING_SOON } from '@stuller/shared/util/constants'

interface CartItemConfigurationEngravingProps {
  /**
   * The engraving data
   */
  engravingData: EngravingConfigurationDetail
}

/**
 * Component for the engraving item in the cart item configuration
 */
function CartItemConfigurationEngraving ({
  engravingData
}: CartItemConfigurationEngravingProps): ReactElement {
  return (
    <>
      {engravingData.engravings != null && engravingData.engravings.length > 0 && (
        <>
          {engravingData.engravings.map((engraving, index) => (
            <Fragment key={index}>
              {engraving?.engraving != null && engraving?.engraving.__typename === 'StandardEngravingLocationConfiguration' && (
                <CartItemConfigurationCard
                  key={index}
                  title={`${engraving.locationDescription} Engraving`}
                >
                  <div className='row'>
                    <div className='col-6 col-sm-auto'>
                      <div className='d-flex flex-column'>
                        {engraving.engraving.lines.map((line, index) => (
                          <Fragment key={index}>
                            {engraving?.engraving != null && engraving.engraving.__typename === 'StandardEngravingLocationConfiguration' && engraving.engraving.lines.length > 1 && (
                              <p className='mb-1 text-uppercase fw-light fs-7'>Line {index + 1}</p>
                            )}
                            <img
                              key={index}
                              className='w-100'
                              src={line.thumbnailPreviewImage ?? `https://meteor.stullercloud.com/?src=(?text=${line.text}&size=150,30&text.font=das/4784148&text.color=%23333333&text.size=16&text.align=l,m&text.wrap=true&fmt=png)&fmt=smart-alpha`}
                            />
                          </Fragment>
                        ))}
                      </div>
                    </div>
                    <div className='col-6 col-sm d-flex flex-column align-items-end'>
                      <div className='d-flex flex-column align-items-end'>
                        <p className='mb-0 fw-light text-gray-70 text-end'>Precision Laser Engraving</p>
                        {engraving.engraving.lines.length > 1 && (
                          <LinesEngravedText lines={engraving.engraving.lines as EngravingText[]} />
                        )}
                        {engraving?.engraving?.fontName != null && (
                          <p className='mb-0 fw-light text-end'>{engraving?.engraving?.fontName}</p>
                        )}
                        <div className='d-flex flex-column'>
                          {engraving?.engraving?.color != null && (
                            <div className='d-flex align-items-center'>
                              {engraving?.engraving?.color.hex != null && (
                                <div
                                  className='rounded-circle me-2'
                                  style={{
                                    backgroundColor: `#${engraving?.engraving?.color.hex}`,
                                    width: 15,
                                    height: 15
                                  }}
                                />
                              )}
                              <div className='fw-light text-end'>{engraving?.engraving?.color.name} Color Fill</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CartItemConfigurationCard>
              )}
              {engraving?.engraving != null && engraving?.engraving.__typename === 'CustomerLogoEngravingLocationConfiguration' && (
                <CartItemConfigurationCard
                  key={index}
                  title='Your Jewelry Logo'
                >
                  <div className='row'>
                    <div className='col-2 col-sm-auto'>
                      <img className='w-100' style={{ maxWidth: 65 }} src={engraving.engraving.logo.imagePreview ?? IMAGE_COMING_SOON} />
                    </div>
                    <div className='col d-flex align-items-center'>
                      <p className='mb-0 fw-bold'>{engraving.engraving.logo.name}</p>
                    </div>
                  </div>
                </CartItemConfigurationCard>
              )}
              {engraving?.engraving != null && engraving?.engraving.__typename === 'HandwrittenEngravingLocationConfiguration' && (
                <CartItemConfigurationCard
                  key={index}
                  title={`${engraving.locationDescription} Engraving`}
                >
                  <div className='row'>
                    <div className='col-12 col-sm-6'>
                      <FontAwesomeIcon icon={fas.faCheck} className='text-blue-200 me-2' size='lg' />
                      <span className='text-blue-400 fw-bold'>File uploaded</span>
                    </div>
                    <div className='col-12 col-sm-6 text-end'>
                      <p className='fw-light mb-0'>Handwritten Message</p>
                      {engraving.engraving.color != null && (
                        <div className='d-flex align-items-center justify-content-end'>
                          {engraving.engraving.color.hex != null && (
                            <div
                              className='rounded-circle me-2'
                              style={{
                                backgroundColor: `#${engraving?.engraving?.color.hex}`,
                                width: 15,
                                height: 15
                              }}
                            />
                          )}
                          <p className='mb-0 fw-light'>{engraving.engraving.color.name} Color Fill</p>
                        </div>
                      )}
                    </div>
                  </div>
                </CartItemConfigurationCard>
              )}
              {engraving?.engraving != null && engraving?.engraving.__typename === 'PatternEngravingLocationConfiguration' && (
                <CartItemConfigurationDetail
                  key={index}
                  title={`${engraving.locationDescription} Engraving Pattern`}
                  description={engraving.engraving.patternName}
                />
              )}
            </Fragment>
          ))}
        </>
      )}
    </>
  )
}

/**
 * Subcomponent to display how many lines have been filled out of the total
 */
function LinesEngravedText ({ lines }: { lines: EngravingText[] }): ReactElement {
  const displayString = useMemo(() => {
    let activeCount = 0
    for (const line of lines) {
      if (line != null) {
        activeCount += 1
      }
    }

    return `${activeCount} of ${lines.length} Lines Engraved`
  }, [lines])

  return <p className='mb-0 fw-light text-end'>{displayString}</p>
}

export {
  CartItemConfigurationEngraving
}
