import { useCallback, type RefObject } from 'react'
import { useEventListener } from './useEventListener'

type EventName = 'mousedown' | 'mouseup' | 'touchstart' | 'touchend' | 'focusin' | 'focusout'

/**
 * Calls a handler when a click is detected outside of the ref element
 */
function useOnClickOutside<T extends HTMLElement = HTMLElement> (
  element: RefObject<T> | Array<RefObject<T>>,
  handler: (event: MouseEvent | TouchEvent | FocusEvent) => void,
  eventName: EventName = 'mousedown'
): void {
  const handleOnClickOutside = useCallback((event: MouseEvent | TouchEvent | FocusEvent) => {
    const target = event.target as Node

    // Do nothing if the target is not connected
    if (!target.isConnected) {
      return
    }

    // Calculate if outside click
    const isOutside = Array.isArray(element)
      ? element
        .filter((r) => Boolean(r.current))
        .every((r) => r.current != null && !r.current.contains(target))
      : element.current != null && !element.current.contains(target)

    // Call hanlder if outside
    if (isOutside) {
      handler(event)
    }
  }, [handler, element])

  useEventListener(eventName, handleOnClickOutside)
}

export {
  useOnClickOutside
}
