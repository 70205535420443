import { forwardRef } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import type { Cart } from '../shared/types'

interface CartSummaryTotalsProps {
  /**
   * The full cart query
   */
  cart?: Cart
}

/**
 * The totals section of the cart summary
 */
const CartSummaryTotals = forwardRef<HTMLDivElement, CartSummaryTotalsProps>(({ cart }, ref) => {
  const { showcaseSettings } = useAuth()
  const hideUSDPrices = showcaseSettings?.hideUSDPrices ?? false
  const currencyCode = cart?.price?.total.exchangeRate.code.toUpperCase() ?? 'USD'
  const showTotalUsdConversionPrice = currencyCode !== 'USD'
  const showUsdConversionPrice = showTotalUsdConversionPrice && !hideUSDPrices
  const [subtotal] = useCurrency(cart?.price?.subtotal.value, cart?.price?.subtotal.exchangeRate)
  const [subtotalUsd] = useCurrency(cart?.price?.subtotal.valueInUsd)
  const showSubtotal = cart?.price?.subtotal.value != null
  const [serviceChargesTotal] = useCurrency(cart?.price?.serviceChargesTotal?.value, cart?.price?.serviceChargesTotal?.exchangeRate)
  const [serviceChargesTotalUsd] = useCurrency(cart?.price?.serviceChargesTotal?.valueInUsd)
  const showServiceChargesTotal = cart?.price?.serviceChargesTotal?.value != null && cart.price.serviceChargesTotal.value > 0
  const [shippingTotal] = useCurrency(cart?.price?.shippingTotal?.value, cart?.price?.shippingTotal?.exchangeRate)
  const [shippingTotalUsd] = useCurrency(cart?.price?.shippingTotal?.valueInUsd)
  const showShippingTotal = cart?.price?.shippingTotal?.value != null
  const shippingTotalFree = cart?.price?.shippingTotal?.value === 0
  const [salesTax] = useCurrency(cart?.price?.salesTaxV2?.value, cart?.price?.salesTaxV2?.exchangeRate)
  const [salesTaxUsd] = useCurrency(cart?.price?.salesTaxV2?.valueInUsd)
  const showSalesTax = cart?.price?.salesTaxV2?.value != null
  const [total] = useCurrency(cart?.price?.total.value, cart?.price?.total.exchangeRate)
  const [totalUsd] = useCurrency(cart?.price?.total.valueInUsd)
  const showTotal = cart?.price?.total.value != null

  return (
    <>
      {showSubtotal && (
        <div className='row'>
          <div className='col'>Subtotal:</div>
          <div className='col-auto text-end' data-test='subtotal-value'>
            {subtotal}
            {showUsdConversionPrice && (
              <div className='fst-italic fs-6 text-gray-600'>
                {subtotalUsd} (USD)
              </div>
            )}
          </div>
        </div>
      )}
      {showServiceChargesTotal && (
        <div className='row'>
          <div className='col'>Imprinting Charges:</div>
          <div className='col-auto text-end' data-test='imprinting-value'>
            {serviceChargesTotal}
            {showUsdConversionPrice && (
              <div className='fst-italic fs-6 text-gray-600'>
                {serviceChargesTotalUsd} (USD)
              </div>
            )}
          </div>
        </div>
      )}
      {showShippingTotal && (
        <div className='row'>
          <div className='col'>Est. Shipping:</div>
          <div className='col-auto text-end' data-test='shipping-value'>
            {shippingTotalFree ? 'Free' : shippingTotal}
            {showUsdConversionPrice && !shippingTotalFree && (
              <div className='fst-italic fs-6 text-gray-600'>
                {shippingTotalUsd} (USD)
              </div>
            )}
          </div>
        </div>
      )}
      {showSalesTax && (
        <div className='row'>
          <div className='col'>Est. Sales Tax:</div>
          <div className='col-auto text-end' data-test='sales-tax-value'>
            {salesTax}
            {showUsdConversionPrice && (
              <div className='fst-italic fs-6 text-gray-600'>
                {salesTaxUsd} (USD)
              </div>
            )}
          </div>
        </div>
      )}
      {showTotal && <hr />}
      <div className='row mb-3' ref={ref}>
        {showTotal && (
          <>
            <div className='col'><h3>Estimated Total:</h3></div>
            <div className='col-auto text-end'>
              <h3 className='mb-0' data-test='estimated-total'>{total}</h3>
              {showTotalUsdConversionPrice && (
                <>
                  <div className='fst-italic fs-6 text-gray-600'>
                    {totalUsd} (USD)
                  </div>
                  <div className='fst-italic fs-6'>
                    Invoices are issued in USD.
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
})
CartSummaryTotals.displayName = 'CartSummaryTotals'

export {
  CartSummaryTotals
}
