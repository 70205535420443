import { type ReactElement, useState, useEffect, type ChangeEvent, type ReactNode } from 'react'
import clsx from 'clsx'
import { FormGroup, FormLabel, Input, Popover, PopoverBody } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useToggle } from '@stuller/shared/util/react-hooks'
import {
  type CartLineMessageInfoFragment,
  useCartPageCartItemUpdateSavedCartLineMutation,
  useCartPageCartItemAcceptConsignmentTermsMutation,
  useGetCartPageCartItemNeedsToAgreeToConsignmentTermsQuery,
  GetCartPageCartItemNeedsToAgreeToConsignmentTermsDocument
} from '@stuller/stullercom/data-access/apollo-queries'
import { type CartLine } from '../shared/types'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { ConsignmentTermsModal } from './ConsignmentTermsModal'
import { ConsignmentDescriptionModal } from './ConsignmentDescriptionModal'
import { useCartContext } from '../shared/useCartContext'

interface CartItemOptionCheckboxProps {
  /**
   * The label of the checkbox
   */
  label: string
  /**
   * The value of the checkbox
   */
  value: 'isAnnealed' | 'isMatched' | 'isForConsignment'
  /**
   * The tooltip of the checkbox
   */
  tooltip?: ReactNode
  /**
   * The checked state of the checkbox
   */
  checked: boolean
  /**
   * If the checkbox is disabled
   */
  disabled?: boolean
  /**
   * The cart line to update
   */
  cartLine: CartLine
  /**
   * Function to set the loading state
   */
  onLoading: (loading: boolean) => void
  /**
   * Function to set the error state
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * Function to set the success state
   */
  onSuccess: (success: boolean) => void
}

/**
 * Component for the checkbox options in the cart items
 */
function CartItemOptionCheckbox ({
  label,
  value,
  tooltip,
  checked,
  disabled,
  cartLine,
  onLoading,
  onError,
  onSuccess,
  ...otherAttributes
}: CartItemOptionCheckboxProps): ReactElement {
  const { useCartContextUpdateCartLineMutation } = useCartContext()
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const [consignmentModalIsOpen, handleConsignmentModalToggle] = useToggle(false)
  const [reference, setReference] = useState<HTMLElement | null>(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { data: needsToAgreeToConsignmentTerms } = useGetCartPageCartItemNeedsToAgreeToConsignmentTermsQuery({ })
  const [isChecked, setIsChecked] = useState(checked)
  const tooltipClassName = clsx(
    'cursor-default d-inline',
    value === 'isMatched' ? 'd-inline-block d-lg-none' : ''
  )

  /**
   * Returning the field to update based on the value
   */
  function returnFieldToUpdate (): 'IS_ANNEALED' | 'IS_MATCHED' | 'IS_FOR_CONSIGNMENT' {
    switch (value) {
      case 'isAnnealed':
        return 'IS_ANNEALED'
      case 'isMatched':
        return 'IS_MATCHED'
      case 'isForConsignment':
        return 'IS_FOR_CONSIGNMENT'
    }
  }

  /**
   * Function to handle the change of the checkbox
   */
  async function handleOnChange (event: ChangeEvent<HTMLInputElement>): Promise<void> {
    const isChecked = event.target.checked

    if (value === 'isForConsignment' && isChecked && needsToAgreeToConsignmentTerms?.viewer?.needsToAgreeToConsignmentTerms === true) {
      setModalIsOpen(true)
    } else {
      setIsChecked(isChecked)
      if (cartLine.__typename === 'CartLine') {
        void handleUpdateCartLine({
          variables: {
            input: {
              cartLineId: cartLine.id,
              fieldsToUpdate: [returnFieldToUpdate()],
              options: {
                [value]: isChecked
              }
            }
          }
        })
      } else {
        void handleUpdateSavedCartLine({
          variables: {
            input: {
              savedCartLineId: cartLine.id,
              fieldsToUpdate: [returnFieldToUpdate()],
              options: {
                [value]: isChecked
              }
            }
          }
        })
      }
    }
  }

  /**
   * Function to close the modal
   */
  const handleCloseModal = (): void => {
    setModalIsOpen(false)
  }

  /**
   * Mutation to accept consignment terms
   */
  const [acceptConsignmentTermMutation, { loading: consignmentLoading }] = useCartPageCartItemAcceptConsignmentTermsMutation({
    onError: (error) => {
      logger.error('Error accepting consignment terms', {}, error)
      throw error
    },
    onCompleted: () => {
      setIsChecked(true)
      setModalIsOpen(false)
    },
    update: () => {
      if (cartLine.__typename === 'CartLine') {
        void handleUpdateCartLine({
          variables: {
            input: {
              cartLineId: cartLine.id,
              fieldsToUpdate: ['IS_FOR_CONSIGNMENT'],
              options: {
                isForConsignment: true
              }
            }
          }
        })
      } else {
        void handleUpdateSavedCartLine({
          variables: {
            input: {
              savedCartLineId: cartLine.id,
              fieldsToUpdate: ['IS_FOR_CONSIGNMENT'],
              options: {
                isForConsignment: true
              }
            }
          }
        })
      }
    },
    refetchQueries: [GetCartPageCartItemNeedsToAgreeToConsignmentTermsDocument]
  })

  /**
   * Mutation to update the saved cart line options
   */
  const [handleUpdateSavedCartLine, { loading: updateSavedCartLineLoading }] = useCartPageCartItemUpdateSavedCartLineMutation({
    onError: (error: Error) => {
      onError([{ code: 'CART_ERROR', message: 'There was an issue saving your options' }])
      logger.error('Error updating cart item options', {}, error)
      setIsChecked((prev) => !prev)
    },
    onCompleted: () => {
      onError([])
      onSuccess(true)
    }
  })

  /**
   * Mutation to update the cart line
   */
  const [handleUpdateCartLine, { loading: updateCartLineLoading }] = useCartContextUpdateCartLineMutation({
    onError: (error: Error) => {
      onError([{ code: 'CART_ERROR', message: 'There was an issue saving your options' }])
      logger.error('Error updating cart item options', {}, error)
      setIsChecked((prev) => !prev)
    },
    onCompleted: () => {
      onError([])
      onSuccess(true)
    }
  })

  /**
   * Reset state on change
   */
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  /**
   * Handle updating parent loading state
   */
  useEffect(() => {
    onLoading(updateSavedCartLineLoading || updateCartLineLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSavedCartLineLoading, updateCartLineLoading])

  return (
    <>
      <FormGroup className='mt-3'>
        <Input type='checkbox' value={value} className='me-2' checked={isChecked} disabled={disabled} onChange={handleOnChange} {...otherAttributes} />
        <FormLabel className='mb-0'>
          <p className='mb-0 d-inline'>{label}</p>
          {tooltip != null && (
            <>
              <span ref={setReference} className={tooltipClassName}>
                <FontAwesomeIcon icon={fas.faInfoCircle} className='ms-2 text-gray-500 fs-4' />
              </span>
              <Popover hover open={popoverOpen} onOpenChange={setPopoverOpen} reference={reference}>
                <PopoverBody>
                  {tooltip}
                </PopoverBody>
              </Popover>
            </>
          )}
          {value === 'isForConsignment' && (
            <div onClick={handleConsignmentModalToggle} className='cursor-pointer d-inline'>
              <FontAwesomeIcon icon={fas.faInfoCircle} className='ms-2 text-gray-500 fs-4' />
            </div>
          )}
          {value === 'isMatched' && tooltip != null && (
            <div className='d-none d-lg-inline-block fw-normal'>
              {tooltip}
            </div>
          )}
        </FormLabel>
      </FormGroup>
      <ConsignmentTermsModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        onAcceptConsignmentTerms={acceptConsignmentTermMutation}
        loading={consignmentLoading}
      />
      <ConsignmentDescriptionModal
        isOpen={consignmentModalIsOpen}
        onToggle={handleConsignmentModalToggle}
      />
    </>
  )
}

export {
  CartItemOptionCheckbox
}
