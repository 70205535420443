import { type ReactElement } from 'react'
import { Button, Table, Collapse } from '@stuller/stullercom/ui'
import { AddToCartQuantityDiscountRow } from './AddToCartQuantityDiscountRow'
import { type PriceBreaks } from './types'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'

interface AddToCartQuantityDiscountsProps {
  priceBreaks: PriceBreaks
}

/**
 * Collapsable table of price break discount information
 */
function AddToCartQuantityDiscounts ({ priceBreaks }: AddToCartQuantityDiscountsProps): ReactElement | null {
  const [containerOpen, handleContainerOpen] = useToggle(false)
  const { options } = useAddToCartSectionContext()

  if (isArrayEmpty(priceBreaks) || options?.isMatched === true) {
    return null
  }

  return (
    <div className='text-end'>
      <Button
        color='link'
        className='p-0 text-start'
        onClick={handleContainerOpen}
      >
        Quantity Discounts
        <FontAwesomeIcon
          size='lg'
          className='px-2'
          icon={containerOpen ? fas.faChevronUp : fas.faChevronDown}
        />
      </Button>
      <Collapse isOpen={containerOpen}>
        <Table className='mt-3' bordered rounded striped size='sm'>
          <thead>
            <tr>
              <th className='text-start bg-gray-200'>
                Quantity
              </th>
              <th className='bg-gray-200'>
                Price
              </th>
              <th className='bg-gray-200'>
                Discount
              </th>
            </tr>
          </thead>
          <tbody>
            {priceBreaks?.map((priceBreak, index) => (
              <AddToCartQuantityDiscountRow key={index} priceBreak={priceBreak} />
            ))}
          </tbody>
        </Table>
      </Collapse>
    </div>
  )
}

export {
  AddToCartQuantityDiscounts
}
