import { type ReactElement, useContext, useEffect } from 'react'
import { Loading } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { trackCartView } from '@stuller/stullercom/feat/google-tag-manager'
import { CartPopoverContext } from './CartPopoverContext'
import { CartList } from './CartList'
import { CartError } from './CartError'

/**
 * The inner content of the cart popover that handles cart query results
 */
function CartPopoverInner (): ReactElement {
  const auth = useAuth()
  const { cart, cartItemCount, scheduledOrderCancel } = useContext(CartPopoverContext)
  const [viewTracked, toggleViewTracked] = useToggle(false)

  /**
   * Track view cart
   */
  useEffect(() => {
    if (!cart.loading && cart.data != null && cart.error == null && !viewTracked) {
      toggleViewTracked()
      trackCartView(cart.data.cart, auth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.loading, cart.data, cart.error])

  if (!cartItemCount.loading && !cart.loading &&
    (cartItemCount.error != null || cart.error != null || cart.data == null)) {
    return <CartError />
  }

  return (
    <Loading
      loading={cartItemCount.loading || cart.loading || scheduledOrderCancel.loading || cart.data == null}
      className='px-3 pb-4'
      spinnerClassName='pb-4'
    >
      {(cart.previousData != null || cart.data != null) && (
        <CartList />
      )}
    </Loading>
  )
}

export {
  CartPopoverInner
}
