import { type CSSProperties, forwardRef, type MouseEventHandler, type ReactNode } from 'react'
import clsx from 'clsx'
import { AlertIcon, Button, type ThemeColor } from '../../..'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

export interface AlertProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of the loading indicator
   */
  children?: ReactNode
  /**
   * Theme color for alert
   */
  color?: ThemeColor
  /**
   * Makes the alert background/border transparent
   */
  transparent?: boolean
  /**
   * Size of alert non-default (adjusts padding)
   */
  size?: 'sm'
  /**
   * Indicates to show the alert
   */
  show?: boolean
  /**
   * Handle when toggled from within the alert (if sent, shows close button that calls this)
   */
  onToggle?: MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLAnchorElement>
  /**
   * Optional button
   */
  button?: ReactNode | null
  /**
   * Optional icon to display to the left of the alert
   * Send `true` to show the default icon
   */
  icon?: IconProp | null | boolean
}

/**
 * Default alert icons
 */
const AlertIcons: Record<ThemeColor, IconProp> = {
  primary: fas.faInfoCircle,
  secondary: fas.faInfoCircle,
  success: fas.faCheck,
  light: fas.faInfoCircle,
  dark: fas.faInfoCircle,
  danger: fas.faExclamationCircle,
  warning: fas.faExclamationTriangle,
  info: fas.faInfoCircle
}

/**
 * Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.
 *
 * Alerts only provide the main layout/styling and a few inner components (`AlertHeading`, `AlertIcon`). For inner alert layout, use `row`, `col`, and flex helpers (see example stories).
 *
 * For easy alert state management, use `useToggle`.
 */
const Alert = forwardRef<HTMLDivElement, AlertProps>(({
  className,
  children,
  color = 'secondary',
  transparent = false,
  size,
  show = true,
  onToggle,
  button,
  icon,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    `alert alert-${color}`,
    transparent && 'alert-transparent',
    size != null && `alert-${size}`,
    onToggle != null && 'alert-dismissible',
    className
  )

  if (!show) {
    return null
  }

  return (
    <div
      className={classNames}
      ref={ref}
      role='alert'
      {...otherAttributes}
    >
      <div className='row gy-3 align-items-center'>
        <div className='col-auto flex-grow-1'>
          <div className='row gx-3 flex-nowrap'>
            {icon != null && icon !== false && (
              <div className='col-auto'>
                <AlertIcon>
                  <FontAwesomeIcon icon={icon === true ? AlertIcons[color] : icon} />
                </AlertIcon>
              </div>
            )}
            <div className='col'>
              {children}
            </div>
          </div>
        </div>
        {button != null && (
          <div className='col d-flex justify-content-end text-nowrap'>
            {button}
          </div>
        )}
      </div>
      {onToggle != null && (
        <Button onClick={onToggle} close />
      )}
    </div>
  )
})
Alert.displayName = 'Alert'

export {
  Alert
}
