import { useGetCanViewQuantityOnHandQuery, type CartLineInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { type ReactElement } from 'react'

interface CartItemInStockStatusProps {
  /**
   * The in stock status of the cart item
   */
  inStockStatus?: CartLineInfoFragment['inStockStatus']
}

/**
 * Component that will show the in stock status of a cart item
 */
function CartItemQuantityOnHand ({ inStockStatus }: CartItemInStockStatusProps): ReactElement | null {
  // TODO: Remove this query when backend handles quantity on hand viewer permission
  const { data, loading } = useGetCanViewQuantityOnHandQuery()

  if (inStockStatus?.quantityOnHand == null || data?.viewerPermissions?.canViewQuantityOnHand === false || loading) {
    return null
  }

  return (
    <div className='col-12 mt-2 d-flex justify-content-end fs-6'>
      ({inStockStatus.quantityOnHand.toLocaleString()} available)
    </div>
  )
}

export {
  CartItemQuantityOnHand
}
