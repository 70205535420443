import { type ReactElement } from 'react'
import { PdpImageCarouselModal } from './PdpImageCarouselModal'
import { PdpImageCarouselInner } from './PdpImageCarouselInner'
import { PdpImageCarouselContextProvider } from './context/PdpImageCarouselContextProvider'
import { PdpImageCarouselDetails } from './PdpImageCarouselDetails'
import { type PdpImageCarouselInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface PdpImageCarouselProps {
  /**
   * The item being shown in the carousel
   */
  item: PdpImageCarouselInfoFragment
  /**
   * The title to be shown in the modal
   */
  title?: string
}

/**
 * Carousel component for PDP images
 */
function PdpImageCarousel ({ item, title }: PdpImageCarouselProps): ReactElement | null {
  if (item == null) {
    return null
  }

  return (
    <PdpImageCarouselContextProvider item={item}>
      <PdpImageCarouselModal title={title} />
      <PdpImageCarouselInner>
        <PdpImageCarouselDetails />
      </PdpImageCarouselInner>
    </PdpImageCarouselContextProvider>
  )
}

export {
  PdpImageCarousel
}
