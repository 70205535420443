import { type AddToCartType, type ItemLineOptionsInput, ItemType, type SalesType, type ShippingCountdownKind } from '@stuller/stullercom/data-access/apollo-queries'
import { type TrackingQueryStringParams } from '@stuller/stullercom/feat/google-tag-manager'
import { createContext, type Dispatch, type SetStateAction } from 'react'

export interface AddToCartSectionContextType {
  /**
   * This is the item id of the item being added to cart
   */
  itemId: string
  /**
   * This is the item type of the item being added to cart
   */
  itemType: ItemType
  /**
   * Indicates if the user has a scheduled order
   */
  hasScheduledOrder: boolean
  /**
   * Onhand quantity for the item being added to cart
   */
  quantityOnHand: number
  /**
   * Is the serialized product a diamond
   */
  isDiamond: boolean
  /**
   * Is the product a gemstone
   */
  isGemstone: boolean
  /**
   * Is the product a lab grown
   */
  isLabGrown: boolean
  /**
   * The unrounded price of the item being added to cart
   */
  itemPrice: number
  /**
   * Callback to load the parent page's data when the add to cart options data has changed
   */
  onAddToCartDataChanged: (options: Partial<ItemLineOptionsInput>) => void
  /**
   * Item line options input for fetching new add to cart info and sending to the add to cart mutation
   */
  options: ItemLineOptionsInput | undefined | null
  /**
   * User's cutoff day for shipping
   */
  cutoffDate: string | undefined
  /**
   * Type of shipping countdown
   */
  countdownKind: ShippingCountdownKind | undefined
  /**
   * All tracking fields that can come in on the querystring from pages rendering the AddToCartSection
   */
  trackingParams?: TrackingQueryStringParams

  addToCartType: AddToCartType
  /**
   * The type of sale made by the DOT consultant
   */
  salesType?: SalesType | undefined
  /**
   * Sets the type of sale made by the DOT consultant
   */
  setSalesType: Dispatch<SetStateAction<SalesType | undefined>>
  /**
   * User-entered Customer Notes field
   */
  customerNotes: string | null
  /**
   * Handles the state of Customer Notes field
   */
  setCustomerNotes: Dispatch<SetStateAction<string | null>>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AddToCartSectionContext = createContext<AddToCartSectionContextType>({
  itemId: '',
  itemType: ItemType.Product,
  hasScheduledOrder: false,
  quantityOnHand: 0,
  isDiamond: false,
  isGemstone: false,
  isLabGrown: false,
  itemPrice: 0,
  customerNotes: '',
  setCustomerNotes: () => { },
  onAddToCartDataChanged: () => { },
  sizeAndColorMatchEnabled: false,
  orderForConsignmentEnabled: false,
  setOrderForConsignmentEnabled: () => {},
  options: {
    quantity: 1,
    isAnnealed: false,
    isForConsignment: false,
    isMatched: false,
    specialInstructions: ''
  },
  countdownKind: undefined,
  cutoffDate: '',
  trackingParams: {
    recommendationContext: undefined,
    recommendationIndex: undefined,
    recommendationSource: undefined,
    recommendationId: undefined,
    recommendationType: undefined
  },
  addToCartType: 'STANDARD',
  salesType: undefined,
  setSalesType: () => {}
} as AddToCartSectionContextType)

export {
  AddToCartSectionContext
}
