import type { SerializedDiamondPageType } from '../types'
import {
  GetSerializedPdpPagesDiamondDetailsDocument,
  type GetSerializedPdpPagesDiamondDetailsQuery
} from '@stuller/stullercom/data-access/apollo-queries'

const numbersOnlyRegex = /^\d+$/

const getSerializedDiamondPageInitialProps: SerializedDiamondPageType['getInitialProps'] = async (context) => {
  const { apolloClient } = context

  const serialNumber = context?.query?.slug?.[0].match(numbersOnlyRegex)?.[0]
  const serialNumberOverflows = parseInt(serialNumber ?? '', 10) > 2147483647

  if (serialNumber == null || serialNumberOverflows) {
    return { statusCode: 404, errorTemplate: 'serialized404' }
  }

  let serializedDiamond: GetSerializedPdpPagesDiamondDetailsQuery['serializedProductBySerialNumber'] | null = null
  let canViewDiamondRebate: boolean | null | undefined = null

  const { data } = await apolloClient.query<GetSerializedPdpPagesDiamondDetailsQuery>({
    query: GetSerializedPdpPagesDiamondDetailsDocument,
    variables: { serialNumber }
  })

  serializedDiamond = data.serializedProductBySerialNumber
  // TODO: remove these viewer permissions from the query and call a separate query to get the viewer permissions where necessary
  canViewDiamondRebate = data.viewerPermissions?.canViewDiamondRebates ?? false

  // 404 if not found or is not a diamond
  if (serializedDiamond?.isDiamond !== true) {
    return { statusCode: 404, errorTemplate: 'serialized404' }
  }

  return {
    serializedDiamond,
    serialNumber,
    canViewDiamondRebate
  }
}

export {
  getSerializedDiamondPageInitialProps
}
