import type {
  Logger,
  createLogger as createLoggerType,
  format as formatType,
  transports
} from 'winston'
import { siteConfig } from '@stuller/stullercom/util/site-config'

interface WinstonImport {
  createLogger: typeof createLoggerType
  format: typeof formatType
  transports: typeof transports
}

// Datadog server side logging with Winston
// https://docs.datadoghq.com/logs/log_collection/?tab=host#supported-endpoints
const apiKey = siteConfig.DATADOG_LOGS_SERVER_API_KEY
const env = siteConfig.NEXT_PUBLIC_DATADOG_ENVIRONMENT ?? ''
const sessionSampleRate = siteConfig.NEXT_PUBLIC_DATADOG_LOGS_SESSION_SAMPLE_RATE
const enabled = ['dev', 'prod'].includes(env) && apiKey != null && sessionSampleRate > 0

// eslint-disable-next-line import/no-mutable-exports
let serverLogger: Logger | null = null

/**
 * Initalizes Datadog Logs server-side
 */
function initDatadogServerLogs (): void {
  if (typeof window === 'undefined' && serverLogger == null && enabled) {
    const { createLogger, format, transports }: WinstonImport = require('winston')

    // Configure Datadog transport
    const httpTransport = new transports.Http({
      host: 'http-intake.logs.datadoghq.com',
      path: `/api/v2/logs?dd-api-key=${apiKey}&ddsource=nodejs&service=stullercom-site&ddtags=env:${env},service:stullercom-site`,
      ssl: true
    })

    serverLogger = createLogger({
      // Only forward errors to Datadog
      level: 'error',
      defaultMeta: { service: 'stullercom-site' },
      exitOnError: false,
      format: format.json(),
      transports: httpTransport
    })
  }
}

export {
  initDatadogServerLogs,
  serverLogger
}
