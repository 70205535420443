import type { CartLine } from '../shared/types'
import { type ReactElement } from 'react'
import { CartItem } from '../cart-item/CartItem'
import { useListTransition } from 'transition-hooks'

interface CartItemListProps {
  /**
   * The cart lines to display
   */
  cartLines: CartLine[]
  /**
   * Disable input fields in the cart item
   */
  disableFormChanges?: boolean
}

/**
 * A list of cart items with animation transition
 */
function CartItemList ({
  cartLines,
  disableFormChanges = false
}: CartItemListProps): ReactElement {
  const { transitionList } = useListTransition(cartLines ?? [], {
    timeout: 500,
    keyExtractor: (i) => i.id
  })

  return (
    <>
      {transitionList((cartLine, { key, simpleStatus }) => {
        return (
          <CartItem
            cartLine={cartLine}
            key={key}
            style={{
              opacity: simpleStatus === 'exit' ? 0 : 1,
              maxHeight: simpleStatus === 'exit' ? 0 : 5000,
              transition: 'all .5s',
              scrollMarginTop: 10
            }}
            disableFormChanges={disableFormChanges}
          />
        )
      })}
    </>
  )
}

export {
  CartItemList
}
