import { type ChangeEvent, useState, type ReactElement } from 'react'
import { FormGroup, FormLabel, Input } from '@stuller/stullercom/ui'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { ConsignmentDescriptionModal, ConsignmentTermsModal } from '@stuller/stullercom/feat/cart-page'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { useToggle } from '@stuller/shared/util/react-hooks'
import {
  useCartPageCartItemAcceptConsignmentTermsMutation,
  useGetAddToCartNeedsToAgreeToConsignmentTermsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { trackEvent } from '@stuller/stullercom/feat/google-tag-manager'

/**
 * Checkbox component for matching size and color
 */
function AddToCartConsignmentCheckbox (): ReactElement | null {
  const { options, onAddToCartDataChanged } = useAddToCartSectionContext()
  const { isForConsignment } = options ?? { isForConsignment: false }
  const [consignmentDescriptionModalIsOpen, toggleConsignmentDescriptionModalIsOpen] = useToggle(false)
  const [consignmentTermsModalIsOpen, setConsignmentTermsModalIsOpen] = useState(false)
  const [checkboxIsForConsignment, setCheckboxIsForConsignment] = useState(isForConsignment ?? false)
  const { data } = useGetAddToCartNeedsToAgreeToConsignmentTermsQuery({ })

  /**
   * Function to handle the change of the checkbox
   */
  async function handleOnChange (event: ChangeEvent<HTMLInputElement>): Promise<void> {
    const isChecked = event.target.checked

    if (isChecked && data?.viewer?.needsToAgreeToConsignmentTerms === true) {
      setConsignmentTermsModalIsOpen(true)
    } else {
      setCheckboxIsForConsignment(isChecked)
      onAddToCartDataChanged({ isForConsignment: isChecked })
    }

    trackEvent('configure', {
      section: 'add_to_cart_section',
      input_name: 'Item Options',
      input_type: 'checkbox',
      input_value: 'Order for Review/Conditional Sale',
      input_checked: isChecked
    })
  }

  /**
   * Mutation to accept consignment terms
   */
  const [acceptConsignmentTermMutation, { loading: consignmentLoading }] = useCartPageCartItemAcceptConsignmentTermsMutation({
    variables: {},
    onError: (error) => {
      logger.error('Error accepting consignment terms', {}, error)
      throw error
    },
    onCompleted: () => {
      setCheckboxIsForConsignment(true)
      onAddToCartDataChanged({ isForConsignment: true })
      setConsignmentTermsModalIsOpen(false)
    }
  })

  return (
    <>
      <div className='row align-items-center'>
        <div className='col'>
          <FormGroup groupId='order-on-consignment' check inline className='mb-0 me-0 w-100'>
            <Input
              type='checkbox'
              checked={checkboxIsForConsignment}
              onChange={handleOnChange}
            />
            <FormLabel check className='mb-0 fw-bold'>
              Order for review/conditional sale
            </FormLabel>
          </FormGroup>
        </div>
        <div onClick={toggleConsignmentDescriptionModalIsOpen} className='cursor-pointer col-auto'>
          <FontAwesomeIcon icon={fas.faInfoCircle} className='text-gray-500 fs-4' />
        </div>
      </div>
      <ConsignmentTermsModal
        isOpen={consignmentTermsModalIsOpen}
        onClose={() => { setConsignmentTermsModalIsOpen(false) }}
        onAcceptConsignmentTerms={acceptConsignmentTermMutation}
        loading={consignmentLoading}
      />
      <ConsignmentDescriptionModal isOpen={consignmentDescriptionModalIsOpen} onToggle={toggleConsignmentDescriptionModalIsOpen} />
    </>
  )
}

export {
  AddToCartConsignmentCheckbox
}
