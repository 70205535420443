
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AccordionAccordionItemsBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "AccordionAccordionItemsHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "AccordionAccordionItemsTargetingNode": [
      "Targeting"
    ],
    "AccordionRichTextBelowAccordionEmbeddedItemsNode": [
      "SysAsset"
    ],
    "AccordionTargetingNode": [
      "Targeting"
    ],
    "AlertBodyHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "AlertBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "BlogCardGroupCardsBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "BlogCardGroupCardsHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "BlogCardGroupCardsHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "BlogCardGroupCardsLinkTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "BlogCardGroupTargetingNode": [
      "Targeting"
    ],
    "BreadcrumbsBreadcrumbNode": [
      "Breadcrumb"
    ],
    "BulletListItemsRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ButtonModalNode": [
      "Modal"
    ],
    "ButtonTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CallUsFeatureBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CallUsFeatureTargetingNode": [
      "Targeting"
    ],
    "CalloutNavHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CalloutNavTargetingNode": [
      "Targeting"
    ],
    "CardGroupCardsBodyBulletListNode": [
      "BulletList"
    ],
    "CardGroupCardsBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CardGroupCardsHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CardGroupCardsHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CardGroupCardsTargetingNode": [
      "Targeting"
    ],
    "CardGroupTargetingNode": [
      "Targeting"
    ],
    "CardWithBackgroundGroupCardsWithBackgroundBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CardWithBackgroundGroupCardsWithBackgroundHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CardWithBackgroundGroupCardsWithBackgroundTargetingNode": [
      "Targeting"
    ],
    "CardWithBackgroundGroupTargetingNode": [
      "Targeting"
    ],
    "CartPageBottomRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "CategoryPagePageContents": [
      "CategoryPagePageContentsBlogCardGroup",
      "CategoryPagePageContentsCardGroup",
      "CategoryPagePageContentsCardWithBackgroundGroup",
      "CategoryPagePageContentsEscapeHatch",
      "CategoryPagePageContentsLargeShoppingBuckets",
      "CategoryPagePageContentsLegacyCmsContent",
      "CategoryPagePageContentsMediaCalloutGroup",
      "CategoryPagePageContentsProductCardCarousel",
      "CategoryPagePageContentsPublicationCarousel",
      "CategoryPagePageContentsRow",
      "CategoryPagePageContentsSearchBySerialNumber",
      "CategoryPagePageContentsSmallShoppingBuckets",
      "CategoryPagePageContentsStoneShapeIcons",
      "CategoryPagePageContentsTopic",
      "CategoryPagePageContentsVerticalMediaBoxGroup",
      "CategoryPagePageContentsVideoCarousel"
    ],
    "CategoryPagePageContentsBlogCardGroupBlockBlogCardGroupNode": [
      "BlogCardGroup"
    ],
    "CategoryPagePageContentsCardGroupBlockCardGroupNode": [
      "CardGroup"
    ],
    "CategoryPagePageContentsCardWithBackgroundGroupBlockCardWithBackgroundGroupNode": [
      "CardWithBackgroundGroup"
    ],
    "CategoryPagePageContentsEscapeHatchBlockEscapeHatchNode": [
      "EscapeHatch"
    ],
    "CategoryPagePageContentsLargeShoppingBucketsBlockLargeShoppingBucketsNode": [
      "LargeShoppingBuckets"
    ],
    "CategoryPagePageContentsLegacyCmsContentBlockLegacyCmsContentNode": [
      "LegacyCmsContent"
    ],
    "CategoryPagePageContentsMediaCalloutGroupBlockMediaCalloutGroupNode": [
      "MediaCalloutGroup"
    ],
    "CategoryPagePageContentsProductCardCarouselBlockProductCardCarouselNode": [
      "ProductCardCarousel"
    ],
    "CategoryPagePageContentsPublicationCarouselBlockPublicationCarouselNode": [
      "PublicationCarousel"
    ],
    "CategoryPagePageContentsRowBlockRowNode": [
      "Row"
    ],
    "CategoryPagePageContentsSearchBySerialNumberBlockSearchBySerialNumberNode": [
      "SearchBySerialNumber"
    ],
    "CategoryPagePageContentsSmallShoppingBucketsBlockSmallShoppingBucketsNode": [
      "SmallShoppingBuckets"
    ],
    "CategoryPagePageContentsStoneShapeIconsBlockStoneShapeIconsNode": [
      "StoneShapeIcons"
    ],
    "CategoryPagePageContentsTopicBlockTopicNode": [
      "Topic"
    ],
    "CategoryPagePageContentsVerticalMediaBoxGroupBlockReferenceNode": [
      "VerticalMediaBoxGroup"
    ],
    "CategoryPagePageContentsVideoCarouselBlockVideoCarouselNode": [
      "VideoCarousel"
    ],
    "CategoryPagePageHeaderNode": [
      "PageHeader"
    ],
    "ContentPagePageContents": [
      "ContentPagePageContentsAccordion",
      "ContentPagePageContentsBackgroundCardGroup",
      "ContentPagePageContentsBlogCardGroup",
      "ContentPagePageContentsCallUsFeature",
      "ContentPagePageContentsCardGroup",
      "ContentPagePageContentsDirectoryCardGroup",
      "ContentPagePageContentsEscapeHatch",
      "ContentPagePageContentsExpandableImageGroup",
      "ContentPagePageContentsHighlightFeature",
      "ContentPagePageContentsLargeShoppingBuckets",
      "ContentPagePageContentsLegacyCmsContent",
      "ContentPagePageContentsMediaBoxGroup",
      "ContentPagePageContentsMediaCalloutGroup",
      "ContentPagePageContentsPopOutFeature",
      "ContentPagePageContentsProductCardCarousel",
      "ContentPagePageContentsPublicationCarousel",
      "ContentPagePageContentsRow",
      "ContentPagePageContentsShoppableFeature",
      "ContentPagePageContentsSmallShoppingBuckets",
      "ContentPagePageContentsSocialIconsFeature",
      "ContentPagePageContentsSplitTopic",
      "ContentPagePageContentsStatisticsCardGroup",
      "ContentPagePageContentsTestimonials",
      "ContentPagePageContentsTopic",
      "ContentPagePageContentsVerticalBackgroundCardGroup",
      "ContentPagePageContentsVerticalMediaBoxGroup",
      "ContentPagePageContentsVideoCarousel"
    ],
    "ContentPagePageContentsAccordionBlockAccordionNode": [
      "Accordion"
    ],
    "ContentPagePageContentsBackgroundCardGroupBlockBackgroundCardGroupNode": [
      "CardWithBackgroundGroup"
    ],
    "ContentPagePageContentsBlogCardGroupBlockBlogCardGroupNode": [
      "BlogCardGroup"
    ],
    "ContentPagePageContentsCallUsFeatureBlockCallUsFeatureNode": [
      "CallUsFeature"
    ],
    "ContentPagePageContentsCardGroupBlockCardGroupNode": [
      "CardGroup"
    ],
    "ContentPagePageContentsDirectoryCardGroupBlockDirectoryCardGroupNode": [
      "DirectoryCardGroup"
    ],
    "ContentPagePageContentsEscapeHatchBlockEscapeHatchNode": [
      "EscapeHatch"
    ],
    "ContentPagePageContentsExpandableImageGroupBlockExpandableImageGroupNode": [
      "ExpandableImageGroup"
    ],
    "ContentPagePageContentsHighlightFeatureBlockHighlightFeatureNode": [
      "HighlightFeature"
    ],
    "ContentPagePageContentsLargeShoppingBucketsBlockLargeShoppingBucketsNode": [
      "LargeShoppingBuckets"
    ],
    "ContentPagePageContentsLegacyCmsContentBlockLegacyCmsContentNode": [
      "LegacyCmsContent"
    ],
    "ContentPagePageContentsMediaBoxGroupBlockMediaBoxGroupNode": [
      "MediaBoxGroup"
    ],
    "ContentPagePageContentsMediaCalloutGroupBlockMediaCalloutGroupNode": [
      "MediaCalloutGroup"
    ],
    "ContentPagePageContentsPopOutFeatureBlockPopOutFeatureNode": [
      "PopOutFeature"
    ],
    "ContentPagePageContentsProductCardCarouselBlockProductCardCarouselNode": [
      "ProductCardCarousel"
    ],
    "ContentPagePageContentsPublicationCarouselBlockPublicationCarouselNode": [
      "PublicationCarousel"
    ],
    "ContentPagePageContentsRowBlockRowNode": [
      "Row"
    ],
    "ContentPagePageContentsShoppableFeatureBlockShoppableFeatureNode": [
      "ShoppableFeature"
    ],
    "ContentPagePageContentsSmallShoppingBucketsBlockSmallShoppingBucketsNode": [
      "SmallShoppingBuckets"
    ],
    "ContentPagePageContentsSocialIconsFeatureBlockSocialIconsFeatureNode": [
      "SocialIconsFeature"
    ],
    "ContentPagePageContentsSplitTopicBlockSplitTopicNode": [
      "SplitTopic"
    ],
    "ContentPagePageContentsStatisticsCardGroupBlockStatisticsCardGroupNode": [
      "StatisticsCardGroup"
    ],
    "ContentPagePageContentsTestimonialsBlockTestimonialsNode": [
      "Testimonials"
    ],
    "ContentPagePageContentsTopicBlockTopicNode": [
      "Topic"
    ],
    "ContentPagePageContentsVerticalBackgroundCardGroupBlockVerticalBackgroundCardGroupNode": [
      "VerticalBackgroundCardGroup"
    ],
    "ContentPagePageContentsVerticalMediaBoxGroupBlockVerticalMediaBoxGroupNode": [
      "VerticalMediaBoxGroup"
    ],
    "ContentPagePageContentsVideoCarouselBlockVideoCarouselNode": [
      "VideoCarousel"
    ],
    "ContentPagePageHeaderNode": [
      "PageHeader"
    ],
    "ContentPageScrollNavNode": [
      "CalloutNav",
      "ScrollNav"
    ],
    "DirectoryCardGroupDirectoryCardsBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "DirectoryCardGroupDirectoryCardsDirectoryLinksLinkTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "DirectoryCardGroupDirectoryCardsHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "DirectoryCardGroupDirectoryCardsTargetingNode": [
      "Targeting"
    ],
    "DirectoryCardGroupDirectoryCardsViewAllLinkViewAllLinkTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "DirectoryCardGroupTargetingNode": [
      "Targeting"
    ],
    "EventDetailPageEventInfoDescriptionEmbeddedItemsNode": [
      "SysAsset"
    ],
    "EventDetailPagePageContents": [
      "EventDetailPagePageContentsAccordion",
      "EventDetailPagePageContentsBlogCardGroup",
      "EventDetailPagePageContentsCardGroup",
      "EventDetailPagePageContentsLegacyCmsContent",
      "EventDetailPagePageContentsRow",
      "EventDetailPagePageContentsTestimonials",
      "EventDetailPagePageContentsTopic",
      "EventDetailPagePageContentsVerticalBackgroundCardGroup",
      "EventDetailPagePageContentsVerticalMediaBoxGroup",
      "EventDetailPagePageContentsVideoCarousel"
    ],
    "EventDetailPagePageContentsAccordionBlockAccordionNode": [
      "Accordion"
    ],
    "EventDetailPagePageContentsBlogCardGroupBlockBlogCardGroupNode": [
      "BlogCardGroup"
    ],
    "EventDetailPagePageContentsCardGroupBlockCardGroupNode": [
      "CardGroup"
    ],
    "EventDetailPagePageContentsLegacyCmsContentBlockLegacyCmsContentNode": [
      "LegacyCmsContent"
    ],
    "EventDetailPagePageContentsRowBlockRowNode": [
      "Row"
    ],
    "EventDetailPagePageContentsTestimonialsBlockTestimonialsNode": [
      "Testimonials"
    ],
    "EventDetailPagePageContentsTopicBlockTopicNode": [
      "Topic"
    ],
    "EventDetailPagePageContentsVerticalBackgroundCardGroupBlockVerticalBackgroundCardGroupNode": [
      "VerticalBackgroundCardGroup"
    ],
    "EventDetailPagePageContentsVerticalMediaBoxGroupBlockVerticalMediaBoxGroupNode": [
      "VerticalMediaBoxGroup"
    ],
    "EventDetailPagePageContentsVideoCarouselBlockVideoCarouselNode": [
      "VideoCarousel"
    ],
    "EventDetailPagePageHeaderNode": [
      "PageHeader"
    ],
    "EventDetailPageScrollNavNode": [
      "CalloutNav",
      "ScrollNav"
    ],
    "EventsTrainingsListingPageBottomPageContents": [
      "EventsTrainingsListingPageBottomPageContentsAccordion",
      "EventsTrainingsListingPageBottomPageContentsRow"
    ],
    "EventsTrainingsListingPageBottomPageContentsAccordionBlockAccordionNode": [
      "Accordion"
    ],
    "EventsTrainingsListingPageBottomPageContentsRowBlockRowNode": [
      "Row"
    ],
    "EventsTrainingsListingPagePageHeaderNode": [
      "PageHeader"
    ],
    "EventsTrainingsListingPageScrollNavNode": [
      "ScrollNav"
    ],
    "EventsTrainingsListingPageTopPageContents": [
      "EventsTrainingsListingPageTopPageContentsCardGroup",
      "EventsTrainingsListingPageTopPageContentsTestimonials",
      "EventsTrainingsListingPageTopPageContentsTopic",
      "EventsTrainingsListingPageTopPageContentsVerticalBackgroundCardGroup",
      "EventsTrainingsListingPageTopPageContentsVerticalMediaBoxGroup"
    ],
    "EventsTrainingsListingPageTopPageContentsCardGroupBlockCardGroupNode": [
      "CardGroup"
    ],
    "EventsTrainingsListingPageTopPageContentsTestimonialsBlockTestimonialsNode": [
      "Testimonials"
    ],
    "EventsTrainingsListingPageTopPageContentsTopicBlockTopicNode": [
      "Topic"
    ],
    "EventsTrainingsListingPageTopPageContentsVerticalBackgroundCardGroupBlockVerticalBackgroundCardGroupNode": [
      "VerticalBackgroundCardGroup"
    ],
    "EventsTrainingsListingPageTopPageContentsVerticalMediaBoxGroupBlockVerticalMediaBoxGroupNode": [
      "VerticalMediaBoxGroup"
    ],
    "EventsTrainingsListingPageTradeShowCardGroupTradeShowCardsHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "EventsTrainingsListingPageTradeShowCardGroupTradeShowCardsLocationsDescriptionEmbeddedItemsNode": [
      "SysAsset"
    ],
    "EventsTrainingsListingPageTradeShowCardGroupTradeShowCardsLocationsNameEmbeddedItemsNode": [
      "SysAsset"
    ],
    "EventsTrainingsListingPageTradeShowCardGroupTradeShowCardsRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "EventsTrainingsListingPageTrainingCardGroupTrainingsNode": [
      "TrainingDetailPage"
    ],
    "ExpandableImageGroupExpandableImagesHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ExpandableImageGroupExpandableImagesHeaderHoverTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ExpandableImageGroupExpandableImagesTargetingNode": [
      "Targeting"
    ],
    "ExpandableImageGroupTargetingNode": [
      "Targeting"
    ],
    "HighlightFeatureMediaDetailsHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "HighlightFeatureTargetingNode": [
      "Targeting"
    ],
    "IconListItemsHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "IconListItemsRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "LargeShoppingBucketsShoppingBucketsBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "LargeShoppingBucketsShoppingBucketsHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "LargeShoppingBucketsShoppingBucketsHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "LargeShoppingBucketsShoppingBucketsTargetingNode": [
      "Targeting"
    ],
    "LargeShoppingBucketsTargetingNode": [
      "Targeting"
    ],
    "MediaBoxGroupMediaBoxesBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "MediaBoxGroupMediaBoxesHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "MediaBoxGroupMediaBoxesHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "MediaBoxGroupMediaBoxesTargetingNode": [
      "Targeting"
    ],
    "MediaBoxGroupTargetingNode": [
      "Targeting"
    ],
    "MediaCalloutGroupMediaCalloutsHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "MediaCalloutGroupMediaCalloutsLinkTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "MediaCalloutGroupMediaCalloutsRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "MediaCalloutGroupMediaCalloutsTargetingNode": [
      "Targeting"
    ],
    "MediaCalloutGroupTargetingNode": [
      "Targeting"
    ],
    "ModalBody": [
      "ModalBodyEscapeHatch",
      "ModalBodyFormstackForm",
      "ModalBodyImage",
      "ModalBodyLegacyCmsContent",
      "ModalBodyRichText",
      "ModalBodyVideo"
    ],
    "ModalBodyEscapeHatchBlockEscapeHatchNode": [
      "EscapeHatch"
    ],
    "ModalBodyFormstackFormBlockFormstackFormNode": [
      "FormstackForm"
    ],
    "ModalBodyLegacyCmsContentBlockLegacyCmsContentNode": [
      "LegacyCmsContent"
    ],
    "ModalBodyRichTextBlockRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ModalHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ModalHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "PageHeaderBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "PageHeaderHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "PageHeaderHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "PageHeaderTargetingNode": [
      "Targeting"
    ],
    "PopOutFeatureBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "PopOutFeatureHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "PopOutFeatureTargetingNode": [
      "Targeting"
    ],
    "PreferenceCenterPageTopContentNode": [
      "Alert"
    ],
    "ProductCardCarouselBodyRichTextBelowCarouselEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ProductCardCarouselTargetingNode": [
      "Targeting"
    ],
    "PublicationCarouselPublicationsHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "PublicationCarouselPublicationsRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "PublicationCarouselTargetingNode": [
      "Targeting"
    ],
    "RowColumnContent": [
      "RowColumnContentAlert",
      "RowColumnContentBulletList",
      "RowColumnContentButton",
      "RowColumnContentFormstackForm",
      "RowColumnContentIconList",
      "RowColumnContentLegacyCmsContent",
      "RowColumnContentRichText",
      "RowColumnContentSimpleImage",
      "RowColumnContentSimpleImageWithLink",
      "RowColumnContentTable",
      "RowColumnContentVideo"
    ],
    "RowColumnContentAlertBlockAlertNode": [
      "Alert"
    ],
    "RowColumnContentBulletListBlockBulletListNode": [
      "BulletList"
    ],
    "RowColumnContentFormstackFormBlockFormstackFormNode": [
      "FormstackForm"
    ],
    "RowColumnContentIconListBlockIconListNode": [
      "IconList"
    ],
    "RowColumnContentLegacyCmsContentBlockLegacyCmsContentNode": [
      "LegacyCmsContent"
    ],
    "RowColumnContentRichTextBlockRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "RowColumnsNode": [
      "RowColumn"
    ],
    "RowTargetingNode": [
      "Targeting"
    ],
    "ScrollNavNavigationLinksNameEmbeddedItemsNode": [
      "SysAsset"
    ],
    "SearchBySerialNumberTargetingNode": [
      "Targeting"
    ],
    "SectionHeaderBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "SectionHeaderHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ServicesListingPageBottomContent": [
      "ServicesListingPageBottomContentBackgroundCardGroup",
      "ServicesListingPageBottomContentTopic"
    ],
    "ServicesListingPageBottomContentBackgroundCardGroupBlockBackgroundCardGroupNode": [
      "CardWithBackgroundGroup"
    ],
    "ServicesListingPageBottomContentTopicBlockTopicNode": [
      "Topic"
    ],
    "ServicesListingPagePageHeaderNode": [
      "PageHeader"
    ],
    "ServicesListingPageServicesCategories": [
      "ServicesListingPageServicesCategoriesContent"
    ],
    "ServicesListingPageServicesCategoriesContentBlockBottomContent": [
      "ServicesListingPageServicesCategoriesContentBlockBottomContentPopOutFeature"
    ],
    "ServicesListingPageServicesCategoriesContentBlockBottomContentPopOutFeatureBlockPopOutFeatureNode": [
      "PopOutFeature"
    ],
    "ServicesListingPageServicesCategoriesContentBlockServicesCardsBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ServicesListingPageServicesCategoriesContentBlockServicesCardsHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ServicesListingPageServicesCategoriesContentBlockServicesCategoryInfoTitleEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ServicesListingPageServicesCategoriesContentBlockTopContent": [
      "ServicesListingPageServicesCategoriesContentBlockTopContentTopic"
    ],
    "ServicesListingPageServicesCategoriesContentBlockTopContentTopicBlockTopicNode": [
      "Topic"
    ],
    "ServicesListingPageViewAllInfoTitleEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ServicesListingPageViewAllTopContent": [
      "ServicesListingPageViewAllTopContentTopic"
    ],
    "ServicesListingPageViewAllTopContentTopicBlockTopicNode": [
      "Topic"
    ],
    "ShoppableFeatureBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ShoppableFeatureHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "ShoppableFeatureTargetingNode": [
      "Targeting"
    ],
    "SmallShoppingBucketsShoppingBucketsHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "SmallShoppingBucketsShoppingBucketsTargetingNode": [
      "Targeting"
    ],
    "SmallShoppingBucketsTargetingNode": [
      "Targeting"
    ],
    "SocialIconsFeatureBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "SocialIconsFeatureTargetingNode": [
      "Targeting"
    ],
    "SplitTopicSplitTopicsHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "SplitTopicSplitTopicsHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "SplitTopicSplitTopicsTargetingNode": [
      "Targeting"
    ],
    "SplitTopicTargetingNode": [
      "Targeting"
    ],
    "StatisticsCardGroupRichTextBelowCardsEmbeddedItemsNode": [
      "SysAsset"
    ],
    "StatisticsCardGroupStatisticsCardsRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "StatisticsCardGroupStatisticsCardsTargetingNode": [
      "Targeting"
    ],
    "StatisticsCardGroupStatisticsCardsValueEmbeddedItemsNode": [
      "SysAsset"
    ],
    "StatisticsCardGroupTargetingNode": [
      "Targeting"
    ],
    "StoneShapeIconsTargetingNode": [
      "Targeting"
    ],
    "TableRichTextTableEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TestimonialsTargetingNode": [
      "Targeting"
    ],
    "TestimonialsTestimonialsQuoteEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TestimonialsTestimonialsSourceDescriptionEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TestimonialsTestimonialsSourceNameEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TestimonialsTestimonialsTargetingNode": [
      "Targeting"
    ],
    "TopicBodyBulletListsNode": [
      "BulletList"
    ],
    "TopicBodyIconListsNode": [
      "IconList"
    ],
    "TopicBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TopicHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TopicHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TopicTargetingNode": [
      "Targeting"
    ],
    "TrainingDetailPageAccomodationsNode": [
      "CardGroup"
    ],
    "TrainingDetailPageContactUsFormNode": [
      "LegacyCmsContent"
    ],
    "TrainingDetailPageFaqsNode": [
      "Accordion"
    ],
    "TrainingDetailPageMoreWaysToTrainNode": [
      "CardGroup"
    ],
    "TrainingDetailPagePageContents": [
      "TrainingDetailPagePageContentsCardGroup"
    ],
    "TrainingDetailPagePageContentsCardGroupBlockCardGroupNode": [
      "CardGroup"
    ],
    "TrainingDetailPageSchedulesCardGroupNode": [
      "CardGroup"
    ],
    "TrainingDetailPageScrollNavNode": [
      "ScrollNav"
    ],
    "TrainingDetailPageTrainingCardCardDescriptionEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TrainingDetailPageTrainingInfoDescriptionEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TrainingDetailPageTrainingInfoHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "TrainingDetailPageWhyShouldIAttendNode": [
      "CardGroup"
    ],
    "VerticalBackgroundCardGroupTargetingNode": [
      "Targeting"
    ],
    "VerticalBackgroundCardGroupVerticalBackgroundCardsHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "VerticalBackgroundCardGroupVerticalBackgroundCardsTargetingNode": [
      "Targeting"
    ],
    "VerticalMediaBoxGroupTargetingNode": [
      "Targeting"
    ],
    "VerticalMediaBoxGroupVerticalMediaBoxesBodyRichTextEmbeddedItemsNode": [
      "SysAsset"
    ],
    "VerticalMediaBoxGroupVerticalMediaBoxesHeaderHeadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "VerticalMediaBoxGroupVerticalMediaBoxesHeaderSubheadingEmbeddedItemsNode": [
      "SysAsset"
    ],
    "VerticalMediaBoxGroupVerticalMediaBoxesTargetingNode": [
      "Targeting"
    ],
    "VideoCarouselBodyRichTextBelowCarouselEmbeddedItemsNode": [
      "SysAsset"
    ],
    "VideoCarouselTargetingNode": [
      "Targeting"
    ]
  }
};
      export default result;
    