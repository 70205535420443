import { type ChangeEvent, type ReactElement } from 'react'
import { FormGroup, FormLabel, Textarea } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'

/**
 * Form Group for Customer Notes input field
 */
function AddToCartCustomerNotes (): ReactElement {
  const { isShowcase } = useAuth()
  const { customerNotes, setCustomerNotes } = useAddToCartSectionContext()

  function handleCustomerNotesChanged (event: ChangeEvent<HTMLTextAreaElement>): void {
    const value = event.target.value
    setCustomerNotes(value)
  }

  return (
    <FormGroup>
      <FormLabel>
        Customer Notes
        {!isShowcase && (
          <span className='fw-normal ms-2'>
            (for your records)
          </span>
        )}
      </FormLabel>
      <Textarea rows={1} value={customerNotes ?? ''} onChange={handleCustomerNotesChanged} />
    </FormGroup>
  )
}

export {
  AddToCartCustomerNotes
}
