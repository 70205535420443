import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { Button } from '@stuller/stullercom/ui'
import {
  useGetUnsupportedBannerMinimumSupportedBrowsersQuery,
  type GetUnsupportedBannerMinimumSupportedBrowsersQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { useUnsupported } from './useUnsupported'
import { UnsupportedBrowserModal } from './UnsupportedBrowserModal'

interface BannerProps {
  /**
   * Minimal browsers
   */
  browsers: GetUnsupportedBannerMinimumSupportedBrowsersQuery['cmsLookupsByType']
}

/**
 * Shows the unsupported browser banner
 */
function Banner ({ browsers }: BannerProps): ReactElement | null {
  const browser = useUnsupported(browsers)
  const [isOpen, toggleIsOpen] = useToggle()

  if (browser == null) {
    return null
  }

  return (
    <>
      <div className='bg-blue-500 text-white d-print-none'>
        <div className='container-xxxl mw-xxl py-3 px-4'>
          <div className='row align-items-center justify-content-center gy-3'>
            <div className='col-auto'>
              <FontAwesomeIcon icon={fas.faBrowser} className='text-white' size='2x' />
            </div>
            <div className='col'>
              <div className='fs-7 text-uppercase lh-sm ls-1 ws-3'>UNSUPPORTED BROWSER</div>
              <div className='fw-bold'>
                {browser.message ?? (
                  <>
                    Stuller.com does not support this version of {browser.name}.
                    This browser is out of date and may cause websites like Stuller.com to function improperly.
                  </>
                )}
              </div>
            </div>
            <div className='col-12 col-sm-auto ms-sm-6'>
              <Button color='secondary' outline className='bg-white bg-secondary-hover w-100 w-sm-auto' onClick={toggleIsOpen}>Upgrade My Browser</Button>
            </div>
          </div>
        </div>
      </div>
      <UnsupportedBrowserModal isOpen={isOpen} onToggle={toggleIsOpen} />
    </>
  )
}

/**
 * Banner for unsupported browser
 */
function UnsupportedBrowserBanner (): ReactElement | null {
  const { data } = useGetUnsupportedBannerMinimumSupportedBrowsersQuery()

  if (data == null) {
    return null
  }

  return <Banner browsers={data.cmsLookupsByType} />
}

export {
  UnsupportedBrowserBanner
}
