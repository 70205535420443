import { memo, type ReactElement, useState } from 'react'
import { createPortal } from 'react-dom'
import { useStullerEventListener, type StullerEvents } from '@stuller/stullercom/feat/stuller-events'
import { useMutationObserver } from '@stuller/shared/util/react-hooks'
import { components } from './components'

/**
 * An individual React mount via `createPortal`
 */
const ReactMountPortal = memo(({ target, name, props }: StullerEvents['react-mount']): ReactElement => {
  const Component = components[name]

  return createPortal(<Component {...props ?? {}} />, target)
})
ReactMountPortal.displayName = 'ReactMountPortal'

/**
 * Listens for 'react-mount' Stuller events to mount React components to targets
 * **Note**: this should only be used from CMS and not from other React code
 *
 * Mounting:
 * This component uses portals to mount React components on the targets emitted
 * If the same target is called multiple times, the React component at that target will be replaced
 * If the same target is called with the same component, that component's state will carry on
 *
 * Disposing:
 * React mount are automatically disposed when the target is removed from the DOM (using `MutationObserver`)
 */
const ReactMount = memo(() => {
  const [portals, setPortals] = useState<Array<StullerEvents['react-mount']>>([])

  // Watch for changes to the DOM to remove portals when the elements are removed
  useMutationObserver(() => {
    const removeTargets: HTMLElement[] = []
    for (const portal of portals) {
      if (!document.body.contains(portal.target)) {
        removeTargets.push(portal.target)
      }
    }

    if (removeTargets.length > 0) {
      setPortals((ps) => ps.filter((p) => !removeTargets.some((t) => t === p.target)))
    }
  })

  // Wait for events to add new mounts
  useStullerEventListener('react-mount', ({ detail }) => {
    const { target, name } = detail ?? {}

    if (detail == null || target == null || !document.body.contains(target)) {
      throw new Error('react-mount target not found')
    }

    if (name == null || components[name] == null) {
      throw new Error('react-mount component name not found')
    }

    // If info sent looks good, add/replace new React mount
    setPortals((ps) => {
      const newP = [...ps]

      if (detail != null) {
        const index = ps.findIndex((p) => p.target === target)

        if (index > -1) {
          newP[index] = detail
        } else {
          newP.push(detail)
        }
      }

      return newP
    })
  })

  return (
    <>
      {portals.map((p, i) => <ReactMountPortal key={i} {...p} />)}
    </>
  )
})
ReactMount.displayName = 'ReactMount'

export {
  ReactMount
}
