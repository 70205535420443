import { type ReactElement, useState, useEffect } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CartItemList } from '../cart-list/CartItemList'
import {
  useCartPageCartListMoveAllLinesToCartMutation,
  type CartLineMessageInfoFragment,
  useGetCartByUserIdQuery,
  useCartPageCartItemUpdateCartLineWithCartListItemMutation,
  useCartPageCartItemDeleteCartLineWithCartListItemMutation,
  GetCustomersWithCartsDocument,
  GetCartItemCountDocument,
  GetCartPageCartSearchPageCustomersWithCartsCountDocument
} from '@stuller/stullercom/data-access/apollo-queries'
import { Button } from '@stuller/stullercom/ui'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { CartAlert } from '../shared/CartAlert'
import { type UsersWithCart } from '../shared/types'
import { trackCartListItemAddToCart } from '@stuller/stullercom/feat/google-tag-manager'
import { CartContext } from '../shared/CartContext'

interface MoveAllLinesToCartButtonProps {
  /**
   * The onClick function for Move All button
   */
  onClick: () => Promise<any>
}

interface CartSearchPageCartProps {
  /**
   * The selected customer data
   */
  selectedCustomer: UsersWithCart
  /**
   * Set the selected customer
   */
  setSelectedCustomer: (customer: UsersWithCart) => void
  /**
   * Set the loading state
   */
  setLoading: (loading: boolean) => void
  /**
   * Set the cart data loading state
   */
  setCartDataExists: (loading: boolean) => void
}

/**
 * The Move All button that is rendered and handles the promise
 */
function MoveAllLinesToCartButton ({ onClick }: MoveAllLinesToCartButtonProps): ReactElement {
  return (
    <Button
      color='success'
      className='w-auto'
      onClick={onClick}
      data-test='move-all-items-to-consultant-cart'
    >
      Move All Items to Consultant Carts
    </Button>
  )
}

function CartSearchPageCart ({
  selectedCustomer,
  setSelectedCustomer,
  setLoading,
  setCartDataExists
}: CartSearchPageCartProps): ReactElement {
  const [actionError, setActionError] = useState<CartLineMessageInfoFragment[] | null>(null)
  const auth = useAuth()
  const { data: cartData, loading: cartDataLoading } = useGetCartByUserIdQuery({
    variables: {
      userId: selectedCustomer?.id ?? ''
    },
    skip: selectedCustomer?.id === 'default',
    onError: (error) => {
      setActionError([{ code: 'CUSTOMER_CART_ERROR', message: 'There was an issue loading the cart' }])
      logger.error('Could not get customer cart: ', {}, error)
    },
    fetchPolicy: 'network-only'
  })

  /**
   * Move all lines to cart mutation
   */
  const [moveAllLinesToCart, { loading: moveAllMutationLoading }] = useCartPageCartListMoveAllLinesToCartMutation({
    variables: {
      input: {
        sourceCartId: cartData?.cartByUserId?.id ?? ''
      }
    },
    onError: (error) => {
      setActionError([{ code: 'MOVE_ALL_ERROR', message: 'We weren\'t able to move the items to your cart. Refresh the page and try again.' }])
      document.documentElement.scrollTop = 0
      logger.error('Could not remove all items from the cart: ', { code: 'MOVE_ALL_ERROR', message: 'We weren\'t able to move the items to your cart. Refresh the page and try again.' }, error)
    },
    onCompleted: () => {
      setSelectedCustomer({ id: 'default', cartItemCount: 0 })
    },
    update: () => {
      if (cartData?.cartByUserId != null) {
        trackCartListItemAddToCart(cartData?.cartByUserId, auth)
      }
    },
    refetchQueries: [GetCustomersWithCartsDocument, GetCartItemCountDocument, GetCartPageCartSearchPageCustomersWithCartsCountDocument]
  })

  /**
   * Handle loading status from parent and reset action status
   */
  useEffect(() => {
    if (moveAllMutationLoading || cartDataLoading) {
      setActionError([])
    }
    setLoading(moveAllMutationLoading || cartDataLoading)
  }, [moveAllMutationLoading, cartDataLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * handle data loaded status from parent
   */
  useEffect(() => {
    setCartDataExists(cartData?.cartByUserId?.items != null && cartData?.cartByUserId?.items.length > 0)
  }, [cartData]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * When last item gets moved via cart line, reset the selected customer
   */
  useEffect(() => {
    if (cartData?.cartByUserId?.items != null && cartData?.cartByUserId?.items.length === 0) {
      setSelectedCustomer({ id: 'default', cartItemCount: 0 })
    }
  }, [cartData?.cartByUserId?.items]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {actionError != null && actionError.length > 0 && (
        <div className='px-0 mt-5'>
          <CartAlert
            type='danger'
            errors={actionError}
          />
        </div>
      )}
      {cartData?.cartByUserId?.errors != null && cartData?.cartByUserId?.errors.length > 0 && (
        <CartAlert type='danger' errors={cartData.cartByUserId.errors} />
      )}
      {cartData?.cartByUserId?.notes != null && cartData?.cartByUserId?.notes.length > 0 && (
        <CartAlert type='warning' notes={cartData.cartByUserId.notes} />
      )}
      <div className='mt-2 px-0'>
        <CartContext.Provider
          value={{
            cartArea: 'cart-search-page',
            useCartContextUpdateCartLineMutation: useCartPageCartItemUpdateCartLineWithCartListItemMutation,
            useCartContextDeleteCartLineMutation: useCartPageCartItemDeleteCartLineWithCartListItemMutation
          }}
        >
          <CartItemList
            cartLines={cartData?.cartByUserId?.items ?? []}
            disableFormChanges={selectedCustomer?.scheduledOrder != null}
          />
        </CartContext.Provider>
      </div>
      {cartData?.cartByUserId?.items != null && cartData?.cartByUserId?.items.length > 0 && selectedCustomer?.scheduledOrder == null && (
        <div className='mt-4'>
          <MoveAllLinesToCartButton onClick={moveAllLinesToCart} />
        </div>
      )}
    </>
  )
}

export {
  CartSearchPageCart
}
