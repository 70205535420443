import { createContext } from 'react'

export interface ContentstackTargetingContextType {
  /**
   * Indicates that the active categories data is being loaded
   */
  activeCategoriesLoading: boolean
  /**
   * The active categories for the data for category exclude targeting
   */
  activeCategories?: string[]
}

const ContentstackTargetingContext = createContext<ContentstackTargetingContextType>({
  activeCategoriesLoading: false
})

export {
  ContentstackTargetingContext
}
