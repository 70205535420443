import { type ReactElement } from 'react'
import { bottomSectionClasses } from './helpers/helpers'
import { StatusTopSection } from './StatusTopSection'
import clsx from 'clsx'
import { useAuth } from '@stuller/stullercom/feat/auth'

function SoldStatus (): ReactElement {
  const bottomClasses = clsx(
    bottomSectionClasses,
    'bg-white sm'
  )
  const { authUser } = useAuth()

  return (
    <>
      <StatusTopSection color='black' overrideShowQuantityOnHand={false}>Sold</StatusTopSection>
      {authUser != null && (
        <div className={bottomClasses}>
          <div>
            This item has been sold and is no longer available for purchase.
          </div>
        </div>
      )}
    </>
  )
}

export {
  SoldStatus
}
