import { type ReactElement, memo } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CustomShowcaseSettingDetailType } from '@stuller/stullercom/data-access/apollo-queries'
import { Search } from './Search'
import { Logo } from './Logo'
import { SearchNavSterling } from './SearchNavSterling'

/**
 * Stuller.com search nav
 */
function SearchNavStullerCom (): ReactElement {
  const { showcaseSettings, isEmbeddedJewelerShowcase, getCustomShowcaseSetting } = useAuth()
  const showSearch = getCustomShowcaseSetting(CustomShowcaseSettingDetailType.HideSearchBar)?.[0] !== 'true'

  return (
    <header
      className='py-5 d-none d-lg-block d-print-none'
      style={{
        backgroundColor: isEmbeddedJewelerShowcase ? 'var(--bs-white)' : `var(--bs-${showcaseSettings?.backgroundColorClass ?? ''}, var(--bs-gray-100))`,
        backgroundImage: `var(--bs-${!isEmbeddedJewelerShowcase ? (showcaseSettings?.backgroundPatternClass ?? '') : ''}, none)`
      }}
      data-gtm-click-section='search-nav'
    >
      <div className='container-xxxl px-4'>
        <div className='row align-items-center justify-content-between'>
          <div className='col'>
            <Logo />
          </div>
          {showSearch && (
            <div className='col col-xl-4 py-3'>
              <Search />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

/**
 * Search nav: search and logo (hidden on mobile)
 */
const SearchNav = memo((): ReactElement | null => {
  const { isJewelerShowcase, isSterling, showcaseSettings } = useAuth()

  if (isSterling) {
    return <SearchNavSterling />
  }

  // The layout should not be shown when on an invalid showcase
  if (isJewelerShowcase && showcaseSettings == null) {
    return null
  }

  return <SearchNavStullerCom />
})
SearchNav.displayName = 'SearchNav'

export {
  SearchNav
}
