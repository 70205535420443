import { type ReactElement } from 'react'
import { StatusTopSection } from './StatusTopSection'
import { EstimatedShipDateSection } from './EstimatedShipDateSection'
import { type InStockStatusInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface LimitedAvailabilityStatusProps {
  /**
   * in-stock status info, including backorder and onhand quantity
   */
  inStockStatus: InStockStatusInfoFragment['inStockStatus']
  /**
   * context-based human readable ship date
   */
  readableShipDate?: string
  /**
   * whether or not the product is returnable
   */
  isReturnable: boolean
  /**
   * whether or not the ship date is today
   */
  isShipDateToday: boolean
}

function LimitedAvailabilityStatus ({ inStockStatus, readableShipDate, isReturnable, isShipDateToday }: LimitedAvailabilityStatusProps): ReactElement {
  return (
    <>
      <StatusTopSection inStockStatusCode={inStockStatus.statusCode} isReturnable={isReturnable} color='blue'>{inStockStatus.status}</StatusTopSection>
      <EstimatedShipDateSection isShipDateToday={isShipDateToday} readableShipDate={readableShipDate} />
    </>
  )
}

export {
  LimitedAvailabilityStatus
}
