import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if website is in Showcase Mode or not
 */
function useShowcaseModeTargeting (targeting: TargetingMap['SHOWCASE_MODE']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const auth = useAuth()
  const isShowcaseMode = targetingPreviewer?.isStullerComShowcaseMode ?? auth.isStullerComShowcaseMode

  return targeting.targetValue === isShowcaseMode
}

export { useShowcaseModeTargeting }
