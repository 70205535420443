import { useEffect, useMemo, useState } from 'react'
import { CartPageLayout } from '../shared/CartPageLayout'
import { Loading, UncontrolledPagination, type UncontrolledPaginationProps } from '@stuller/stullercom/ui'
import {
  useCartPageCartItemDeleteCartLineWithCartListItemMutation,
  useGetSavedCartLinesQuery,
  useCartPageCartItemUpdateCartLineWithCartListItemMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { CartItemList } from '../cart-list/CartItemList'
import { CartEmptyState } from '../shared/CartEmptyState'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import Link from 'next/link'
import { useApolloClient } from '@apollo/client'
import { CartContext } from '../shared/CartContext'
import { CartPageLoadError } from '../shared/CartPageLoadError'
import { useRouter } from 'next/router'
import { type CustomNextPage } from '@stuller/stullercom/feat/layout-context'
import {
  GetCartPageSavedItemsPagePermissionsDocument,
  type GetCartPageSavedItemsPagePermissionsQuery
} from '@stuller/stullercom/data-access/apollo-queries'

/**
 * The page that will show all the saved cart items
 */
const SavedItemsPage: CustomNextPage = () => {
  const pageLimit = 20
  const router = useRouter()
  const { page: pageIn } = router.query
  const [pageNumber, setPageNumber] = useState<number>(pageIn != null ? parseInt(pageIn as string) : 1)
  const client = useApolloClient()
  const { data, loading } = useGetSavedCartLinesQuery({
    variables: {
      paging: {
        limit: pageLimit,
        page: pageNumber
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network'
  })
  const itemCount = data?.savedCartLines?.count ?? 0

  /**
   * Manually evict the cache (used when changing pages)
   */
  function evictCache (): void {
    client.cache.evict({ fieldName: 'savedCartLines' })
    client.cache.gc()
  }

  /**
   * The items to display
   */
  const cartLines = useMemo(() => {
    return data != null && data != null ? data?.savedCartLines?.results : null
  }, [data])

  /**
   * Handles the pagination change
   */
  async function handlePaginationChange (...args: Parameters<NonNullable<UncontrolledPaginationProps['onChange']>>): Promise<void> {
    setPageNumber(...args)
    const newPage = args[0]
    await router.push({ query: { ...router.query, page: newPage } })
    evictCache()
  }

  /**
   * Handles the pagination change and scrolls to the top of the page
   */
  async function handleBottomPaginationChange (...args: Parameters<NonNullable<UncontrolledPaginationProps['onChange']>>): Promise<void> {
    await handlePaginationChange(...args)
    document.documentElement.scrollTo({ top: 0, behavior: 'instant' })
  }

  /**
   * If there are no items on the current page, go back a page
   */
  useEffect(() => {
    if (cartLines?.length === 0 && pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }, [cartLines, pageNumber])

  return (
    <CartPageLayout title='Saved For Later' activeNavigationLink='saveditems'>
      {itemCount != null && itemCount > pageLimit && (
        <div className='row'>
          <UncontrolledPagination
            className='w-auto ms-auto mb-5'
            page={pageNumber}
            perPage={pageLimit}
            total={itemCount}
            onChange={handlePaginationChange}
          />
        </div>
      )}
      <Loading loading={loading}>
        <CartContext.Provider
          value={{
            cartArea: 'saved-items-page',
            useCartContextUpdateCartLineMutation: useCartPageCartItemUpdateCartLineWithCartListItemMutation,
            useCartContextDeleteCartLineMutation: useCartPageCartItemDeleteCartLineWithCartListItemMutation
          }}
        >
          <CartItemList cartLines={cartLines ?? []} />
        </CartContext.Provider>
        {(itemCount == null || cartLines == null) && !loading &&
          <CartPageLoadError cartName='Saved Cart' />}
        {itemCount != null && cartLines != null && (itemCount === 0 || cartLines?.length === 0) && !loading && (
          <CartEmptyState
            icon={fas.faBoxOpen}
            title="You haven't saved any items"
            description="Saved items will appear here whenever you're ready to add them to your cart."
          >
            <p>Looking for your <Link href='/favorite' passHref>Favorites Lists</Link>?</p>
          </CartEmptyState>
        )}
      </Loading>
      {itemCount != null && itemCount > pageLimit && !loading && (
        <div className='row'>
          <UncontrolledPagination
            className='w-auto ms-auto mt-3'
            page={pageNumber}
            perPage={pageLimit}
            total={itemCount}
            onChange={handleBottomPaginationChange}
          />
        </div>
      )}
    </CartPageLayout>
  )
}

SavedItemsPage.getInitialProps = async (context) => {
  const { apolloClient } = context

  const { data } = await apolloClient.query<GetCartPageSavedItemsPagePermissionsQuery>({
    query: GetCartPageSavedItemsPagePermissionsDocument
  })

  if (!data.viewerPermissions.canSaveCartItems) {
    return { statusCode: 403 }
  }

  return {}
}

SavedItemsPage.layout = {
  title: {
    children: 'Your Saved Items'
  }
}

export {
  SavedItemsPage
}
