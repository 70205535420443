import type { SerializedGemstonePageType } from '../types'
import {
  GetSerializedPdpPagesGemstoneDetailsDocument,
  type GetSerializedPdpPagesGemstoneDetailsQuery
} from '@stuller/stullercom/data-access/apollo-queries'

const numbersOnlyRegex = /^\d+$/

const getSerializedGemstonePageInitialProps: SerializedGemstonePageType['getInitialProps'] = async (context) => {
  const { apolloClient } = context

  const serialNumber = context?.query?.slug?.[0].match(numbersOnlyRegex)?.[0]
  const serialNumberOverflows = parseInt(serialNumber ?? '', 10) > 2147483647

  if (serialNumber == null || serialNumberOverflows) {
    return { statusCode: 404, errorTemplate: 'serialized404' }
  }

  const { data } = await apolloClient.query<GetSerializedPdpPagesGemstoneDetailsQuery>({
    query: GetSerializedPdpPagesGemstoneDetailsDocument,
    variables: { serialNumber }
  })

  const serializedGemstone = data.serializedProductBySerialNumber

  // 404 if not found or is not a diamond
  if (serializedGemstone == null || serializedGemstone?.isDiamond) {
    return { statusCode: 404, errorTemplate: 'serialized404' }
  }

  return {
    serializedGemstone,
    serialNumber
  }
}

export {
  getSerializedGemstonePageInitialProps
}
