import { type RefObject } from 'react'
import { useEventListener } from '@stuller/shared/util/react-hooks'
import { STULLER_EVENT_PREFIX } from './constants'
import { type StullerEvents } from './types'

/**
 * Wrapper around `useEventListener` to listen for events from `dispatchStullerEvent`
 * Defaults to listening on `window`, send an `element` ref to prevent bubbling to other components with the same listener
 */
function useStullerEventListener<T extends keyof StullerEvents, R extends HTMLElement | Document = HTMLDivElement> (
  eventName: T,
  handler: (event: CustomEvent<StullerEvents[T] | null>) => void | Promise<void>,
  element?: RefObject<R>
): void {
  useEventListener(`${STULLER_EVENT_PREFIX}${eventName}`, handler, element)
}

export {
  useStullerEventListener
}
