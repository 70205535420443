import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from '@stuller/stullercom/ui'
import Link from 'next/link'
import { useRef, type ReactElement } from 'react'

interface ConsignmentDescriptionModalProps {
  /**
   * Indicates if the modal is open
   */
  isOpen: boolean
  /**
   * Function to toggle the modal
   */
  onToggle: () => void
}

function ConsignmentDescriptionModal ({
  isOpen,
  onToggle
}: ConsignmentDescriptionModalProps): ReactElement {
  const ref = useRef(typeof window !== 'undefined' ? document.body : undefined)

  return (
    <Modal
      isOpen={isOpen}
      onToggle={onToggle}
      portalRef={ref}
    >
      <ModalHeader
        onToggle={onToggle}
      >
        <div className='lh-xs'>
          Order for Product Review/Conditional Sale
        </div>
      </ModalHeader>
      <ModalBody>
        <h2 className='pb-2'>Sale with Right of Return</h2>
        <div className='fw-bold'>Product Review/Conditional Sale Timelines:</div>
        <ul>
          <li className='pb-0'>Select Finished Jewelry and Bridal Products – 15 calendar days</li>
          <li>Diamonds with Grading Report & Notable Gems&trade; – 5 calendar days</li>
        </ul>
        <p>
          A prepaid return air bill is shipped with PRCS products.
          If the product is not returned withtin the reveiw dates, it will automatically be invoiced.
          If the product is returned after it has been invoiced, a 15% restock fee will be applied.
        </p>
        <p className='mb-0'><Link href='/conditionalsale'>View full terms and conditions</Link> for Product Review/Conditional sale</p>
      </ModalBody>
      <ModalFooter>
        <Button className='ml-auto' color='secondary' outline onClick={onToggle}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export {
  ConsignmentDescriptionModal
}
