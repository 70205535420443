import { type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

export interface PaginationProps {
  /**
   * ID of the element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Size of the pagination
   */
  size?: 'sm' | 'lg'
}

/**
 * Pagination component to wrap pagination items and pagination links.
 *
 * ⚠️ You should try using the `UncontrolledPagination` component instead of this one directly as it does all the heavy lifting for you.
 */
const Pagination = forwardRef<HTMLDivElement, PaginationProps>(({
  id,
  className,
  children,
  size,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(className)
  const ulClassNames = clsx(
    'pagination',
    size != null && `pagination-${size}`
  )

  return (
    <nav id={id} className={classNames} aria-label='Page navigation' ref={ref} {...otherAttributes}>
      <ul className={ulClassNames}>
        {children}
      </ul>
    </nav>
  )
})
Pagination.displayName = 'Pagination'

export {
  Pagination
}
