import { useContext } from 'react'
import { AddToCartSectionContext, type AddToCartSectionContextType } from './AddToCartSectionContext'

/**
 * Hook to fetch Cart context information
 */
function useAddToCartSectionContext (): AddToCartSectionContextType {
  return useContext(AddToCartSectionContext)
}

export {
  useAddToCartSectionContext
}
