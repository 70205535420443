import { type ApolloError } from '@apollo/client/errors'

export interface ValidationError {
  /**
   * The main error message (or class of error) that occurred
   */
  message: string
  /**
   * A custom extension field we are using to send through the property names that failed validation
   * Adds the ability to hightlight invalid fields on forms
   */
  propertyNames: string[]
}

/**
 * Get client validation errors from Apollo errors
 */
function getClientValidationErrors (apolloError: ApolloError | null | undefined): ValidationError[] {
  const validationErrors = new Array<ValidationError>()
  if (apolloError != null) {
    for (const graphQLError of apolloError.graphQLErrors) {
      if (graphQLError.extensions?.code != null && graphQLError.extensions.code === 'BAD_USER_INPUT') {
        validationErrors.push({
          message: graphQLError.message,
          propertyNames: (graphQLError.extensions as { propertyNames: string[] })?.propertyNames
        })
      }
    }
  }

  return validationErrors
}

export {
  getClientValidationErrors
}
