import type { BuiltInCreditCardType } from 'credit-card-type/dist/types'
import {
  CARD_AMERICAN_EXPRESS_ICON,
  CARD_DISCOVER_ICON,
  CARD_MASTERCARD_ICON,
  CARD_OTHER_ICON,
  CARD_VISA_ICON
} from './media'

export type CardType =
  BuiltInCreditCardType['type']
  | 'AMERICAN_EXPRESS'
  | 'DISCOVER'
  | 'MASTERCARD'
  | 'VISA'
  | 'other'
  | 'OTHER'

/**
 * Mapping of card types and their info
 */
const CARD_TYPE_INFO: Record<CardType, { name: string, icon: string }> = {
  'american-express': {
    name: 'American Express',
    icon: CARD_AMERICAN_EXPRESS_ICON
  },
  AMERICAN_EXPRESS: {
    name: 'American Express',
    icon: CARD_AMERICAN_EXPRESS_ICON
  },
  discover: {
    name: 'Discover',
    icon: CARD_DISCOVER_ICON
  },
  DISCOVER: {
    name: 'Discover',
    icon: CARD_DISCOVER_ICON
  },
  mastercard: {
    name: 'Mastercard',
    icon: CARD_MASTERCARD_ICON
  },
  MASTERCARD: {
    name: 'Mastercard',
    icon: CARD_MASTERCARD_ICON
  },
  visa: {
    name: 'Visa',
    icon: CARD_VISA_ICON
  },
  VISA: {
    name: 'Visa',
    icon: CARD_VISA_ICON
  },
  'diners-club': {
    name: 'Diners Club',
    icon: CARD_OTHER_ICON
  },
  elo: {
    name: 'Elo',
    icon: CARD_OTHER_ICON
  },
  hiper: {
    name: 'Hiper',
    icon: CARD_OTHER_ICON
  },
  hipercard: {
    name: 'Hipercard',
    icon: CARD_OTHER_ICON
  },
  jcb: {
    name: 'JCB',
    icon: CARD_OTHER_ICON
  },
  maestro: {
    name: 'Maestro',
    icon: CARD_OTHER_ICON
  },
  mir: {
    name: 'Mir',
    icon: CARD_OTHER_ICON
  },
  unionpay: {
    name: 'UnionPay',
    icon: CARD_OTHER_ICON
  },
  other: {
    name: 'N/A',
    icon: CARD_OTHER_ICON
  },
  OTHER: {
    name: 'N/A',
    icon: CARD_OTHER_ICON
  }
}

export {
  CARD_TYPE_INFO
}
