/**
 * Error wrapper around an error that isn't of type `Error` and has no `.toString`
 */
class InvalidError extends Error {
  cause?: unknown

  constructor (message: string, cause?: unknown) {
    super(message)
    this.name = 'InvalidError'
    this.cause = cause

    Object.setPrototypeOf(this, new.target.prototype)
  }
}

/**
 * Ensures that the value passed in is always returned as an Error.
 * * If the object is already an Error it is returned as is.
 * * If the object is a string it is coerced into an Error with its value as the message.
 * * If the object is null or some other type then a new InvalidError is returned with the
 * message 'Unknown Error' and the cause set to the object's original value.
 */
function ensureError (error?: unknown): Error {
  if (error instanceof Error) {
    return error
  }

  if (typeof error === 'string') {
    return new Error(error)
  }

  return new InvalidError('Unknown Error', error)
}

/**
 * Rethrows an error. If the value is not an Error it will be coersed into one.
 */
function rethrow (error: unknown): never {
  throw ensureError(error)
}

export {
  InvalidError,
  ensureError,
  rethrow
}
