import { type ReactElement, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { dayjs } from '@stuller/shared/util/core'
import { useCartContext } from '../shared/useCartContext'

interface CartItemShipStatusProps {
  /**
   * The date the item will ship
   */
  shipDate?: string | null
}

/**
 * Calculate ship date formatted string
 */
function formatShipDate (date: string | undefined | null): string {
  if (date == null) {
    return ''
  }

  return dayjs(date).toReadableDateString()
}

/**
 * Ship status of the cart item
 */
function CartItemShipStatus ({ shipDate }: CartItemShipStatusProps): ReactElement | null {
  const formattedShipDate = useMemo(() => formatShipDate(shipDate), [shipDate])
  const { scheduledOrder } = useCartContext()

  // Don't show if null or scheduled order
  if (shipDate == null || scheduledOrder != null) {
    return null
  }

  return (
    <div className='d-flex justify-content-end mt-2 d-print-none text-xl-nowrap'>
      <div>
        {formattedShipDate === 'Today' && <FontAwesomeIcon icon={fas.faTruckFast} className='text-blue me-2' />}
        <span className='fw-bold'>Ready to Ship {formattedShipDate}</span>
      </div>
    </div>
  )
}

export {
  CartItemShipStatus
}
