import { ensureError } from '@stuller/shared/util/core'
import { serverLogger } from './datadogServerLogs'
import { clientLogger } from './useDatadogClientLogs'
import type { Context } from '@datadog/browser-core'

/**
 * Helper class to log to console and Datadog on server or client side respectively
 */
class Logger {
  /**
   * Debug log level
   */
  debug (message: string, messageContext?: Context): void {
    console.debug(message, messageContext)
    if (typeof window === 'undefined') {
      serverLogger?.debug(message, messageContext)
    } else {
      clientLogger?.debug(message, messageContext)
    }
  }

  /**
   * Info log level
   */
  info (message: string, messageContext?: Context): void {
    console.info(message, messageContext)
    if (typeof window === 'undefined') {
      serverLogger?.info(message, messageContext)
    } else {
      clientLogger?.info(message, messageContext)
    }
  }

  /**
   * Warn log level
   */
  warn (message: string, messageContext?: Context): void {
    console.warn(message, messageContext)
    if (typeof window === 'undefined') {
      serverLogger?.warn(message, messageContext)
    } else {
      clientLogger?.warn(message, messageContext)
    }
  }

  /**
   * Error log level
   */
  error (message: string, messageContext?: Context, error?: any): void {
    error = ensureError(error)
    console.error(message, messageContext, error)

    // The `console.error` will send to Datadog on automatically client side
    if (typeof window === 'undefined') {
      serverLogger?.error(message, messageContext, error)
    }
  }
}

const logger = new Logger()

export {
  logger
}
