import { type ReactElement } from 'react'
import { AddToCartSection } from '@stuller/stullercom/feat/add-to-cart'
import { type AddToCartSectionInfoFragment, type ItemLineOptionsInput } from '@stuller/stullercom/data-access/apollo-queries'
import { type TrackingQueryStringParams } from '@stuller/stullercom/feat/google-tag-manager'

interface SerializedDiamondAddToCartProps {
  data?: AddToCartSectionInfoFragment | null
  onOptionsChanged: (options: ItemLineOptionsInput) => void
  dataLoading: boolean
  trackingParams?: TrackingQueryStringParams
}

function SerializedDiamondAddToCart ({ data, onOptionsChanged, dataLoading, trackingParams }: SerializedDiamondAddToCartProps): ReactElement {
  return (
    <div className='col-4'>
      <AddToCartSection data={data} onOptionsChanged={onOptionsChanged} dataLoading={dataLoading} trackingParams={trackingParams} />
    </div>
  )
}

export {
  SerializedDiamondAddToCart
}
