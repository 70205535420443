import { type ChangeEvent, useState, type ReactElement } from 'react'
import { FormGroup, FormLabel, Input } from '@stuller/stullercom/ui'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'
import { trackEvent } from '@stuller/stullercom/feat/google-tag-manager'

/**
 * Checkbox component for matching size and color
 */
function AddToCartMatch (): ReactElement | null {
  const { onAddToCartDataChanged, options } = useAddToCartSectionContext()
  const { isMatched } = options ?? {}
  const [checkboxIsMatched, setCheckboxIsMatched] = useState(isMatched ?? false)

  function handleToggle (event: ChangeEvent<HTMLInputElement>): void {
    setCheckboxIsMatched(event.target.checked)
    onAddToCartDataChanged({ isMatched: event.target.checked })
    trackEvent('configure', {
      section: 'add_to_cart_section',
      input_name: 'Item Options',
      input_type: 'checkbox',
      input_value: 'Match for Color & Size',
      input_checked: event.target.checked
    })
  }

  return (
    <FormGroup groupId='match-size-and-color' check inline className='mb-0 me-0 w-100'>
      <Input
        type='checkbox'
        checked={checkboxIsMatched}
        onChange={handleToggle}
      />
      <FormLabel check className='mb-0 fw-bold'>
        Match for size & color
        <p className='fw-normal mb-0'>
          Bulk pricing is not available if this option is selected.
          The standard per-item price will apply instead.
        </p>
      </FormLabel>
    </FormGroup>
  )
}

export {
  AddToCartMatch
}
