import { useEffect, useMemo, useState } from 'react'
import { CartPageLayout } from '../shared/CartPageLayout'
import {
  useCartPageCartItemDeleteCartLineWithCartListItemMutation,
  useGetShowcaseOnlyCartsQuery,
  useCartPageCartItemUpdateCartLineWithCartListItemMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { Loading, UncontrolledPagination, type UncontrolledPaginationProps } from '@stuller/stullercom/ui'
import { CartListCollapseList } from '../cart-list/CartListCollapseList'
import { CartEmptyState } from '../shared/CartEmptyState'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { CartContext } from '../shared/CartContext'
import { CartPageLoadError } from '../shared/CartPageLoadError'
import { useRouter } from 'next/router'
import { type CustomNextPage } from '@stuller/stullercom/feat/layout-context'
import {
  GetCartPageSubCartsPagePermissionsDocument,
  type GetCartPageSubCartsPagePermissionsQuery
} from '@stuller/stullercom/data-access/apollo-queries'

const SubCartsPage: CustomNextPage = () => {
  const pageLimit = 8
  const router = useRouter()
  const { page: pageIn } = router.query
  const [pageNumber, setPageNumber] = useState<number>(pageIn != null ? parseInt(pageIn as string) : 1)
  const { data, loading } = useGetShowcaseOnlyCartsQuery({
    variables: {
      paging: {
        limit: pageLimit,
        page: pageNumber
      }
    },
    fetchPolicy: 'network-only'
  })
  const itemCount = data?.showcaseOnlyCarts?.count ?? 0

  /**
   * The cart header information to display
   */
  const cartHeaders = useMemo(() => {
    return data?.showcaseOnlyCarts?.cartHeaders ?? null
  }, [data])

  /**
   * Handles the pagination change
  */
  async function handlePaginationChange (...args: Parameters<NonNullable<UncontrolledPaginationProps['onChange']>>): Promise<void> {
    setPageNumber(...args)
    const newPage = args[0]
    await router.push({ query: { ...router.query, page: newPage } })
  }

  /**
   * Handles the pagination change and scrolls to the top of the page
   */
  async function handleBottomPaginationChange (...args: Parameters<NonNullable<UncontrolledPaginationProps['onChange']>>): Promise<void> {
    await handlePaginationChange(...args)
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
  }

  /**
   * If there are no items on the current page, go back a page
   */
  useEffect(() => {
    if (cartHeaders?.length === 0 && pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }, [cartHeaders, pageNumber])

  return (
    <CartPageLayout title='Showcase Only Shopping Carts' activeNavigationLink='subcarts'>
      {itemCount != null && itemCount > pageLimit && (
        <div className='row'>
          <UncontrolledPagination
            className='w-auto ms-auto mb-5'
            page={pageNumber}
            perPage={pageLimit}
            total={itemCount}
            onChange={handlePaginationChange}
          />
        </div>
      )}
      <Loading loading={loading}>
        {cartHeaders == null && !loading && <CartPageLoadError cartName='Showcase Only Carts' />}
        {cartHeaders != null && itemCount === 0 && !loading && (
          <CartEmptyState
            icon={fas.faCartShopping}
            title='No Showcase Only Carts'
            description='There are no shopping cart items from other carts at this time'
          />
        )}
        <CartContext.Provider
          value={{
            cartArea: 'sub-carts-page',
            useCartContextUpdateCartLineMutation: useCartPageCartItemUpdateCartLineWithCartListItemMutation,
            useCartContextDeleteCartLineMutation: useCartPageCartItemDeleteCartLineWithCartListItemMutation
          }}
        >
          <CartListCollapseList cartHeaders={cartHeaders} />
        </CartContext.Provider>
      </Loading>
      {itemCount != null && itemCount > pageLimit && !loading && (
        <div className='row'>
          <UncontrolledPagination
            className='w-auto ms-auto mt-3'
            page={pageNumber}
            perPage={pageLimit}
            total={itemCount}
            onChange={handleBottomPaginationChange}
          />
        </div>
      )}
    </CartPageLayout>
  )
}

SubCartsPage.getInitialProps = async (context) => {
  const { apolloClient } = context

  const { data } = await apolloClient.query<GetCartPageSubCartsPagePermissionsQuery>({
    query: GetCartPageSubCartsPagePermissionsDocument
  })

  if (!data.viewerPermissions.canViewShowcaseOnlyUsers) {
    return { statusCode: 403 }
  }

  return {}
}

SubCartsPage.layout = {
  title: {
    children: 'Showcase Only Carts'
  }
}

export {
  SubCartsPage
}
