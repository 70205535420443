import { type ReactElement } from 'react'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Button } from '@stuller/stullercom/ui'
import { type AddToCartSectionInfoFragment, type CartLineMessageInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { CartItemFavoriteModal } from '@stuller/stullercom/feat/cart-page'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'
import { trackItemAddToWishlist } from '@stuller/stullercom/feat/google-tag-manager'

interface AdddToCartFavoriteButtonProps {
  /**
   * The item to favorite
   */
  data: AddToCartSectionInfoFragment
  /**
   * Function to set the error state
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * Whether to disable the button
   */
  disabled?: boolean
}

/**
 * This component is a button that allows the user to favorite an item in the add to cart section.
 */
function AddToCartFavoriteButton ({ onError, data, disabled = false }: AdddToCartFavoriteButtonProps): ReactElement | null {
  const [isOpen, toggleIsOpen] = useToggle()
  const { trackingParams } = useAddToCartSectionContext()
  const auth = useAuth()

  function handleTrackAddToWishList (): void {
    trackItemAddToWishlist({
      ...data,
      index: trackingParams?.recommendationIndex != null ? parseInt(trackingParams.recommendationIndex) : null,
      item_list_id: trackingParams?.recommendationSource ?? null
    }, auth)
  }

  return (
    <>
      <Button
        onClick={toggleIsOpen}
        color='secondary'
        outline='fill'
        disabled={disabled}
        data-test='add-to-favorite-button'
        data-gtm-click-section=''
      >
        <FontAwesomeIcon icon={fas.faHeart} size='lg' className='me-2' />
        Add To Favorites
      </Button>
      <CartItemFavoriteModal
        open={isOpen}
        onToggle={toggleIsOpen}
        onError={onError}
        data={data}
        trackAddToWishList={handleTrackAddToWishList}
      />
    </>
  )
}

export {
  AddToCartFavoriteButton
}
