import { datadogLogs, type Logger } from '@datadog/browser-logs'
import { useEffect } from 'react'
import { type AuthProviderProps } from '@stuller/stullercom/feat/auth'
import { siteConfig } from '@stuller/stullercom/util/site-config'

const clientToken = siteConfig.NEXT_PUBLIC_DATADOG_LOGS_CLIENT_TOKEN
const env = siteConfig.NEXT_PUBLIC_DATADOG_ENVIRONMENT ?? ''
const sessionSampleRate = siteConfig.NEXT_PUBLIC_DATADOG_LOGS_SESSION_SAMPLE_RATE
const enabled = ['dev', 'prod'].includes(env) && clientToken != null && sessionSampleRate > 0

// eslint-disable-next-line import/no-mutable-exports
let clientLogger: Logger | null = null

/**
 * Initalizes Datadog Logs client-side
 * https://docs.datadoghq.com/logs/log_collection/javascript/#initialization-parameters
 */
function initDatadogClientLogs (): void {
  if (enabled) {
    if (datadogLogs.getInitConfiguration() === undefined) {
      datadogLogs.init({
        clientToken,
        site: 'datadoghq.com',
        service: 'stullercom-site',
        env,
        version: '1.0.0',
        allowFallbackToLocalStorage: false,
        forwardErrorsToLogs: false,
        // Only forward errors to Datadog
        forwardConsoleLogs: ['error'],
        sessionSampleRate,
        silentMultipleInit: true
      })
      clientLogger = datadogLogs.logger
    }
  }
}

// Call init on file include to make sure it's called ASAP
if (typeof window !== 'undefined') {
  initDatadogClientLogs()
}

/**
 * Adds Datadog Logs to the page
 *
 * To enable:
 * Set `NEXT_PUBLIC_DATADOG_ENVIRONMENT` to `dev` or `prod`.
 * Set `NEXT_PUBLIC_DATADOG_LOGS_SESSION_SAMPLE_RATE` to a number greater than 0.
 */
function useDatadogClientLogs (authUser: AuthProviderProps['authUser']): void {
  useEffect(() => {
    if (enabled) {
      datadogLogs.setUser({
        id: authUser?.user?.memberId ?? undefined
      })
    }
  }, [authUser])
}

export {
  useDatadogClientLogs,
  clientLogger
}
