// Credit card icons
export const CARD_VISA_ICON = 'https://assets.stullercloud.com/das/107103319.svg'
export const CARD_AMERICAN_EXPRESS_ICON = 'https://assets.stullercloud.com/das/107103331.svg'
export const CARD_MASTERCARD_ICON = 'https://assets.stullercloud.com/das/107103328.svg'
export const CARD_DISCOVER_ICON = 'https://assets.stullercloud.com/das/107103332.svg'
export const CARD_OTHER_ICON = 'https://assets.stullercloud.com/das/107933808.svg'

// Carrier icons
export const CARRIER_FEDEX_ICON = 'https://assets.stullercloud.com/das/143778046.svg'
export const CARRIER_UPS_ICON = 'https://assets.stullercloud.com/das/143778044.svg'
export const CARRIER_USPS_ICON = 'https://assets.stullercloud.com/das/143778036.svg'
export const CARRIER_PUROLATOR_ICON = 'https://assets.stullercloud.com/das/143778035.svg'
export const CARRIER_DHL_ICON = 'https://assets.stullercloud.com/das/143778187.svg'
export const CARRIER_MOTOR_FREIGHT_ICON = 'https://assets.stullercloud.com/das/143776657.svg'
export const CARRIER_OCEAN_FREIGHT_ICON = 'https://assets.stullercloud.com/das/143776658.svg'
export const CARRIER_PICK_UP_ICON = null
export const CARRIER_OTHER_ICON = null
