import { forwardRef, useMemo, type CSSProperties } from 'react'
import { type CardType, CARD_TYPE_INFO } from '../../util/constants/cardTypes'
import clsx from 'clsx'

export interface CreditCardIconProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Additional styles to apply to the containing element
   */
  style?: CSSProperties
  /**
   * The type of credit card to display
   */
  type?: CardType
  /**
   * The width of the icon
   */
  width?: number
}

/**
 * Component for rendering a credit card icon.
 * Aliases for some card types are included to work with the input library and enums.
 */
const CreditCardIcon = forwardRef<HTMLSpanElement, CreditCardIconProps>(({
  id,
  className,
  style,
  type: typeIn,
  width = 30,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('form-credit-card-icon', className)
  const matchingCardType = useMemo(() => {
    let cardType = typeIn != null ? CARD_TYPE_INFO[typeIn] : undefined
    cardType ??= CARD_TYPE_INFO.other

    return cardType
  }, [typeIn])
  const iconStyles = useMemo(() => ({
    width: `${width}px`
  }), [width])

  return (
    <span id={id} className={classNames} style={style} ref={ref} {...otherAttributes}>
      <img src={matchingCardType.icon} alt={matchingCardType.name} style={iconStyles} />
    </span>
  )
})
CreditCardIcon.displayName = 'CreditCardIcon'

export { CreditCardIcon }
