import type { ReactElement } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far } from '@awesome.me/kit-3dbd93c064/icons'
import { Alert, Button } from '@stuller/stullercom/ui'
import { useGetCanCheckoutQuery, useGetCanSubmitOrderForApprovalBypassCheckoutQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useCartContext } from '../shared/useCartContext'
import type { Cart } from '../shared/types'
import { CancelScheduledOrderButton } from './CancelScheduledOrderButton'

interface CartSummaryButtonsProps {
  /**
   * The full cart query
   */
  cart?: Cart
  /**
   * Indicates the cart summary is being rendered in the offcanvas
   */
  offcanvas: boolean
  /**
   * Indicates if the cart is empty
   */
  cartIsEmpty: boolean
}

interface CheckoutButtonsProps {
  /**
   * Indicates that the cart has errors
   */
  hasErrors: boolean
}

interface ShowcaseModeButtonsProps {
  /**
   * Indicates the cart summary is being rendered in the offcanvas
   */
  offcanvas: boolean
}

interface ScheduledOrderButtonsProps {
  /**
   * Indicates if the cart is empty
   */
  cartIsEmpty: boolean
}

/**
 * The jeweler showcase buttons for cart summary
 */
function JewelerShowcaseButtons (): ReactElement {
  return (
    <>
      <Button tag='a' href='/cart/requestacallback' color='success' className='w-100 mb-3'>
        Request Items <FontAwesomeIcon icon={far.faAngleRight} size='xl' className='ps-1' />
      </Button>
      <p>
        We'll contact you about purchasing options after you submit your request.
      </p>
    </>
  )
}

/**
 * The jeweler showcase mode buttons for cart summary
 */
function ShowcaseModeButtons ({ offcanvas }: ShowcaseModeButtonsProps): ReactElement {
  const classNames = clsx(
    'rounded-3 p-4 text-center',
    offcanvas ? 'bg-white' : 'bg-gray-100'
  )

  return (
    <div className={classNames}>
      This login is unable to place order
    </div>
  )
}

/**
 * The normal checkout buttons for cart summary
 */
function CheckoutButtons ({ hasErrors }: CheckoutButtonsProps): ReactElement {
  return (
    <>
      <Button tag='a' href='/checkout' color='success' className='w-100' disabled={hasErrors} data-test='secure-checkout-button'>
        Secure Checkout <FontAwesomeIcon icon={far.faAngleRight} size='xl' className='ps-1' />
      </Button>
      {hasErrors && (
        <Alert size='sm' color='danger' icon className='mt-3 mb-0'>
          Update or remove the items outlined in red to proceed to checkout.
        </Alert>
      )}
    </>
  )
}

/**
 * The approval checkout button when users can submit orders for approval in Showcase
 */
function ApprovalCheckoutButtons (): ReactElement {
  return (
    <Button tag='a' href='/checkout/placeorderforapproval' color='success' className='w-100'>
      Place Order For Approval
    </Button>
  )
}

/**
 * The Button to edit a scheduled order
 */
function EditScheduledOrderButton (): ReactElement {
  return (
    <Button color='primary' tag='a' className='w-100 mb-3' href='/checkout' data-test='edit-order-information-button'>Edit Order Information</Button>
  )
}

/**
 * The buttons to show when an order is actively scheduled
 */
function ScheduledOrderButtons ({ cartIsEmpty }: ScheduledOrderButtonsProps): ReactElement {
  return (
    <>
      <EditScheduledOrderButton />
      <CancelScheduledOrderButton cartIsEmpty={cartIsEmpty} />
    </>
  )
}

/**
 * Buttons in cart summary
 */
function CartSummaryButtons ({ cart, offcanvas, cartIsEmpty }: CartSummaryButtonsProps): ReactElement | null {
  const { isJewelerShowcase } = useAuth()
  const { scheduledOrder } = useCartContext()
  const { data: canCheckout } = useGetCanCheckoutQuery()
  const { data: canSubmitOrderApproval } = useGetCanSubmitOrderForApprovalBypassCheckoutQuery()
  const hasCartLineErrors = cart?.shipments.some((s) => s.lines.some((l) => l.errors.length > 0)) ?? false
  const hasCartErrors = cart?.errors != null && cart.errors.length > 0
  const hasErrors = hasCartLineErrors || hasCartErrors

  if (canCheckout == null && canSubmitOrderApproval == null) {
    return null
  }

  // TODO: implement each in new component
  if (scheduledOrder != null) {
    return <ScheduledOrderButtons cartIsEmpty={cartIsEmpty} />
  }
  if (canSubmitOrderApproval?.viewerPermissions.canSubmitOrderForApprovalBypassCheckout === true) {
    return <ApprovalCheckoutButtons />
  }
  if (canCheckout?.viewerPermissions.canCheckout === true) {
    return <CheckoutButtons hasErrors={hasErrors} />
  }
  if (isJewelerShowcase) {
    return <JewelerShowcaseButtons />
  }

  return <ShowcaseModeButtons offcanvas={offcanvas} />
}

export {
  CartSummaryButtons,
  ScheduledOrderButtons
}
