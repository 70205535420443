import { type ComponentRef, useState, type ReactElement } from 'react'
import { Button, Popover, PopoverBody } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import type { ItemType } from '@stuller/stullercom/data-access/apollo-queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { ItemShareEmail } from './item-share-email/ItemShareEmail'
import { ItemShareCopyLink } from './ItemShareCopyLink'
import { ItemShareFacebook } from './ItemShareFacebook'
import { ItemSharePinterest } from './ItemSharePinterest'
import { ItemShareX } from './ItemShareX'

interface ItemShareButtonProps {
  /**
   * The id of the item
   */
  itemId: string
  /**
   * The type of the item
   */
  itemType: ItemType
  /**
   * The image of the product to share
   */
  image?: string | null
}

/**
 * Component description
 */
function ItemShareButton ({ itemId, itemType, image }: ItemShareButtonProps): ReactElement {
  const [open, handleToggle, handleOpenChange] = useToggle(false)
  const [reference, setReference] = useState<ComponentRef<typeof Button> | null>(null)

  return (
    <>
      <Button color='anchor-dark' onClick={handleToggle} className='fw-normal' ref={setReference}>
        <FontAwesomeIcon icon={fas.faArrowUpFromBracket} className='me-2 text-gray-600' />
        Share
      </Button>
      <Popover open={open} onOpenChange={handleOpenChange} reference={reference} dismiss>
        <PopoverBody>
          <div className='row gy-2'>
            <div className='col-6'>
              <ItemShareEmail onShare={handleToggle} itemId={itemId} itemType={itemType} image={image} />
            </div>
            <div className='col-6'>
              <ItemShareCopyLink />
            </div>
            <div className='col-6'>
              <ItemShareFacebook onShare={handleToggle} />
            </div>
            <div className='col-6'>
              <ItemSharePinterest onShare={handleToggle} />
            </div>
            <div className='col-6'>
              <ItemShareX onShare={handleToggle} />
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </>
  )
}

export {
  ItemShareButton
}
