import { type ReactElement } from 'react'
import { StatusTopSection } from './StatusTopSection'
import { EstimatedShipDateSection } from './EstimatedShipDateSection'
import { type InStockStatusInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface PartiallyAvailableStatusProps {
  /**
   * in-stock status info, including backorder and onhand quantity
   */
  inStockStatus: InStockStatusInfoFragment['inStockStatus']
  /**
   * context-based human readable ship date
   */
  readableShipDate?: string
  /**
   * whether or not the product is returnable
   */
  isReturnable: boolean
  /**
   * whether or not the ship date is today
   */
  isShipDateToday: boolean
}

function PartiallyAvailableStatus ({ inStockStatus, readableShipDate, isReturnable, isShipDateToday }: PartiallyAvailableStatusProps): ReactElement {
  return (
    <>
      <StatusTopSection inStockStatusCode={inStockStatus.statusCode} isReturnable={isReturnable} color='black'>{inStockStatus.status}</StatusTopSection>
      <EstimatedShipDateSection isShipDateToday={isShipDateToday} readableShipDate={readableShipDate} />
    </>
  )
}

export {
  PartiallyAvailableStatus
}
