import { type ReactElement } from 'react'
import { type IconDefinition } from '@awesome.me/kit-3dbd93c064/icons'
import { Alert } from '@stuller/stullercom/ui'
import { type CartMessageInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import clsx from 'clsx'

/**
 * The props for the AlertItem component
 */
export interface CartAlertItemProps {
  /**
   * The type of the alert
   */
  type: 'danger' | 'warning' | 'primary'
  /**
   * The title of the alert
   */
  title?: string
  /**
   * The messages of the alert
   */
  messages?: string[]
  /**
   * The errors of the alert
   */
  errors?: CartMessageInfoFragment[]
  /**
   * The notes of the alert
   */
  notes?: CartMessageInfoFragment[]
  /**
   * The icon override of the alert
   */
  icon?: IconDefinition
  /**
   * Additional class names for the alert
   */
  className?: string
}

/**
 * The component that will show the alerts
 */
function CartAlert ({
  type,
  messages,
  title,
  errors,
  notes,
  icon,
  className
}: CartAlertItemProps): ReactElement {
  const classNames = clsx(
    'mb-3 py-0 px-0',
    className
  )

  return (
    <div className={classNames}>
      <Alert color={type} className='mb-0' icon={icon ?? true}>
        {title != null && <p className='mb-0 fw-bold'>{title}</p>}
        {messages != null && (
          <>
            {messages.map((message, index) => (
              <p key={index} className='mb-0'>{message}</p>
            ))}
          </>
        )}
        {errors != null && (
          <>
            {errors.map((error, index) => (
              <p key={index} className='mb-0'>{error.message}</p>
            ))}
          </>
        )}
        {notes != null && (
          <>
            {notes.map((note, index) => (
              <p key={index} className='mb-0'>{note.message}</p>
            ))}
          </>
        )}
      </Alert>
    </div>
  )
}

export {
  CartAlert
}
