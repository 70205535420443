import { forwardRef, memo, useRef, useImperativeHandle, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Loading, type LoadingProps } from '@stuller/stullercom/ui'
import { type CmsOptionsInput } from '@stuller/stullercom/data-access/apollo-queries'
import { useStullerEventListener, type StullerEvents } from '@stuller/stullercom/feat/stuller-events'
import { CmsContentPopover } from './CmsContentPopover'
import { useGetCmsContent, type UseGetCmsContentQueryOptions } from './useGetCmsContent'

export interface CmsContentProps extends UseGetCmsContentQueryOptions {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Id of the container to fetch and render (send either id OR name)
   */
  contentContainerId?: string | null
  /**
   * Name of the container to fetch and render (send either id OR name)
   */
  contentContainerName?: string | null
  /**
   * Options for rendering content
   */
  contentOptions?: CmsOptionsInput
  /**
   * Skips the loading indicator and just loads content when ready
   */
  skipLoading?: boolean
  /**
   * Doesn't show content when loading
   * Useful for loading different content in a single `CmsContent` component and not showing the previous content while loading
   */
  emptyLoading?: boolean
  /**
   * Indicates to only fetch the content once with the initial props/context
   * This is useful for header/footer content that doesn't change according to props or context (page url etc)
   */
  noRefetch?: boolean
  /**
   * Handler called when content is loaded
   */
  onLoad?: (html: string) => void
  /**
   * Extra loading props to send to the `Loading` component
   */
  loadingProps?: Omit<LoadingProps, 'loading'>
}

/**
 * Get and render CMS content
 *
 * Remember you can use `loadingProps` to customize the `Loading` component that wraps the content
 */
const CmsContent = forwardRef<HTMLDivElement, CmsContentProps>(({
  className,
  contentContainerId,
  contentContainerName,
  contentOptions = {},
  skipLoading = false,
  emptyLoading = false,
  ssr = false,
  fetchPolicy,
  noRefetch = false,
  onLoad,
  loadingProps,
  ...otherAttributes
}, ref) => {
  const innerRef = useRef<HTMLDivElement>(null)
  // Expose innerRef, must use non-null assertion because signature requires it and conditional is not allowed
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => innerRef.current!, [])
  const [editableEl, setEditableEl] = useState<HTMLDivElement | null>(null)
  const classNames = clsx(
    (editableEl != null) && 'outline outline-2 outline-green-200 outline-dashed',
    className
  )
  const { loading, content } = useGetCmsContent(contentContainerName, contentContainerId, contentOptions, { ssr, fetchPolicy }, noRefetch)
  const cleanup = useRef<StullerEvents['cms-content-cleanup']>()

  // On unmount, call cleanup
  useEffect(() => {
    return () => {
      cleanup.current?.()
    }
  }, [])

  // When content changes, call cleanup and unset
  useEffect(() => {
    if (cleanup.current != null) {
      cleanup.current?.()
      cleanup.current = undefined
    }
  }, [content])

  // Setup listener for setting a cleanup function when the CMS content is removed from the page
  useStullerEventListener('cms-content-cleanup', (event) => {
    event.stopPropagation()
    cleanup.current = event.detail ?? undefined
  }, innerRef)

  const contentDiv = (
    <>
      <div
        className={classNames}
        ref={innerRef}
        style={{ minHeight: (editableEl != null) ? 10 : undefined }}
        {...otherAttributes}
      />
      {innerRef.current != null && editableEl != null &&
        <CmsContentPopover reference={innerRef.current} editable={editableEl} />}
    </>
  )

  // Attach HTML from CMS using a Range and appending to the div
  useEffect(() => {
    if (innerRef.current == null || (loading && !emptyLoading)) {
      return
    }
    const fragment = content ?? ''
    const html = document.createRange().createContextualFragment(fragment)
    innerRef.current.innerHTML = ''
    innerRef.current.appendChild(html)
    onLoad?.(fragment)

    // Init CMS popover if needed
    const el = innerRef.current.children[0] as HTMLDivElement | undefined
    if (el != null && el.dataset.is_editable === 'TRUE') {
      setEditableEl(el)
    } else {
      setEditableEl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, innerRef, contentContainerName, contentContainerId])

  if (skipLoading) {
    return contentDiv
  }

  return <Loading loading={loading} {...loadingProps}>{contentDiv}</Loading>
})
CmsContent.displayName = 'CmsContent'

const CmsContentMemo = memo(CmsContent)

export {
  CmsContentMemo as CmsContent
}
