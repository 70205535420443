import { useToggle } from '@stuller/shared/util/react-hooks'
import { Alert, Button, FormGroup, FormLabel, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@stuller/stullercom/ui'
import { type ReactElement, useRef, useState } from 'react'
import { type CartPageCartItemAcceptConsignmentTermsMutation } from '@stuller/stullercom/data-access/apollo-queries'
import { type FetchResult } from '@apollo/client'

interface ConsignmentTermModalProps {
  /**
   * Modal open state
   */
  isOpen: boolean
  /**
   * Function to close the modal
   */
  onClose: () => void
  /**
   * If the consignment terms have been accepted
   */
  onAcceptConsignmentTerms: () => Promise<FetchResult<CartPageCartItemAcceptConsignmentTermsMutation>>
  /**
   * If the consignment terms are loading
   */
  loading: boolean
}

/**
 * This component renders the consignment terms modal
 */
function ConsignmentTermsModal ({
  isOpen,
  onClose,
  onAcceptConsignmentTerms,
  loading
}: ConsignmentTermModalProps): ReactElement {
  const ref = useRef(typeof window !== 'undefined' ? document.body : undefined)
  const [isConsignmentTermsAccepted, toggleIsConsignmentTermsAccepted] = useToggle(false)
  const [error, setError] = useState<string | null>(null)

  const handleCloseModal = (): void => {
    setError(null)
    if (isConsignmentTermsAccepted) {
      toggleIsConsignmentTermsAccepted()
    }
    onClose()
  }

  /**
   * This function handles the submit button click event
   */
  const handleSubmit = async (): Promise<void> => {
    setError(null)
    if (isConsignmentTermsAccepted) {
      try {
        await onAcceptConsignmentTerms()
      } catch (e) {
        setError('There was an issue accepting the terms')
        toggleIsConsignmentTermsAccepted()
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      portalRef={ref}
      size='lg'
      onHide={handleCloseModal}
    >
      <ModalHeader onToggle={handleCloseModal}>
        Order for Product Review/Conditional Sale
      </ModalHeader>
      <ModalBody>
        <h2>Sale with Right of Return</h2>
        <h3>You must agree to these terms to add the item to your cart.</h3>
        <p>
          For the purpose of this transaction, Product Review - Conditional Sale (PRCS) is herein defined as a sale with the right of return,
          whereas such rights are more fully defined below. The “Merchandise” represents the item(s) described on the PRCS document, which are
          fully delivered at the Customer’s risk including all hazards of losses which may occur to the said Merchandise, regardless of the cause of the
          loss or damages, for examination, inspection, purchase or return by prospective purchasers of Customer. The Customer shall have the right to return
          the Merchandise regardless of reason, other than abuse or damage caused while under Customer’s care and custody, provided such merchandise is
          returned within below noted times, in its full and original form. When returning PRCS Merchandise, please use the provided prepaid air bill
          include all original packaging plus a copy of the conditional sale document or invoice.

          <strong> NOTE: Customer must use the specific prepaid air bill
            provided with the PRCS Merchandise when returning to Stuller. Additional items cannot be added to a return.
            Customer will assume risk of loss for return items not associated with the air bill provided.
          </strong>
        </p>
        <h5 className='mb-1'>Product Review/Conditional Sale Timelines:</h5>
        <ul>
          <li className='pb-0'>
            Diamonds with Grading Report & Notable Gems™ - 5 calendar days
          </li>
          <li>
            Select jewelry and bridal products - 15 calendar days
          </li>
        </ul>
        <p>
          Until the Merchandise is actually returned to, and received by, Stuller or the right of return expires, the Customer shall remain fully
          responsible and, in the event of damage or loss, whether caused by the Customer or by another, whether or not under Customer’s control,
          Customer will indemnify Stuller immediately by payment of the stated value, which represents the extent of the actual loss
          (and is not intended to constitute a price for the sale of the Merchandise) plus legal fees and costs if litigation is commenced to
          enforce Stuller’s rights. The Customer will also be responsible for all federal, state and local taxes applicable to the Merchandise
          physically located at the Customer’s business location.
        </p>
        <p>
          A sale, under the normal return policy of Stuller, of all or any portion of the Merchandise may occur only when Customer has received a
          separate invoice from Stuller covering the specific Merchandise.
        </p>
        <p>
          Receipt of the Merchandise constitutes Customer’s agreement to the foregoing terms which represent the entire contract with respect to
          the Merchandise herein described. This transaction and all other transactions between the parties shall be governed and construed in
          accordance with the laws of the State of Louisiana.
        </p>
        <p>
          PRCS products not returned by above timelines will be automatically invoiced. Returned PRCS products after invoice date are subject to a
          15% restocking fee.
        </p>
      </ModalBody>
      <ModalFooter className='container-fluid'>
        <div className='row g-0 gap-3 align-items-center w-100'>
          <div className='col'>
            <Button color='anchor-dark' onClick={handleCloseModal} className='fw-normal'>Cancel</Button>
          </div>
          <div className='col-auto align-items-center'>
            <div className='row flex-column flex-sm-row align-items-sm-center align-items-end'>
              <div className='col-auto'>
                <FormGroup inline className='mb-sm-0 mb-3 me-0' check groupId='consignment-terms-agree'>
                  <Input type='checkbox' checked={isConsignmentTermsAccepted} onChange={toggleIsConsignmentTermsAccepted} />
                  <FormLabel check className='text-nowrap fw-bold'>
                    I agree to these terms
                  </FormLabel>
                </FormGroup>
              </div>
              <div className='col-auto'>
                <Button onClick={handleSubmit} loading={loading} disabled={!isConsignmentTermsAccepted}>I Agree</Button>
              </div>
            </div>
          </div>
        </div>
        {error != null && (
          <Alert color='danger' className='mt-3 w-100' icon>
            {error}
          </Alert>
        )}
      </ModalFooter>
    </Modal>
  )
}

export { ConsignmentTermsModal }
