import { type ReactElement } from 'react'
import { Modal, ModalHeader, ModalBody } from '@stuller/stullercom/ui'
import type { ItemType } from '@stuller/stullercom/data-access/apollo-queries'
import { ItemShareEmailForm } from './ItemShareEmailForm'

interface ItemShareEmailModalProps {
  /**
   * Whether the modal is open
   */
  open: boolean
  /**
   * Function to toggle the modal
   */
  onToggle: () => void
  /**
   * The id of the item
   */
  itemId: string
  /**
   * The type of the item
   */
  itemType: ItemType
  /**
   * The image of the product to share
   */
  image?: string | null
}

/**
 * Modal that holds the ItemShareEmailForm
 */
function ItemShareEmailModal ({ open, onToggle, itemId, itemType, image }: ItemShareEmailModalProps): ReactElement {
  return (
    <Modal isOpen={open} onToggle={onToggle}>
      <ModalHeader onToggle={onToggle}>
        Share Product
      </ModalHeader>
      <ModalBody className='pb-8'>
        <ItemShareEmailForm
          itemId={itemId}
          itemType={itemType}
          onToggle={onToggle}
          image={image}
        />
      </ModalBody>
    </Modal>
  )
}

export {
  ItemShareEmailModal
}
