import { useRouter } from 'next/router'
import { useMemo } from 'react'

export interface TrackingQueryStringParams {
  recommendationSource: string | undefined
  recommendationType: string | undefined
  recommendationId: string | undefined
  recommendationIndex: string | undefined
  recommendationContext: string | undefined
}

/**
 * Converts the query string parameters to a string
 */
function convertToString (value: string | string[] | undefined): string | undefined {
  if (value == null) {
    return undefined
  }

  if (Array.isArray(value)) {
    return value.join(',')
  }

  return value
}

/**
 * Gets the recommendation query string parameters from the URL
 */
function useTrackingQueryStringParams (): TrackingQueryStringParams {
  const { query } = useRouter()

  const recommendationSource = useMemo(() => convertToString(query.recommendationSource), [query])
  const recommendationType = useMemo(() => convertToString(query.recommendationType), [query])
  const recommendationId = useMemo(() => convertToString(query.recommendationId), [query])
  const recommendationIndex = useMemo(() => convertToString(query.recommendationIndex), [query])
  const recommendationContext = useMemo(() => convertToString(query.recommendationContext), [query])

  return {
    recommendationSource,
    recommendationType,
    recommendationId,
    recommendationIndex,
    recommendationContext
  }
}

export {
  useTrackingQueryStringParams
}
