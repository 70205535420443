import { Card } from '@stuller/stullercom/ui'
import React, { type ReactElement } from 'react'

interface CartItemConfigurationDetailProps {
  /**
   * The title of the item
   */
  title: string
  /**
   * The description of the item
   */
  description: string
}

/**
 * Component for the detail item in the cart item configuration
 */
function CartItemConfigurationDetail ({
  title,
  description
}: CartItemConfigurationDetailProps): ReactElement {
  return (
    <Card className='p-4 border-0'>
      <div className='row'>
        <p className='fs-4 fw-bold mb-0'>{title}</p>
        <p className='mb-0'>{description}</p>
      </div>
    </Card>
  )
}

export {
  CartItemConfigurationDetail
}
