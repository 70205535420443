import { type ReactElement, type ChangeEvent, type FormEvent, useState, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, Loading, Form, FormGroup, FormLabel, Input, FormFeedback, Button, Select } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'
import {
  type GetFavoriteListsQuery,
  useGetFavoriteListsQuery,
  type AddToFavoritesInput,
  useAddToFavoritesMutation,
  ItemType,
  type CartLineMessageInfoFragment,
  type ItemLineOptionsInput,
  type OrderLineOptionsInput
} from '@stuller/stullercom/data-access/apollo-queries'
import type { AddToFavoriteCartLineOrItem } from '../shared/types'
import Link from 'next/link'
import { validateForm, type ValidateFormErrors } from '@stuller/shared/util/schema-validation'
import { useLock } from '@stuller/shared/util/react-hooks'
import { z } from 'zod'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

interface SuccessFormGroupProps {
  /**
   * The name of the favorite list
   */
  favoriteListName: string
  /**
   * The id of the selected favorite list
   */
  selectedFavoriteListId: string
}

interface DropDownProps {
  /**
   * Favorite list options
   */
  options?: GetFavoriteListsQuery
  /**
   * The selected value
   */
  value: string
  /**
   * Function to handle the change of the selected value
   */
  onValueChange: (value: string) => void
}

interface CartItemFavoriteModalProps {
  /**
   * Whether the modal is open
   */
  open: boolean
  /**
   * Function to toggle the modal
   */
  onToggle: () => void
  /**
   * Function to set the error state
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * The data needed to add an item to favorites
   */
  data: AddToFavoriteCartLineOrItem
  /**
   * The function called to send the trackings to the tracking event
   */
  trackAddToWishList: () => void
}

/**
 * This component is a dropdown that allows the user to select a favorite list to add an item to.
 */
function DropDown ({ options, value, onValueChange }: DropDownProps): ReactElement {
  function handleValueChange (event: ChangeEvent<HTMLSelectElement>): void {
    onValueChange(event.target.value)
  }

  return (
    <Select value={value} onChange={handleValueChange} data-test='choose-your-list-dropdown'>
      <option value=''>Create New List</option>
      {options?.favoriteLists.map((x) => (<option key={x.id} value={x.name}>{x.name}</option>))}
    </Select>
  )
}

/**
 * This component is a form group that displays a success message when an item is added to favorites
 */
function SuccessFormGroup ({ favoriteListName, selectedFavoriteListId }: SuccessFormGroupProps): ReactElement {
  return (
    <FormGroup groupId='choose' className='row pb-6 pt-3'>
      <div className='d-flex flex-column align-items-center'>
        <FontAwesomeIcon icon={fas.faCheckCircle} className='mb-3 text-green fs-1' />
        <h2 className='fs-1 mb-4 mt-1'>Added to Favorites</h2>
        <p className='mb-0' data-test='added-to-favorites-message'>This item has been saved to your favorites.</p>
        <p>View this item on your list, <Link href={`/favorite/?favoriteListId=${selectedFavoriteListId}`}>{favoriteListName}</Link>.</p>
      </div>
    </FormGroup>
  )
}

/**
 * This function converts the string item type to the ItemType enum
 */
function getItemType (itemType: string): ItemType {
  switch (itemType) {
    case 'Product':
      return ItemType.Product
    case 'Configuration':
      return ItemType.Configuration
    case 'SerializedProduct':
      return ItemType.SerializedProduct
    default:
      throw new Error('Invalid item type')
  }
}

/**
 * This function builds the AddToFavoritesInput object for CartLines
 */
function getAddToFavoriteInput (data: AddToFavoriteCartLineOrItem): Omit<AddToFavoritesInput, 'favoriteListName'> {
  if (data.__typename === 'CartLine' || data.__typename === 'SavedCartLine') {
    const {
      item,
      options
    } = data

    return {
      itemId: {
        id: item.id,
        type: getItemType(item.__typename ?? '')
      },
      ...getAddToFavoriteInputOptions(options)
    }
  }

  return {
    itemId: {
      id: data.id,
      type: getItemType(data.__typename ?? '')
    },
    ...getAddToFavoriteInputOptions(data.options)
  }
}

/**
 * Builds the add to favorites input
 */
function getAddToFavoriteInputOptions (options: ItemLineOptionsInput | OrderLineOptionsInput | null | undefined): Pick<AddToFavoritesInput, 'options'> {
  const {
    isAnnealed,
    isForConsignment,
    isMatched,
    quantity,
    specialInstructions,
    length,
    width
  } = options ?? {
    isAnnealed: false,
    isForConsignment: false,
    isMatched: false,
    quantity: 1,
    specialInstructions: null,
    length: null,
    width: null
  }

  return {
    options: {
      customerNotes: null,
      isAnnealed: isAnnealed ?? false,
      isForConsignment: isForConsignment ?? false,
      isMatched: isMatched ?? false,
      quantity: quantity ?? 1,
      specialInstructions,
      length: length == null
        ? null
        : {
          uom: length?.uom,
          value: length?.value
        },
      width: width == null
        ? null
        : {
          uom: width?.uom,
          value: width?.value
        }
    }
  }
}

/**
 * The favorite item modal
 */
function CartItemFavoriteModal ({
  open,
  onToggle,
  onError,
  data: dataIn,
  trackAddToWishList
}: CartItemFavoriteModalProps): ReactElement | null {
  if (!open) {
    return null
  }

  return <CartItemFavoriteModalInner open={open} onToggle={onToggle} onError={onError} data={dataIn} trackAddToWishList={trackAddToWishList} />
}

/**
 * The inside of the favorite item modal when open
 */
function CartItemFavoriteModalInner ({
  open,
  onToggle,
  onError,
  data: dataIn,
  trackAddToWishList
}: CartItemFavoriteModalProps): ReactElement {
  const ref = useRef(typeof window !== 'undefined' ? document.body : undefined)
  const [selectedFavoriteList, setselectedFavoriteList] = useState<string>('')
  const [newFavoriteListName, setNewFavoriteListName] = useState<string>('')
  const [favoriteListNotes, setFavoriteListNotes] = useState<string>('')
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [selectedFavoriteListId, setSelectedFavoriteListId] = useState<string>('')
  const favoriteList = useGetFavoriteListsQuery()
  const newFavoriteListNameClass = clsx(
    selectedFavoriteList === '' ? '' : 'd-none',
    showSuccess ? 'd-none' : '',
    'mb-4'
  )
  const inputFormGroupClass = clsx(
    showSuccess ? 'd-none' : '',
    'mb-4'
  )
  const buttonContainerClass = clsx(
    showSuccess ? 'd-none' : '',
    'pb-6 pt-3 d-flex justify-content-end'
  )

  /**
   * Build the input object for the AddToFavoritesMutation
   */
  const input: Omit<AddToFavoritesInput, 'favoriteListName'> = getAddToFavoriteInput(dataIn)

  /**
   * Add to favorites mutation
   */
  const [addToFavoritesMutation, { loading }] = useAddToFavoritesMutation({
    variables: {
      input: {
        ...input,
        favoriteListName: newFavoriteListName,
        options: { ...input.options, customerNotes: favoriteListNotes }
      }
    },
    onCompleted: (data) => {
      if (data.addToFavorites != null) {
        setSelectedFavoriteListId(data.addToFavorites.favorite.favoriteList.id ?? '')
      }
      void favoriteList.refetch()
      setselectedFavoriteList('')
      setFavoriteListNotes('')
      trackAddToWishList()
    },
    onError: (error: Error) => {
      logger.error('Error Adding to Favorites', { code: 'CART_ERROR', message: error.message }, error)
      onToggle()
      onError([{ code: 'CART_ERROR', message: 'There was an issue adding this item to favorites' }])
    }
  })

  const schema = z.object({
    newFavoriteListName: z.string().trim().min(1)
  })
  const [errors, setErrors] = useState<ValidateFormErrors>({})

  /**
   * Handle the submit of the form
   */
  const [handleSubmit, locked] = useLock(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const result = validateForm(schema, { newFavoriteListName })
    if (result.success) {
      await addToFavoritesMutation()
      setShowSuccess(true)
      setErrors({})
    } else {
      setErrors(result.errors)
    }
  })

  const handleFormReset = (): void => {
    setselectedFavoriteList('')
    setNewFavoriteListName('')
    setFavoriteListNotes('')
    setShowSuccess(false)
  }

  /**
   * Handle the change of the dropdown
   */
  function handleFavoritesListChange (selectedFavoriteList: string): void {
    setselectedFavoriteList(selectedFavoriteList)
    // User has choosen `Create New List`
    if (selectedFavoriteList === '') {
      setNewFavoriteListName('')
    } else {
      // User has choosen an existing list, populate the hidden input with the list name
      setNewFavoriteListName(selectedFavoriteList)
    }
  }

  /**
   * Handle the change of the new name input
   */
  function handleFavoriteListNameChange (event: ChangeEvent<HTMLInputElement>): void {
    setNewFavoriteListName(event.target.value)
  }

  /**
   * Handle the change of the notes input
   */
  function handleFavoriteListNotesChange (event: ChangeEvent<HTMLInputElement>): void {
    setFavoriteListNotes(event.target.value)
  }

  return (
    <Modal
      isOpen={open}
      onToggle={onToggle}
      onHidden={handleFormReset}
      portalRef={ref}
    >
      <Loading loading={favoriteList.loading || loading}>
        <Form className='g-3' onSubmit={handleSubmit}>
          <ModalHeader onToggle={onToggle}>
            {!showSuccess && ('Add to Favorites')}
          </ModalHeader>
          <ModalBody>
            {showSuccess && <SuccessFormGroup favoriteListName={newFavoriteListName} selectedFavoriteListId={selectedFavoriteListId} />}
            <FormGroup groupId='choose' className={inputFormGroupClass}>
              <FormLabel>Choose your list:</FormLabel>
              <DropDown options={favoriteList.data} value={selectedFavoriteList} onValueChange={handleFavoritesListChange} />
            </FormGroup>
            <FormGroup groupId='container-list-name' className={newFavoriteListNameClass}>
              <FormLabel>New List:</FormLabel>
              <Input
                value={newFavoriteListName}
                onChange={handleFavoriteListNameChange}
                maxLength={255}
                invalid={errors.newFavoriteListName}
                data-test='list-name'
              />
              <FormFeedback>{errors.newFavoriteListName}</FormFeedback>
            </FormGroup>
            <FormGroup groupId='container-notes' className={inputFormGroupClass}>
              <FormLabel>Notes:</FormLabel>
              <FormLabel>(Optional)</FormLabel>
              <Input value={favoriteListNotes} onChange={handleFavoriteListNotesChange} maxLength={255} data-test='list-notes' />
            </FormGroup>
            <div className={buttonContainerClass}>
              <Button onClick={onToggle} color='anchor' className='me-4 text-decoration-none' hidden={showSuccess} data-test='cancel-button'>Cancel</Button>
              <Button color='primary' type='submit' disabled={locked || loading} hidden={showSuccess} data-test='save-button'>Save Favorite</Button>
            </div>
          </ModalBody>
        </Form>
      </Loading>
    </Modal>
  )
}

export {
  CartItemFavoriteModal
}
