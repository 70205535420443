import { forwardRef, type ReactNode } from 'react'
import { CardTitle } from '../../card/CardTitle'
import clsx from 'clsx'

interface OptionCardTitleProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Content of the option card title
   */
  children?: ReactNode
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
}

const OptionCardTitle = forwardRef<HTMLDivElement, OptionCardTitleProps>(({
  id,
  children,
  className,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'form-option-card-title',
    className
  )

  return (
    <CardTitle
      id={id}
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </CardTitle>
  )
})
OptionCardTitle.displayName = 'OptionCardTitle'

export {
  OptionCardTitle
}
