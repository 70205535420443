import { useToggle } from '@stuller/shared/util/react-hooks'
import { type AddToCartSectionInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { Button } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'
import { OneClickQuoteModal } from './OneClickQuoteModal'

interface OneClickQuoteButtonProps {
  addToCartSectionInfo: AddToCartSectionInfoFragment
}

/*
* This component is a button that opens a modal to request a quote for an item.
*/
function OneClickQuoteButton ({ addToCartSectionInfo }: OneClickQuoteButtonProps): ReactElement {
  const [quoteModalOpen, toggleQuoteModalOpen] = useToggle(false)

  return (
    <>
      <Button color='success' onClick={toggleQuoteModalOpen}>Request this Item</Button>
      <OneClickQuoteModal addToCartSectionInfo={addToCartSectionInfo} modalOpen={quoteModalOpen} onToggle={toggleQuoteModalOpen} />
    </>
  )
}

export { OneClickQuoteButton }
