import { type ReactElement } from 'react'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Button } from '@stuller/stullercom/ui'
import { type CartLineMessageInfoFragment, useGetCanAddToFavoritesQuery } from '@stuller/stullercom/data-access/apollo-queries'
import type { CartLine } from '../shared/types'
import { CartItemFavoriteModal } from './CartItemFavoriteModal'
import { useCartContext } from '../shared/useCartContext'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { trackCartLineAddToWishList } from '@stuller/stullercom/feat/google-tag-manager'

interface CartItemFavoriteButtonProps {
  /**
   * The cart line to favorite
   */
  cartLine: CartLine
  /**
   * Function to set the error state
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * Whether to disable the button
   */
  disabled?: boolean
}

/**
 * This component is a button that allows the user to favorite an item in the cart.
 */
function CartItemFavoriteButton ({ onError, cartLine, disabled = false }: CartItemFavoriteButtonProps): ReactElement | null {
  const { cartArea } = useCartContext()
  const auth = useAuth()
  const [isOpen, toggleIsOpen] = useToggle()
  const { data } = useGetCanAddToFavoritesQuery()
  const canAddToFavorites = data?.viewerPermissions.canAddToFavorites ?? false

  function handleTrackAddToWishList (): void {
    trackCartLineAddToWishList(cartLine, auth)
  }

  if (!canAddToFavorites || cartArea === 'dot-carts-page' || cartArea === 'cart-search-page') {
    return null
  }

  return (
    <div className='me-4 order-2 order-xl-1'>
      <Button onClick={toggleIsOpen} color='anchor-dark' disabled={disabled}>
        <FontAwesomeIcon icon={fas.faHeart} className='me-2' />
        <span className='fw-normal' data-test='add-to-favorite-button'>Favorite</span>
      </Button>
      <CartItemFavoriteModal
        open={isOpen}
        onToggle={toggleIsOpen}
        onError={onError}
        data={cartLine}
        trackAddToWishList={handleTrackAddToWishList}
      />
    </div>
  )
}

export {
  CartItemFavoriteButton
}
