import React, { useMemo, useState, useEffect } from 'react'
import {
  useCartPageCartPageDeleteAllCartLinesMutation,
  useGetCartPageCartPageCartQuery,
  useCartPageCartPageUpdateScheduledOrderMutation,
  GetCartPageCartPagePermissionsDocument,
  type GetCartPageCartPagePermissionsQuery,
  useCartPageCartItemUpdateCartLineWithShipmentsMutation,
  type ShipCompleteMethod,
  useCartPageCartItemDeleteCartLineWithShipmentsMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { Alert, Loading } from '@stuller/stullercom/ui'
import { useToggleKey, useTrackVisibility } from '@stuller/shared/util/react-hooks'
import { CartPageLayout } from '../shared/CartPageLayout'
import { CartContext } from '../shared/CartContext'
import { CartSummary, CartSummaryOffcanvas, CartSummaryWrapper } from '../cart-summary/CartSummary'
import { CartPageError } from './CartPageError'
import { CartErrorsNotes } from './CartErrorsNotes'
import { CartEmpty } from './CartEmpty'
import { CartLines } from './CartLines'
import { CartScheduledOrderInfo } from './CartScheduledCartInfo'
import { CartProductRecommendationCarousel } from './CartProductRecommendationCarousel'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { type CustomNextPage } from '@stuller/stullercom/feat/layout-context'
import { dayjs } from '@stuller/shared/util/core'
import { getClientValidationErrors } from '@stuller/stullercom/data-access/apollo-client'
import { trackCartView, trackCartRemoveAll } from '@stuller/stullercom/feat/google-tag-manager'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * The page that will show all the saved cart items
 */
const CartPage: CustomNextPage = () => {
  const auth = useAuth()
  const [offcanvasSentryRef, { visible }] = useTrackVisibility({ rootMargin: '-30px 0px 9999px 0px' })
  const { data, previousData, loading: getCartLoading, error } = useGetCartPageCartPageCartQuery({
    fetchPolicy: 'network-only'
  })
  const [scheduledShippingOptionIndex, setScheduledShippingOptionIndex] = useState<number | null>(null)
  const [scheduledShippingOptionError, setScheduledShippingOptionError] = useState<Array<{ code: string, message: string }>>([])
  const [scheduledShippingOptionValue, setScheduledShippingOptionValue] = useState<ShipCompleteMethod | null | undefined>()
  const { cart, shipments, scheduledOrder, serviceCharges, sortedCartLines } = useMemo(() => {
    const cart = data?.cart
    const shipments = cart?.shipments
    const scheduledOrder = data?.viewer?.scheduledOrder
    const serviceCharges = cart?.price?.serviceCharges
    const cartItemIds = cart?.items.map((item) => item.id) ?? []
    const sortedCartLines = shipments?.flatMap((shipment) => shipment.lines).sort((a, b) => cartItemIds.indexOf(a.id) - cartItemIds.indexOf(b.id)) ?? []

    return { cart, shipments, scheduledOrder, serviceCharges, sortedCartLines }
  }, [data])
  const [shipmentsOpen, handleToggleShipments, setOpenShipments] = useToggleKey([])

  /**
   * Set the scheduled shipping option value when the scheduled order is loaded or updated
   */
  useEffect(() => {
    if (scheduledOrder != null) {
      setScheduledShippingOptionValue(scheduledOrder.shipCompleteMethod)
    }
  }, [scheduledOrder])

  /**
   * Track view cart
   */
  useEffect(() => {
    if (!getCartLoading && data != null && previousData == null && error == null) {
      trackCartView(data.cart, auth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCartLoading, data, error])

  /**
   * Mutation to remove all items from the cart
   */
  const [removeAllItems, { loading: removeAllItemsLoading, error: removeAllError }] = useCartPageCartPageDeleteAllCartLinesMutation({
    variables: { input: { cartId: cart?.id ?? '' } },
    onError: (error: Error) => {
      document.documentElement.scrollTop = 0
      logger.error('Could not remove all items from the cart: ', { code: 'REMOVE_ALL_ERROR', message: 'We weren\'t able to remove the items from your cart. Refresh the page and try again.' }, error)
    },
    onCompleted: () => {
      document.documentElement.scrollTop = 0
      if (cart != null) {
        trackCartRemoveAll(cart, auth)
      }
    },
    update: (cache, { data }) => {
      // Update the cartItemCount in the cache
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          cartItemCount () {
            return data?.deleteAllCartLines?.cart?.itemCount ?? 0
          }
        }
      })
      cache.gc()
    }
  })

  /**
   * Mutation to update the scheduled order shipping method
   */
  const [updateScheduledOrder, { loading: updateScheduledOrderLoading }] = useCartPageCartPageUpdateScheduledOrderMutation({
    onError: (error) => {
      const validationErrors = getClientValidationErrors(error).map((e) => e.message)
      const errorMessage = validationErrors.length > 0 ? validationErrors.map((error) => ({ code: 'CART_ERROR', message: error })) : [{ code: 'CART_ERROR', message: 'There was an issue updating the shipping method. Refresh the page and try again.' }]
      setScheduledShippingOptionError(errorMessage)
      logger.error('Could not update the scheduled order: ', { code: 'UPDATE_SCHEDULED_ORDER_ERROR', message: error.message }, error)
      setScheduledShippingOptionValue(data?.viewer?.scheduledOrder?.shipCompleteMethod)
    }
  })

  /**
   * Function to handle updating the scheduled order shipping method
   */
  async function handleUpdateScheduledOrder (shipCompleteMethod: ShipCompleteMethod, index: number): Promise<void> {
    setScheduledShippingOptionError([])
    setScheduledShippingOptionIndex(index)
    await updateScheduledOrder({
      variables: {
        input: {
          shipCompleteMethod
        }
      }
    })
  }

  // Calculated values
  const { showCartPageError, showEmptyCart, showCartSummary, showScheduledOrderInfo, scheduledOrderCutoffTime } = useMemo(() => {
    const showCartPageError = !getCartLoading && error != null
    const showEmptyCart = !getCartLoading && (cart == null || cart.itemCount === 0) && error == null
    const showCartSummary = !getCartLoading && (!showEmptyCart || scheduledOrder != null) && error == null
    const showScheduledOrderInfo = !getCartLoading
    const cutOffDateObj = dayjs(scheduledOrder?.timeZone?.cutoff)
    const scheduledOrderCutoffTime = scheduledOrder != null ? (cutOffDateObj.isToday() ? `at ${cutOffDateObj.format('h:mm a')} today` : `at ${cutOffDateObj.format('h:mm a on dddd, MMMM D')}`) : ''

    return { showCartPageError, showEmptyCart, showCartSummary, showScheduledOrderInfo, scheduledOrderCutoffTime }
  }, [cart, error, getCartLoading, scheduledOrder])

  return (
    <CartPageLayout
      title='Cart Items'
      activeNavigationLink='cart'
      skipContainer
    >
      <CartContext.Provider
        value={{
          cartArea: 'cart-page',
          scheduledOrder,
          shipmentsOpen,
          handleToggleShipments,
          setOpenShipments,
          useCartContextUpdateCartLineMutation: useCartPageCartItemUpdateCartLineWithShipmentsMutation,
          useCartContextDeleteCartLineMutation: useCartPageCartItemDeleteCartLineWithShipmentsMutation
        }}
      >
        <div className='py-5'>
          {showCartSummary && (
            <CartSummaryOffcanvas
              cart={cart}
              cartLines={sortedCartLines}
              cartSummaryVisible={visible}
              showEmptyCart={showEmptyCart}
              scheduledOrderCutoffTime={scheduledOrderCutoffTime}
            />
          )}

          <Loading loading={getCartLoading || updateScheduledOrderLoading || removeAllItemsLoading}>
            {showCartPageError && <CartPageError />}
            <CartErrorsNotes type='danger' messages={cart?.errors} />
            <CartErrorsNotes type='warning' messages={cart?.notes} />

            <div className='position-relative'>
              {scheduledOrder == null
                ? (
                  <div className='bg-gray-100'>
                    <div className='container-xxl mw-xxl px-4'>
                      <div className='row'>
                        {showCartSummary && (
                          <div className='col-12 col-md-auto order-md-last pb-5 pb-md-3 mb-md-n3'>
                            <div className='position-md-sticky top-0 pt-md-5 mt-md-n5'>
                              <CartSummary
                                cart={cart}
                                cartLines={sortedCartLines}
                                ref={offcanvasSentryRef}
                                showEmptyCart={showEmptyCart}
                                scheduledOrderCutoffTime={scheduledOrderCutoffTime}
                              />
                            </div>
                          </div>
                        )}
                        <div className='col'>
                          {removeAllError != null && (
                            <Alert color='danger'>There was an issue removing your items from the cart</Alert>
                          )}
                          {showEmptyCart
                            ? <CartEmpty />
                            : <CartLines cartLines={sortedCartLines} shipments={shipments} onRemoveAllItems={removeAllItems} serviceCharges={serviceCharges} />}
                        </div>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <>
                    {showScheduledOrderInfo && (
                      <CartScheduledOrderInfo
                        scheduledOrder={data?.viewer?.scheduledOrder}
                        onUpdateScheduledOrder={handleUpdateScheduledOrder}
                        selectedIndex={scheduledShippingOptionIndex}
                        error={scheduledShippingOptionError}
                        removeAllError={removeAllError}
                        shippingMethod={scheduledShippingOptionValue}
                        setShippingMethod={setScheduledShippingOptionValue}
                      />
                    )}
                    <div className='mt-n5 mb-6 position-md-absolute h-100 container-xxl mw-xxl px-4 top-0 start-0 end-0 bottom-0 m-auto'>
                      <div className='position-md-sticky top-0 mb-3'>
                        <div className='row'>
                          <div className='col d-none d-md-flex' />
                          <div className='col-12 col-md-auto pt-5 mb-n3'>
                            <CartSummary
                              cart={cart}
                              cartLines={sortedCartLines}
                              ref={offcanvasSentryRef}
                              showEmptyCart={showEmptyCart}
                              scheduledOrderCutoffTime={scheduledOrderCutoffTime}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {!showEmptyCart && !getCartLoading && (
                      <div className='bg-white pt-5 mb-n5 pb-3'>
                        <div className='container-xxl mw-xxl px-4'>
                          <div className='row'>
                            <div className='col z-1'>
                              <CartLines cartLines={sortedCartLines} shipments={shipments} onRemoveAllItems={removeAllItems} serviceCharges={serviceCharges} />
                            </div>
                            <div className='col-auto d-none d-md-flex'>
                              <CartSummaryWrapper />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
            </div>

            {showEmptyCart && <CartProductRecommendationCarousel />}
          </Loading>
        </div>
      </CartContext.Provider>
    </CartPageLayout>
  )
}

CartPage.getInitialProps = async (context) => {
  const { apolloClient } = context

  const { data } = await apolloClient.query<GetCartPageCartPagePermissionsQuery>({
    query: GetCartPageCartPagePermissionsDocument
  })

  if (data.viewerPermissions.canViewCart === false) {
    return { statusCode: 403 }
  }

  return {}
}

CartPage.layout = {
  title: {
    children: 'Your Shopping Cart'
  }
}

export {
  CartPage
}
