import { useState, type ReactElement } from 'react'
import {
  type ItemLineOptionsInput,
  type AddToCartSectionInfoFragment,
  type SalesType,
  useGetAddToCartTypeQuery,
  AddToCartType,
  useGetAddToCartItemsQuantityQuery,
  ItemType
} from '@stuller/stullercom/data-access/apollo-queries'
import { AddToCartQuantityDiscounts } from './AddToCartQuantityDiscounts'
import { AddToCartPrice } from './AddToCartPrice'
import { InStockStatusSection } from './in-stock-status/InStockStatusSection'
import { AddToCartSectionContext } from './AddToCartSectionContext'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { Loading } from '@stuller/stullercom/ui'
import { type TrackingQueryStringParams } from '@stuller/stullercom/feat/google-tag-manager'
import { ViewInCartSection } from './ViewInCartSection'
import { AddToCartSectionInner } from './AddToCartSectionInner'
import { useSearchParams } from 'next/navigation'
import AddToCartQuoteDetails from './AddToCartQuoteDetails'

interface AddToCartSectionProps {
  /**
   * most of the data needed to produce the entire add to cart section
   */
  data?: AddToCartSectionInfoFragment | null
  /**
   * Callback to notify parent component when the add to cart data has changed
   * TODO: make this required.  all the test pages were breaking so i just made it nullable for now
   */
  onOptionsChanged?: (options: Partial<ItemLineOptionsInput>) => void
  /**
   * Boolean to determine if the data is loading
   */
  dataLoading?: boolean
  /**
   * All tracking fields that can come in on the querystring from pages rendering the AddToCartSection
   */
  trackingParams?: TrackingQueryStringParams
}

/**
 * Renders the Add to Cart Partial
 */
function AddToCartSection ({ data, onOptionsChanged = () => {}, dataLoading, trackingParams }: AddToCartSectionProps): ReactElement | null {
  const { authUser } = useAuth()
  const [customerNotes, setCustomerNotes] = useState<string | null>(null)
  const [salesType, setSalesType] = useState<SalesType | undefined>(undefined)
  const options = data?.options ?? { quantity: 1 }
  const requestedQuantity = options?.quantity ?? 1
  const { data: addToCartTypeData } = useGetAddToCartTypeQuery()
  const addToCartType = addToCartTypeData?.viewerPermissions?.addToCartType ?? AddToCartType.Hidden
  const { data: cartItemsWithQuantity } = useGetAddToCartItemsQuantityQuery()
  const quoteParam = useSearchParams().get('searchTerm')
  const quoteId = quoteParam !== null ? quoteParam.split('.')[1] : null

  if (data == null) {
    return null
  }

  const {
    itemId: dataItemId,
    inStockStatus,
    shipDate,
    isReturnable,
    isLimitedAvailability,
    price,
    shippingCountdown
  } = data

  const hasScheduledOrder = authUser?.user?.scheduledOrder != null
  const itemPrice = price?.extended.total.value ?? 0
  const itemId = dataItemId.id
  const itemType = dataItemId.type
  let isActive = false
  let isOnConsignment = false
  let isSerializedProduct = false
  let isDiamond = false
  let isGemstone = false
  let isLabGrown = false
  let isSerializedProductInCart = false
  if (data.__typename === 'SerializedProduct') {
    isActive = data.isActive
    isOnConsignment = data.isOnConsignment ?? false
    isSerializedProduct = true
    isDiamond = data.isDiamond
    isGemstone = data.isGemstone
    isLabGrown = data.isLabGrown
    const cartItem = cartItemsWithQuantity?.cart?.items.find(({ item }) => item.itemId.type === ItemType.SerializedProduct &&
      item.id === itemId)
    isSerializedProductInCart = (cartItem?.options?.quantity ?? 0) > 0
  }

  return (
    <AddToCartSectionContext.Provider
      value={{
        itemId,
        itemType,
        isDiamond,
        isGemstone,
        isLabGrown,
        itemPrice,
        hasScheduledOrder,
        quantityOnHand: inStockStatus.backOrderInfo.quantityOnHand,
        customerNotes,
        setCustomerNotes,
        onAddToCartDataChanged: onOptionsChanged,
        cutoffDate: shippingCountdown?.cutoffDate,
        countdownKind: shippingCountdown?.countdownKind,
        options,
        trackingParams,
        addToCartType,
        salesType,
        setSalesType
      }}
    >
      <Loading loading={dataLoading}>
        <div
          className='d-flex flex-column gap-3'
          data-gtm-click-section='section'
          data-gtm-click-subsection1='add_to_cart_section'
        >
          <AddToCartPrice addToCartSectionInfo={data} />
          <AddToCartQuantityDiscounts priceBreaks={price?.priceBreaks} />
          {quoteId !== null && (
            <AddToCartQuoteDetails quoteId={quoteId} />
          )}
          <div className='bg-gray-100 p-4 rounded-3'>
            <div className='d-flex gap-5 flex-column'>
              <InStockStatusSection
                inStockStatus={inStockStatus}
                shipDate={shipDate?.date}
                isReturnable={isReturnable}
                isSerializedProduct={isSerializedProduct}
                isOnConsignment={isOnConsignment}
                isLimitedAvailability={isLimitedAvailability}
                isActive={isActive}
                requestedQuantity={requestedQuantity}
              />
              {isSerializedProductInCart ?
                <ViewInCartSection />
                : (
                  <AddToCartSectionInner
                    data={data}
                    isOnConsignment={isOnConsignment}
                    dataLoading={dataLoading}
                  />
                )}
            </div>
          </div>
        </div>
      </Loading>
    </AddToCartSectionContext.Provider>
  )
}

export {
  AddToCartSection
}
