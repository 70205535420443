import { memo, type ReactElement, useEffect, useState } from 'react'
import Script from 'next/script'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useStullerEventListener } from '@stuller/stullercom/feat/stuller-events'
import { track, trackEvent, trackUniversalEvent } from './track'
import { siteConfig } from '@stuller/stullercom/util/site-config'

/**
 * Google tag manager `script` tag
 * Use in Next.js `_app.tsx` (or via layout in `_app.tsx`)
 */
const GoogleTagManager = memo((): ReactElement | null => {
  const {
    authUser,
    hasRole,
    sessionId,
    isShowcase,
    isJewelerShowcase,
    isEmbeddedJewelerShowcase,
    isStullerComShowcaseMode,
    isSterling
  } = useAuth()
  const [processed, setProcessed] = useState(false)

  // Listen for proper Google Tag Manager events
  useStullerEventListener('track-event', ({ detail }) => {
    const { event, details } = detail ?? {}

    if (typeof event === 'string') {
      trackEvent(event, details)
    } else {
      throw new Error('track-event called with invalid event')
    }
  })

  // Listen for Google Tag Manager 'universal' event Google Tag Manager events
  useStullerEventListener('track-universal-event', ({ detail }) => {
    if (detail == null) {
      throw new Error('track-universal-event called without detail')
    }

    trackUniversalEvent(detail)
  })

  // Track values needed for things for initalization
  useEffect(() => {
    window.dataLayer = window.dataLayer ?? []
    track({
      site_version: 'nextjs',
      user_id: authUser?.user?.memberId ?? undefined,
      session_id: sessionId,
      showcase_mode: isShowcase,
      jeweler_showcase_mode: isJewelerShowcase,
      embedded_jeweler_showcase_mode: isEmbeddedJewelerShowcase,
      stullercom_showcase_mode: isStullerComShowcaseMode,
      signet_portal: isSterling,
      consultant_mode: hasRole('Consultant Mode'),
      member_stuller_first: authUser?.user?.isStullerFirstEnrolled ?? false,
      member_302: authUser?.user?.is302Member ?? false,
      member_ever_and_ever: authUser?.user?.isEverAndEverMember ?? false,
      member_gemvision: authUser?.user?.hasGemvisionAccount ?? false,
      segment: authUser?.user?.defaultActiveShipToAccount?.customerSegment ?? undefined,
      customer_value_score: authUser?.user?.defaultActiveShipToAccount?.customerValueScore ?? undefined
    })

    setProcessed(true)
  }, [authUser, hasRole, sessionId, isShowcase, isJewelerShowcase, isEmbeddedJewelerShowcase, isStullerComShowcaseMode, isSterling])

  if (siteConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID == null || !processed) {
    return null
  }

  return (
    <Script
      id='google-tag-manager-script'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${siteConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PARAMS ?? ''}';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${siteConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
        `
      }}
    />
  )
})
GoogleTagManager.displayName = 'GoogleTagManager'

export {
  GoogleTagManager
}
