import {
  type Schema,
  type SafeParseSuccess,
  type SafeParseError,
  type ParseParams
} from 'zod'

export type ValidateFormErrors = Record<string, string>

export type ValidateFormInfoSuccess<O> = SafeParseSuccess<O>

export type ValidateFormInfoError<I> = SafeParseError<I> & {
  errors: ValidateFormErrors
}

export type ValidateFormInfo<I, O> = ValidateFormInfoSuccess<O> | ValidateFormInfoError<I>

/**
 * Wrapper around Zod to help validate a form object given a Zod schema and data
 * Returns Zod's safe parse return type, plus a form friendly `errors` object if there are errors
 * Useful for validating React forms
 */
function validateForm<T> (schema: Schema, data: T, params?: Partial<ParseParams>): ValidateFormInfo<T, T> {
  const result = schema.safeParse(data, params)

  if (!result.success) {
    const errors: ValidateFormErrors = {}

    for (const issue of result.error.issues) {
      errors[issue.path.join('.')] = issue.message
    }

    return {
      ...result,
      errors
    }
  }

  return result
}

export { validateForm }
