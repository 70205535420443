import { type ReactElement, useMemo } from 'react'
import { Alert, Button, Card, CARD_TYPE_INFO, CardBody, CreditCardIcon, OptionCard, OptionCardDescription, OptionCardTitle } from '@stuller/stullercom/ui'
import { CartSummaryWrapper } from '../cart-summary/CartSummary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { dayjs } from '@stuller/shared/util/core'
import { type CartScheduledOrder } from '../shared/types'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { ShipCompleteMethod } from '@stuller/stullercom/data-access/apollo-queries'
import { type ApolloError } from '@apollo/client'

interface CartScheduledOrderInfoProps {
  /**
   * The cutoff time for the scheduled order
   */
  scheduledOrder: CartScheduledOrder
  /**
   * Function to update the scheduled order
   */
  onUpdateScheduledOrder: (shipCompleteMethod: ShipCompleteMethod, index: number) => Promise<void>
  /**
   * The index of the selected shipping option
   */
  selectedIndex: number | null
  /**
   * Error message for the scheduled shipping options
   */
  error: Array<{ code: string, message: string }>
  /**
   * Error message for removing all items from the cart
   */
  removeAllError?: ApolloError
  /**
   * The selected shipping method
   */
  shippingMethod: ShipCompleteMethod | null | undefined
  /**
   * Function to set the selected shipping method
   */
  setShippingMethod: (value: ShipCompleteMethod | null | undefined) => void
}

interface ShippingMethodOptionValues {
  /**
   * The index of the option card
   */
  index: number
  /**
   * The value of the option card
   */
  value: ShipCompleteMethod
  /**
   * The title of the option card
   */
  title: string
  /**
   * The description of the option card
   */
  description: string
  /**
   * The icon for the option card
   */
  icon: IconProp
}

interface ShippingMethodOptionCardProps extends ShippingMethodOptionValues {
  /**
   * Shipping Method
   */
  shippingMethod: ShipCompleteMethod | null | undefined
  /**
   * Set the shipping method
   */
  setShippingMethod: (value: ShipCompleteMethod) => void
  /**
   * Update the scheduled order
   */
  onUpdateScheduledOrder: (shipCompleteMethod: ShipCompleteMethod, index: number) => Promise<void>
  /**
   * Error message for the scheduled shipping options
   */
  error: Array<{ code: string, message: string }>
  /**
   * The index of the selected shipping option
   */
  selectedIndex: number | null
}

/**
 * Shipping Methods Options Data
 */
const shippingMethods: ShippingMethodOptionValues[] = [
  {
    index: 0,
    value: ShipCompleteMethod.ShipComplete,
    title: 'One Shipment',
    description: 'Ship when everything is available',
    icon: fas.faArrowTurnRight
  },
  {
    index: 1,
    value: ShipCompleteMethod.PartialShipComplete,
    title: 'Two Shipments',
    description: 'Ship in-stock items today and all other items when they become available',
    icon: fas.faArrowsTurnRight
  },
  {
    index: 2,
    value: ShipCompleteMethod.Individual,
    title: 'Ship each item as they become available',
    description: '',
    icon: fas.faSync
  },
  {
    index: 3,
    value: ShipCompleteMethod.LineLevel,
    title: 'Choose shipping method for each individual item',
    description: 'Create additional shipments and select which items should be in each shipment',
    icon: fas.faShuffle
  }
]

/**
 * Shipping Method Option Card
 */
function ShippingMethodOptionCard ({
  value,
  title,
  description,
  icon,
  index,
  shippingMethod,
  setShippingMethod,
  onUpdateScheduledOrder,
  error,
  selectedIndex
}: ShippingMethodOptionCardProps): ReactElement {
  /**
   * Handler for when the shipping method is changed
   */
  async function handleOptionChange (event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const shipCompleteMethod = event.target.value as ShipCompleteMethod
    setShippingMethod(shipCompleteMethod)
    await onUpdateScheduledOrder(shipCompleteMethod, index)
  }

  return (
    <OptionCard
      className='mb-3'
      value={value}
      onChange={handleOptionChange}
      checked={shippingMethod === value}
    >
      <OptionCardTitle>
        <FontAwesomeIcon
          icon={icon}
          className='me-2'
        />
        {title}
      </OptionCardTitle>
      <OptionCardDescription>
        {description}
      </OptionCardDescription>
      {error.length > 0 && selectedIndex === index && (
        <Alert color='danger' size='sm' icon className='fw-normal mb-0 mt-2'>
          {error.map((errorObj, index) => (
            <div key={index}>{errorObj.message}</div>
          ))}
        </Alert>
      )}
    </OptionCard>
  )
}

/**
 * Shows the cart page scheduled order info
 */
function CartScheduledOrderInfo ({
  scheduledOrder,
  onUpdateScheduledOrder,
  selectedIndex,
  error,
  removeAllError,
  shippingMethod,
  setShippingMethod
}: CartScheduledOrderInfoProps): ReactElement {
  /**
   * Calculated variables
   */
  const { cutoffTimeString, cutoffTimeDateString } = useMemo(() => {
    const dateObj = dayjs(scheduledOrder?.timeZone.cutoff)
    const cutoffTimeString = dateObj.isToday() ? `today at ${dateObj.format('h:mm a')}` : `on ${dateObj.format('h:mm a on dddd, MMMM D')}`
    const cutoffTimeDateString = `${dateObj.format('MMMM D')} at ${dateObj.format('h:mm a')}`

    return { cutoffTimeString, cutoffTimeDateString }
  }, [scheduledOrder])

  return (
    <div className='bg-gray-100'>
      <div className='container-xxl mw-xxl px-4'>
        <div className='row'>
          <div className='col pb-5 z-1'>
            {removeAllError != null && (
              <Alert color='danger'>There was an issue removing your items from the cart</Alert>
            )}
            <Card className='p-3 border-0'>
              <CardBody>
                <h3>You've scheduled an order</h3>
                <div className='d-flex'>
                  <FontAwesomeIcon icon={fas.faClock} size='2x' className='text-primary me-2' />
                  <h2>This order will be placed {cutoffTimeString}</h2>
                </div>
                <p>On {cutoffTimeDateString} (your local time), an automatic order will be placed for everything in your cart. Scheduling an order reserves inventory and ensures your order will be placed before the order cutoff time.</p>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <div className='d-flex align-items-center mb-2'>
                      <h3 className='mb-0 me-3'>Shipping Address</h3>
                      <Button
                        pill
                        outline
                        size='sm'
                        color='secondary'
                        tag='a'
                        href='/checkout/#/shipping-method'
                      >
                        Change
                      </Button>
                    </div>
                    <div className='d-flex flex-column'>
                      <p className='mb-0 fw-bold'>{scheduledOrder?.shipToAccount?.id}</p>
                      {scheduledOrder?.address.addressLines.map((address, index) => (
                        <p key={index} className='mb-0'>{address}</p>
                      ))}
                      <p className='mb-0'>{scheduledOrder?.address.city}, {scheduledOrder?.address.state ?? scheduledOrder?.address.province} {scheduledOrder?.address.zip}</p>
                      <p className='mb-0'>{scheduledOrder?.address.country}</p>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6 mt-5 mt-lg-0'>
                    <div className='d-flex align-items-center mb-2'>
                      <h3 className='mb-0 me-3'>Payment Method</h3>
                      <Button
                        pill
                        outline
                        size='sm'
                        color='secondary'
                        tag='a'
                        href='/checkout/#/payment-method'
                      >
                        Change
                      </Button>
                    </div>
                    <div className='d-flex'>
                      {scheduledOrder?.paymentMethod?.id === 'COD' && (
                        <p className='mb-0'>COD</p>
                      )}
                      {scheduledOrder?.paymentMethod?.id === 'Normal Terms' && (
                        <p className='mb-0'>Normal Terms</p>
                      )}
                      {scheduledOrder?.paymentMethod?.id === 'Credit Card' && 'type' in scheduledOrder.paymentMethod && (
                        <div className='d-flex align-items-center'>
                          <CreditCardIcon id='icon' type={scheduledOrder.paymentMethod.type} />
                          <p className='mb-0 ms-2 mt-1'>{CARD_TYPE_INFO[scheduledOrder.paymentMethod.type].name} credit card ending in {scheduledOrder.paymentMethod.lastFourDigits}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column mt-5'>
                  <h3>Shipping Method</h3>
                  <div className='d-flex flex-column'>
                    {shippingMethods.map((method) => (
                      <ShippingMethodOptionCard
                        key={method.index}
                        value={method.value}
                        title={method.title}
                        description={method.description}
                        icon={method.icon}
                        index={method.index}
                        shippingMethod={shippingMethod}
                        setShippingMethod={setShippingMethod}
                        onUpdateScheduledOrder={onUpdateScheduledOrder}
                        error={error}
                        selectedIndex={selectedIndex}
                      />
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className='col-auto d-none d-md-flex'>
            <CartSummaryWrapper />
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  CartScheduledOrderInfo
}
