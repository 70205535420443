import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'

interface DiamondGradingReportDisclaimerModalProps {
  /**
   * Whether the modal is open
   */
  isOpen: boolean
  /**
   * Function to toggle the modal open and closed
   */
  toggleIsOpen: () => void
}

function DiamondGradingReportDisclaimerModal ({ isOpen, toggleIsOpen }: DiamondGradingReportDisclaimerModalProps): ReactElement {
  return (
    <Modal isOpen={isOpen} onToggle={toggleIsOpen} size='lg'>
      <ModalHeader tag='h2' onToggle={toggleIsOpen}>Disclaimer & Limitation of Liability Regarding Diamond Laboratory Grading Reports</ModalHeader>
      <ModalBody>
        <p className='fw-bold text-uppercase'>Important Disclaimer & Limitation of Liability Regarding Diamond Laboratory Grading Reports </p>
        <p>A Diamond Laboratory Grading Report (“Report”) is an independent third-party opinion regarding the quality of a diamond. While such Reports are based upon quantifiable standards, a certain level of subjectivity is always inherent in the diamond grading process.
          Moreover, the standards that the various diamond laboratories use are not uniform. Accordingly, Stuller, Inc.’s (“Stuller”) transmittal or use of any such Report should not and does not imply Stuller’s agreement with or endorsement thereof.
        </p>
        <p className='text-uppercase'>Further, Stuller makes no representation or express warranty, and disclaims all implied warranties, with respect to the diamond laboratory grading reports accompanying or applicable to the diamonds that it sells, or the accuracy thereof.
          In no event shall Stuller or any of its employees or agents be liable to a customer or any other third party for any loss, damage or expense, or any consequential, incidental, indirect, exemplary,
          statutory, special or punitive damages, resulting from an error in or omission from, or the issuance of, use of, or reliance upon, a report, regardless of whether or not the customer or such other third party was advised of the possibility of such damages.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleIsOpen}>OK</Button>
      </ModalFooter>
    </Modal>
  )
}

export {
  DiamondGradingReportDisclaimerModal
}
