import { getSerializedDiamondPageInitialProps } from './getSerializedDiamondInitialProps'
import { NaturalSerializedDiamondSellingInfo } from './NaturalSerializedDiamondSellingInfo'
import { SerializedDiamondDetails } from './SerializedDiamondDetails'
import { SerializedDiamondPageHeader } from './SerializedDiamondPageHeader'
import { SerializedDiamondSpecialRequest } from './SerializedDiamondSpecialRequest'
import type { SerializedDiamond, SerializedDiamondPageType } from '../types'
import { SerializedDiamondAddToCart } from './SerializedDiamondAddToCart'
import { Title } from '@stuller/stullercom/feat/layout'
import { type ItemLineOptionsInput, useGetSerializedPdpPagesDiamondDetailsLazyQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { useTrackingQueryStringParams } from '@stuller/stullercom/feat/google-tag-manager'
import { PdpImageCarousel } from '@stuller/stullercom/feat/pdp-image-carousel'

/**
 * The Page that will render the content entries with the Content Page content type
 */
const SerializedDiamondPage: SerializedDiamondPageType = ({ serializedDiamond: serializedDiamondIn, serialNumber, canViewDiamondRebate }) => {
  const trackingParams = useTrackingQueryStringParams()
  const [refetch, { data, loading }] = useGetSerializedPdpPagesDiamondDetailsLazyQuery()
  const serializedDiamond: SerializedDiamond = data?.serializedProductBySerialNumber ?? serializedDiamondIn
  const pageTitle = `${serializedDiamond.stoneOrigin !== 'Natural' ? serializedDiamond.stoneOrigin : ''} ${serializedDiamond.stoneFamily} #${serializedDiamond?.id}`

  const handleAddToCartDataChanged = (optionsIn: Partial<ItemLineOptionsInput>): void => {
    const { options } = serializedDiamond ?? {}
    const sanitizedOptions = {
      quantity: options?.quantity ?? 1,
      isAnnealed: options?.isAnnealed ?? false,
      isForConsignment: options?.isForConsignment ?? false,
      isMatched: options?.isMatched ?? false,
      specialInstructions: options?.specialInstructions ?? ''
    }

    void refetch({
      variables: { serialNumber, options: { ...sanitizedOptions, ...optionsIn } },
      fetchPolicy: 'no-cache'
    })
  }

  return (
    <div itemScope itemType='https://schema.org/Product'>
      <div className='container-xxxl mw-xxl px-4'>
        <Title>{`${serializedDiamond.stoneOrigin !== 'Natural' ? serializedDiamond.stoneOrigin : ''} ${serializedDiamond.stoneFamily} #${serializedDiamond?.id}`}</Title>
        <SerializedDiamondPageHeader serializedDiamond={serializedDiamond} title={pageTitle} />
        <div style={{ maxWidth: '500px' }}>
          <PdpImageCarousel item={serializedDiamond} title={pageTitle} />
        </div>
        <SerializedDiamondDetails serializedDiamond={serializedDiamond} />
        <SerializedDiamondAddToCart data={serializedDiamond} onOptionsChanged={handleAddToCartDataChanged} dataLoading={loading} trackingParams={trackingParams} />
      </div>
      {serializedDiamond.stoneOrigin === 'Natural' &&
        <NaturalSerializedDiamondSellingInfo canViewDiamondRebate={canViewDiamondRebate} />}
      <div>
        <SerializedDiamondSpecialRequest serializedDiamond={serializedDiamond} />
      </div>
    </div>
  )
}

SerializedDiamondPage.getInitialProps = getSerializedDiamondPageInitialProps

export {
  SerializedDiamondPage
}
