import { type ReactElement, useState, useEffect } from 'react'
import { Form } from '@stuller/stullercom/ui'
import {
  type CartLineMessageInfoFragment,
  useCartPageCartItemUpdateSavedCartLineMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { ItemQuantityInputGroupWrapper } from './ItemQuantityInputGroupWrapper'
import { type CartLine } from '../shared/types'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { useCartContext } from '../shared/useCartContext'
import { trackCartLineAddToCart, trackCartLineRemoveFromCart } from '@stuller/stullercom/feat/google-tag-manager'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { getClientValidationErrors } from '@stuller/stullercom/data-access/apollo-client'

interface CartItemQuantityInputProps {
  /**
   * The current quantity of the cart item
   */
  quantity: number
  /**
   * The quantity description
   */
  soldByDescription: string
  /**
   * The cart line object
   */
  cartLine: CartLine
  /**
   * Function to handle loading state
   */
  onLoading: (loading: boolean) => void
  /**
   * Function to handle errors
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * Function to handle success
   */
  onSuccess: (success: boolean) => void
  /**
   * Whether the input is disabled
   */
  disabled?: boolean
}

/**
 * Component that renders the input for the quantity of a cart item
 */
function CartItemQuantityInput ({
  quantity,
  soldByDescription,
  cartLine,
  onLoading,
  onError,
  onSuccess,
  disabled = false
}: CartItemQuantityInputProps): ReactElement {
  const auth = useAuth()
  const { cartArea, useCartContextUpdateCartLineMutation } = useCartContext()
  const [currentQuantity, setCurrentQuantity] = useState<string>(quantity.toString())
  const [hasActionError, setHasActionError] = useState<boolean>(false)

  /**
   * Handles the change of the input value
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentQuantity(event.target.value)
  }

  /**
   * Set hasActionError and onError
   */
  const setHasActionErrorAndOnError = (error: CartLineMessageInfoFragment[]): void => {
    setHasActionError(error.length > 0)
    onError(error)
  }

  /**
   * Mutation to update the quantity of a cart item for saved cart line
   */
  const [handleUpdateSavedCartLine, { loading: updateSavedCartLineLoading }] = useCartPageCartItemUpdateSavedCartLineMutation({
    onError: (error) => {
      const errorStrings = getClientValidationErrors(error).map((e) => e.message)
      const errorMessage = errorStrings.length > 0 ? errorStrings : 'There was an issue saving your options'

      setHasActionErrorAndOnError([{ code: 'CART_ERROR', message: errorMessage.toString() }])
      logger.error('Error updating saved cart item options', {}, error)
      setCurrentQuantity(quantity.toString())
    },
    onCompleted: () => {
      setHasActionErrorAndOnError([])
      onSuccess(true)
    }
  })

  /**
   * Mutation to update the cart line
   */
  const [handleUpdateCartLine, { loading: updateCartLineLoading }] = useCartContextUpdateCartLineMutation({
    onError: (error) => {
      const errorStrings = getClientValidationErrors(error).map((e) => e.message)
      const errorMessage = errorStrings.length > 0 ? errorStrings : 'There was an issue saving your options'

      setHasActionErrorAndOnError([{ code: 'CART_ERROR', message: errorMessage.toString() }])
      logger.error('Error updating cart item options', {}, error)
      setCurrentQuantity(quantity.toString())
    },
    onCompleted: () => {
      const oldQuantity = quantity
      const newQuantity = Math.max(0, parseInt(currentQuantity))
      if (cartArea === 'cart-page' && !isNaN(newQuantity) && newQuantity !== oldQuantity) {
        if (oldQuantity < newQuantity) {
          trackCartLineAddToCart(cartLine, auth, newQuantity - oldQuantity)
        }
        if (oldQuantity > newQuantity) {
          trackCartLineRemoveFromCart(cartLine, auth, oldQuantity - newQuantity)
        }
      }
      setHasActionErrorAndOnError([])
      onSuccess(true)
    }
  })

  /**
   * Handles the submit of the quantity input
   */
  const handleOnSubmitQuantity = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const quantityToSubmit = parseInt(currentQuantity)
    if (isNaN(quantityToSubmit) || quantityToSubmit === quantity) {
      setCurrentQuantity(quantity.toString())

      return
    }
    setCurrentQuantity(quantityToSubmit.toString())
    if (quantityToSubmit !== quantity || hasActionError) {
      if (cartLine.__typename === 'CartLine') {
        void handleUpdateCartLine({
          variables: {
            input: {
              cartLineId: cartLine.id,
              fieldsToUpdate: ['QUANTITY'],
              options: {
                quantity: quantityToSubmit
              }
            }
          }
        })
      } else {
        void handleUpdateSavedCartLine({
          variables: {
            input: {
              savedCartLineId: cartLine.id,
              fieldsToUpdate: ['QUANTITY'],
              options: {
                quantity: quantityToSubmit
              }
            }
          }
        })
      }
    }
  }

  /**
   * Reset state on change
   */
  useEffect(() => {
    setCurrentQuantity(quantity.toString())
  }, [quantity])

  /**
   * Handle updating parent loading state
   */
  useEffect(() => {
    onLoading(updateSavedCartLineLoading || updateCartLineLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSavedCartLineLoading, updateCartLineLoading])

  return (
    <div className='col-12 mt-2 d-print-none'>
      <Form onSubmit={handleOnSubmitQuantity} onBlur={handleOnSubmitQuantity}>
        {/* Large Desktop */}
        <ItemQuantityInputGroupWrapper
          quantity={currentQuantity}
          onChangeQuantity={handleChange}
          isDisabled={disabled}
          soldByDescription={soldByDescription}
          inputGroupClassName='ms-auto d-none d-xl-flex'
          inputClassName='text-center'
          inputGroupTextClassName='justify-content-center'
          inputGroupMaxWidth={180}
        />
        {/* Small Desktop/Mobile */}
        <ItemQuantityInputGroupWrapper
          quantity={currentQuantity}
          onChangeQuantity={handleChange}
          isDisabled={disabled}
          soldByDescription={soldByDescription}
          inputGroupClassName='ms-auto d-xl-none'
          inputClassName='text-center w-100 border py-1 rounded-top-2 rounded-bottom-0'
          inputGroupTextClassName='bg-gray-100 w-100 justify-content-center py-2 m-0 border rounded-bottom-2 rounded-top-0 border-top-0 fs-6'
          inputGroupMaxWidth={120}
        />
        <input type='submit' className='d-none' />
      </Form>
    </div>
  )
}

export {
  CartItemQuantityInput
}
