import { type ReactElement, memo, useState } from 'react'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CustomShowcaseSettingDetailType } from '@stuller/stullercom/data-access/apollo-queries'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { MainLinks } from './MainLinks'
import { MobileMenu } from './MobileMenu'
import { Search } from '../search-nav/Search'
import { QuickLinks } from './QuickLinks'
import { MainNavSterling } from './MainNavSterling'

interface NavMobileProps {
  /**
   * Indicates to hide the mobile nav menu
   */
  hideNav: boolean
  /**
   * Reference element for the popover
   */
  reference: HTMLDivElement | null
}

/**
 * Main nav for mobile (menu button and search)
 */
function MobileNav ({ hideNav, reference }: NavMobileProps): ReactElement {
  const { getCustomShowcaseSetting } = useAuth()
  const showSearch = getCustomShowcaseSetting(CustomShowcaseSettingDetailType.HideSearchBar)?.[0] !== 'true'

  return (
    <div className='row d-lg-none m-0 py-3 align-items-center justify-content-between'>
      <div className='col-auto'>
        {!hideNav && <MobileMenu reference={reference} />}
      </div>
      {showSearch && (
        <div className='col col-sm-8 col-md-7'>
          <Search />
        </div>
      )}
    </div>
  )
}

/**
 * Main nav for desktop (main links and quick links)
 */
function DesktopNav (): ReactElement {
  return (
    <div className='d-none d-lg-flex m-0 align-items-center px-2 px-xl-0'>
      <MainLinks />
      <QuickLinks />
    </div>
  )
}

/**
 * Stuller.com main nav
 */
function MainNavStullerCom (): ReactElement {
  const { isShowcase, isEmbeddedJewelerShowcase, showcaseSettings, getCustomShowcaseSetting } = useAuth()
  const [reference, setReference] = useState<HTMLDivElement | null>(null)
  const showcaseSettingIFrameShowNavigationBar = showcaseSettings?.iFrameShowNavigationBar ?? false
  const customShowcaseSettingHideNavBarForShowcase = getCustomShowcaseSetting(CustomShowcaseSettingDetailType.HideNavBarForShowcase)?.[0] === 'true'
  const hideForShowcase = isShowcase && customShowcaseSettingHideNavBarForShowcase
  const hideForEmbeddedShowcase = isEmbeddedJewelerShowcase && !showcaseSettingIFrameShowNavigationBar
  const hideNav = hideForShowcase || hideForEmbeddedShowcase

  return (
    <>
      <nav data-gtm-click-section='main-nav' className='d-print-none'>
        <div ref={setReference} className='container-xxxl px-0'>
          {!hideNav && <DesktopNav />}
          <MobileNav hideNav={hideNav} reference={reference} />
        </div>

        <style jsx>{`
          nav {
            background-color: ${isEmbeddedJewelerShowcase ? 'var(--bs-white)' : `var(--bs-${showcaseSettings?.backgroundColorClass ?? ''}, var(--bs-gray-100))`};
            background-image: var(--bs-${!isEmbeddedJewelerShowcase ? (showcaseSettings?.backgroundPatternClass ?? '') : ''}, none);
          }

          @media (min-width: ${variables.breakpointLg}) {
            nav {
              background-color: var(${isShowcase ? '--bs-gray-700' : '--bs-cyan-500'});
              background-image: none;
            }
          }
        `}
        </style>
      </nav>
      <CmsContent
        contentContainerName='main-nav-callout-banner'
        className='d-xl-none position-relative d-print-none'
        skipLoading
      />
    </>
  )
}

/**
 * Main nav: main nav links (Products, Services, Quick Order, etc.) and search on mobile
 */
const MainNav = memo((): ReactElement | null => {
  const { isJewelerShowcase, isSterling, showcaseSettings } = useAuth()

  if (isSterling) {
    return <MainNavSterling />
  }

  // The layout should not be shown when on an invalid showcase
  if (isJewelerShowcase && showcaseSettings == null) {
    return null
  }

  return <MainNavStullerCom />
})
MainNav.displayName = 'MainNav'

export {
  MainNav
}
