import { getForbiddenRedirect } from './getForbiddenRedirect'
import type { CustomAppContext, CustomAppProps } from './types'

/**
 * Handles setting the correct status code and redirecting if necessary
 * For 403, it will check if the user is unauthorized and redirect to login if needed
 */
async function handleStatusCode (appContext: CustomAppContext, appProps: CustomAppProps): Promise<CustomAppProps> {
  if (appProps.pageProps?.statusCode != null) {
    const { ctx, router } = appContext
    let statusCode: number = appProps.pageProps.statusCode
    let location: string | undefined = appProps.pageProps.location

    // Handle 403
    if (statusCode === 403) {
      const unauthorizedInitial = getForbiddenRedirect(ctx)
      statusCode = unauthorizedInitial.statusCode
      location = unauthorizedInitial.location
    }

    // Set the response status code if server side
    if (ctx.res != null) {
      ctx.res.statusCode = statusCode
    }

    // Handle redirects client/server side
    if ([301, 302, 307, 308].includes(statusCode) && location != null) {
      if (ctx.res != null) {
        // Using redirect codes here to ensure http method is not changed in case this was a post
        ctx.res.writeHead(statusCode, { Location: location })
        ctx.res.end()
      } else {
        await router.push(location)
      }
    }
  }

  return appProps
}

export {
  handleStatusCode
}
