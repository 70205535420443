import { type ReactElement, useCallback, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { Button } from '@stuller/stullercom/ui'
import { MessagesPopoverContext } from './MessagesPopoverContext'
import { MessagesEmpty } from './MessagesEmpty'
import { MessageCard } from './MessageCard'
import { useInfiniteScroll } from '@stuller/shared/util/react-hooks'
import { type Notification } from './types'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

interface MessagesListInnerProps {
  notifications: Notification[]
}

/**
 * The list of messages
 */
function MessagesListInner ({ notifications }: MessagesListInnerProps): ReactElement {
  const { feed } = useContext(MessagesPopoverContext)
  const handleLoadMore = useCallback(() => {
    void feed.fetchMore({ variables: { cursor: feed.data?.viewer?.notificationsFeed.cursor } })
  }, [feed])
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: feed.loading,
    hasMore: feed.data?.viewer?.notificationsFeed.cursor != null,
    disabled: feed.error != null,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 15px 0px'
  })
  const sentryClassnames = clsx(feed.data?.viewer?.notificationsFeed.cursor != null && 'my-5 border border-transparent')

  // Scroll to top when the feed variables change (doesn't get called on fetch more)
  useEffect(() => {
    if (rootRef.current != null) {
      rootRef.current.scrollTo(0, 0)
    }
  }, [feed.variables, rootRef])

  return (
    <div ref={rootRef} className='overflow-auto scrollbar max-vh-50 mb-6' data-gtm-click-subsection2='messages-list'>
      {notifications?.map((n) => <MessageCard key={n.id} notification={n} />)}
      <div ref={sentryRef} className={sentryClassnames} />
    </div>
  )
}

/**
 * The container that renders the list of messages
 */
function MessagesList (): ReactElement {
  const { feed, updateFeedRead } = useContext(MessagesPopoverContext)
  const notifications = feed.loading
    ? feed.previousData?.viewer?.notificationsFeed.notifications
    : feed.data?.viewer?.notificationsFeed.notifications

  const hasUnreadNotifications = notifications?.some((n) => n.status === 'UNREAD') ?? false

  /**
   * Mark the entire feed as read, log to console if any errors
   */
  async function handleMarkAsRead (): Promise<void> {
    try {
      await updateFeedRead()
    } catch (error) {
      logger.error('handleMarkAsRead() failed.', {}, error)
    }
  }

  if (notifications == null || notifications?.length === 0) {
    return <MessagesEmpty />
  }

  return (
    <>
      <div className='d-flex justify-content-between pe-3 pb-2'>
        <strong>
          Recent messages
        </strong>
        <div>Mark as Read</div>
      </div>
      <MessagesListInner notifications={notifications} />
      {hasUnreadNotifications && (
        <Button
          color='secondary'
          pill
          outline='fill'
          className='w-100 mb-5'
          onClick={handleMarkAsRead}
        >
          Mark Entire Feed as Read
        </Button>
      )}
    </>
  )
}

export {
  MessagesList
}
