import React, { type ReactElement, Fragment, useMemo } from 'react'
import { CartItemConfigurationCard } from './CartItemConfigurationCard'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type MonogramConfigurationDetail, type MonogramLocationConfiguration } from '../../shared/types'

interface CartItemConfigurationMonogramProps {
  /**
   * The monogram data
   */
  monogramData: MonogramConfigurationDetail
}

/**
 * Component for the monogram item in the cart item configuration
 */
function CartItemConfigurationMonogram ({
  monogramData
}: CartItemConfigurationMonogramProps): ReactElement {
  return (
    <>
      {monogramData.locations != null && monogramData.locations.length > 0 && (
        <CartItemConfigurationCard
          title='Monogram'
        >
          <div className='row'>
            <div className='col-6 justify-content-center d-flex flex-column'>
              {monogramData.locations.map((location, index) => (
                <Fragment key={index}>
                  <p className='fs-7 mb-0 text-uppercase text-gray-600 lh-1'>{location.locationDescription}</p>
                  <p className='mb-0'>{location.value}</p>
                </Fragment>
              ))}
            </div>
            <div className='col-6 text-end'>
              <p className='fw-light mb-0'>Monogram</p>
              {monogramData.locations.length > 1 && (
                <LettersChosenText locations={monogramData.locations} />
              )}
              <p className='fw-light mb-0'>No Color Fill</p>
            </div>
          </div>
        </CartItemConfigurationCard>
      )}
    </>
  )
}

/**
 * Subcomponent to return a paragraph element of how many letters have been filled out of the total
 */
function LettersChosenText ({ locations }: {
  locations: MonogramLocationConfiguration[]
}): ReactElement {
  const displayString = useMemo(() => {
    let letterCount = 0
    for (const location of locations) {
      if (!isStringEmpty(location.value)) {
        letterCount += 1
      }
    }

    return `${letterCount} of ${locations.length} Letters Chosen`
  }, [locations])

  return (
    <p className='fw-light mb-0'>{displayString}</p>
  )
}

export {
  CartItemConfigurationMonogram
}
