import { useMemo, useState, type ReactElement } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { Card, Loading } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { type CartLineMessageInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { dayjs } from '@stuller/shared/util/core'
import { IMAGE_COMING_SOON } from '@stuller/shared/util/constants'
import { CartItemFavoriteButton } from './CartItemFavoriteButton'
import { CartItemMoveFromButton } from './CartItemMoveFromButton'
import { CartItemRemoveButton } from './CartItemRemoveButton'
import { CartItemQuantityInput } from './CartItemQuantityInput'
import { CartItemInstructionsNotes } from './CartItemInstructionsNotes'
import { CartItemConfigurationSummary } from './cart-item-configuration/CartItemConfigurationSummary'
import { CartItemAlert } from './CartItemAlert'
import { CartItemDOTDescription } from './CartItemDOTDescription'
import { CartItemOptionCheckbox } from './CartItemOptionCheckbox'
import { CartItemShipStatus } from './CartItemShipStatus'
import type { CartLine } from '../shared/types'
import { CartItemInStockStatus } from './CartItemInStockStatus'
import { CartItemQuantityOnHand } from './CartItemQuantityOnHand'
import { CartItemDimensions } from './CartItemDimensions'
import { CartItemPrice } from './CartItemPrice'
import { CartItemSuccess } from './CartItemSuccess'
import { useCartContext } from '../shared/useCartContext'
import { CartItemDOTOptions } from './CartItemDOTOptions'
import { CartItemReport } from './CartItemReport'
import { CartItemShipmentSelect } from './CartItemShipmentSelect'
import { getItemUrl } from '@stuller/stullercom/util/core'

interface CartItemProps {
  /**
   * The cart line data
   */
  cartLine: CartLine
  /**
   * The style of the cart item
   */
  style?: React.CSSProperties
  /**
   * Disable input fields in the cart item
   */
  disableFormChanges?: boolean
}

function buildUomString (uom?: string): string {
  if (uom == null) {
    return ''
  }

  switch (uom) {
    case 'INCH':
      return 'in'
    case 'MILLIMETER':
      return 'mm'
    default:
      return uom
  }
}

/**
 * The component that will show a single item in the cart
 */
function CartItem ({
  cartLine,
  style,
  disableFormChanges = false
}: CartItemProps): ReactElement {
  const { cartArea } = useCartContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [actionError, setActionError] = useState<CartLineMessageInfoFragment[]>([])
  const [success, setSuccess] = useState<boolean>(false)
  const isConfiguration = cartLine.item.__typename === 'Configuration'
  const isSerializedProduct = cartLine.item.__typename === 'SerializedProduct'
  const imageUrl = cartLine.item.image ?? IMAGE_COMING_SOON
  const quotedPrice = 'quotedPrice' in cartLine ? cartLine.quotedPrice?.total : undefined
  const cardClassName = clsx(
    'p-4 mb-3',
    actionError.length > 0 ? 'border-danger border-3' : 'border-0'
  )

  /**
   * Formats the creation date
   */
  const formattedCreationDate = useMemo(() => {
    return dayjs(cartLine.creationDate).format('l')
  }, [cartLine.creationDate])

  /**
   * Builds the product url
   */
  const productUrl = cartLine.item.__typename !== undefined ? getItemUrl(cartLine.item.__typename, cartLine.item.id, cartLine.productGroup?.id, 'Cart') : ''
  /**
   * Builds the dimensions string
   */
  const dimensionsString = useMemo(() => {
    // dimension 1 is length if it exists, otherwise it is width
    const dimension1 = cartLine.options.length?.value ?? cartLine.options.width?.value
    const uom1 = cartLine.options.length?.uom ?? cartLine.options.width?.uom

    // only use dimension 2 as width if there is a valid length value
    const dimension2 = cartLine.options.length?.value != null ? cartLine.options.width?.value : null
    const uom2 = cartLine.options.length?.uom != null ? cartLine.options.width?.uom : undefined

    if (dimension1 != null || dimension2 != null) {
      const dimension1String = `${dimension1} ${buildUomString(uom1)}`
      const dimension2String = dimension2 != null ? ` x ${dimension2} ${buildUomString(uom2)}` : ''
      // Only show '(L x W)' if both dimensions are present
      const dimensionsLayoutString = (dimension1 != null && dimension2 != null) ? ' (L x W)' : ''

      return `${dimension1String}${dimension2String}${dimensionsLayoutString}`
    }

    return null
  }, [cartLine.options.length, cartLine.options.width])

  /**
   * Return the item number of serial number depending on product type
   */
  const itemNumberDisplay: string = useMemo(() => {
    return isSerializedProduct ? `Serial #${cartLine.item.id}` : cartLine.item.itemNumber
  }, [cartLine.item.id, cartLine.item.itemNumber, isSerializedProduct])

  const creationDateTitle = useMemo(() => {
    if (cartArea === 'saved-items-page') {
      return `Saved for later on ${formattedCreationDate}`
    }
    if (cartArea === 'jeweler-showcase-carts-page') {
      return `Requested on ${formattedCreationDate}`
    }

    return `Added to cart on ${formattedCreationDate}`
  }, [formattedCreationDate, cartArea])

  return (
    <div style={style} className='p-0' data-test='saved-cart-item' id={`cart-item-${cartLine.id}`}>
      <Loading loading={loading}>
        <CartItemSuccess success={success} onSuccess={setSuccess} />
        <Card className={cardClassName}>
          <div className='row' data-test='saved-items-container'>
            {actionError.length > 0 && (
              <div className='mb-4'>
                <CartItemAlert
                  type='danger'
                  errors={actionError}
                />
              </div>
            )}
            <div className='d-block d-xl-none'>
              <CartItemInStockStatus inStockStatus={cartLine.inStockStatus} />
              <CartItemShipStatus shipDate={cartLine.shipDate} />
            </div>
            <div className='d-none d-xl-block col-auto'>
              <img src={imageUrl} alt={cartLine.item.title} className='h-auto w-100' style={{ maxWidth: 160 }} />
            </div>
            <div className='col'>
              <div className='row'>
                <div className='col col-lg-auto d-none d-lg-block d-xl-none'>
                  <img src={imageUrl} alt={cartLine.item.title} className='h-auto w-100 mb-3' style={{ maxWidth: 120 }} />
                </div>
                <div className='col'>
                  <img src={imageUrl} alt={cartLine.item.title} className='d-block d-lg-none h-auto w-100 mb-3' style={{ maxWidth: 65 }} />
                  <CartItemShipmentSelect
                    cartLine={cartLine}
                    onLoading={setLoading}
                    onError={setActionError}
                    onSuccess={setSuccess}
                    disabled={disableFormChanges}
                  />
                  <div>
                    <Link href={productUrl} data-test='item-number'>{itemNumberDisplay}</Link>
                    <CartItemReport cartLine={cartLine} />
                  </div>
                  <p className='mb-0 mt-1 fs-5 lh-xs' data-test='item-description'>{cartLine.item.title}</p>

                  <CartItemDimensions dimensionsString={dimensionsString} />
                  {isConfiguration && (
                    <div className='d-none d-lg-block mt-3 d-print-none'>
                      <CartItemConfigurationSummary configurationId={cartLine.item.id} />
                    </div>
                  )}
                  <CartItemDOTDescription
                    description={cartLine.consultantDescription ?? null}
                    bagAndTagOptions={cartLine.options.bagAndTagOptions}
                  />
                  <div className='d-print-none'>
                    {cartLine.item.canMatch && (
                      <CartItemOptionCheckbox
                        label='Match for size & color'
                        value='isMatched'
                        checked={cartLine.options.isMatched}
                        tooltip={<p className='mb-0'>Bulk pricing is not available if this option is selected. The standard per-item price will apply instead.</p>}
                        cartLine={cartLine}
                        onLoading={setLoading}
                        onError={setActionError}
                        onSuccess={setSuccess}
                        disabled={disableFormChanges}
                        data-test='match-for-size-and-color-checkbox'
                      />
                    )}
                    {cartLine.item.canAnneal && (
                      <CartItemOptionCheckbox
                        label='Anneal metal'
                        value='isAnnealed'
                        checked={cartLine.options.isAnnealed}
                        tooltip={<p className='mb-0'><b>Anneal:</b> Eliminate the stresses created during mechanical working and restore the metal to maximum softness.</p>}
                        cartLine={cartLine}
                        onLoading={setLoading}
                        onError={setActionError}
                        onSuccess={setSuccess}
                        disabled={disableFormChanges}
                        data-test='anneal-metal-checkbox'
                      />
                    )}
                    {cartLine.item.canOrderOnConsignment && (
                      <CartItemOptionCheckbox
                        label='Order for review'
                        value='isForConsignment'
                        checked={cartLine.options.isForConsignment}
                        cartLine={cartLine}
                        onLoading={setLoading}
                        onError={setActionError}
                        onSuccess={setSuccess}
                        disabled={disableFormChanges}
                        data-test='order-for-review-checkbox'
                      />
                    )}
                  </div>
                </div>
                <div className='col-5 col-sm-4'>
                  <div className='row'>
                    <div className='d-none d-xl-block'>
                      <CartItemInStockStatus inStockStatus={cartLine.inStockStatus} />
                      <CartItemShipStatus shipDate={cartLine.shipDate} />
                    </div>
                    <CartItemPrice
                      totalPrice={cartLine.price?.total}
                      quotePrice={quotedPrice}
                      discountedPrice={cartLine.price?.discount}
                      isForConsignment={cartLine.options.isForConsignment}
                      consultantDiscount={cartLine.options.consultantDiscount}
                    />
                    <CartItemQuantityInput
                      quantity={cartLine.options.quantity}
                      soldByDescription={cartLine.item.soldByDescription}
                      cartLine={cartLine}
                      onLoading={setLoading}
                      onError={setActionError}
                      onSuccess={setSuccess}
                      disabled={(cartLine.item.maximumQuantity != null && cartLine.item.maximumQuantity === 1) || disableFormChanges}
                    />
                    <CartItemQuantityOnHand inStockStatus={cartLine.inStockStatus} />
                    {isConfiguration && (
                      <div className='mt-2'>
                        <div className='d-flex justify-content-end'>
                          <div>
                            <FontAwesomeIcon icon={fas.faPenRuler} className='me-2' size='lg' />
                            Custom Order
                          </div>
                        </div>
                      </div>
                    )}
                    {/* FIXME: This will be nullable and we will need to instead check for isReturnable === true */}
                    {!cartLine.item.isReturnable && (
                      <div className='mt-2'>
                        <div className='d-flex justify-content-end'>
                          <div>
                            <FontAwesomeIcon icon={fas.faBan} className='me-2' size='lg' />
                            Non-Returnable
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isConfiguration && (
                <div className='d-block d-lg-none mt-4'>
                  <CartItemConfigurationSummary configurationId={cartLine.item.id} />
                </div>
              )}
              <CartItemInstructionsNotes
                specialInstructions={cartLine.options.specialInstructions}
                customerNotes={cartLine.options.customerNotes}
                onLoading={setLoading}
                onError={setActionError}
                cartLine={cartLine}
                onSuccess={setSuccess}
                disabled={disableFormChanges}
              />
              {cartLine.errors != null && cartLine.errors.length > 0 && (
                <div className='mt-4'>
                  <CartItemAlert type='danger' errors={cartLine.errors} />
                </div>
              )}
              {cartLine.notes != null && cartLine.notes.length > 0 && (
                <div className='mt-4'>
                  <CartItemAlert type='warning' notes={cartLine.notes} />
                </div>
              )}
            </div>
          </div>
          <CartItemDOTOptions
            cartLine={cartLine}
            onLoading={setLoading}
            onError={setActionError}
            onSuccess={setSuccess}
          />
          <div className='row mt-4 mt-xl-5 d-flex align-items-center d-print-none'>
            <div className='col-xl-auto text-end text-xl-start'>
              <p className='mb-2 mb-xl-0 fs-6'>{creationDateTitle}</p>
            </div>
            <div className='col justify-content-end'>
              <div className='d-flex justify-content-end'>
                <CartItemFavoriteButton
                  cartLine={cartLine}
                  onError={setActionError}
                  disabled={cartLine.errors.length > 0}
                />
                <CartItemMoveFromButton
                  cartLine={cartLine}
                  onLoading={setLoading}
                  onError={setActionError}
                  disabled={disableFormChanges || cartLine.errors.length > 0}
                  isSerializedProduct={isSerializedProduct}
                />
                <CartItemRemoveButton
                  cartLine={cartLine}
                  onLoading={setLoading}
                  onError={setActionError}
                  isSerializedProduct={isSerializedProduct}
                  isLimitedAvailability={cartLine.item.isLimitedAvailability}
                />
              </div>
            </div>
          </div>
        </Card>
      </Loading>
    </div>
  )
}

export {
  CartItem
}
