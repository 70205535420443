import { type ReactElement } from 'react'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Button } from '@stuller/stullercom/ui'
import { CartEmptyState } from '../shared/CartEmptyState'
import { useAuth } from '@stuller/stullercom/feat/auth'
import Link from 'next/link'

/*
* The empty state when the user is logged in
*/
function LoggedInEmptyState (): ReactElement {
  return (
    <CartEmptyState
      icon={fas.faCartShopping}
      title='Your cart is empty'
      description='Ready to restock? Browse new products or rediscover your favorites.'
    >
      <div className='d-flex flex-column flex-sm-row justify-content-center align-items-center'>
        <div className='mx-1 my-2 my-sm-0'>
          <Button tag='a' href='/browse/new' className='mx-2' data-test='browse-new-products-button'>
            New Products
          </Button>
        </div>
        <div className='mx-1 my-2 my-sm-0'>
          <Button tag='a' href='/favorite' className='mx-2' data-test='my-favorites-button'>
            <FontAwesomeIcon icon={fas.faHeart} className='me-3' />
            My Favorites
          </Button>
        </div>
      </div>
    </CartEmptyState>
  )
}

/*
* The empty state when the user is not logged in
*/
function LoggedOutEmptyState (): ReactElement {
  const { asPath } = useRouter()

  return (
    <CartEmptyState
      icon={fas.faCartShopping}
      title='Your cart is empty'
      description={
        <p>Ready to restock? Browse new products or <Link href={`/login?returnUrl=${encodeURIComponent(asPath)}`} passHref>log in</Link> to see your favorites.</p>
      }
    >
      <div className='d-flex flex-column flex-sm-row justify-content-center align-items-center'>
        <div className='mx-1 my-2 my-sm-0'>
          <Button tag='a' href='/browse/new' className='mx-2'>
            New Products
          </Button>
        </div>
      </div>
    </CartEmptyState>
  )
}

/*
* The empty state when the site is jeweler showcase
*/
function JewelerShowcaseEmptyState (): ReactElement {
  return (
    <CartEmptyState
      icon={fas.faCartShopping}
      title='Your cart is empty'
      description={
        <p>Return to the homepage to start shopping.</p>
      }
    >
      <div className='d-flex flex-column flex-sm-row justify-content-center align-items-center'>
        <div className='mx-1 my-2 my-sm-0'>
          <Button tag='a' href='/' className='mx-2'>
            Go to Homepage
          </Button>
        </div>
      </div>
    </CartEmptyState>
  )
}

/**
 * Shows the cart is empty state
 */
function CartEmpty (): ReactElement {
  const { authUser, isJewelerShowcase } = useAuth()
  const isLoggedIn = authUser != null

  if (isJewelerShowcase) {
    return <JewelerShowcaseEmptyState />
  }
  if (isLoggedIn) {
    return <LoggedInEmptyState />
  }

  return <LoggedOutEmptyState />
}

export {
  CartEmpty
}
