import { useState, useEffect, type ReactElement } from 'react'
import type { Shipment, Shipments, Estimate } from '../../shared/types'
import { CartShipmentShipStatus } from './CartShipmentShipStatus'
import { CartShipmentMethodSelect } from './CartShipmentMethodSelect'
import { CartShipmentItemListCollapse } from './CartShipmentItemListCollapse'
import { Alert, Loading } from '@stuller/stullercom/ui'
import {
  type CartPageCartListUpdateScheduledCartShippingMethodMutationVariables,
  useCartPageCartListUpdateScheduledCartShippingMethodMutation,
  useGetCartPageCartListShippingEstimatesLazyQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { getClientValidationErrors } from '@stuller/stullercom/data-access/apollo-client'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { CartShipmentContext } from '../../shared/CartShipmentContext'

interface CartShipmentItemListProps {
  /**
   * The shipment index
   */
  index: number
  /**
   * The shipment item list
   */
  shipment: Shipment
  /**
   * All shipments
   */
  shipments: Shipments
}

/**
 * The shipment item list
 */
function CartShipment ({ index, shipment, shipments }: CartShipmentItemListProps): ReactElement {
  const [shippingMethodError, setShippingMethodError] = useState<string[]>([])
  const [estimates, setEstimates] = useState<Estimate[] | null>(null)

  useEffect(() => {
    if (shipment.shippingMethodSelection != null) {
      setEstimates([shipment.shippingMethodSelection])
    }
  }, [shipment.shippingMethodSelection])

  const [updateScheduledCartShippingMethod, { loading: updateScheduledCartShippingMethodLoading }] = useCartPageCartListUpdateScheduledCartShippingMethodMutation({
    onError: (error) => {
      const validationErrors = getClientValidationErrors(error).map((e) => e.message)
      const errorMessage = validationErrors.length > 0 ? validationErrors : ['There was an issue updating the shipping method. Refresh the page and try again.']
      setShippingMethodError(errorMessage)
      logger.error('Could not update the shipment shipping method: ', { code: 'UPDATE_SHIPMENT_SHIPPING_METHOD_ERROR', message: error.message }, error)
    }
  })
  const shipmentValidationErrors = shipment.validationErrors ?? []

  const [fetchShippingEstimates, { loading: shippingEstimatesLoading }] = useGetCartPageCartListShippingEstimatesLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      const validationErrors = getClientValidationErrors(error).map((e) => e.message)
      const errorMessage = validationErrors.length > 0 ? validationErrors : ['There was an issue getting the shipping methods. Refresh the page and try again.']
      setShippingMethodError(errorMessage)
      logger.error('Could not get the shipment shipping methods: ', { code: 'GET_SHIPMENT_SHIPPING_METHODS_ERROR', message: error.message }, error)
    }
  })

  /**
   * Function to handle updating the shipment shipping method
   */
  async function handleUpdateShippingMethod (input: Omit<CartPageCartListUpdateScheduledCartShippingMethodMutationVariables['input'], 'shipSetCode'>): Promise<void> {
    setShippingMethodError([])
    await updateScheduledCartShippingMethod({
      variables: {
        input: {
          ...input,
          shipSetCode: shipment.shipSetCode
        }
      }
    })
  }

  /**
   * Function to handle when the menu is opened
   */
  async function handleMenuOpen (): Promise<void> {
    const { data } = await fetchShippingEstimates()
    // Match results based on ShipSetCode to this current shipment
    const thisShipment = data?.cart?.shipments.find((s) => s.shipSetCode === shipment.shipSetCode)
    if (thisShipment?.estimates != null) {
      setEstimates(thisShipment?.estimates)
    }
  }

  return (
    <CartShipmentContext.Provider
      value={{
        shipment,
        shipments
      }}
    >
      <Loading loading={updateScheduledCartShippingMethodLoading} position='top' spinnerClassName='mt-8 pt-8'>
        <div id={`cart-shipment-${index}`}>
          <div className='bg-gray-100 rounded-top-3 px-4 pt-3 d-inline-block h3 mb-0 position-relative'>
            Shipment #{index + 1}
            <span className='bg-white rounded-3 position-absolute bottom-0' />

            <style jsx>{`
              div:after {
                background: var(--bs-gray-100);
                bottom: 0;
                content: '';
                height: 5px;
                position: absolute;
                right: -5px;
                width: 5px;
                z-index: 1;
              }

              div > span {
                content: '';
                height: 10px;
                right: -10px;
                width: 10px;
                z-index: 2;
              }
            `}
            </style>
          </div>
          <div className='bg-gray-100 rounded-bottom-3 rounded-end-3 px-4 pb-4 pt-3 mb-5'>
            <CartShipmentShipStatus shipDate={shipment.shipDate} />
            {shipmentValidationErrors.length > 0 && (
              <Alert color='danger' size='sm' icon className='mt-3'>
                {shipmentValidationErrors.map((e, index) => (
                  <div key={index}>{e}</div>
                ))}
              </Alert>
            )}
            {shipmentValidationErrors.length === 0 && (
              <CartShipmentMethodSelect
                shippingMethodSelectionId={`${shipment.shippingMethodSelection?.method.id}:${shipment.shippingMethodSelection?.isSaturdayDelivery}`}
                estimates={estimates}
                onUpdateShippingMethod={handleUpdateShippingMethod}
                onMenuOpen={handleMenuOpen}
                isLoading={shippingEstimatesLoading}
                error={shippingMethodError}
              />
            )}
            <CartShipmentItemListCollapse index={index} cartLines={shipment.lines} />
          </div>
        </div>
      </Loading>
    </CartShipmentContext.Provider>
  )
}

export {
  CartShipment
}
