import { useId, useState, forwardRef } from 'react'
import { useInterval } from '@stuller/shared/util/react-hooks'
import clsx from 'clsx'

declare global {
  interface Window {
    /**
     * Google Tag Manager data layer array
     */
    grecaptcha: {
      render: (element: string, options: object) => string
    }
  }
}

interface RecaptchaProps {
  /**
   * The site key for the recaptcha
   */
  siteKey: string
  /**
   * The callback for when the recaptcha value changes
   */
  onChange: (value: string | null) => void
  /**
   * If the recaptcha is invalid
   */
  invalid?: boolean | string
  /**
   * If the recaptcha is valid
   */
  valid?: boolean
  /**
   * Additional class names for the recaptcha
   */
  className?: string
}

/**
 * A recaptcha component to verify that the user is not a robot
 */
const Recaptcha = forwardRef<HTMLDivElement, RecaptchaProps>(({
  siteKey,
  onChange,
  valid = false,
  invalid = false,
  className,
  ...otherAttributes
}, ref) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const randomId = useId()
  const classNames = clsx(
    'form-recaptcha',
    invalid !== false && invalid !== '' && 'is-invalid',
    valid && 'is-valid',
    className
  )
  const id = `form-recaptcha-${randomId}`

  function loadRecaptcha (): void {
    if (!loaded && window.grecaptcha != null) {
      window.grecaptcha.render(id, {
        sitekey: siteKey,
        callback: (token: string) => {
          onChange(token)
        },
        'expired-callback': () => {
          onChange(null)
        },
        'error-callback': () => {
          onChange(null)
        }
      })
      setLoaded(true)
    }
  }

  useInterval(loadRecaptcha, !loaded ? 1000 : null)

  return (
    <div
      id={id}
      className={classNames}
      ref={ref}
      {...otherAttributes}
    />
  )
})

Recaptcha.displayName = 'Recaptcha'

export {
  Recaptcha
}
