import { type ReactElement, type ReactNode } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import clsx from 'clsx'
import { useAddToCartSectionContext } from '../useAddToCartSectionContext'
import { CustomShowcaseSettingDetailType, type ProductInStockStatusCode, useGetCanViewQuantityOnHandQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { isStringEmpty } from '@stuller/shared/util/core'

type StatusTopSectionColor = 'blue' | 'black'

const statusTopSectionColorClasses = {
  blue: 'bg-blue-400 border-blue-400',
  black: 'bg-gray-800 border-gray-800'
}

interface GenericTopSectionProps {
  /**
   * children to render inside the top section
   */
  children?: ReactNode
  /**
   * additional classes to apply to the top section
    */
  className?: string
  /**
   * whether or not the product is returnable
   */
  isReturnable?: boolean
  /**
   * color of the top section
   */
  color: StatusTopSectionColor
  /**
   * override bool to be able to hide quantity on hand even if onHandViewer permission exists
   */
  overrideShowQuantityOnHand?: boolean
  /**
   * Product in stock status code
   */
  inStockStatusCode?: ProductInStockStatusCode
}

function StatusTopSection ({
  children,
  className,
  inStockStatusCode,
  isReturnable = true,
  color,
  overrideShowQuantityOnHand = true
}: GenericTopSectionProps): ReactElement {
  const { authUser, getCustomShowcaseSetting } = useAuth()
  const { ReplaceStatusDisplaimerOfNonReturnable, ChangeColorOfStatusDisplayCustomOrder } = CustomShowcaseSettingDetailType
  const replaceStatusDisplaimerOfNonReturnable = getCustomShowcaseSetting(ReplaceStatusDisplaimerOfNonReturnable)?.[0]
  const changeColorOfStatusDisplayCustomOrder = getCustomShowcaseSetting(ChangeColorOfStatusDisplayCustomOrder)?.[0]

  const { quantityOnHand } = useAddToCartSectionContext()
  const { data } = useGetCanViewQuantityOnHandQuery()

  const hasCustomShowcaseBackground = !isStringEmpty(changeColorOfStatusDisplayCustomOrder) && inStockStatusCode?.toLowerCase() === 'custom_order'
  const classes = clsx(
    'px-3 py-2 rounded-top-3 text-white fw-bold fs-5 border-start border-end border-top',
    className,
    !hasCustomShowcaseBackground && statusTopSectionColorClasses[color],
    authUser == null && 'rounded-bottom-3'
  )
  const customShowcaseSettingsColor = hasCustomShowcaseBackground ? { background: changeColorOfStatusDisplayCustomOrder ?? '' } : {}

  return (
    <div className={classes} style={customShowcaseSettingsColor}>
      <div className='d-inline-block lh-sm'>
        {children}
        {!isReturnable && (
          <span className='ms-2'>
            {isStringEmpty(replaceStatusDisplaimerOfNonReturnable) ? '(non-returnable)' : `(${replaceStatusDisplaimerOfNonReturnable})`}
          </span>
        )}
        {data?.viewerPermissions.canViewQuantityOnHand === true && overrideShowQuantityOnHand && (
          <span className='d-block fs-6'>({quantityOnHand} in stock)</span>
        )}
      </div>
    </div>
  )
}

export {
  StatusTopSection
}
