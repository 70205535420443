import { type ReactElement } from 'react'
import Script from 'next/script'

/**
 * Component to include the Recaptcha script from google
 */
function RecaptchaInclude (): ReactElement {
  return (
    <Script src='https://www.google.com/recaptcha/api.js?render=explicit' async defer />
  )
}

export {
  RecaptchaInclude
}
