import { Alert } from '@stuller/stullercom/ui'
import type { ReactElement } from 'react'

export interface CartPageLoadErrorProps {
  /**
   * Name of the cart where Error occurred
  */
  cartName: string
}

function CartPageLoadError ({ cartName }: CartPageLoadErrorProps): ReactElement {
  return (
    <Alert color='danger' icon className='fw-bold'>
      Something went wrong when loading the {cartName}. Try refreshing the page.
    </Alert>
  )
}

export {
  CartPageLoadError
}
