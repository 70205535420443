import { type ReactElement } from 'react'
import { type InStockStatusInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { StatusTopSection } from './StatusTopSection'
import { EstimatedShipDateSection } from './EstimatedShipDateSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

interface InStockStatusProps {
  /**
   * in-stock status info, including backorder and onhand quantity
   */
  inStockStatus: InStockStatusInfoFragment['inStockStatus']
  /**
   * context-based human readable ship date
   */
  readableShipDate?: string
  /**
   * whether or not the product is returnable
   */
  isReturnable: boolean
  /**
   * whether or not the ship date is today
   */
  isShipDateToday: boolean
}

function InStockStatus ({
  inStockStatus,
  isReturnable,
  isShipDateToday,
  readableShipDate
}: InStockStatusProps): ReactElement {
  return (
    <>
      <StatusTopSection inStockStatusCode={inStockStatus.statusCode} isReturnable={isReturnable} color='blue'>
        <FontAwesomeIcon icon={fas.faCheck} className='me-2' size='xl' />
        {inStockStatus.status}
      </StatusTopSection>
      <EstimatedShipDateSection
        isShipDateToday={isShipDateToday}
        readableShipDate={readableShipDate}
      />
    </>
  )
}

export {
  InStockStatus
}
