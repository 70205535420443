import { type ReactElement } from 'react'
import {
  ProductRecommendationCarousel,
  type RecommendationPlacementProps
} from '@stuller/stullercom/feat/recommendations'

const PLACEMENTS: RecommendationPlacementProps[] = [
  {
    recommendationSource: 'cart-recommendation',
    placementId: 'cart_page.rr1'
  }
]

/**
 * Shows the cart product recommendation carousel
 */
function CartProductRecommendationCarousel (): ReactElement {
  return (
    <div className='bg-white mb-n5' data-test='product-recommendation-carousel'>
      <div className='container-xxl mw-xxl px-4'>
        <div className='row'>
          <div className='col'>
            <ProductRecommendationCarousel placements={PLACEMENTS} />
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  CartProductRecommendationCarousel
}
