import { type ReactElement } from 'react'
import { StatusTopSection } from './StatusTopSection'
import { EstimatedShipDateSection } from './EstimatedShipDateSection'
import type { ProductInStockStatusCode } from '@stuller/stullercom/data-access/apollo-queries'

interface BackorderedStatusProps {
  /**
   * context-based human readable ship date
   */
  readableShipDate?: string
  /**
   * whether or not the product is returnable
   */
  isReturnable: boolean
  /**
   * whether or not the ship date is today
   */
  isShipDateToday: boolean
  /**
   * Product in stock status code
   */
  inStockStatusCode: ProductInStockStatusCode
}

function BackorderedStatus ({ readableShipDate, isReturnable, isShipDateToday, inStockStatusCode }: BackorderedStatusProps): ReactElement {
  return (
    <>
      <StatusTopSection isReturnable={isReturnable} inStockStatusCode={inStockStatusCode} color='black'>Shipping Information</StatusTopSection>
      <EstimatedShipDateSection isShipDateToday={isShipDateToday} readableShipDate={readableShipDate} />
    </>
  )
}

export {
  BackorderedStatus
}
