import { useCallback, useState } from 'react'

type AnyFunction = (...args: any[]) => any

/**
 * Callback lock to prevent multiple simultaneous function calls
 * Note that this function does not handle thrown errors so make sure to use try/catch in the handler
 */
function useLock<T extends AnyFunction> (handler: T): [T, boolean] {
  const [locked, setLocked] = useState(false)
  const handleLock = useCallback(async (...args: any) => {
    if (locked) {
      return
    }

    setLocked(true)

    const result = await handler(...args)
    setLocked(false)

    return result
  }, [handler, locked])

  return [handleLock as any, locked]
}

export {
  useLock
}
