import React, { type ReactElement, Fragment, useMemo } from 'react'
import { CartItemConfigurationCard } from './CartItemConfigurationCard'
import { CartItemConfigurationProduct } from './CartItemConfigurationProduct'
import { type StoneSettingConfigurationDetail } from '../../shared/types'
import { consolidateStoneSettings, returnGemstoneSetString } from './CartItemConfigurationSummary'
import { useCurrency } from '@stuller/shared/util/react-hooks'

interface CartItemConfigurationStoneSettingProps {
  /**
   * The stone setting data
   */
  stoneSettingData: StoneSettingConfigurationDetail
}

/**
 * Component to display the stone settings
 */
function CartItemConfigurationStoneSetting ({
  stoneSettingData
}: CartItemConfigurationStoneSettingProps): ReactElement {
  /**
   * Return the "# of # set" string for the gemstone set
   */
  const gemstoneString = useMemo(() => {
    return returnGemstoneSetString(stoneSettingData)
  }, [stoneSettingData])

  return (
    <>
      {stoneSettingData.stoneSettings != null && stoneSettingData.stoneSettings.length > 0 && (
        <CartItemConfigurationCard
          title='Diamonds/Gemstones'
          description={gemstoneString}
        >
          {consolidateStoneSettings(stoneSettingData).map((stoneSetting, index) => (
            <Fragment key={index}>
              {stoneSetting.stone != null && stoneSetting.stone.__typename === 'Product' && (
                <CartItemConfigurationProduct
                  key={index}
                  image={stoneSetting.stone.image}
                  title={stoneSetting.stone.title}
                  itemNumber={stoneSetting.stone.itemNumber}
                  id={stoneSetting.stone.id}
                  isActive={stoneSetting.stone.isWebActive}
                  quantity={stoneSetting.quantity}
                  paddingBottom={index !== stoneSettingData.stoneSettings.length - 1}
                />
              )}
              {stoneSetting.stone != null && stoneSetting.stone.__typename === 'SerializedProduct' && (
                <CartItemConfigurationProduct
                  key={index}
                  image={stoneSetting.stone.image}
                  title={stoneSetting.stone.title}
                  id={stoneSetting.stone.id}
                  serialNumber={stoneSetting.stone.serialNumber}
                  quantity={stoneSetting.quantity}
                  paddingBottom={index !== stoneSettingData.stoneSettings.length - 1}
                />
              )}
              {stoneSetting.stone != null && stoneSetting.stone.__typename === 'CustomerStone' && (
                <CustomerStoneDetail value={stoneSetting.stone.stoneValue.value} />
              )}
            </Fragment>
          ))}
        </CartItemConfigurationCard>
      )}
    </>
  )
}

/**
 * Component to display the customer stone details
 */
function CustomerStoneDetail ({
  value
}: { value: number }): ReactElement {
  const [price] = useCurrency(value)

  return (
    <div>
      <p className='fs-4 fw-bold mb-0'>Customer Stone</p>
      <p className='mb-0'>Valued at {price}</p>
    </div>
  )
}

export {
  CartItemConfigurationStoneSetting
}
