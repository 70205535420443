import clsx from 'clsx'
import { type ReactElement } from 'react'

interface InStockStatusCountdownProps {
  /**
   * future date duration to coundown towards
   */
  duration: plugin.Duration
}

function InStockStatusCountdown ({ duration }: InStockStatusCountdownProps): ReactElement {
  const countdownClassNames = clsx(
    'fw-bold  d-inline-block me-2',
    duration.hours() > 1 ? 'text-blue-400' : 'text-red-400'
  )

  return (
    <span className={countdownClassNames}>{duration.format(' H[h] m[m] s[s]')}</span>
  )
}

export {
  InStockStatusCountdown
}
