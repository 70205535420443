import { type ReactElement, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useTransition } from 'transition-hooks'

/**
 * The props for the CartItemSuccess component
 */
interface CartItemSuccesProps {
  /**
   * The success state
   */
  success: boolean
  /**
   * The callback to call when the success animation is done
   */
  onSuccess: (success: boolean) => void
}

function CartItemSuccess ({
  success,
  onSuccess
}: CartItemSuccesProps): ReactElement | null {
  const animationTimeout = 500
  const { status, shouldMount } = useTransition(success, { timeout: animationTimeout })

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        onSuccess(false)
      }, animationTimeout)
    }
  }, [success]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {shouldMount && (
        <div
          className='position-absolute bg-gray-100 h-100 w-100 z-1'
          style={{
            '--bs-bg-opacity': '.7',
            transition: 'all .5s',
            opacity: status === 'entering' || status === 'entered' || status === 'from' ? 1 : 0
          }}
        >
          <div
            className='success position-absolute z-2 translate-middle top-50 start-50'
          >
            <FontAwesomeIcon
              icon={fas.faCheck}
              size='4x'
              className='text-green-300'
              style={{
                transform: `translateY(${status === 'entering' || status === 'entered' || status === 'from' ? '0' : '-40px'})`,
                transition: 'all .5s'
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export {
  CartItemSuccess
}
