import { type ReactElement } from 'react'

interface CartItemDimensionsProp {
  /**
   * The dimensions of the cart item
   */
  dimensionsString: string | null
}

/**
 * Component that will show the dimensions of a cart item
 */
function CartItemDimensions ({ dimensionsString }: CartItemDimensionsProp): ReactElement {
  return (
    <>
      {dimensionsString != null && (<p className='mt-3 mb-0 d-print-none'><b>Dimensions:</b> {dimensionsString}</p>)}
    </>
  )
}

export {
  CartItemDimensions
}
