import { throwIfNull } from '@stuller/shared/util/core'
import { type Auth, type GetAuth } from './types'
import { siteConfig } from '@stuller/stullercom/util/site-config'

/*
 * Uses the `NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_HOST_MASK` environment variable to parse information from the host specific to Jeweler Showcase Punchout settings
 * The `NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_HOST_MASK` value should contain captures for `<?sessionId>, <?clientId>, and <?userMemberId>`
 * Ex. host name from prod: punchout-css-d229eebe-613c-4afd-aeee-c1a1d5198b05-180802.jewelershowcase.com
 */
function getJewelerShowcasePunchoutSettings (host: string): Auth['jewelerShowcasePunchoutInfo'] | undefined {
  const punchoutMatch = host.match(throwIfNull(siteConfig.NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_HOST_MASK, 'NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_HOST_MASK is a required environment variable.'))?.groups
  if (punchoutMatch == null) {
    return
  }

  const sessionId = punchoutMatch.sessionId
  const userMemberId = Number(punchoutMatch.userMemberId)
  const clientId = punchoutMatch.clientId

  return {
    clientId,
    sessionId,
    userMemberId
  }
}

/*
 * Uses the `NEXT_PUBLIC_JEWELER_SHOWCASE_HOST_MASK` environment variable to parse information from the URL specific to Jeweler Showcase
 * The `NEXT_PUBLIC_JEWELER_SHOWCASE_HOST_MASK` value should contain captures for `<?showcaseSubdomain>, <?frame>, <?categoryembed>, and <?catid>`
 */
function getJewelershowcaseSettings (host: string): Auth['jewelerShowcaseDomainInfo'] | undefined {
  const { showcaseSubdomain, frame, categoryembed, catid } = host.match(throwIfNull(siteConfig.NEXT_PUBLIC_JEWELER_SHOWCASE_HOST_MASK, 'NEXT_PUBLIC_JEWELER_SHOWCASE_HOST_MASK is a required environment variable.'))?.groups ?? {}

  if (showcaseSubdomain != null) {
    return {
      subdomain: showcaseSubdomain,
      isIFrame: frame != null,
      isEmbeddedCategoryIFrame: categoryembed != null,
      embeddedCategoryId: catid == null ? undefined : Number(catid)
    }
  }
}

/**
 * Does the host match the `NEXT_PUBLIC_STERLING_HOST_MASK` environment variable?
 */
function getIsSterling (host: string): boolean {
  return host.match(throwIfNull(siteConfig.NEXT_PUBLIC_STERLING_HOST_MASK, 'NEXT_PUBLIC_STERLING_HOST_MASK is a required environment variable.')) != null
}

/**
 * Get Domain Info based on host
 */
function getDomainInfo (host: string): {
  host: Auth['host']
  jewelerShowcaseDomainInfo: Auth['jewelerShowcaseDomainInfo'] | undefined
  jewelerShowcasePunchoutInfo: Auth['jewelerShowcasePunchoutInfo'] | undefined
  isJewelerShowcase: Auth['isJewelerShowcase']
  isEmbeddedJewelerShowcase: Auth['isEmbeddedJewelerShowcase']
  isJewelerShowcasePunchout: Auth['isJewelerShowcasePunchout']
  isSterling: Auth['isSterling']
} {
  const hostToUse = siteConfig.NEXT_PUBLIC_HOST_OVERRIDE ?? host
  const jewelerShowcasePunchoutInfo = getJewelerShowcasePunchoutSettings(hostToUse)
  const isJewelerShowcasePunchout = jewelerShowcasePunchoutInfo != null
  const jewelerShowcaseDomainInfo = isJewelerShowcasePunchout ? undefined : getJewelershowcaseSettings(hostToUse)
  const isJewelerShowcase = jewelerShowcaseDomainInfo != null || isJewelerShowcasePunchout
  const isEmbeddedJewelerShowcase = jewelerShowcaseDomainInfo?.isIFrame === true ||
    jewelerShowcaseDomainInfo?.isEmbeddedCategoryIFrame === true ||
    isJewelerShowcasePunchout
  const isSterling = getIsSterling(hostToUse)

  return {
    host: hostToUse,
    jewelerShowcaseDomainInfo,
    jewelerShowcasePunchoutInfo,
    isJewelerShowcase,
    isEmbeddedJewelerShowcase,
    isJewelerShowcasePunchout,
    isSterling
  }
}

/**
 * Get auth user
 */
function getAuth (
  authUser: Auth['authUser'],
  inputHost: Auth['host'],
  sessionId: string
): GetAuth {
  const {
    host,
    jewelerShowcaseDomainInfo,
    jewelerShowcasePunchoutInfo,
    isJewelerShowcase,
    isEmbeddedJewelerShowcase,
    isJewelerShowcasePunchout,
    isSterling
  } = getDomainInfo(inputHost)
  const isStullerComShowcaseMode = (authUser?.roles.includes('RetailMode') ?? false) && !isJewelerShowcase
  const isShowcase = isJewelerShowcase || isStullerComShowcaseMode

  return {
    authUser,
    hasRole: (role) => authUser?.roles.includes(role) ?? false,
    host,
    jewelerShowcaseDomainInfo,
    jewelerShowcasePunchoutInfo,
    isShowcase,
    isJewelerShowcase,
    isEmbeddedJewelerShowcase,
    isJewelerShowcasePunchout,
    isStullerComShowcaseMode,
    isSterling,
    sessionId
  }
}

export {
  getJewelerShowcasePunchoutSettings,
  getJewelershowcaseSettings,
  getIsSterling,
  getDomainInfo,
  getAuth
}
