import { type ReactNode, type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface CartEmptyStateProps {
  /**
   * Children of the empty state
  */
  children?: ReactNode
  /**
   * Icon to display
  */
  icon: any
  /**
   * Title to display
  */
  title: string
  /**
   * Description to display
  */
  description: string | ReactNode
}

/**
 * The empty cart component
 */
function CartEmptyState ({ children, icon, title, description }: CartEmptyStateProps): ReactElement {
  return (
    <div className='py-3 py-sm-8 row'>
      <div className='col-12 text-center pt-6'>
        <FontAwesomeIcon
          icon={icon}
          size='3x'
          className='text-gray-400 text-center'
        />
      </div>
      <div className='col-12 text-center fw-bold mt-3 fs-3' data-test='empty-cart-message'>{title}</div>
      <p className='col-12 text-center mb-0'>{description}</p>
      <div className='col-12 text-center mt-5 pb-6'>
        {children}
      </div>
    </div>
  )
}

export {
  CartEmptyState
}
