import { type ReactElement } from 'react'
import { CartListCollapse } from './CartListCollapse'
import { useListTransition } from 'transition-hooks'
import {
  type JewelerShowcaseCallbackRequestInfoFragment,
  type CartListHeaderInfoFragment
} from '@stuller/stullercom/data-access/apollo-queries'
import type { CartListCollapseCartListHeader } from '../shared/types'

interface CartListCollapseListProps {
  /**
   * Header information to show for the cart
   */
  cartHeaders: CartListCollapseCartListHeader[] | JewelerShowcaseCallbackRequestInfoFragment[] | null
}

/**
 * This component is a list of carts that can be collapsed
 */
function CartListCollapseList ({ cartHeaders }: CartListCollapseListProps): ReactElement {
  /**
   * Transition the cart in and out
   */
  const { transitionList } = useListTransition<CartListHeaderInfoFragment | JewelerShowcaseCallbackRequestInfoFragment>(cartHeaders ?? [], {
    timeout: 500,
    keyExtractor: (item) => {
      if (item.__typename === 'JewelerShowcaseCallbackRequest') {
        return item.cartHeader?.cartId ?? item.id
      }

      return (item as CartListHeaderInfoFragment).cartId
    }
  })

  return (
    <>
      {transitionList((cartHeader, { key, simpleStatus }) => {
        return (
          <CartListCollapse
            cartHeader={cartHeader}
            style={{
              opacity: simpleStatus === 'exit' ? 0 : 1,
              transition: 'all .5s'
            }}
            key={key}
          />
        )
      })}
    </>
  )
}

export {
  CartListCollapseList
}
