import { useEffect, useMemo, useState } from 'react'
import { CartPageLayout } from '../shared/CartPageLayout'
import { Button, Loading, UncontrolledPagination, type UncontrolledPaginationProps } from '@stuller/stullercom/ui'
import { CartListCollapseList } from '../cart-list/CartListCollapseList'
import {
  useGetPendingJewelerShowcaseRequestsQuery,
  GetCartPageJewelerShowcaseCartsPagePermissionsDocument,
  type GetCartPageJewelerShowcaseCartsPagePermissionsQuery,
  useCartPageCartItemUpdateCartLineWithCartListItemMutation,
  type JewelerShowcaseCallbackRequestInfoFragment,
  useCartPageCartItemDeleteCartLineWithCartListItemMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { CartEmptyState } from '../shared/CartEmptyState'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { CartContext } from '../shared/CartContext'
import { CartPageLoadError } from '../shared/CartPageLoadError'
import { type CustomNextPage } from '@stuller/stullercom/feat/layout-context'

/**
 * The page that will show all the jeweler showcase cart items
 */
const JewelerShowcaseCartsPage: CustomNextPage = () => {
  const pageLimit = 20
  const [pageNumber, setPageNumber] = useState(1)
  const { data, loading } = useGetPendingJewelerShowcaseRequestsQuery({
    variables: {
      paging: {
        limit: pageLimit,
        page: pageNumber
      },
      onlyRecent: false
    },
    fetchPolicy: 'network-only'
  })
  const itemCount = data?.pendingJewelerShowcaseCallbackRequests?.count ?? 0

  /**
   * The carts to display
   */
  const jewelerShowcaseCallbackRequests: JewelerShowcaseCallbackRequestInfoFragment[] | null = useMemo(() => {
    if (data?.pendingJewelerShowcaseCallbackRequests != null && Array.isArray(data.pendingJewelerShowcaseCallbackRequests?.results)) {
      return data.pendingJewelerShowcaseCallbackRequests.results.filter((c) => c.cartHeader?.itemCount != null && c.cartHeader.itemCount > 0)
    }

    return null
  }, [data]) ?? null

  /**
   * Handles the pagination change and scrolls to the top of the page
   */
  function handleBottomPaginationChange (...args: Parameters<NonNullable<UncontrolledPaginationProps['onChange']>>): void {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
    setPageNumber(...args)
  }

  /**
   * If there are no items on the current page, go back a page
   */
  useEffect(() => {
    if (jewelerShowcaseCallbackRequests?.length === 0 && pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }, [jewelerShowcaseCallbackRequests, pageNumber])

  return (
    <CartPageLayout title='Jeweler Showcase Requests' activeNavigationLink='jewelershowcasecarts'>
      {itemCount != null && itemCount > pageLimit && (
        <div className='row d-print-none'>
          <UncontrolledPagination
            className='w-auto ms-auto mb-5'
            page={pageNumber}
            perPage={pageLimit}
            total={itemCount}
            onChange={setPageNumber}
          />
        </div>
      )}
      <Loading loading={loading}>
        <CartContext.Provider
          value={{
            cartArea: 'jeweler-showcase-carts-page',
            useCartContextUpdateCartLineMutation: useCartPageCartItemUpdateCartLineWithCartListItemMutation,
            useCartContextDeleteCartLineMutation: useCartPageCartItemDeleteCartLineWithCartListItemMutation
          }}
        >
          <CartListCollapseList cartHeaders={jewelerShowcaseCallbackRequests} />
        </CartContext.Provider>
        {jewelerShowcaseCallbackRequests === null && !loading && <CartPageLoadError cartName='Jeweler Showcase Requests' />}
        {jewelerShowcaseCallbackRequests != null && jewelerShowcaseCallbackRequests?.length === 0 && !loading && (
          <CartEmptyState
            icon={fas.faTag}
            title='You have no Jeweler Showcase requests'
            description='When a customer requests a quote for an item through your Jeweler Showcase website, the request will appear here.'
          >
            <div className='d-flex flex-column flex-sm-row justify-content-center align-items-center'>
              <div className='mx-1 my-2 my-sm-0'>
                <Button tag='a' href='/myaccount/showcasesettings/pastcallbackrequests' className='mx-2'>
                  Request History
                </Button>
              </div>
              <div className='mx-1 my-2 my-sm-0'>
                <Button tag='a' href='/myaccount/showcasesettings' className='mx-2'>
                  Showcase Settings
                </Button>
              </div>
            </div>
          </CartEmptyState>
        )}
      </Loading>
      {itemCount != null && itemCount > pageLimit && !loading && (
        <div className='row d-print-none'>
          <UncontrolledPagination
            className='w-auto ms-auto mt-3'
            page={pageNumber}
            perPage={pageLimit}
            total={itemCount}
            onChange={handleBottomPaginationChange}
          />
        </div>
      )}
    </CartPageLayout>
  )
}

JewelerShowcaseCartsPage.getInitialProps = async (context) => {
  const { apolloClient } = context

  const { data } = await apolloClient.query<GetCartPageJewelerShowcaseCartsPagePermissionsQuery>({
    query: GetCartPageJewelerShowcaseCartsPagePermissionsDocument
  })

  if (!data.viewerPermissions.canViewJewelerShowcaseRequests) {
    return { statusCode: 403 }
  }

  return {}
}

JewelerShowcaseCartsPage.layout = {
  title: {
    children: 'Jeweler Showcase Requests'
  }
}

export {
  JewelerShowcaseCartsPage
}
