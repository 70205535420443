import { useMemo, useState, useCallback, type DependencyList } from 'react'
import {
  useHotkeys as useHotkeysIn,
  type Options,
  type Keys,
  type HotkeyCallback
} from 'react-hotkeys-hook'
import { type AsyncFunction } from '@stuller/shared/util/type-helpers'

type UseHotkeysParams = Parameters<typeof useHotkeysIn>
type OptionsOrDependencyArray = UseHotkeysParams[2]
type HotkeyCallbackAsync = AsyncFunction<HotkeyCallback>
type HotkeyCallbackParams = Parameters<HotkeyCallbackAsync>
type Hotkey = HotkeyCallbackParams[1]

/**
 * Wraps [react-hotkeys-hook](https://react-hotkeys-hook.vercel.app) `useHotkeys` to allow async callbacks
 */
function useHotkeys (
  keys: Keys,
  callback: HotkeyCallbackAsync,
  options?: OptionsOrDependencyArray,
  dependencies?: OptionsOrDependencyArray
): void {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useHotkeysIn(keys, callback, options, dependencies)
}

/**
 * Wraps [react-hotkeys-hook](https://react-hotkeys-hook.vercel.app) `useHotkeys` to allow sequence of keys like 'ab'
 *
 * Note: This **ONLY** works with single characters like letters/numbers/special (no modifier or special keys like `ctrl`, `tab`, `up`, `enter`, etc.)
 * Also this will default to `keyup: true` since it's required for sequences to work
 */
function useHotkeysSequence (
  keys: Keys,
  callback: HotkeyCallbackAsync,
  options?: OptionsOrDependencyArray,
  dependencies?: OptionsOrDependencyArray
): void {
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const keysArray: Keys = useMemo(() => typeof keys === 'string' ? keys.split(',').map((k) => k.trim()) : keys, [])
  const [, setKeyTracking] = useState<Keys>(new Array(keysArray.length).fill(''))

  // Get the options and dependencies from params
  let _options: Options | undefined = !(options instanceof Array)
    ? options
    : !(dependencies instanceof Array)
      ? dependencies
      : undefined
  _options = { ..._options, keyup: true, keydown: false }
  const _deps: DependencyList | undefined =
    options instanceof Array ? options : dependencies instanceof Array ? dependencies : undefined

  /**
   * Wraps `callback` to keep track of sequence keys
   */
  const handler = useCallback((keyboardEvent: KeyboardEvent, hotkeysEvent: Hotkey) => {
    setKeyTracking((keyTracking) => {
      const newKeyTracking = [...keyTracking]

      for (const [index, key] of keysArray.entries()) {
        const newKey = keyboardEvent.key
        newKeyTracking[index] += newKey

        if (key === newKeyTracking[index]) {
          newKeyTracking[index] = ''
          void callback(keyboardEvent, hotkeysEvent)
        } else if (!key.startsWith(newKeyTracking[index])) {
          if (key.startsWith(newKey)) {
            newKeyTracking[index] = newKey
          } else {
            newKeyTracking[index] = ''
          }
        }
      }

      return newKeyTracking
    })
  }, [keysArray, callback])

  useHotkeysIn('*', handler, _options, _deps)
}

export {
  useHotkeys,
  useHotkeysSequence
}
