import { useState, type ReactElement } from 'react'
import { Button, Popover, PopoverBody } from '@stuller/stullercom/ui'
import { type CartLineMessageInfoFragment, useCreateQuoteMutation } from '@stuller/stullercom/data-access/apollo-queries'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { isStringEmpty } from '@stuller/shared/util/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

interface AddToQuoteIdButtonProps {
  /**
   * Function to set the error state
   */
  onError: (errors: CartLineMessageInfoFragment[]) => void
}

/**
 * Component to generate Quote Id
 */
function AddToCartQuoteIdButton ({ onError }: AddToQuoteIdButtonProps): ReactElement {
  const {
    options,
    itemId,
    itemType,
    trackingParams,
    customerNotes,
    salesType
  } = useAddToCartSectionContext()
  const {
    recommendationIndex,
    recommendationSource,
    recommendationId,
    recommendationType
  } = trackingParams ?? {}
  const numericRecommendationIndex = recommendationIndex != null ? parseInt(recommendationIndex) : undefined
  const numericRecommendationId = recommendationId != null ? parseInt(recommendationId) : undefined
  const [fullQuoteId, setFullQuoteId] = useState<string | null>(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [reference, setReference] = useState<HTMLElement | null>(null)
  const [createQuote, { loading }] = useCreateQuoteMutation({
    variables: {
      addToCarts: {
        itemId: {
          id: itemId,
          type: itemType
        },
        options: {
          isAnnealed: options?.isAnnealed ?? false,
          isForConsignment: options?.isForConsignment ?? false,
          isMatched: options?.isMatched ?? false,
          bagAndTagOptions: options?.bagAndTagOptions,
          customerNotes,
          specialInstructions: options?.specialInstructions,
          quantity: options?.quantity ?? 1,
          length: options?.length,
          width: options?.width,
          matchingCode: undefined, // TODO: add options?.matchingCode,
          consultantDiscount: undefined, // TODO: add options?.consultantDiscount,
          salesType
        },
        tracking: {
          index: numericRecommendationIndex,
          source: recommendationSource ?? '',
          sourceId: numericRecommendationId,
          type: recommendationType
        }
      }
    },
    onError: (error) => {
      logger.error('Could not get quote ID: ', { code: 'CART_ERROR', message: error.message }, error)
      onError([{ code: 'CART_ERROR', message: 'There was an issue getting this quote ID' }])
    }
  })

  async function handleGetQuoteId (): Promise<void> {
    const quoteResult = await createQuote()
    const quoteId = quoteResult.data?.createQuote?.publicQuoteLines?.[0].id
    setFullQuoteId(!isStringEmpty(quoteId) ? `quote.${quoteId}` : null)
  }

  async function handleCopyQuoteId (): Promise<void> {
    try {
      await navigator.clipboard.writeText(fullQuoteId ?? '')
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 3000)
    } catch (e) {
      logger.error('Error copying quote ID number to clipboard', {}, e)
    }
  }

  return (
    <div ref={setReference} className='row g-3'>
      <Popover placement='top' open={showSuccess} reference={reference}>
        <PopoverBody>
          <p className='mb-0'>Copied to clipboard</p>
        </PopoverBody>
      </Popover>
      {!isStringEmpty(fullQuoteId)
        ? (
          <div className='col-12 text-center'>
            Click to Copy ID:
            <FontAwesomeIcon icon={fas.faFiles} className='ms-3 me-2' />
            <Button color='anchor-dark' onClick={handleCopyQuoteId}>
              {fullQuoteId}
            </Button>
          </div>
        )
        : (
          <Button
            color='primary'
            onClick={handleGetQuoteId}
            loading={loading}
            className='w-100'
          >
            Generate Quote ID
          </Button>
        )}
    </div>
  )
}

export {
  AddToCartQuoteIdButton
}
