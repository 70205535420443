import { type ReactElement } from 'react'
import { AddToCartQuoteIdButton } from './AddToCartQuoteIdButton'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'
import { type AddToCartSectionInfoFragment, AddToCartType, type CartLineMessageInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { AddToStandardCartButton } from './AddToStandardCartButton'
import { OneClickQuoteButton } from './one-click/OneClickQuoteButton'
import { useAddToExternalCartPostMessageAPI } from './useAddToExternalCartPostMessageAPI'
import { AddToExternalCartButton } from './AddToExternalCartButton'

interface AddToCartButtonProps {
  /**
    * Function to set the error state of add to cart button
    */
  onAddToCartError: (errors: CartLineMessageInfoFragment[]) => void
  /**
  * Function to set the error state generate quote id button
  */
  onQuoteIdError: (errors: CartLineMessageInfoFragment[]) => void
  /**
   * Function to set the success state
   */
  onSuccess: () => void
  /**
   * Loading state of the AddToCartSection used to disable the button when refetching data
   */
  isLoading: boolean
  /**
   * Add to cart section data for one click quote button
   */
  addToCartSectionInfo: AddToCartSectionInfoFragment
}

/**
 * Wrapper for rendering add to cart buttons based on cart type
 */
function AddToCartButton ({
  addToCartSectionInfo,
  onAddToCartError,
  onQuoteIdError,
  onSuccess,
  isLoading
}: AddToCartButtonProps): ReactElement | null {
  const { addToCartType } = useAddToCartSectionContext()
  const { shouldPostAddToExternalCartMessage } = useAddToExternalCartPostMessageAPI()
  switch (addToCartType) {
    case AddToCartType.Standard:
      return (
        <AddToStandardCartButton
          onError={onAddToCartError}
          onSuccess={onSuccess}
          isLoading={isLoading}
        />
      )
    case AddToCartType.OneClick:
      return (
        <OneClickQuoteButton addToCartSectionInfo={addToCartSectionInfo} />
      )
    case AddToCartType.IframePostMessage:
      return (
        shouldPostAddToExternalCartMessage
          ? (
            <AddToExternalCartButton />
          )
          : (
            <AddToStandardCartButton onError={onAddToCartError} onSuccess={onSuccess} isLoading={isLoading} />
          )
      )
    case AddToCartType.QuoteId:
      return (
        <AddToCartQuoteIdButton onError={onQuoteIdError} />
      )
    case AddToCartType.Hidden:
      return null
  }
}

export {
  AddToCartButton
}
