import { Modal, ModalBody, ModalHeader } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'
import { PdpImageCarouselInner } from './PdpImageCarouselInner'
import { usePdpImageCarouselContext } from './context/usePdpImageCarouselContext'

interface PdpImageCarouselModalProps {
  /**
   * The title to be shown in the modal
   */
  title?: string
}

/**
 * Modal for showing the PDP carousel after zooming
 */
function PdpImageCarouselModal ({ title }: PdpImageCarouselModalProps): ReactElement {
  const { isModalOpen, onToggleModal } = usePdpImageCarouselContext()

  return (
    <Modal
      isOpen={isModalOpen}
      onToggle={onToggleModal}
      size='lg'
    >
      <ModalHeader onToggle={onToggleModal}>
        {title != null && <h1 className='mb-0'>{title}</h1>}
      </ModalHeader>
      <ModalBody>
        <PdpImageCarouselInner isInModal />
      </ModalBody>
    </Modal>
  )
}

export {
  PdpImageCarouselModal
}
