import { useCallback, useState } from 'react'

interface UseCounterOptions {
  /**
   * Minimum counter limit
   */
  min?: number
  /**
   * Maximum counter limit
   */
  max?: number
  /**
   * The amount to increase/decrease the counter
   */
  step?: number
  /**
   * Indicates to wrap around with min or max is hit
   */
  wrap?: boolean
}

/**
 * Custom hook to help step through an index
 *
 * Returns `[index, setIndex, handlePrev, handleNext]`
 */
function useCounter (
  defaultValue: number = 0,
  {
    min = 0,
    max = Infinity,
    step = 1,
    wrap = true
  }: UseCounterOptions = {}
): [number, (index: number) => void, () => void, () => void] {
  const [index, setIndex] = useState(defaultValue)

  // Set index directly (prevent going outside min/max)
  const handleSetIndex = useCallback((newIndex: number) => {
    if (newIndex >= min && newIndex <= max) {
      setIndex(newIndex)
    }
  }, [min, max])

  // Go to the previous index
  const handlePrev = useCallback(() => {
    setIndex((i) => {
      let newIndex = i - step

      if (newIndex < min) {
        if (wrap && max !== Infinity) {
          newIndex = max
        } else {
          return i
        }
      }

      return newIndex
    })
  }, [min, max, step, wrap])

  // Go to the next index
  const handleNext = useCallback(() => {
    setIndex((i) => {
      let newIndex = i + step

      if (newIndex > max) {
        if (wrap && min !== -Infinity) {
          newIndex = min
        } else {
          return i
        }
      }

      return newIndex
    })
  }, [min, max, step, wrap])

  return [index, handleSetIndex, handlePrev, handleNext]
}

export {
  useCounter
}
