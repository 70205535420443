import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { type ReactElement } from 'react'
import { ScheduledOrderButtons } from './CartSummaryButtons'

interface CartSummaryEmptyScheduledCartProps {
  /**
   * The scheduled order cutoff time
   */
  scheduledOrderCutoffTime: string | null
}

/**
 * The empty scheduled cart summary
 */
function CartSummaryEmptyScheduledCart ({ scheduledOrderCutoffTime }: CartSummaryEmptyScheduledCartProps): ReactElement {
  return (
    <div className='d-flex flex-column align-items-center'>
      <FontAwesomeIcon icon={fas.faShoppingCart} className='mb-3 text-gray-400' size='3x' />
      <p className='fw-bold fs-3 mb-0'>Your scheduled cart is empty</p>
      <p className='text-center mb-5'>Add items to your cart and the order will be placed automatically {scheduledOrderCutoffTime ?? 'your designated cutoff time'}.</p>
      <ScheduledOrderButtons cartIsEmpty />
    </div>
  )
}

export {
  CartSummaryEmptyScheduledCart
}
