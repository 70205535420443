import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'
import { usePdpImageCarouselContext } from './context/usePdpImageCarouselContext'

/**
 * Additional details that is rendered underneath the carousel
 */
function PdpImageCarouselDetails (): ReactElement {
  const { serialNumber, isGemstone } = usePdpImageCarouselContext()

  // FIXME: This needs to be calculated based on the item
  const shouldShowCompatibleMountings = serialNumber != null

  return (
    <>
      {shouldShowCompatibleMountings && (
        <div className='d-flex flex-column gap-3 p-5 bg-gray-100 text-center'>
          <Button
            color='secondary'
            tag='a'
            href={`/search/results/?serialNumber=${serialNumber}&recommendationSource=ViewAvailableMtg`}
            target='_blank'
          >
            <FontAwesomeIcon icon={fas.faRingDiamond} size='lg' className='me-3' />
            View Compatible Mountings
          </Button>
          {isGemstone && (
            <div>The color on your screen may not reflect actual stone color due to the variations in computer monitors.</div>
          )}

        </div>
      )}
    </>
  )
}

export {
  PdpImageCarouselDetails
}
