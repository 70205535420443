import { type ReactElement } from 'react'
import { usePdpImageCarouselContext } from './context/usePdpImageCarouselContext'
import { ProductBadge, type ProductBadgeType } from '@stuller/stullercom/ui'
import { isArrayEmpty } from '@stuller/shared/util/core'

/**
 * Returns an array of badges to display on the PDP image carousel
 */
function getBadges (isLabGrown: boolean): ProductBadgeType[] {
  const badges: ProductBadgeType[] = []

  if (isLabGrown) {
    badges.push('lab-grown')
  }

  return badges
}

/**
 * Displays badges on the PDP image carousel
 */
function PdpImageCarouselBadgeSection (): ReactElement | null {
  const { isLabGrown } = usePdpImageCarouselContext()

  const badges = getBadges(isLabGrown)

  if (isArrayEmpty(badges)) {
    return null
  }

  return (
    <div className='text-end mb-3 mt-n3'>
      {badges?.map((badge) => (
        <ProductBadge key={badge} type={badge} />
      ))}
    </div>
  )
}

export {
  PdpImageCarouselBadgeSection
}
