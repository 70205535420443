import { Modal } from '@stuller/stullercom/ui'
import { AddToCartPricingSummaryModalContents } from './AddToCartPricingSummaryModalContents'
import { type ReactElement } from 'react'
import { type ItemType } from '@stuller/stullercom/data-access/apollo-queries'

interface AddToCartPricingSummaryModalProps {
  /**
   * boolean to determine if the modal is open
   */
  isOpen: boolean
  /**
   * function to close the modal
   */
  onToggle: () => void
  /**
   * id of the item
   */
  id: string
  /**
   * type of item
   */
  itemType: ItemType
  /**
   * account number
   */
  shipToAcountNumber?: string | null
}

/**
 * This component is responsible for rendering the pricing summary modal
 */
function AddToCartPricingSummaryModal ({
  isOpen,
  onToggle,
  id,
  itemType,
  shipToAcountNumber = null
}: AddToCartPricingSummaryModalProps): ReactElement | null {
  return (
    <Modal
      isOpen={isOpen}
      onToggle={onToggle}
      data-gtm-click-section=''
    >
      <AddToCartPricingSummaryModalContents
        onToggle={onToggle}
        id={id}
        itemType={itemType}
        shipToAcountNumber={shipToAcountNumber}
      />
    </Modal>
  )
}

export { AddToCartPricingSummaryModal }
