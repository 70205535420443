import { useRequestItemsPageCreateQuoteMutation } from '@stuller/stullercom/data-access/apollo-queries'
import { Loading, Alert, Form, FormGroup, FormLabel, Input, FormFeedback, PhoneNumberInput, Textarea, Recaptcha, Button } from '@stuller/stullercom/ui'
import { siteConfig } from '@stuller/stullercom/util/site-config'
import { type ReactNode, type ReactElement, useState, type ChangeEvent } from 'react'
import { validateForm, type ValidateFormErrors } from '@stuller/shared/util/schema-validation'
import { getClientValidationErrors, type ValidationError } from '@stuller/stullercom/data-access/apollo-client'
import { z } from 'zod'

interface RequestItemsFormInnerProps {
  /**
   * Title for the form
   */
  title?: string
  /**
   * Callback to set form success state
   */
  onFormSuccess: (email: string) => void
  /**
   * Array of items to add to cart. This will be passed to the mutation when creating a quote.
   */
  addToCarts?: any[]
  /**
   * Callback function when the user clicks the cancel button.
   */
  onCancel?: () => void
  /**
   * Optional children to render inside the form.
   */
  children?: ReactNode
}

const requestItemsFormSchema = z.object({
  recaptchaResponseToken: z.string().min(1, { message: 'Required' }),
  firstName: z.string().trim().min(1, { message: 'Required' }).max(250, { message: 'First name must be less than 250 characters' }),
  lastName: z.string().trim().min(1, { message: 'Required' }).max(250, { message: 'Last name must be less than 250 characters' }),
  email: z.string().trim().min(1, { message: 'Required' }).max(250, { message: 'Email must be less than 250 characters' }).email({ message: 'Invalid email address' }),
  phone: z.string().trim().min(7, { message: 'Required' }).max(250, { message: 'Phone number must be less than 250 characters' }).regex(/^[0-9 ()+-.]+((x|ext?)[0-9 .]+)?$/, { message: 'Invalid phone number' }),
  message: z.string().trim().max(5000, { message: 'Message must be less than 5000 characters' }).nullish()
})

/**
 * Component holding the form content
 */
function RequestItemsFormInner ({ title, onFormSuccess, addToCarts, onCancel, children }: RequestItemsFormInnerProps): ReactElement {
  const [formErrors, setFormErrors] = useState<ValidateFormErrors>({})
  const [serverFormErrors, setServerFormErrors] = useState<ValidationError[]>([])
  const [formInput, setFormInput] = useState<Record<string, string>>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    recaptchaResponseToken: ''
  })

  const [createQuote, { loading: createQuoteLoading }] = useRequestItemsPageCreateQuoteMutation({
    variables: {
      addToCarts,
      callbackRequest: {
        emailAddress: formInput.email,
        firstName: formInput.firstName,
        lastName: formInput.lastName,
        messageToJeweler: formInput.message,
        phoneNumber: formInput.phone,
        recaptchaResponseToken: formInput.recaptchaResponseToken
      }
    },
    onCompleted: () => {
      onFormSuccess(formInput.email)
    },
    onError: (error) => {
      // show validation errors if they exist, otherwise show a generic error message
      const validationErrors = getClientValidationErrors(error)
      const errorMessages = validationErrors.length > 0 ? validationErrors : [{ message: 'There was an issue creating requesting a quote for this item', propertyNames: [] }]
      setServerFormErrors(errorMessages)
    }
  })

  /**
   * Function to handle the schema validation and run the mutation or display errors
   */
  async function handleSubmit (event: React.FormEvent): Promise<void> {
    event.preventDefault()
    event.stopPropagation()
    setFormErrors({})
    setServerFormErrors([])
    const result = validateForm(requestItemsFormSchema, formInput)
    if (result.success) {
      try {
        await createQuote()
      } catch {
        // Already handling errors in createQuote()
      }
    } else {
      setFormErrors(result.errors)
    }
  }

  /**
   * Handle the recaptcha token change
   */
  function handleRecaptchaChange (token: string | null): void {
    setFormInput((prev) => ({
      ...prev,
      recaptchaResponseToken: token ?? ''
    }))
  }

  /**
   * Handle the input change from all inputs
   */
  function handleInputChange (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void | Promise<void> {
    setFormInput((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  /**
   * Handle the phone number input change
   */
  function handlePhoneNumberChange (phoneNumber: string): void {
    setFormInput((prev) => ({
      ...prev,
      phone: phoneNumber
    }))
  }

  return (
    <Loading loading={createQuoteLoading}>
      <h1 className='mb-2'>{title}</h1>
      <p className='mb-5'>Fill out this form and we’ll contact you about purchasing your selected items.</p>
      {children}
      {serverFormErrors?.length > 0 && (
        <Alert color='danger' icon className='mt-4'>
          <div className='row align-items-center gy-3'>
            <div className='col'>
              {serverFormErrors?.map((error, i) => (
                <p key={i} className='m-0'>{error.message}</p>
              ))}
            </div>
          </div>
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <div className='row gy-5'>
          <div className='col-12'>
            <div className='row gy-5'>
              <FormGroup groupId='first-name' className='col-sm'>
                <FormLabel>First Name</FormLabel>
                <Input
                  name='firstName'
                  value={formInput.firstName}
                  onChange={handleInputChange}
                  invalid={formErrors.firstName}
                />
                <FormFeedback valid={formErrors.firstName == null}>{formErrors.firstName}</FormFeedback>
              </FormGroup>
              <FormGroup groupId='last-name' className='col-sm'>
                <FormLabel>Last Name</FormLabel>
                <Input
                  name='lastName'
                  value={formInput.lastName}
                  onChange={handleInputChange}
                  invalid={formErrors.lastName}
                />
                <FormFeedback valid={formErrors.lastName == null}>{formErrors.lastName}</FormFeedback>
              </FormGroup>
            </div>
          </div>
          <div className='col-12'>
            <div className='row gy-5'>
              <FormGroup groupId='email' className='col-sm'>
                <FormLabel>Email</FormLabel>
                <Input
                  name='email'
                  value={formInput.email}
                  onChange={handleInputChange}
                  invalid={formErrors.email}
                />
                <FormFeedback valid={formErrors.email == null}>{formErrors.email}</FormFeedback>
              </FormGroup>
              <FormGroup groupId='phone' className='col-sm'>
                <FormLabel>Phone</FormLabel>
                <PhoneNumberInput
                  name='phone'
                  onChange={handlePhoneNumberChange}
                  invalid={formErrors.phone}
                />
                <FormFeedback valid={formErrors.phone == null}>{formErrors.phone}</FormFeedback>
              </FormGroup>
            </div>
          </div>
          <FormGroup groupId='message'>
            <FormLabel>
              Message <span className='fw-normal'>(optional)</span>
            </FormLabel>
            <Textarea
              rows={2}
              name='message'
              value={formInput.message}
              onChange={handleInputChange}
              invalid={formErrors.message}
            />
            <FormFeedback valid={formErrors.message == null}>{formErrors.message}</FormFeedback>
          </FormGroup>
          <FormGroup groupId='recaptcha' id='recaptcha'>
            <Recaptcha
              onChange={handleRecaptchaChange}
              siteKey={siteConfig.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? ''}
              invalid={formErrors.recaptchaResponseToken != null}
            />
            <FormFeedback className='d-block w-100' valid={false}>{formErrors.recaptchaResponseToken}</FormFeedback>
          </FormGroup>
          <div className='d-flex justify-content-end'>
            {onCancel != null && (
              <Button color='link-dark' className='text-end' onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              color='primary'
              type='submit'
              disabled={formInput.recaptchaResponseToken === ''}
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </Loading>
  )
}

export {
  RequestItemsFormInner
}
