import { Button, Form, FormLabel, Input, InputGroup } from '@stuller/stullercom/ui'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type FormEvent, type ReactElement, useState } from 'react'
import { isStringEmpty } from '@stuller/shared/util/core'
import { trackEvent } from '@stuller/stullercom/feat/google-tag-manager'
import clsx from 'clsx'

interface SearchBySerialNumberProps {
  /**
   * Option to center the entire content
   */
  centered?: boolean
  /**
   * Option to open the results in a new tab
   */
  openInNewTab?: boolean
}

/**
 * Renders a text input field that opens the results for a serial number in a new tab
 */
function SearchBySerialNumber ({ centered = false, openInNewTab = false }: SearchBySerialNumberProps): ReactElement | null {
  const [serialNumber, setSerialNumber] = useState<string>('')

  function handleSerialNumberSearchChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setSerialNumber(e.target.value)
  }

  function handleSearch (event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (!isStringEmpty(serialNumber)) {
      trackEvent('filter', {
        section: 'search_by_serial',
        input_name: 'Search by Serial #',
        input_type: 'text',
        input_value: serialNumber
      })
      const target = openInNewTab ? '_blank' : '_self'
      window.open(`/serializedproduct/${serialNumber}?recommendationSource=SearchBySerialNumber`, target)
    }
  }

  const containerClassNames = clsx(
    'row align-items-sm-center gy-3 gx-4',
    centered && 'justify-content-sm-center'
  )

  return (
    <div className={containerClassNames}>
      <div className='col-auto'>
        <h3 className='mb-0'>Search by Serial #</h3>
      </div>
      <div className='col-12 col-sm-6 col-md-5 col-lg-4'>
        <Form onSubmit={handleSearch}>
          <FormLabel
            htmlFor='searchBySerialNumber'
            className='sr-only'
          >
            Enter a Serial Number
          </FormLabel>
          <InputGroup>
            <Input
              type='number'
              id='searchBySerialNumber'
              placeholder='Enter a Serial #'
              onChange={handleSerialNumberSearchChange}
              required
            />
            <Button
              type='submit'
              aria-label='Search'
              role='button'
            >
              <FontAwesomeIcon className='px-2' icon={fas.faSearch} />
            </Button>
          </InputGroup>
        </Form>
      </div>
    </div>
  )
}

export {
  SearchBySerialNumber
}
