import { type AppContext } from 'next/app'
import { type GetAuth } from '@stuller/stullercom/feat/auth'
import type { StatusCodeProps } from '@stuller/stullercom/feat/layout-context'

const REFRESH_CUTOFF_MINUTES = -65
const REFRESH_AUTH_PATH = '/homes/refreshauth?r='

/**
 * Redirect if auth user is due for a refresh
 */
function getRefreshAuthUserRedirect (appContext: AppContext, auth: GetAuth): StatusCodeProps | null {
  const { ctx } = appContext
  const { authUser } = auth
  const refreshCutoff = new Date()
  refreshCutoff.setMinutes(REFRESH_CUTOFF_MINUTES)
  const returnPath = ctx.asPath

  if (authUser != null &&
    new Date(authUser.lastRefreshDate).getTime() < refreshCutoff.getTime() &&
      returnPath != null) {
    const location = `${REFRESH_AUTH_PATH}${encodeURIComponent(returnPath)}`

    return { statusCode: 307, location }
  }

  return null
}

export {
  getRefreshAuthUserRedirect,
  REFRESH_CUTOFF_MINUTES,
  REFRESH_AUTH_PATH
}
