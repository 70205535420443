import { type ReactElement } from 'react'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import { type AddToCartSectionInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { useAuth } from '@stuller/stullercom/feat/auth'
import clsx from 'clsx'
import { SchemaMarkupPriceWrapper } from './SchemaMarkupPriceWrapper'

interface AddToCartPriceProps {
  addToCartSectionInfo: AddToCartSectionInfoFragment
}

const usdCultureInfo = { code: 'USD', culture: 'en-US' }

function AddToCartPrice ({ addToCartSectionInfo }: AddToCartPriceProps): ReactElement | null {
  const { showcaseSettings, isShowcase } = useAuth()
  const hideUSDPrices = showcaseSettings?.hideUSDPrices ?? false

  const { price, soldByDescription } = addToCartSectionInfo
  const { rounded } = price ?? {}
  const exchangeRate = rounded?.total.exchangeRate ?? usdCultureInfo
  const priceDecimal = rounded?.total.value ?? 0
  const usdPriceDecimal = rounded?.total.valueInUsd ?? 0
  const currencyCode = exchangeRate.code.toUpperCase() ?? 'USD'
  const showUsdConversionPrice = currencyCode !== 'USD' && usdPriceDecimal != null && !hideUSDPrices

  // discount stuff
  const discount = price?.rounded.discount
  const isDiscounted = discount != null
  const isStullerFirstDiscountSource = discount?.source?.toLowerCase() === 'stullerfirst'
  // in showcase mode, we don't want to show the stuller first discount styling
  const isRegularDiscount = isDiscounted && (!isStullerFirstDiscountSource || isShowcase)
  // same thing here, dont show stuller first discount styling in showcase mode
  const isStullerFirstDiscount = isDiscounted && isStullerFirstDiscountSource && !isShowcase
  const priceColorClasses = clsx(
    isRegularDiscount && 'text-red-400',
    isStullerFirstDiscount && 'text-gold-300'
  )

  // discount original price params
  const [discountOriginalPriceDisplay] = useCurrency(discount?.originalPrice?.value, exchangeRate)
  const [usdDiscountOriginalPriceCurrency] = useCurrency(discount?.originalPrice?.valueInUsd, usdCultureInfo)
  const usdDiscountOriginalPriceDisplay = `${usdDiscountOriginalPriceCurrency} (USD)`

  // total price params
  const [totalPrice] = useCurrency(priceDecimal, exchangeRate)
  const [usdTotalPrice] = useCurrency(usdPriceDecimal, usdCultureInfo)
  const usdTotalPriceDisplay = `${usdTotalPrice} (USD)`
  const totalPriceMainClasses = clsx(
    priceColorClasses,
    'fs-1 lh-1'
  )

  // discount value params
  const [discountValueCurrency] = useCurrency(discount?.value.value, exchangeRate)
  const discountValueDisplay = discount?.percent != null ? `Save ${discountValueCurrency} (${discount?.percent}% off)` : `Save ${discountValueCurrency}`
  const [usdDiscountValueCurrency] = useCurrency(discount?.value.valueInUsd, usdCultureInfo)
  const usdDiscountValueDisplay = `${usdDiscountValueCurrency} (USD)`
  const discountValueMainClasses = clsx(priceColorClasses, 'fs-5 fw-bold')

  // price per carat params
  const pricePerCarat = rounded?.caratPrice?.value ?? 0
  const usdPricePerCarat = rounded?.caratPrice?.valueInUsd ?? 0
  const [pricePerCaratCurrency] = useCurrency(pricePerCarat, exchangeRate)
  const pricePerCaratDisplay = `${pricePerCaratCurrency}/ct`
  const [usdPricePerCaratCurrency] = useCurrency(usdPricePerCarat, usdCultureInfo)
  const currencyPricePerCarat = `${usdPricePerCaratCurrency} (USD)`

  // price per uom params
  const pricePerUOM = price?.roundedPricePerPackUnit?.value ?? 0
  const usdPricePerUOM = price?.roundedPricePerPackUnit?.valueInUsd ?? 0
  const [pricePerUOMCurrency] = useCurrency(pricePerUOM, exchangeRate)
  const [usdPricePerUOMCurrency] = useCurrency(usdPricePerUOM, usdCultureInfo)
  const pricePerUOMDisplay = `${pricePerUOMCurrency}/${soldByDescription}`
  const usdPricePerUOMDisplay = `${usdPricePerUOMCurrency} (USD)`

  // percent off rapaport params
  const percentOffRapaport = addToCartSectionInfo.__typename === 'SerializedProduct'
    ? addToCartSectionInfo.percentOffRapaport
    : null
  const roundedPercentOffRapaport = percentOffRapaport != null && percentOffRapaport > 0
    ? percentOffRapaport.toFixed(2)
    : null

  if (priceDecimal <= 0) {
    return null
  }

  return (
    <div itemProp='offers' itemScope itemType='https://schema.org/AggregateOffer'>
      <div className='text-end'>
        {isDiscounted && (
          <SchemaMarkupPriceWrapper
            schemaPrice={discount?.originalPrice?.value.toString() ?? ''}
            schemaPriceCurrency={currencyCode}
            schemaPriceType='https://schema.org/ListPrice'
          >
            <PriceDisplay
              mainPrice={discountOriginalPriceDisplay}
              usdPrice={usdDiscountOriginalPriceDisplay}
              showUsdConversionPrice={showUsdConversionPrice}
              mainPriceClasses='text-decoration-line-through fs-4'
              usdPriceClasses='text-decoration-line-through fs-6 fst-italic text-gray-600'
            />
          </SchemaMarkupPriceWrapper>
        )}
        <SchemaMarkupPriceWrapper
          schemaPrice={rounded?.total.value.toString() ?? ''}
          schemaPriceCurrency={currencyCode}
          schemaPriceType={isDiscounted ? 'https://schema.org/SalePrice' : 'https://schema.org/ListPrice'}
        >
          <PriceDisplay
            mainPrice={totalPrice}
            usdPrice={usdTotalPriceDisplay}
            showUsdConversionPrice={showUsdConversionPrice}
            mainPriceClasses={totalPriceMainClasses}
            usdPriceClasses='fst-italic text-gray-600'
          />
        </SchemaMarkupPriceWrapper>
        {isStullerFirstDiscount &&
          <div className='fs-5 text-gold-300'>Stuller First Member Discount</div>}
        {isDiscounted && (
          <PriceDisplay
            mainPrice={discountValueDisplay}
            usdPrice={usdDiscountValueDisplay}
            showUsdConversionPrice={showUsdConversionPrice}
            mainPriceClasses={discountValueMainClasses}
            usdPriceClasses='fs-6 fst-italic text-gray-600'
          />
        )}
        {pricePerCarat > 0 && (
          <SchemaMarkupPriceWrapper
            schemaPrice={rounded?.caratPrice?.value.toString() ?? ''}
            schemaPriceCurrency={currencyCode}
            schemaUnitCode='CAR'
          >
            <PriceDisplay
              mainPrice={pricePerCaratDisplay}
              usdPrice={currencyPricePerCarat}
              showUsdConversionPrice={showUsdConversionPrice}
              mainPriceClasses='fw-bold'
              usdPriceClasses='fs-6 fst-italic text-gray-600'
            />
          </SchemaMarkupPriceWrapper>
        )}
        {pricePerUOM > 0 && soldByDescription != null && (
          <SchemaMarkupPriceWrapper
            schemaPrice={price?.roundedPricePerPackUnit?.value.toString() ?? ''}
            schemaPriceCurrency={currencyCode}
          >
            <PriceDisplay
              mainPrice={pricePerUOMDisplay}
              usdPrice={usdPricePerUOMDisplay}
              showUsdConversionPrice={showUsdConversionPrice}
              mainPriceClasses='fw-bold'
              usdPriceClasses='fs-6 fst-italic text-gray-600'
            />
          </SchemaMarkupPriceWrapper>
        )}
        {percentOffRapaport != null && percentOffRapaport > 0 && (
          <div className='fw-bold'>{roundedPercentOffRapaport}% Off Rap.</div>)}
      </div>
    </div>
  )
}

interface PriceDisplayProps {
  mainPrice: string
  showUsdConversionPrice: boolean
  usdPrice: string
  mainPriceClasses?: string
  usdPriceClasses?: string
}

function PriceDisplay ({ mainPrice, showUsdConversionPrice, usdPrice, mainPriceClasses, usdPriceClasses }: PriceDisplayProps): ReactElement {
  return (
    <>
      <div className={mainPriceClasses}>{mainPrice}</div>
      {showUsdConversionPrice && (
        <div className={usdPriceClasses}>{usdPrice}</div>
      )}
    </>
  )
}

export { AddToCartPrice }
