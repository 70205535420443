import { type ReactElement } from 'react'
import { bottomSectionClasses } from './helpers/helpers'
import { StatusTopSection } from './StatusTopSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'
import { RequestConsignmentStatusModal } from './RequestConsignmentStatusModal'
import { useAuth } from '@stuller/stullercom/feat/auth'

function OutOnConsignmentStatus (): ReactElement {
  const bottomClasses = clsx(
    bottomSectionClasses,
    'bg-white lh-sm'
  )
  const { authUser } = useAuth()

  return (
    <>
      <StatusTopSection color='black' overrideShowQuantityOnHand={false}>
        <FontAwesomeIcon icon={fas.faClock} className='me-2' size='lg' />
        Out for Review
      </StatusTopSection>
      {authUser != null && (
        <div className={bottomClasses}>
          <div className='mb-3'>
            This item has been checked out for review by another customer.
          </div>
          <div className='d-grid'>
            <RequestConsignmentStatusModal />
          </div>
        </div>
      )}
    </>
  )
}

export {
  OutOnConsignmentStatus
}
