import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type ReactElement } from 'react'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { AddToCartPricingSummaryModal } from './AddToCartPricingSummaryModal'
import { Button } from '@stuller/stullercom/ui'
import { type ItemType } from '@stuller/stullercom/data-access/apollo-queries'

interface AddToCartPricingSummaryProps {
  /**
   * id of the item
   */
  id: string
  itemType: ItemType
  shipToAcountNumber?: string | null
}

/**
 * This component is has the button to open the pricing summary modal
 */
function AddToCartPricingSummary ({
  id,
  itemType,
  shipToAcountNumber = null
}: AddToCartPricingSummaryProps): ReactElement | null {
  const [isOpen, toggleIsOpen] = useToggle()

  return (
    <div>
      <Button color='anchor-dark' onClick={toggleIsOpen}>
        <FontAwesomeIcon icon={fas.faFileInvoice} className='me-2' />Price Summary
      </Button>
      <AddToCartPricingSummaryModal
        isOpen={isOpen}
        onToggle={toggleIsOpen}
        id={id}
        itemType={itemType}
        shipToAcountNumber={shipToAcountNumber}
      />
    </div>
  )
}

export { AddToCartPricingSummary }
