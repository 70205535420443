import { type ReactElement } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { useAuth } from '@stuller/stullercom/feat/auth'
import {
  type QuickLink as QuickLinkType,
  quickLinks,
  showcaseQuickLinks
} from './links'

interface MobileQuickLinkProps extends QuickLinkType {
}

/**
 * A single quick link for mobile popover
 */
function MobileQuickLink ({ label, url }: MobileQuickLinkProps): ReactElement {
  return (
    <Link href={url} className='text-gray-300 text-decoration-none fw-normal'>
      <div className='row g-0 align-items-center'>
        <div className='col px-4 py-3 lh-sm'>
          {label}
        </div>
      </div>
    </Link>
  )
}

/**
 * Quick links for mobile popover
 */
function MobileQuickLinks (): ReactElement | null {
  const auth = useAuth()
  const { isShowcase } = auth
  const classNames = clsx(
    'row g-0 border-top py-3',
    isShowcase ? 'border-gray-700' : 'border-cyan-500'
  )
  const links = (isShowcase ? showcaseQuickLinks : quickLinks)
    .filter((link) => link.show(auth))

  if (links.length === 0) {
    return null
  }

  return (
    <div className={classNames}>
      <div className='col'>
        {links.map((link) => <MobileQuickLink key={link.label} {...link} />)}
      </div>
    </div>
  )
}

export {
  MobileQuickLinks
}
