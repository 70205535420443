import { useState, type ReactElement } from 'react'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'
import { ItemQuantityInputGroupWrapper } from '@stuller/stullercom/feat/cart-page'
import { Form } from '@stuller/stullercom/ui'

interface AddToCartQuantityInputProps {
  /**
   * The quantity description
   */
  soldByDescription: string
  /**
   * Whether the input is disabled
   */
  disabled?: boolean
}

function AddToCartQuantityInput ({ soldByDescription, disabled }: AddToCartQuantityInputProps): ReactElement | null {
  const { options, onAddToCartDataChanged } = useAddToCartSectionContext()
  const quantity = options?.quantity?.toString() ?? '1'
  const [currentQuantity, setCurrentQuantity] = useState<string>(quantity)

  /**
   * Handles the change of the input value
   */
  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentQuantity(event.target.value)
  }

  /**
   * Handles the submission of the form submitted when the quantity is changed
   */
  const handleSubmitQuantity = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const quantityToSubmit = parseInt(currentQuantity)
    if (isNaN(quantityToSubmit) || quantityToSubmit <= 0 || quantityToSubmit === options?.quantity) {
      setCurrentQuantity(quantity)

      return
    }
    setCurrentQuantity(quantityToSubmit.toString())
    onAddToCartDataChanged({ quantity: quantityToSubmit })
  }

  return (
    <Form onSubmit={handleSubmitQuantity} onBlur={handleSubmitQuantity}>
      <ItemQuantityInputGroupWrapper
        quantity={currentQuantity}
        onChangeQuantity={handleChangeQuantity}
        isDisabled={disabled ?? false}
        soldByDescription={soldByDescription}
        inputGroupClassName='ms-auto d-flex'
        inputClassName='text-center rounded-start-2'
        inputGroupTextClassName='justify-content-center'
      />
    </Form>
  )
}

export {
  AddToCartQuantityInput
}
