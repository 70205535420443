import { isStringEmpty } from '@stuller/shared/util/core'
import { Card } from '@stuller/stullercom/ui'
import React, { type ReactNode, type ReactElement, Children } from 'react'

interface CartItemConfigurationCardProps {
  /**
   * The title of the product
   */
  title?: string
  /**
   * The description of the product
   */
  description?: string
  /**
   * The children of the component
   */
  children: ReactNode
}

/**
 * Component for the product title and description in the cart item configuration
 */
function CartItemConfigurationCard ({
  title,
  description,
  children
}: CartItemConfigurationCardProps): ReactElement {
  return (
    <Card className='p-4 border-0'>
      {!isStringEmpty(title) && (
        <p className='fs-4 fw-bold mb-0 lh-sm'>{title}</p>
      )}
      {!isStringEmpty(description) && (
        <p className='mb-0 lh-sm'>{description}</p>
      )}
      {(!isStringEmpty(title) || !isStringEmpty(description)) && Children.count(children) > 0 && (
        <div className='my-3 border-bottom' />
      )}
      {children}
    </Card>
  )
}

export {
  CartItemConfigurationCard
}
