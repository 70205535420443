import { useState, type ReactElement } from 'react'
import type { ServiceCharge, ServiceCharges } from '../shared/types'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Popover, PopoverBody, Table } from '@stuller/stullercom/ui'
import { useCurrency, useToggleKey } from '@stuller/shared/util/react-hooks'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'
import { DIGITAL_IMPRINTING_LOGO, HOT_STAMPTING_LOGO } from '@stuller/shared/util/constants'

interface CustomImprintingSectionProps {
  /**
   * Service charges for the cart
   */
  serviceCharges?: ServiceCharges
}

interface ServiceChargeRowProps {
  /**
   * Service charges for the cart
   */
  serviceCharge: ServiceCharge
}

interface ServiceChargePopoverProps {
  /**
   * Reference for the popover
   */
  reference: HTMLDivElement | null
  /**
   * Quantity of the service charge
   */
  quantity: number
}

interface ServiceChargePopoverRowProps {
  /**
   * Quantity of the selected service charge
   */
  quantity: number
  /**
   * Minimum quantity for the service charge price break
   */
  minQuantity: number
  /**
   * Maximum quantity for the service charge price break
   */
  maxQuantity?: number
  /**
   * Title of the service charge price break
   */
  title: string
  /**
   * Type of the service charge price break
   */
  type: string
  /**
   * Price of the service charge price break
   */
  price: string
}

const fees = [
  {
    minQuantity: 0,
    maxQuantity: 200,
    title: '0\u2013200',
    type: 'Flat Charge',
    price: '$89.00 (USD)'
  },
  {
    minQuantity: 201,
    maxQuantity: 400,
    title: '201\u2013400',
    type: 'Each',
    price: '$0.40 (USD)'
  },
  {
    minQuantity: 401,
    maxQuantity: 600,
    title: '401\u2013600',
    type: 'Each',
    price: '$0.35 (USD)'
  },
  {
    minQuantity: 601,
    title: '601+',
    type: 'Each',
    price: '$0.30 (USD)'
  }
]

/**
 * One row in the popover table
 */
function ServiceChargePopoverRow ({ quantity, minQuantity, maxQuantity, title, type, price }: ServiceChargePopoverRowProps): ReactElement {
  const isCurrentPricebreak = quantity >= minQuantity && (maxQuantity === undefined || quantity <= maxQuantity)

  const classNames = clsx(isCurrentPricebreak && 'table-primary')

  return (
    <tr className={classNames}>
      <td>{title}</td>
      <td>{price}</td>
      <td>{type}</td>
    </tr>
  )
}

/**
 * Popover showing the service charge price breaks
 */
function ServiceChargePopover ({ reference, quantity }: ServiceChargePopoverProps): ReactElement {
  const [hoverOpen, handleHoverToggle] = useState(false)

  return (
    <Popover
      hover
      open={hoverOpen}
      onOpenChange={handleHoverToggle}
      placement='top'
      reference={reference}
    >
      <PopoverBody className='p-4'>
        <Table bordered rounded className='mb-0'>
          <thead>
            <tr className='table-light'>
              <th>Quantities</th>
              <th>Price</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee) => (
              <ServiceChargePopoverRow
                key={fee.title}
                quantity={quantity}
                minQuantity={fee.minQuantity}
                maxQuantity={fee.maxQuantity}
                title={fee.title}
                type={fee.type}
                price={fee.price}
              />
            ))}
          </tbody>
        </Table>
      </PopoverBody>
    </Popover>
  )
}

/**
 * One row in the service charge table
 */
function ServiceChargeRow ({ serviceCharge }: ServiceChargeRowProps): ReactElement {
  const [perUnitPrice] = useCurrency(serviceCharge.price?.perUnit?.value, serviceCharge.price?.perUnit?.exchangeRate)
  const [totalPrice] = useCurrency(serviceCharge.price?.total?.value, serviceCharge.price?.total?.exchangeRate)
  const chargePricingType = serviceCharge.price?.type === 'PER_UNIT' ? 'Per Printing' : 'Flat Charge'
  const [hoverRef, setHoverRef] = useState<HTMLDivElement | null>(null)
  const imprintingImage = serviceCharge.type === 'HOT_STAMPING' ? HOT_STAMPTING_LOGO : DIGITAL_IMPRINTING_LOGO

  return (
    <tr key={serviceCharge.type}>
      <td className='align-middle'>
        <div className='d-flex'>
          <img src={imprintingImage} width='20px' height='20px' className='me-2' />
          <div>
            {serviceCharge.title}
          </div>
        </div>
      </td>
      <td className='text-end text-nowrap'>
        <p className='mb-0'>{`${serviceCharge.quantity} @ ${perUnitPrice}`}</p>
        <div className='fs-6 fw-bold d-flex justify-content-end'>
          <div ref={setHoverRef}>
            <FontAwesomeIcon icon={fas.faInfoCircle} className='me-2' size='lg' />
          </div>
          <p className='mb-0'>{chargePricingType}</p>
          <ServiceChargePopover reference={hoverRef} quantity={serviceCharge.quantity ?? 0} />
        </div>
      </td>
      <td className='text-end'>{totalPrice}</td>
    </tr>
  )
}

/**
 * Shows the cart lines and imprinting charges
 */
function CustomImprintingSection ({ serviceCharges }: CustomImprintingSectionProps): ReactElement | null {
  const [open, handleToggle] = useToggleKey('imprinting-charges')

  if (isArrayEmpty(serviceCharges)) {
    return null
  }

  return (
    <Accordion open={open} onToggle={handleToggle}>
      <AccordionItem itemId='imprinting-charges' className='shadow-sm'>
        <AccordionHeader buttonClassName='bg-white'>Custom Imprinting Charges</AccordionHeader>
        <AccordionBody>
          <p>
            Quantity discounts are based on the total quantity of all custom imprinted items in your cart.
          </p>
          <Table bordered rounded>
            <thead>
              <tr className='table-light'>
                <th>Imprinting Type</th>
                <th className='text-end'>Qty Discounts</th>
                <th className='text-end'>Charges</th>
              </tr>
            </thead>
            <tbody>
              {serviceCharges?.map((serviceCharge) => (
                <ServiceChargeRow serviceCharge={serviceCharge} key={serviceCharge.type} />
              ))}
            </tbody>
          </Table>
          <p>
            If you’re uploading a new logo to use or if you’re new to imprinting, the below charges will also be added to your order:
          </p>
          <p className='fw-bold mb-0'>Digital logo setup fee: $99 (USD)</p>
          <p>Logos can be resized to fit any packaging.</p>
          <p className='fw-bold mb-0'>Hot foil setup fee: $99 (USD)</p>
          <p>
            Logos are debossed onto a metal plate up to 20 sq. in. to press your logo into packaging. You will receive templates to preview for sizing approval.
          </p>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  )
}

export {
  CustomImprintingSection
}
