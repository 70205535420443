import { type ReactElement } from 'react'
import { fab } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@stuller/stullercom/ui'

interface ItemShareFacebookProps {
  /**
   * Callback to execute when the item is shared
   */
  onShare: () => void
}

/**
 * Share item via Facebook
 */
function ItemShareFacebook ({ onShare }: ItemShareFacebookProps): ReactElement {
  return (
    <Button
      tag='a'
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}`}
      target='_blank'
      color='anchor-dark'
      className='fw-normal'
      onClick={onShare}
    >
      <FontAwesomeIcon icon={fab.faFacebookSquare} className='me-2' color='#1778f2' />
      Facebook
    </Button>
  )
}

export {
  ItemShareFacebook
}
