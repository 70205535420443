import { type ReactNode, type ReactElement } from 'react'
import { useGetCartPageQuery } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { Card, CardBody } from '@stuller/stullercom/ui'
import { ContentstackJsonRte } from '@stuller/stullercom/feat/contentstack-json-rte'
import { CartPageNavigation, type CartPageNavigationLinks } from './CartPageNavigation'
import { clsx } from 'clsx'
import { ReturnToLegacyPageBanner } from '@stuller/stullercom/feat/piloting'

interface CartPageLayoutProps {
  /**
   * The title of the page
   */
  title: string
  /**
    * The active cart page navigation link
    */
  activeNavigationLink: CartPageNavigationLinks
  /**
   * The children of the page
   */
  children: ReactNode
  /**
   * Additional classes for the wrapper
   */
  wrapperClassName?: string
  /**
   * Skips including the default page container
   */
  skipContainer?: boolean
}

/**
 * The layout that is used for all of the cart pages
 */
function CartPageLayout ({
  title,
  children,
  activeNavigationLink,
  wrapperClassName,
  skipContainer = false,
  ...otherAttributes
}: CartPageLayoutProps): ReactElement {
  const { data } = useGetCartPageQuery()
  const {
    bottom_rich_text: bottom
  } = data?.all_cart_page?.items?.[0] ?? {}

  const wrapperClassNames = clsx(
    wrapperClassName,
    'border-top',
    activeNavigationLink === 'subcarts' || activeNavigationLink === 'alldotcarts' || activeNavigationLink === 'jewelershowcasecarts' ? 'bg-white' : 'bg-gray-100'
  )

  return (
    <>
      {activeNavigationLink === 'cart' && <ReturnToLegacyPageBanner title='shopping cart' buttonTitle='Cart' pilotName='cart' />}
      <div className='container-xxl mw-xxl px-4 py-5 d-print-none' {...otherAttributes}>
        <div className='col-12'>
          <h1 id='cart-page-title'>{title}</h1>
        </div>
      </div>
      <div className='container-xxl mw-xxl px-4'>
        <CartPageNavigation activeNavigationLink={activeNavigationLink} />
      </div>
      <div className={wrapperClassNames}>
        {skipContainer
          ? children
          : (
            <div className='container-xxl mw-xxl py-5 px-4'>
              <div className='row'>
                <div className='col-12'>
                  {children}
                </div>
              </div>
            </div>
          )}
      </div>
      {bottom != null && (
        <div className='container-xxl mw-xxl px-4 py-6 pb-8 mb-8 d-print-none'>
          <div className='col-12'>
            <Card>
              <CardBody className='px-5 pt-5 pb-3'>
                <ContentstackJsonRte json={bottom.json} />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  )
}

export {
  CartPageLayout
}
