import { useState, type ReactElement, type ReactNode } from 'react'
import { Button } from '@stuller/stullercom/ui'
import { RequestItemsFormInner } from './RequestItemsFormInner'

interface RequestItemsFormProps {
  /**
   * Title for the form
   */
  title?: string
  /**
   * Array of items to add to cart. This will be passed to the mutation when creating a quote.
   */
  addToCarts?: any[]
  /**
   * Callback function when the user clicks the cancel button.
   */
  onCancel?: () => void
  /**
   * Callback function when the user clicks the continue button after the quote request has been submitted.
   */
  onContinue: () => void
  /**
   * Optional children to render inside the form.
   */
  children?: ReactNode
}

interface SubmitSuccessViewProps {
  /**
   * Email address that the quote request confirmation was sent to.
   */
  email: string
  /**
   * Callback function when the user clicks the continue button after the quote request has been submitted.
   */
  onContinue: () => void
}

/*
* This component is a confirmation message that the quote request has been submitted.
*/
function SubmitSuccessView ({ email, onContinue }: SubmitSuccessViewProps): ReactElement {
  return (
    <div className='text-center'>
      <h1>Request Submitted</h1>
      <div className='fw-bold fs-4'>Thank You!</div>
      <p className='mb-5'>
        We’ll contact you soon about purchasing options. A copy of this inquiry was sent to <span className='fw-bold'>{email}</span>.
      </p>
      <Button onClick={onContinue}>
        Continue Shopping
      </Button>
    </div>
  )
}

/**
 * Form to handle jeweler showcase quote requests
 */
function RequestItemsForm ({ title, addToCarts, onCancel, onContinue, children }: RequestItemsFormProps): ReactElement {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [contactEmail, setContactEmail] = useState('')

  /**
   * Callback function when the form is successfully submitted
   */
  function handleFormSuccess (email: string): void {
    setSubmitSuccess(true)
    setContactEmail(email)
  }

  return (
    <>
      {submitSuccess
        ? (
          <SubmitSuccessView onContinue={onContinue} email={contactEmail} />
        )
        : (
          <RequestItemsFormInner
            title={title}
            onFormSuccess={handleFormSuccess}
            addToCarts={addToCarts}
            onCancel={onCancel}
          >
            {children}
          </RequestItemsFormInner>
        )}
    </>
  )
}

export {
  RequestItemsForm
}
