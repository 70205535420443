import { type ReactElement, useRef } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'

function ScheduledOrdersDisclaimer (): ReactElement {
  const [isOpen, toggleIsOpen] = useToggle()
  const ref = useRef(typeof window !== 'undefined' ? document.body : undefined)

  return (
    <>
      <p className='mt-3 mb-0 float-start d-inline-block'>
        Canceling will leave all items in your cart, but inventory will no longer be reserved.&nbsp;
        <Button
          color='link'
          onClick={toggleIsOpen}
          className='p-0 min-w-initial d-inline-block align-baseline'
          style={{ minWidth: 'initial' }}
        >
          Learn More
        </Button>
      </p>
      <Modal isOpen={isOpen} onToggle={toggleIsOpen} portalRef={ref}>
        <ModalHeader className='pt-6' onToggle={toggleIsOpen}>Scheduled Orders</ModalHeader>
        <ModalBody>
          <p className='fw-bold mb-0'>Reserve inventory</p>
          <p>Scheduling an order reserves inventory and ensures your order will be placed before the order cutoff time at the end of the day.</p>
          <p>To use this feature, set up your order and go through the checkout process as you normally would. At the last step, click the "Schedule Order" button and you're done!</p>
          <p>This will allow you to add and remove items from your cart throughout the day. Anything left in your cart at your cut-off time will be ordered automatically.</p>
          <p className='mb-0 fw-bold'>Availability is not guaranteed</p>
          <p>Product Availability is not guaranteed, therefore inventory critical items (large diamonds or large gemstones) may not be available when your order is placed.</p>
          <p className='fw-bold mb-0'>You may cancel or place your scheduled order at any time.</p>
        </ModalBody>
        <ModalFooter className='pb-3' />
      </Modal>
    </>
  )
}

export {
  ScheduledOrdersDisclaimer
}
