import { type ChangeEvent, type ReactElement, type FormEvent } from 'react'
import { Button, Form, FormFeedback, FormGroup, FormLabel, Input, Loading } from '@stuller/stullercom/ui'

export interface LoginFormProps {
  username: string
  password: string
  rememberMe: boolean
  onUsernameChange: (event: ChangeEvent<HTMLInputElement>) => void
  onPasswordChange: (event: ChangeEvent<HTMLInputElement>) => void
  onRememberMeChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>
  loading: boolean
  errors: Record<string, string>
}

/**
 * Login form display elements
 */
function LoginForm ({
  username,
  password,
  rememberMe,
  onUsernameChange,
  onPasswordChange,
  onRememberMeChange,
  onSubmit,
  loading,
  errors
}: LoginFormProps): ReactElement {
  return (
    <Loading loading={loading}>
      <Form onSubmit={onSubmit} className='row align-items-center'>
        <div className='col-12'>
          <FormGroup className='mb-4'>
            <Input
              placeholder='Username...'
              pill
              value={username}
              onChange={onUsernameChange}
              spellCheck={false}
              invalid={errors.userName}
              data-test='username'
              tabIndex={1}
            />
            <FormFeedback>{errors.userName}</FormFeedback>
          </FormGroup>
        </div>
        <div className='col-12'>
          <FormGroup className='mb-4'>
            <Input
              placeholder='Password...'
              type='password'
              pill
              value={password}
              onChange={onPasswordChange}
              spellCheck={false}
              autoComplete='off'
              invalid={errors.password}
              data-test='password'
              tabIndex={2}
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>
        </div>
        {errors.Login != null && (
          <div className='col-12 text-center mb-4 text-danger fw-bold'>
            {errors.Login}
          </div>
        )}
        <div className='col-12'>
          <Button
            type='submit'
            className='w-100 mb-4'
            color='primary'
            pill
            tabIndex={4}
          >
            Log In
          </Button>
        </div>
        <div className='col-auto m-auto mb-5'>
          <FormGroup groupId='login-remember-me' check='switch'>
            <Input
              type='checkbox'
              checked={rememberMe}
              onChange={onRememberMeChange}
              tabIndex={3}
            />
            <FormLabel check>Keep me signed in on this computer</FormLabel>
          </FormGroup>
        </div>
      </Form>
    </Loading>
  )
}

export {
  LoginForm
}
