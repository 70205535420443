import { useContext } from 'react'
import { ContentstackTargetingContext, type ContentstackTargetingContextType } from './ContentstackTargetingContext'

/**
 * Hook to fetch Contentstack targeting context information
 */
function useContentstackTargetingContext (): ContentstackTargetingContextType {
  return useContext(ContentstackTargetingContext)
}

export {
  useContentstackTargetingContext
}
