import { isArrayEmpty, isStringEmpty } from '@stuller/shared/util/core'
import { CustomShowcaseSettingDetailType } from '@stuller/stullercom/data-access/apollo-queries'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { memo, useEffect } from 'react'

/**
 * Checks if the given domain is allowed based on allowedCSV.
 * If allowedCSV is '*' then all domains are allowed.
 */
function isDomainAllowed (domain: string, allowedCSV: string | null): boolean {
  if (isStringEmpty(domain) || isStringEmpty(allowedCSV)) {
    return false
  }
  if (allowedCSV === '*') {
    return true
  }
  const allowedDomains = allowedCSV.split(',').map((d) => d.trim())

  return allowedDomains.includes(domain)
}

/**
 * Manager to handle the parent domain for the iframe host that uses external carts.
 * This is used to restrict the iframe host to a specific domain as per the csv list.
 */
const IframeHostDomainManager = memo(() => {
  const { getCustomShowcaseSetting } = useAuth()

  useEffect(() => {
    const allowIframeHostApi =
    getCustomShowcaseSetting(CustomShowcaseSettingDetailType.AllowIframeHostApi)?.[0] === 'true'
    const allowedDomainsCSV =
    getCustomShowcaseSetting(CustomShowcaseSettingDetailType.AllowIframeHostApiDomainsCsvList)?.[0] ?? ''

    const storage = window.sessionStorage
    if (!allowIframeHostApi || storage == null) {
      return
    }

    let parentDomain = storage.getItem('IframeHostDomain')

    // Update parentDomain using document.referrer if available.
    if (isStringEmpty(parentDomain)) {
      const match = /^https?:\/\/[^/]+/i.exec(document.referrer)
      if (match != null && !isArrayEmpty(match)) {
        parentDomain = match[0]
        storage.setItem('IframeHostDomain', parentDomain)
      }
    }

    // Remove stored domain if any of the conditions fail.
    if (
      isStringEmpty(parentDomain) ||
      isStringEmpty(allowedDomainsCSV) ||
      !isDomainAllowed(parentDomain, allowedDomainsCSV)
    ) {
      storage.removeItem('IframeHostDomain')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
})
IframeHostDomainManager.displayName = 'IframeHostDomainManager'

export { IframeHostDomainManager }
