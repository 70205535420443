import { type ReactNode, type ReactElement } from 'react'
import { PdpImageCarouselVideo360Player } from './PdpImageCarouselVideo360Player'
import { usePdpImageCarouselContext } from './context/usePdpImageCarouselContext'
import { PdpImageCarouselBadgeSection } from './PdpImageCarouselBadgeSection'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { IMAGE_COMING_SOON } from '@stuller/shared/util/constants'
import { type PdpImageCarouselMediaInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface PdpImageCarouselInnerProps {
  /**
   * Whether the PDP is in the modal or not
   */
  isInModal?: boolean
  /**
   * The children of the component
   */
  children?: ReactNode
}

interface PdpMediaProps {
  /**
   * The media to render
   */
  media: PdpImageCarouselMediaInfoFragment
  /**
   * Whether the PDP is in the modal or not
   */
  isInModal: boolean
}

/**
 * Function Comment
 */
function PdpMedia ({ media, isInModal }: PdpMediaProps): ReactElement | null {
  if (media.__typename === 'Video360') {
    return (
      <PdpImageCarouselVideo360Player video={media} key={media.id} isInModal={isInModal} />
    )
  }

  return null
}

/**
 * Render the main content of the PDP carousel
 */
function PdpImageCarouselInner ({ isInModal = false, children }: PdpImageCarouselInnerProps): ReactElement {
  const { media } = usePdpImageCarouselContext()

  return (
    <div className='border rounded-4 overflow-hidden'>
      <div className='p-5'>
        {!isInModal && (
          <PdpImageCarouselBadgeSection />
        )}
        {isArrayEmpty(media)
          ? (
            <div className='text-center'>
              <img src={IMAGE_COMING_SOON} />
            </div>
          )
          : (
            <>
              {media?.map((mediaItem) => (
                <PdpMedia key={mediaItem.id} media={mediaItem} isInModal={isInModal} />
              ))}
            </>
          )}
      </div>
      {children}
    </div>
  )
}

export {
  PdpImageCarouselInner
}
