export function getItemUrl (
  itemType: 'Product' | 'Configuration' | 'SerializedProduct',
  itemId: string,
  groupId: string | null | undefined,
  recommendationSource?: string | null
): string {
  const searchParams = new URLSearchParams()

  switch (itemType) {
    case 'Product':
      searchParams.append('iid', itemId)
      break
    case 'SerializedProduct':
      searchParams.append('serialNumber', itemId)
      break
    case 'Configuration':
      searchParams.append('configId', itemId)
      break
  }

  if (groupId != null) {
    searchParams.append('gid', groupId)
  }

  if (recommendationSource != null) {
    searchParams.append('recommendationSource', recommendationSource)
  }

  return `/products/details/?${searchParams.toString()}`
}
