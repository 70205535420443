import { StoneRebateType, useGetSerializedPdpPagesStoneRebateStatusQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { type ChartOptions, type ChartData, Chart as ChartJs, ArcElement, DoughnutController, type Plugin, Tooltip, type TooltipModel } from 'chart.js'
import { type ChartJSOrUndefined } from 'node_modules/react-chartjs-2/dist/types'
import { useRef, useState, type ReactElement } from 'react'
import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Card, CardBody } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useAuth } from '@stuller/stullercom/feat/auth'

// Chart.js plugins need to be registered since its an ESM module
ChartJs.register(ArcElement, DoughnutController, ChartDataLabels, Tooltip)

interface StoneRebateGraphProps {
  /**
   * Whether the diamond or gemstone graph is shown
   */
  stoneType: StoneRebateType
}

interface StoneRebateToolTipProps {
  /**
   * The top position of the tooltip
   */
  top: number
  /**
   * The left position of the tooltip
   */
  left: number
  /**
   * The content of the tooltip
   */
  content: string
}

interface ExternalTooltipHandlerArgs {
  /**
   * The chart instance
   */
  chart: ChartJs<'doughnut'>
  /**
   * The tooltip model
   */
  tooltip: TooltipModel<'doughnut'>
}

// Define colors
const finishedBackgroundColor = '#6E9B3B'
const currentTierBackgroundColor = '#BCD9EB'
const defaultBackgroundColors = [
  '#DCDDDF',
  '#D0D2D4',
  '#C7C9CA',
  '#B9B9B9',
  '#AAAAAA',
  '#9D9D9D'
]

/**
 * Custom tooltip component for the Stone Rebate graph
 */
function StoneRebateToolTip ({ top, left, content }: StoneRebateToolTipProps): ReactElement {
  const topOffset = 0
  const leftOffset = 0

  return (
    <Card style={{ position: 'absolute', top: top + topOffset, left: left + leftOffset, minWidth: '180px', pointerEvents: 'none' }}>
      <CardBody>
        {content}
      </CardBody>
    </Card>
  )
}

/**
 * Stone Rebate graph component
 */
function StoneRebateGraph ({ stoneType }: StoneRebateGraphProps): ReactElement | null {
  const chartRef = useRef<ChartJSOrUndefined<'doughnut'>>(null)
  const [tooltipData, setTooltipData] = useState<StoneRebateToolTipProps | null>(null)
  const { authUser } = useAuth()

  // Fetch rebate data
  const { data: rebateData, loading: rebateDataLoading, error: rebateDataError } = useGetSerializedPdpPagesStoneRebateStatusQuery({
    variables: {
      type: stoneType
    }
  })

  // Handle loading and error states
  if (rebateDataLoading || authUser == null || rebateDataError != null) {
    return null
  }

  const tiers = rebateData?.stoneRebateStatus?.tiers
  const stonesPurchased = rebateData?.stoneRebateStatus?.stonesPurchased ?? 0
  const stoneTypeLower = stoneType.toLocaleLowerCase()

  const currentTierIndex = tiers?.findIndex((tier) => stonesPurchased >= (tier.minimumQuantity ?? 0) && stonesPurchased <= (tier.maximumQuantity ?? 0)) ?? 0

  const backgroundColors = defaultBackgroundColors.map((color, index) => {
    if (index === currentTierIndex) {
      return currentTierBackgroundColor
    }
    if (index < currentTierIndex) {
      return finishedBackgroundColor
    }

    return color
  })
  const fontColors = backgroundColors.map((color) => color === finishedBackgroundColor ? '#FFFFFF' : '#323333')

  // Define chart labels and data
  const labels = tiers?.slice(1).map((tier) => `${tier.rebatePercent}%`) ?? []
  const tierSizes = tiers?.slice(0, -1).map((tier) => {
    const minQuantity = tier.minimumQuantity ?? 0
    const start = minQuantity > 0 ? minQuantity : 0
    const end = tier.maximumQuantity ?? 0

    return end - start
  })

  const chartData: ChartData<'doughnut'> = {
    datasets: [
      {
        data: tierSizes ?? [],
        backgroundColor: backgroundColors
      }
    ],
    labels
  }

  /**
   * Custom tooltip handler
   */
  function customTooltipHandler ({ tooltip }: ExternalTooltipHandlerArgs): void {
    if (tooltip.opacity === 0) {
      setTooltipData(null)

      return
    }

    const dataIndex = tooltip.dataPoints?.[0].dataIndex
    const top = tooltip.caretY
    const left = tooltip.caretX

    const rebatePercent = tiers?.[dataIndex + 1].rebatePercent
    const minimumQuantity = tiers?.[dataIndex + 1].minimumQuantity
    let content = ''
    if (dataIndex < currentTierIndex) {
      content = `You’ve purchased ${minimumQuantity} ${stoneTypeLower}s with grading report and unlocked ${rebatePercent}% rebate.`
    } else {
      content = `Purchase ${minimumQuantity} ${stoneTypeLower}s with grading report to unlock ${rebatePercent}% rebate.`
    }
    tooltipData?.top !== top && setTooltipData({ top, left, content })
  }

  // Calculate stones needed for the next tier
  const nextTier = tiers?.[currentTierIndex + 1]
  const stonesNeeded = (nextTier?.minimumQuantity ?? 0) - stonesPurchased
  const stonesNeededText = stonesNeeded > 0
    ? `Purchase ${stonesNeeded} more ${stoneTypeLower}${stonesNeeded > 1 ? 's' : ''} to reach ${nextTier?.rebatePercent}% rebate`
    : `Maximum ${tiers?.[currentTierIndex].rebatePercent}% rebate reached`

  // Chart options
  const options: ChartOptions<'doughnut'> = {
    rotation: -120,
    circumference: 240,
    animation: {
      animateRotate: true
    },
    plugins: {
      legend: {
        display: true
      },
      tooltip: {
        enabled: false,
        external: customTooltipHandler
      },
      title: {
        display: true,
        text: 'Stone Rebate'
      },
      datalabels: {
        formatter: (_, context) => context.chart.data.labels?.[context.dataIndex] ?? '',
        color: fontColors,
        font: {
          weight: 'bold',
          family: 'Roboto'
        }
      }
    }
  }

  // Plugin to draw content around the chart
  const plugins: Plugin<'doughnut'> = {
    id: 'doughnutAfterDraw',
    afterDraw: function (chart) {
      const ctx = chart.ctx
      const width = chart.width
      const height = chart.height

      ctx.restore()
      ctx.font = '300 50px Roboto'
      ctx.textBaseline = 'top'

      // Draw number of stones purchased in the center
      const stonesPurchasedText = stonesPurchased.toString()
      const stonesPurchasedX = Math.round((width - ctx.measureText(stonesPurchasedText).width) / 2)
      const stonesPurchasedY = height / 2 + 5
      ctx.fillText(stonesPurchasedText, stonesPurchasedX, stonesPurchasedY)

      // Draw content under the number of stones purchased
      ctx.font = '400 10px Roboto'
      const line1 = `${stoneType}${stonesPurchased === 1 ? '' : 'S'}`
      const line2 = 'PURCHASED'
      const line1X = Math.round((width - ctx.measureText(line1).width) / 2)
      const line2X = Math.round((width - ctx.measureText(line2).width) / 2)
      const line1Y = stonesPurchasedY + 50
      const line2Y = line1Y + 15
      ctx.fillText(line1, line1X, line1Y)
      ctx.fillText(line2, line2X, line2Y)
      ctx.save()
    }
  }

  // Define chart title and info link based on stone type
  const chartTitle = stoneType === StoneRebateType.Diamond ? 'Diamonds with Grading Report' : 'Notable Gems\u00AE'
  const infoLink = stoneType === StoneRebateType.Diamond ? '/diamondsinfo' : '/aboutnotablegems'

  return (
    <div className='text-center' style={{ width: 'max-content' }}>
      <p className='h5 mb-0'>{chartTitle}</p>
      <div className='position-relative mt-n4 mx-auto' style={{ width: '180px' }}>
        <Doughnut
          ref={chartRef}
          data={chartData}
          options={options}
          plugins={[plugins]}
        />
        {tooltipData != null && (
          <StoneRebateToolTip
            top={tooltipData.top}
            left={tooltipData.left}
            content={tooltipData.content}
          />
        )}
      </div>
      <div className='h5'>
        {stonesNeededText}
        <a className='ms-2 link-dark' href={infoLink} target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={fas.faInfoCircle} />
        </a>
      </div>
    </div>
  )
}

export {
  StoneRebateGraph
}
