import { type ReactNode, type ReactElement } from 'react'
import type { Targeting } from '@stuller/contentstack/util/types'
import { useGetContentstackTargetingActiveCategoriesByIdsQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { ContentstackTargetingContext } from './ContentstackTargetingContext'

interface ContentstackTargetingContextProviderProps {
  /**
   * The Contentstack data for the page/section
   */
  data: any
  /**
   * The children of the component
   */
  children: ReactNode
}

/**
 * Finds all the targeting on a given Contentstack results
 */
function findTargeting (obj: any): Targeting[] {
  const results: any[] = []

  /**
   * Searches through the object recursively to find all instances of targets
   */
  function search (obj: any): void {
    if (obj != null && typeof obj === 'object') {
      for (const key in obj) {
        if (key === 'targetingConnection') {
          const targeting = obj[key]?.edges?.[0]?.node?.targeting
          if (targeting != null) {
            results.push(targeting)
          }
        } else {
          search(obj[key])
        }
      }
    }
  }

  search(obj)

  return results
}

/**
 * Finds all the category exlucde targets in an array of categories to exclude
 */
function findCategoryExcludes (targets: Targeting[]): string[] {
  let results: string[] = []

  for (const target of targets) {
    if (target.targetType === 'CATEGORY_EXCLUDE') {
      results.push(target.targetValue)
    } else if (target.targetType === 'ALL' || target.targetType === 'ANY' || target.targetType === 'NONE') {
      results = results.concat(findCategoryExcludes(target.targetValue))
    }
  }

  return results
}

/**
 * Wrapper for the Contentstack targeting context
 * This should be used any time we are rendering content that may have targeting, especially around modular blocks
 * This provider helps improve performance by pre-fetching data that may be needed for targeting
 */
function ContentstackTargetingContextProvider ({ data, children }: ContentstackTargetingContextProviderProps): ReactElement {
  const targeting = findTargeting(data)

  /**
   * Get active categories needed for targets in data
   */
  const categoryExcludes = findCategoryExcludes(targeting)
  const { loading: activeCategoriesLoading, data: activeCategoriesData } = useGetContentstackTargetingActiveCategoriesByIdsQuery({
    variables: {
      ids: categoryExcludes
    },
    ssr: true,
    skip: categoryExcludes.length === 0
  })
  const activeCategories = categoryExcludes.length === 0 ? [] : activeCategoriesData?.activeCategoriesByIds.map((category) => category?.id)

  return (
    <ContentstackTargetingContext.Provider
      value={{
        activeCategoriesLoading,
        activeCategories
      }}
    >
      {children}
    </ContentstackTargetingContext.Provider>
  )
}

export {
  ContentstackTargetingContextProvider
}
