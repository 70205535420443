import { type ReactNode, type ReactElement } from 'react'
import { PdpImageCarouselContext } from './PdpImageCarouselContext'
import { type PdpImageCarouselInfoFragment, type PdpImageCarouselMediaInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { useToggle } from '@stuller/shared/util/react-hooks'

interface PdpImageCarouselContextProviderProps {
  /**
   * The item being shown in the carousel
   */
  item: PdpImageCarouselInfoFragment
  /**
   * The children of the component
   */
  children: ReactNode
}

/**
 * Wrapper for the PDP image carousel context
 */
function PdpImageCarouselContextProvider ({ item, children }: PdpImageCarouselContextProviderProps): ReactElement {
  const [modalOpen, handleToggleModal] = useToggle(false)

  // Serialized Product specific fields
  let media: PdpImageCarouselMediaInfoFragment[] | undefined
  let isLabGrown = false
  let isGemstone = false
  let serialNumber: string | undefined
  if (item.__typename === 'SerializedProduct') {
    media = item.media ?? []
    isLabGrown = item?.isLabGrown
    serialNumber = item.id
    isGemstone = item.isGemstone
  }

  return (
    <PdpImageCarouselContext.Provider
      value={{
        media,
        isLabGrown,
        serialNumber,
        isGemstone,
        isModalOpen: modalOpen,
        onToggleModal: handleToggleModal
      }}
    >
      {children}
    </PdpImageCarouselContext.Provider>
  )
}

export {
  PdpImageCarouselContextProvider
}
