import { type ReactElement, memo, useEffect, useMemo } from 'react'
import { type MarketRatesFooterProps, useLayout } from '@stuller/stullercom/feat/layout-context'
import { dayjs } from '@stuller/shared/util/core'
import { useGetMarketRatesFooterMarketRatesQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { MarketRate } from './MarketRate'

/**
 * Market rates bottom bar for the site
 */
const MarketRatesFooter = memo(({
  disabled = false
}: MarketRatesFooterProps): ReactElement | null => {
  const { isShowcase, isSterling } = useAuth()
  const { setLayoutState } = useLayout()
  const skip = disabled || isShowcase || isSterling
  const { data, error } = useGetMarketRatesFooterMarketRatesQuery({ skip, variables: { limit: 2 } })
  const hide = useMemo(() => skip || data == null || error != null || data.marketRates.length !== 2, [skip, data, error])

  useEffect(() => {
    setLayoutState((s) => ({ ...s, marketRatesFooterShown: !hide }))
  }, [hide, setLayoutState])

  if (hide) {
    return null
  }

  const [currentRate, previousRate] = data?.marketRates ?? []

  return (
    <div
      data-test='market-rates-footer'
      className='container-fluid position-static position-md-fixed bottom-0 bg-cyan-500 text-white text-center z-fixed d-print-none'
    >
      <div className='row align-items-center justify-content-center p-2'>
        <div className='col-12 col-md-auto fs-7 text-uppercase text-cyan-200 lh-1 py-2 py-md-0'>
          {dayjs.utc(currentRate.date).format('l')} Market Prices:
        </div>
        <MarketRate
          name='Gold'
          currentRate={currentRate.gold}
          previousRate={previousRate.gold}
          url={currentRate.url}
        />
        <MarketRate
          name='Platinum'
          currentRate={currentRate.platinum}
          previousRate={previousRate.platinum}
          url={currentRate.url}
        />
        <MarketRate
          name='Silver'
          currentRate={currentRate.silver}
          previousRate={previousRate.silver}
          url={currentRate.url}
        />
      </div>
    </div>
  )
})
MarketRatesFooter.displayName = 'MarketRatesFooter'

export {
  MarketRatesFooter
}
