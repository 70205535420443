import App from 'next/app'
import { initializeApollo } from '@stuller/stullercom/data-access/apollo-client'
import { getAuthProvider } from '@stuller/stullercom/feat/auth'
import { getSessionId } from './getSessionId'
import { getAuth } from './getAuth'
import { getRefreshAuthUserRedirect } from './getRefreshAuthUserRedirect'
import { getResetPasswordRedirect } from './getResetPasswordRedirect'
import { getSterlingRedirect } from './getSterlingRedirect'
import { getAuthenticatedShowcaseRedirect } from './getAuthenticatedShowcaseRedirect'
import { getShowcaseSettings } from './getShowcaseSettings'
import { getInvalidShowcaseForbidden } from './getInvalidShowcaseForbidden'
import { getUA } from './getUA'
import { getApolloData } from './getApolloData'
import { ensureCartSessionId } from './ensureCartSessionId'
import { handleStatusCode } from './handleStatusCode'
import type { CustomAppContext, CustomAppProps } from './types'

/**
 * Gets server-side (initial load) and client-side (client routed) props for App (auth, user agent, etc.)
 * See https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 */
async function getInitialProps (appContext: CustomAppContext): Promise<CustomAppProps> {
  const { ctx } = appContext

  // Get the user session id
  const userSessionId = getSessionId(ctx)

  // Ensure the cart session id cookie exists as it is required for certain graphql calls
  ensureCartSessionId(ctx)

  // Init apollo
  const apolloClient = initializeApollo(ctx)

  // Get auth
  const auth = await getAuth(ctx, apolloClient, userSessionId)
  ctx.auth = { ...auth, showcaseSettings: null, getCustomShowcaseSetting: () => null }

  let appProps: CustomAppProps = {
    asPath: ctx.asPath,
    ua: getUA(ctx),
    pageProps: undefined,
    host: auth.host,
    authUser: auth.authUser,
    sessionId: auth.sessionId,
    showcaseSettings: null,
    apolloClient
  }

  // Refresh auth user redirect
  const refreshAuthUserRedirect = getRefreshAuthUserRedirect(appContext, auth)
  if (refreshAuthUserRedirect != null) {
    return await handleStatusCode(appContext, { ...appProps, pageProps: refreshAuthUserRedirect })
  }

  // Reset password redirect
  const resetPasswordRedirect = getResetPasswordRedirect(appContext, auth)
  if (resetPasswordRedirect != null) {
    return await handleStatusCode(appContext, { ...appProps, pageProps: resetPasswordRedirect })
  }

  // Sterling redirect
  const sterlingRedirect = getSterlingRedirect(appContext, auth)
  if (sterlingRedirect != null) {
    return await handleStatusCode(appContext, { ...appProps, pageProps: sterlingRedirect })
  }

  // Get showcase settings
  const showcaseSettings = await getShowcaseSettings(apolloClient, auth)
  appProps = { ...appProps, showcaseSettings }

  // Send 403 forbidden when this is a jeweler showcase with missing or incomplete showcaseSettings
  const invalidShowcase = getInvalidShowcaseForbidden(auth, showcaseSettings)
  if (invalidShowcase != null) {
    return await handleStatusCode(appContext, { ...appProps, pageProps: invalidShowcase })
  }

  // Authenticated jeweler showcase redirect
  const authenticatedShowcaseRedirect = getAuthenticatedShowcaseRedirect(appContext, auth, showcaseSettings)
  if (authenticatedShowcaseRedirect != null) {
    return await handleStatusCode(appContext, { ...appProps, pageProps: authenticatedShowcaseRedirect })
  }

  // Get `App` props along with `pageProps`
  ctx.apolloClient = apolloClient
  ctx.auth = getAuthProvider(auth.authUser, auth.host, auth.sessionId, showcaseSettings)
  const appInitialProps = await App.getInitialProps(appContext)
  appProps = { ...appProps, ...appInitialProps }

  // Get Apollo data from tree
  await getApolloData(appContext, appProps)

  // Add Apollo state to App props
  appProps.apolloState = apolloClient.cache.extract()

  // Handle status codes from page props
  await handleStatusCode(appContext, appProps)

  return appProps
}

export {
  getInitialProps
}
