import { type ReactElement } from 'react'

function YourCostPricingSummary (): ReactElement {
  return (
    <div>
      <div className='w-100 d-flex justify-content-center'>
        "Your Cost" Pricing Summary Area
      </div>
    </div>
  )
}

export { YourCostPricingSummary }
