import { useEffect, type ReactElement } from 'react'
import { Button } from '@stuller/stullercom/ui'
import {
  type CartLineMessageInfoFragment,
  useCartPageCartItemDeleteSavedCartLineMutation,
  GetSavedCartLinesDocument
} from '@stuller/stullercom/data-access/apollo-queries'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { useCartContext } from '../shared/useCartContext'
import { ReleaseReservationModal } from './ReleaseReservationModal'
import { useToggle } from '@stuller/shared/util/react-hooks'
import Cookies from 'js-cookie'
import { trackCartLineRemoveFromCart } from '@stuller/stullercom/feat/google-tag-manager'
import { useAuth } from '@stuller/stullercom/feat/auth'
import type { CartLine } from '../shared/types'

interface CartItemRemoveButtonProps {
  /**
   * The cart line data
   */
  cartLine: CartLine
  /**
   * Function to set the error state
   */
  onLoading: (loading: boolean) => void
  /**
   * Function to set the error state
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * If the product is limited availability
   */
  isLimitedAvailability: boolean
  /**
   * If the product is serialized
   */
  isSerializedProduct: boolean
}

interface RemoveFromCartButtonProps {
  onClick: (() => void) | (() => Promise<any>)
}

/**
 * This component is a button that allows the user to remove an item from the cart.
 */
function RemoveButton ({ onClick }: RemoveFromCartButtonProps): ReactElement {
  return (
    <Button className='fw-normal' color='anchor-dark' onClick={onClick} data-test='remove-button'>
      Remove
    </Button>
  )
}

/**
 * Removes the cart item from the cart/saved items
 */
function CartItemRemoveButton ({
  cartLine,
  onLoading,
  onError,
  isLimitedAvailability,
  isSerializedProduct
}: CartItemRemoveButtonProps): ReactElement | null {
  const auth = useAuth()
  const { cartArea, scheduledOrder, useCartContextDeleteCartLineMutation } = useCartContext()
  const [releaseReservationModalIsOpen, toggleReleaseReservationModalIsOpen] = useToggle(false)
  const dontShowReleaseReservationModal = Cookies.get('cart-scheduled-release-reservation-alert-hide')
  const showReleaseReservationModal = (isLimitedAvailability || isSerializedProduct) && dontShowReleaseReservationModal !== 'true'

  /**
   * Mutation to remove a saved cart line from the cart
   */
  const [removeSavedCartLineFromCart, { loading: deleteSavedCartLineLoading }] = useCartPageCartItemDeleteSavedCartLineMutation({
    variables: { input: { savedCartLineId: cartLine.id } },
    onError: (error: Error) => {
      onLoading(false)
      onError([{ code: 'CART_ERROR', message: 'There was an error removing this item from the cart. Refresh the page and try again.' }])
      logger.error('Could not remove item from cart: ', { code: 'CART_ERROR', message: 'There was an error removing this item from the cart. Refresh the page and try again.' }, error)
    },
    onCompleted: () => {
      onError([])
    },
    update: (cache) => {
      // Update count
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          savedCartLines (existingSavedCartLinesRefs, { readField }) {
            const currentCount: number | undefined = readField('count', existingSavedCartLinesRefs)
            const newCount = currentCount != null ? currentCount - 1 : existingSavedCartLinesRefs.count

            return {
              ...existingSavedCartLinesRefs,
              count: newCount
            }
          }
        }
      })

      // Remove the item from the cache
      cache.evict({ id: `SavedCartLine:${cartLine.id}` })
      cache.gc()
    },
    refetchQueries: [GetSavedCartLinesDocument]
  })

  /**
   * Mutation to remove a cart line from the cart
   */
  const [removeCartLineFromCart, { loading: deleteCartLineLoading }] = useCartContextDeleteCartLineMutation({
    variables: { input: { id: cartLine.id } },
    onError: (error: Error) => {
      onLoading(false)
      onError([{ code: 'CART_ERROR', message: 'There was an error removing this item from the cart. Refresh the page and try again.' }])
      logger.error('Could not remove item from cart: ', { code: 'CART_ERROR', message: 'There was an error removing this item from the cart. Refresh the page and try again.' }, error)
    },
    onCompleted: () => {
      onError([])
      if (cartArea === 'cart-page') {
        trackCartLineRemoveFromCart(cartLine, auth)
      }
    },
    update: (cache) => {
      if (cartArea === 'cart-page') {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'cartItemCount' })
      }
      if (cartArea === 'sub-carts-page') {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'showcaseOnlyCarts' })
      }
      if (cartArea === 'jeweler-showcase-carts-page') {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'pendingJewelerShowcaseCallbackRequests', args: { onlyRecent: false } })
      }
      cache.gc()
    }
  })

  useEffect(() => {
    onLoading(deleteSavedCartLineLoading || deleteCartLineLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSavedCartLineLoading, deleteCartLineLoading])

  if (cartArea === 'cart-search-page') {
    return null
  }

  return (
    <>
      <div className='order-1 order-xl-3 me-auto me-xl-0'>
        <RemoveButton onClick={
          scheduledOrder != null && showReleaseReservationModal
            ? toggleReleaseReservationModalIsOpen
            : cartArea === 'saved-items-page'
              ? removeSavedCartLineFromCart
              : removeCartLineFromCart
          }
        />
      </div>
      <ReleaseReservationModal
        isOpen={releaseReservationModalIsOpen}
        onToggle={toggleReleaseReservationModalIsOpen}
        actionType='remove'
        action={removeCartLineFromCart}
      />
    </>
  )
}

export {
  CartItemRemoveButton
}
