import { type ChangeEvent, type ReactElement, useRef, useState } from 'react'
import { useToggle } from '@stuller/shared/util/react-hooks'
import {
  Button,
  DropdownItem,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@stuller/stullercom/ui'
import { type TargetingPreviewer, useTargetingPreviewer } from '@stuller/stullercom/feat/contentstack-targeting'
import { ContentstackTargetingFields } from './ContentstackTargetingFields'

interface ContentstackTargetingPreviewerProps {
  /**
   * Callback to toggle the tools dropdown
   */
  onToggleTools: () => void
}

const targetingPreviewerLabels: Record<keyof NonNullable<TargetingPreviewer>, string> = {
  isAuthenticated: 'Authenticated',
  billToAccount: 'Bill to Account',
  is302Member: '302 Member',
  excludedCategory: 'Category Exclude',
  country: 'Country',
  isEverandEverMember: 'ever&ever member',
  isJewelerShowcase: 'Jeweler Showcase',
  queryStringValues: 'Query String',
  shipToAccount: 'Ship to Account',
  isStullerComShowcaseMode: 'Showcase Mode',
  subdomain: 'Subdomain',
  isShowcase: 'Showcase',
  isSterling: 'Sterling',
  isStullerFirstEligible: 'Stuller First Eligible',
  isStullerFirstEnrolled: 'Stuller First Enrolled',
  isEcommerceMerchandisingMode: 'Ecommerce Merchandising Mode',
  priceList: 'Price List'
}

const targetingPreviewerDefaults: Record<keyof NonNullable<TargetingPreviewer>, string | boolean> = {
  isAuthenticated: false,
  billToAccount: '',
  is302Member: false,
  excludedCategory: '',
  country: '',
  isEverandEverMember: false,
  isJewelerShowcase: false,
  queryStringValues: '',
  shipToAccount: '',
  isStullerComShowcaseMode: false,
  subdomain: '',
  isShowcase: false,
  isSterling: false,
  isStullerFirstEligible: false,
  isStullerFirstEnrolled: false,
  isEcommerceMerchandisingMode: false,
  priceList: ''
}

type TargetingPreviewerKey = keyof TargetingPreviewer

type TargetingPreviewerOverrides = Record<keyof NonNullable<TargetingPreviewer>, boolean>

/**
 * Contentstack targeting previewer
 */
function ContentstackTargetingPreviewer ({ onToggleTools }: ContentstackTargetingPreviewerProps): ReactElement {
  const [targetingPreviewerOpen, handleToggleTargetingPreviewer] = useToggle(false)
  const ref = useRef(typeof window !== 'undefined' ? document.body : undefined)
  const [targetingPreviewer, setTargetingPreviewer] = useTargetingPreviewer()
  const defaultPreviewerState = Object.fromEntries((
    Object.keys(targetingPreviewerDefaults) as TargetingPreviewerKey[]).map((key) => {
    return [key, targetingPreviewer?.[key] ?? targetingPreviewerDefaults[key]]
  }))
  const [targetingPreviewerState, setTargetingPreviewerState] = useState<NonNullable<TargetingPreviewer>>(defaultPreviewerState)
  const targetingPreviewKeys = Object.keys(targetingPreviewerState) as TargetingPreviewerKey[]
  const targetingPreviewerMap = targetingPreviewKeys.map((key) => {
    return {
      key,
      label: targetingPreviewerLabels[key],
      value: targetingPreviewerState[key]
    }
  })

  const defaultState = Object.fromEntries(targetingPreviewKeys.map((key) => [key, targetingPreviewer?.[key] != null])) as TargetingPreviewerOverrides
  const [targetingPreviewerOverrides, setTargetingPreviewerOverrides] = useState<TargetingPreviewerOverrides>(defaultState)

  /**
   * Handle saving the new target previewer settings
   */
  function handleTargetingPreviewerSave (): void {
    const newTargetingPreviewer: TargetingPreviewer = {}
    for (const [key, value] of Object.entries(targetingPreviewerOverrides)) {
      // @ts-expect-error TypeScript don't understand that the key is a valid key of TargetingPreviewer
      newTargetingPreviewer[key] = value ? targetingPreviewerState[key] : undefined
    }
    setTargetingPreviewer(newTargetingPreviewer)
    handleToggleTargetingPreviewer()
  }

  /**
   * Handle changing an override setting
   */
  function handleOverrideChange (e: ChangeEvent<HTMLInputElement>): void {
    if (e.target?.name != null) {
      setTargetingPreviewerOverrides((s) => ({ ...s, [e.target.name]: e.target.checked }))
    }
  }

  /**
   * Handle changing targeting previewer setting
   */
  function handleTargetingPreviewerChange (e: ChangeEvent<HTMLInputElement>): void {
    const { name, type, checked, value } = e.target
    if (name != null) {
      setTargetingPreviewerState((s) => ({ ...s, [name]: type === 'checkbox' ? checked : value }))
    }
  }

  return (
    <>
      <DropdownItem onClick={handleToggleTargetingPreviewer}>
        Contentstack Targeting Previewer
      </DropdownItem>
      <Modal
        isOpen={targetingPreviewerOpen}
        onToggle={handleToggleTargetingPreviewer}
        portalRef={ref}
        onHide={onToggleTools}
        size='lg'
      >
        <ModalHeader onToggle={handleToggleTargetingPreviewer}>
          Contentstack Targeting Previewer
        </ModalHeader>
        <ModalBody>
          <div />
          <Form className='list-group rounded overflow-auto'>
            {targetingPreviewerMap.map((state, index) => (
              <ContentstackTargetingFields
                id={state.key}
                key={index}
                label={state.label}
                handleOverrideChange={handleOverrideChange}
                handleTargetingPreviewerChange={handleTargetingPreviewerChange}
                value={state.value}
                override={targetingPreviewerOverrides[state.key]}
              />
            ))}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleTargetingPreviewerSave} color='primary'>Save</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export {
  ContentstackTargetingPreviewer
}
