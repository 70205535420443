import { type ReactElement } from 'react'
import type { CartLine, ServiceCharges, Shipments } from '../shared/types'
import { Button } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { RemoveAllConfirmationModal } from './RemoveAllConfirmationModal'
import { CustomImprintingSection } from './CustomImprintingSection'
import { CartShipmentList } from '../cart-list/cart-shipment-list/CartShipmentList'

interface CartLinesProps {
  /**
   * The cart lines in the cart
   */
  cartLines: CartLine[]
  /**
   * The shipments in the cart
   */
  shipments?: Shipments
  /**
   * Function to remove all items from the cart
   */
  onRemoveAllItems: () => void
  /**
   * Service charges for the cart
   */
  serviceCharges: ServiceCharges
}

/**
 * Shows the imprinting charges, cart lines, and remove all button
 */
function CartLines ({ cartLines: cartItems, shipments, onRemoveAllItems, serviceCharges }: CartLinesProps): ReactElement | null {
  const [isRemoveAllModalOpen, toggleRemoveAllModal] = useToggle()

  if (shipments == null || shipments.length === 0) {
    return null
  }
  const lines = shipments.flatMap((shipment) => shipment.lines)

  return (
    <>
      <CustomImprintingSection serviceCharges={serviceCharges} />
      <CartShipmentList cartLines={cartItems} shipments={shipments} />
      <Button color='danger' outline='fill' onClick={toggleRemoveAllModal} data-test='remove-all-button'>
        Remove All From My Cart
      </Button>
      <RemoveAllConfirmationModal
        isOpen={isRemoveAllModalOpen}
        onToggle={toggleRemoveAllModal}
        onConfirm={onRemoveAllItems}
        lines={lines}
      />
    </>
  )
}

export {
  CartLines
}
