import { useState, type ChangeEvent } from 'react'
import { CartPageLayout } from '../shared/CartPageLayout'
import { Select, Loading } from '@stuller/stullercom/ui'
import { useGetCustomersWithCartsQuery } from '@stuller/stullercom/data-access/apollo-queries'
import clsx from 'clsx'
import { CartAlert } from '../shared/CartAlert'
import { type UsersWithCart } from '../shared/types'
import { far } from '@awesome.me/kit-3dbd93c064/icons'
import { CartSearchPageCart } from './CartSearchPageCart'
import { CartPageLoadError } from '../shared/CartPageLoadError'
import { type CustomNextPage } from '@stuller/stullercom/feat/layout-context'
import {
  GetCartPageCartSearchPagePermissionsDocument,
  type GetCartPageCartSearchPagePermissionsQuery
} from '@stuller/stullercom/data-access/apollo-queries'

/**
 * The page that allows searching for customer carts
 */
const CartSearchPage: CustomNextPage = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<UsersWithCart>({ id: 'default', cartItemCount: 0 })
  const [loading, setLoading] = useState<boolean>(false)
  const [cartDataExists, setCartDataExists] = useState<boolean>(false)

  /**
   * Fetch customers with carts
   */
  const { data: { customersWithCart } = {}, loading: customersLoading } = useGetCustomersWithCartsQuery()
  const shipmentScheduled = selectedCustomer?.scheduledOrder != null
  const wrapperClassName = clsx(
    !cartDataExists && 'border-bottom'
  )
  const borderClassName = clsx(
    !cartDataExists && 'd-none',
    'position-absolute vw-100 border-1 border-bottom start-50 bottom-0'
  )
  const rowClassName = clsx(
    'position-relative transition-all',
    cartDataExists && 'mb-5',
    (cartDataExists && shipmentScheduled && 'pb-3'),
    (!loading && cartDataExists && !shipmentScheduled && 'pb-5')
  )

  /**
   * Handle the select change event
   */
  function handleSelectChange (event: ChangeEvent<HTMLSelectElement>): void {
    setSelectedCustomer(customersWithCart?.find((customer) => customer.id === event.target.value) ?? { id: 'default', cartItemCount: 0 })
  }

  return (
    <CartPageLayout
      title='Search For Customer Cart'
      activeNavigationLink='cartsearch'
      wrapperClassName={wrapperClassName}
    >
      <Loading loading={customersLoading || loading}>
        <div className={rowClassName}>
          {customersWithCart == null && !customersLoading && <CartPageLoadError cartName='Cart Search' />}
          {customersWithCart != null && customersWithCart.length > 0 && (
            <>
              <label className='fw-bold mb-2 ps-0'>Login</label>
              <Select style={{ maxWidth: 395 }} onChange={handleSelectChange} value={selectedCustomer.id} data-test='select-login-dropdown'>
                <option disabled value='default'>Select a login</option>
                {customersWithCart.map((customer, index) => (
                  <option key={index} value={customer.id}>{customer.id} ({customer.cartItemCount})</option>
                ))}
              </Select>
            </>
          )}
          {cartDataExists && shipmentScheduled && (
            <CartAlert
              type='primary'
              messages={['This cart currently has a scheduled order. No move actions are allowed on this cart.']}
              icon={far.faClock}
              className='mt-5'
            />
          )}
          <div className={borderClassName} style={{ maxWidth: 'initial', transform: 'translateX(-50%)' }} />
        </div>
        <div>
          {customersWithCart != null && customersWithCart.length === 0 && (
            <CartAlert type='primary' messages={['No carts with items found on this account']} />
          )}
          <CartSearchPageCart
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            setLoading={setLoading}
            setCartDataExists={setCartDataExists}
          />
        </div>
      </Loading>
    </CartPageLayout>
  )
}

CartSearchPage.getInitialProps = async (context) => {
  const { apolloClient } = context

  const { data } = await apolloClient.query<GetCartPageCartSearchPagePermissionsQuery>({
    query: GetCartPageCartSearchPagePermissionsDocument
  })

  if (!data.viewerPermissions.canViewConsultantCarts) {
    return { statusCode: 403 }
  }

  return {}
}

CartSearchPage.layout = {
  title: {
    children: 'Shopping Cart Search'
  }
}

export {
  CartSearchPage
}
