import { type ReactElement } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useGetHeaderUtilityNavPermissionsQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { StullerMobileLogo, ShowcaseMobileLogoOrTitle } from './Logo'
import { MenuAccount } from './menu-account/MenuAccount'
import { MenuCart } from './menu-cart/MenuCart'
import { MenuLogin } from './menu-login/MenuLogin'
import { MenuMessages } from './menu-messages/MenuMessages'
import { MenuShowcase } from './menu-showcase/MenuShowcase'
import { MenuLogout } from './menu-logout/MenuLogout'

/**
 * Menu items for utility nav (Cart, account, etc.)
 */
function Menu (): ReactElement | null {
  const {
    authUser,
    isJewelerShowcase,
    isShowcase,
    showcaseSettings
  } = useAuth()
  // Show showcase
  const isArtisanAccount = authUser?.user?.defaultActiveShipToAccount?.priceList?.type === 'Artisan'
  const showShowcase = authUser != null && !isJewelerShowcase && !isArtisanAccount
  // Show messages
  const showMessages = authUser != null && !isShowcase
  // Show cart
  const { data } = useGetHeaderUtilityNavPermissionsQuery({
    ssr: true
  })
  const showCart = data?.viewerPermissions.canViewCart ?? false
  // Show account
  const showAccount = authUser != null && !isShowcase
  // Show login
  const showLogin = authUser == null && !isShowcase
  // Show logout
  const showLogout = isJewelerShowcase && showcaseSettings?.requiresAuthentication === true && authUser != null

  return (
    <ul className='d-flex justify-content-end align-items-center w-100 position-relative list-unstyled ml-auto my-0'>
      {isShowcase ? <ShowcaseMobileLogoOrTitle /> : <StullerMobileLogo />}
      {showShowcase && <MenuShowcase />}
      {showMessages && <MenuMessages />}
      {showCart && <MenuCart />}
      {showAccount && <MenuAccount />}
      {showLogin && <MenuLogin />}
      {showLogout && <MenuLogout />}
    </ul>
  )
}

export {
  Menu
}
