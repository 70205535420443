import { type AlgonomyQueryParams, type AlgonomyUrlOptions, type RecommendationPlacement, type RecommendationPlacementProps, type RecommendationPlacementsResponse, type RecommendationProduct, type RecommendationProductGroupResponse } from './types'
import { siteConfig } from '@stuller/stullercom/util/site-config'

const ALGONOMY_RECOMMENDATION_PLACEMENTS_PATH = '/api/rrPlatform/recsForPlacements'
const ALGONOMY_BASE_URL = siteConfig.NEXT_PUBLIC_ALGONOMY_BASE_URL

/**
 * Builds the response object from the Algonomy API call
 */
export function buildResponse (response: any, userInput: RecommendationPlacementProps[]): RecommendationPlacementsResponse[] | null {
  if (response != null) {
    const placements: RecommendationPlacement[] = response.placements?.map((placement: RecommendationPlacement) => ({
      htmlElementId: placement.htmlElementId,
      placementType: placement.placementType,
      strategyMessage: placement.strategyMessage,
      placement: placement.placement,
      recommendedProducts: buildRecommendedProducts(placement.recommendedProducts)
    }))

    return buildRecommendationPlacementsResponse(placements, userInput)
  }

  return null
}

/**
 * Builds the recommended products from the Algonomy API call
 */
export function buildRecommendedProducts (recommendedProducts: RecommendationProduct[]): RecommendationProduct[] {
  const recommendations: RecommendationProduct[] = []

  if (recommendedProducts != null && recommendedProducts.length > 0) {
    for (const [index, product] of Object.entries(recommendedProducts)) {
      const productUrl = product.productURL != null ? product.productURL.replace('http://www.stuller.com', '') : product.productURL

      recommendations.push({
        clickURL: product.clickURL,
        regionPriceDescription: product.regionPriceDescription,
        rating: product.rating,
        numReviews: product.numReviews,
        priceRangeCents: product.priceRangeCents,
        clickTrackingURL: product.clickTrackingURL,
        regionalProductSku: product.regionalProductSku,
        imageURL: product.imageURL,
        name: product.name,
        genre: product.genre,
        isRecommendable: product.isRecommendable,
        priceCents: product.priceCents,
        id: product.id,
        productURL: productUrl,
        recommendationIndex: Number(index)
      })
    }
  }

  return recommendations
}

/**
 * Builds the recommended placements response
 */
export function buildRecommendationPlacementsResponse (placements: RecommendationPlacement[], userInput: RecommendationPlacementProps[]): RecommendationPlacementsResponse[] {
  const placementsResponse: RecommendationPlacementsResponse[] = []
  let products: RecommendationProductGroupResponse[] = []

  if (placements != null && placements.length > 0) {
    for (const placement of placements) {
      // reset products array for this placement
      products = []

      const userInputQueryParams = userInput.find((p) => p.placementId === placement.placement)

      let querystring = ''
      if (userInputQueryParams != null) {
        for (const [key, value] of Object.entries(userInputQueryParams)) {
          // 'placementId' property of RecommendationPlacementProps is not needed in the querystring
          if (key !== 'placementId') {
            querystring = querystring.length === 0 ? `${key}=${String(value)}` : `&${key}=${String(value)}`
          }
        }
      }

      // For each recommended product, build the product url and add it to the products array
      for (const product of placement.recommendedProducts) {
        // Product url from Algonomy can be null, default to current page
        const recommendations = `&recommendationType=recommendation&recommendationIndex=${product.recommendationIndex}`
        let url = '#'
        if (product.productURL != null) {
          const delimiter = (product.productURL.includes('?')) ? '&' : '?'
          url = `${product.productURL}${delimiter}${querystring}${recommendations}`
        }

        products.push({
          id: product.id,
          title: product.name,
          url,
          clickTrackingURL: product.clickTrackingURL,
          image: product.imageURL,
          recommendationIndex: product.recommendationIndex
        })
      }
      placementsResponse.push({
        elementId: placement.htmlElementId,
        strategyMessage: placement.strategyMessage,
        placement: placement.placement,
        products
      })
    }
  }

  return placementsResponse
}

/**
 * Builds the url for the Algonomy API call
 */
export function buildAlgonomyUrl (recommendationPlacements: RecommendationPlacementProps[], memberId: string | undefined, sessionId: string, options?: AlgonomyUrlOptions): string | undefined {
  const { groupId, categoryId } = options ?? {}
  if (ALGONOMY_BASE_URL != null) {
    const url = new URL(ALGONOMY_BASE_URL + ALGONOMY_RECOMMENDATION_PLACEMENTS_PATH)

    const queryParams: AlgonomyQueryParams = {
      apiKey: '3f9b8c0d349847e7',
      apiClientKey: 'a99211f4b95f7b21',
      categoryData: false,
      excludeHtml: true,
      excludeItemAttributes: true,
      placements: recommendationPlacements.map((placement) => placement.placementId).join('|'),
      sessionId
    }

    // Only add the memberId and groupId if they are defined
    if (memberId != null) {
      queryParams.userId = memberId
    }

    if (groupId != null) {
      queryParams.productId = groupId
    }

    if (categoryId != null) {
      queryParams.categoryId = categoryId
    }

    url.search = new URLSearchParams(queryParams).toString()

    return url.toString()
  }

  return undefined
}
