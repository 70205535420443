import type { ReactElement } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Button, Collapse } from '@stuller/stullercom/ui'
import { CartItemList } from '../CartItemList'
import type { CartLine } from '../../shared/types'
import { useCartContext } from '../../shared/useCartContext'

const LARGE_THUMBNAILS = 7
const SMALL_THUMBNAILS = 3
const LARGE_BREAKPOINT_POINT_CLASSES = 'd-none d-sm-inline d-md-none d-lg-inline'
const SMALL_BREAKPOINT_POINT_CLASSES = 'd-inline d-sm-none d-md-inline d-lg-none'

interface CartShipmentItemListCollapseProps {
  /**
   * The HTML id for the shipment
   */
  index: number
  /**
   * The cart lines to display
   */
  cartLines: CartLine[]
}

interface CollapseThumbnailsProps {
  /**
   * The cart lines to display
   */
  cartLines: CartLine[]
}

interface CollapseThumbnailProps {
  /**
   * The index of the image
   */
  index: number
  /**
   * The cart line to display
   */
  cartLine: CartLine
}

/**
 * Show a single preview thumbnail
 */
function CollapseThumbnail ({ index, cartLine }: CollapseThumbnailProps): ReactElement {
  const classNames = clsx(
    'img-thumbnail me-1',
    index >= SMALL_THUMBNAILS && LARGE_BREAKPOINT_POINT_CLASSES
  )

  return (
    <img
      src={cartLine.item.image ?? ''}
      alt={cartLine.item.title}
      className={classNames}
      width={40}
    />
  )
}

/**
 * Shows the preview thumbnails for the shipment item list
 */
function CollapseThumbnails ({ cartLines }: CollapseThumbnailsProps): ReactElement | null {
  const cartLinesWithImages = cartLines.filter((line) => line.item.image != null)
  const numWithImages = cartLinesWithImages.length
  const numWithoutImages = cartLines.length - numWithImages
  const numLargeLeftover = Math.max(numWithImages - LARGE_THUMBNAILS, 0) + numWithoutImages
  const numSmallLeftover = Math.max(numWithImages - SMALL_THUMBNAILS, 0) + numWithoutImages
  const largeLeftoverClassNames = clsx('ms-2', LARGE_BREAKPOINT_POINT_CLASSES)
  const smallLeftoverClassNames = clsx('ms-2', SMALL_BREAKPOINT_POINT_CLASSES)

  if (numWithImages === 0) {
    return null
  }

  return (
    <>
      {cartLinesWithImages
        .slice(0, LARGE_THUMBNAILS)
        .map((cartLine, index) => <CollapseThumbnail key={cartLine.id} index={index} cartLine={cartLine} />)}
      {numLargeLeftover > 0 && <div className={largeLeftoverClassNames}>+{numLargeLeftover}</div>}
      {numSmallLeftover > 0 && <div className={smallLeftoverClassNames}>+{numSmallLeftover}</div>}
    </>
  )
}

/**
 * Shows the cart shipment item list collapse
 */
function CartShipmentItemListCollapse ({ index, cartLines }: CartShipmentItemListCollapseProps): ReactElement {
  const { shipmentsOpen, handleToggleShipments } = useCartContext()
  const indexString = index.toString()
  const open = shipmentsOpen?.includes(indexString) ?? true

  /**
   * Handle the toggle and scroll to the shipment
   */
  function handleToggle (): void {
    handleToggleShipments?.(indexString)
  }

  return (
    <>
      <div className='row align-items-center'>
        <div className='col'>
          <CollapseThumbnails cartLines={cartLines} />
        </div>
        <div className='col-auto'>
          <Button color='secondary' outline='fill' pill onClick={handleToggle}>
            {open
              ? <><FontAwesomeIcon icon={fas.faMinus} size='lg' /> Hide </>
              : <><FontAwesomeIcon icon={fas.faPlus} size='lg' /> Show </>}
            Details
          </Button>
        </div>
      </div>
      <Collapse isOpen={open}>
        <div className='mt-4'>
          <CartItemList cartLines={cartLines} />
        </div>
      </Collapse>
    </>
  )
}

export {
  CartShipmentItemListCollapse
}
