import { Button, Card, CardBody } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'
import { useAddToCartSectionContext } from '../useAddToCartSectionContext'
import { type AddToCartSectionInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import { getItemUrl } from '@stuller/stullercom/util/core'

interface OneClickQuoteProductCardProps {
  addToCartSectionInfo: AddToCartSectionInfoFragment
}

const usdCultureInfo = { code: 'USD', culture: 'en-US' }

/*
* This component is a card that displays the product information of the product for which a quote is being requested.
*/
function OneClickQuoteProductCard ({ addToCartSectionInfo }: OneClickQuoteProductCardProps): ReactElement {
  const { options } = useAddToCartSectionContext()

  const quantity = options?.quantity?.toString() ?? '1'

  const { price, title, itemNumber, image, __typename: typeName, defaultProductGroup, id } = addToCartSectionInfo
  const isSerializedProduct = addToCartSectionInfo.__typename === 'SerializedProduct'
  const cardTitle = isSerializedProduct ? `Serial #: ${addToCartSectionInfo.serialNumber}` : title
  const { rounded } = price ?? {}
  const exchangeRate = rounded?.total.exchangeRate ?? usdCultureInfo
  const priceDecimal = rounded?.total.value ?? 0
  const [priceDisplay] = useCurrency(priceDecimal, exchangeRate)
  const pricePerCarat = rounded?.caratPrice?.value ?? 0
  const [pricePerCaratDisplay] = useCurrency(pricePerCarat, exchangeRate)

  /**
   * Builds the product url
   */
  const productUrl = typeName !== undefined ? getItemUrl(typeName, id, defaultProductGroup?.id) : ''

  return (
    <Card className='mb-5'>
      <CardBody className='p-4'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='order-1 me-4'>
            <img alt={`image for ${cardTitle}`} src={image ?? ''} style={{ width: '80px' }} />
          </div>
          <div className='order-4 order-sm-2 me-4'>
            <div className='fw-bold'>{cardTitle}</div>
            <Button tag='a' color='link' className='p-0 text-start' href={productUrl}>Item #{itemNumber}</Button>
          </div>
          <div className='w-100 d-block d-sm-none order-3' />
          <div className='d-flex flex-column order-2 order-sm-3 ms-auto'>
            <div className='fw-bold text-end fs-3'>{priceDisplay}</div>
            {pricePerCarat > 0 && (
              <div className='text-end'>
                {pricePerCaratDisplay}/ct
              </div>
            )}
            <div className='text-end'>Quantity: {quantity}</div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          Price does not include sales tax.
        </div>
      </CardBody>
    </Card>
  )
}

export { OneClickQuoteProductCard }
