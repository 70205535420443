import { Fragment, useMemo, useState, type ReactElement, useRef } from 'react'
import { Collapse, Loading } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import {
  type JewelerShowcaseCallbackRequestInfoFragment,
  type CartListHeaderWithEmployeeInfoFragment
} from '@stuller/stullercom/data-access/apollo-queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'
import { CartListCollapseInner } from './CartListCollapseInner'
import { dayjs } from '@stuller/shared/util/core'
import { getJewelerShowcaseUrl } from '@stuller/stullercom/util/core'
import { useCartContext } from '../shared/useCartContext'
import type { CartListCollapseCartListHeader } from '../shared/types'

interface CartListCollapseProps {
  /**
   * The cart
   */
  cartHeader: CartListCollapseCartListHeader | JewelerShowcaseCallbackRequestInfoFragment
  /**
   * The style of the cart item
   */
  style?: React.CSSProperties
}

/**
 * Scroll to the top of the page
 */
function scrollToTop (): void {
  document.documentElement.scrollTop = 0
}

/**
 * This component is a collapsible container that displays the items in a cart
 */
function CartListCollapse ({
  cartHeader,
  style
}: CartListCollapseProps): ReactElement | null {
  const { cartArea } = useCartContext()
  const [cartOpen, toggleCartOpen] = useToggle(false)
  const [containerOpen, toggleContainerOpen, setContainerOpen] = useToggle(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [hasBeenOpened, setHasBeenOpened] = useState<boolean>(false)
  const cartCollapseRef = useRef<HTMLDivElement>(null)
  const collapseContainerClassName = clsx(
    'row bg-gray-100 cursor-pointer d-print-none',
    cartOpen ? 'rounded-top-3' : 'rounded-3'
  )
  const iconClassName = clsx(
    'col-auto bg-blue-300 d-flex align-items-center justify-content-center px-3 px-sm-4',
    cartOpen ? 'rounded-end-3 rounded-bottom-0' : 'rounded-end-3'
  )

  /**
   * The jeweler showcase callback data
   */
  const jewelerShowcaseCallbackData = useMemo<JewelerShowcaseCallbackRequestInfoFragment | null>(() => {
    if (cartHeader.__typename === 'JewelerShowcaseCallbackRequest') {
      return (cartHeader)
    }

    return null
  }, [cartHeader])

  /**
   * The cart list item
   */
  const cartListHeader = useMemo<CartListHeaderWithEmployeeInfoFragment | null>(() => {
    if (cartHeader.__typename === 'JewelerShowcaseCallbackRequest') {
      return cartHeader.cartHeader ?? null
    }

    return cartHeader as CartListHeaderWithEmployeeInfoFragment
  }, [cartHeader])

  const cartCollapseIdString = `cart-collapse-${cartListHeader?.cartId}`
  const itemImages = cartListHeader?.topImages ?? []

  /**
   * Formats the creation date
   */
  const formattedCreationDate = useMemo(() => {
    return (creationDate: string): string => {
      return dayjs(creationDate).format('l, LT')
    }
  }, [])

  /**
   * The jeweler showcase url
   */
  const jewelerShowcaseUrl = useMemo<string | null>(() => {
    const jewelerShowcaseSubdomain = jewelerShowcaseCallbackData?.cartHeader?.user?.showcaseSettings?.jewelerShowcaseSubdomain

    return getJewelerShowcaseUrl(jewelerShowcaseSubdomain)?.replace('https://', '') ?? null
  }, [jewelerShowcaseCallbackData])

  if (cartListHeader == null) {
    return null
  }

  /**
   * Handles the jeweler showcase print
   */
  function handleJewelerShowcasePrint (forCustomer: boolean): void {
    cartCollapseRef.current?.classList.remove('d-print-none')
    if (forCustomer) {
      cartCollapseRef.current?.classList.add('d-print-none-active')
    }
    window.print()
    cartCollapseRef.current?.classList.add('d-print-none')
    cartCollapseRef.current?.classList.remove('d-print-none-active')
  }

  /**
   * Handles completion of remove all and move all mutations
   */
  function handleMutationCompleted (): void {
    toggleContainerOpen()
    scrollToTop()
  }

  if (cartListHeader.itemCount === 0) {
    if (containerOpen) {
      setContainerOpen(false)
    }
  }

  /**
   * Handles toggling the cart list
   */
  function handleToggleCart (): void {
    toggleCartOpen()
    if (!hasBeenOpened) {
      setHasBeenOpened(true)
    }
  }

  return (
    <Collapse isOpen={containerOpen}>
      <div>
        <Loading loading={loading}>
          <div id={cartCollapseIdString} ref={cartCollapseRef} style={style} className='container-xxl mw-xxl mb-3 d-print-none' data-test='cart-login-details-with-items'>
            <div className={collapseContainerClassName} onClick={handleToggleCart}>
              <div className='col p-5'>
                <div className='row'>
                  <div className='col-12 col-lg mb-2 mb-lg-0'>
                    <div className='row'>
                      <div className='col-auto col-lg-12 mb-lg-2 align-items-center d-flex'>
                        <p className='mb-0 fw-bold' data-test='showcase-only-login-id'>
                          {cartArea === 'jeweler-showcase-carts-page' ? `${jewelerShowcaseCallbackData?.firstName} ${jewelerShowcaseCallbackData?.lastName}` : (cartListHeader.lastModifiedByEmployee?.displayName ?? cartListHeader.user?.id)}
                        </p>
                      </div>
                      <div className='col ps-0 ps-lg-3 col-lg-12'>
                        <div className='d-flex align-items-center'>
                          {itemImages.map((image, itemImageIndex) => (
                            <Fragment key={itemImageIndex}>
                              {itemImageIndex <= 4 && (
                                <img
                                  key={itemImageIndex}
                                  src={image}
                                  alt='Product Image'
                                  className='me-2 h-auto border-gray-300 border-1 border rounded-2'
                                  style={{ maxWidth: 28 }}
                                />
                              )}
                            </Fragment>
                          ))}
                          {itemImages.length > 5 && cartListHeader.itemCount != null && (
                            <p className='fs-3 mb-0'>+ {cartListHeader.itemCount - 5}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-auto d-flex flex-column justify-content-center pe-5'>
                    {cartListHeader.createdOn != null && (
                      <p className='fs-6 fs-lg-5 mb-0 fw-bold'>{formattedCreationDate(cartListHeader.createdOn)}</p>
                    )}
                    {cartArea === 'jeweler-showcase-carts-page' && (
                      <p className='fs-6 fs-lg-5 mb-0'>{jewelerShowcaseUrl}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className={iconClassName} data-test='collapse-button'>
                <FontAwesomeIcon
                  icon={fas.faChevronDown}
                  size='xl'
                  className='text-white'
                  style={{
                    transform: cartOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform .3s'
                  }}
                />
              </div>
            </div>
            <div className='row bg-gray-100 rounded-bottom-3'>
              <Collapse isOpen={cartOpen} className='p-0' data-test='collapse-class'>
                <CartListCollapseInner
                  cartId={cartListHeader.cartId}
                  onLoading={setLoading}
                  onJewelerShowcasePrint={handleJewelerShowcasePrint}
                  jewelerShowcaseCallbackData={jewelerShowcaseCallbackData}
                  onMutationCompleted={handleMutationCompleted}
                  hasBeenOpened={hasBeenOpened}
                />
              </Collapse>
            </div>
          </div>
        </Loading>
      </div>
    </Collapse>
  )
}

export {
  CartListCollapse
}
