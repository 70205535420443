import { useEffect, useMemo } from 'react'
import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useGetContentstackTargetingActiveCategoriesByIdsQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { useTargetingPreviewer } from './targetingPreviewer'
import { useContentstackTargetingContext } from './context/useContentstackTargetingContext'

/**
 * Hook to check if a web user is excluding a specific category
 */
function useCategoryExcludeTargeting (targeting: TargetingMap['CATEGORY_EXCLUDE']): boolean {
  const { activeCategoriesLoading, activeCategories } = useContentstackTargetingContext()
  const [targetingPreviewer] = useTargetingPreviewer()
  const skip = activeCategoriesLoading || activeCategories != null
  const { data } = useGetContentstackTargetingActiveCategoriesByIdsQuery({
    variables: {
      ids: targeting.targetValue
    },
    ssr: true,
    skip
  })
  const categoryIncluded = useMemo(() => {
    const categories = [
      ...(activeCategories ?? []),
      ...(data?.activeCategoriesByIds.map((category) => category?.id) ?? [])
    ]

    return categories.includes(targeting.targetValue)
  }, [activeCategories, data?.activeCategoriesByIds, targeting.targetValue])

  // Log if we aren't skipping the query to alert that we might be missing the ContentstackTargetingProvider
  useEffect(() => {
    if (!skip) {
      logger.warn('Contentstack useCategoryExcludeTargeting ran without ContentstackTargetingProvider')
    }
  }, [skip])

  return targetingPreviewer?.excludedCategory != null
    ? targetingPreviewer?.excludedCategory !== targeting.targetValue
    : categoryIncluded
}

export { useCategoryExcludeTargeting }
