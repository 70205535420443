import { useEffect, useRef } from 'react'

/**
 * Wrapper around `setInterval` to call a function at a specified interval
 * Use `useCallback` to memoize the `callback` to avoid unnecessary re-renders
 * Set `delay` to `null` to stop the interval
 */
function useInterval (callback: () => void | Promise<void>, delay: number | null): void {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    // Don't schedule if no delay is specified
    if (delay === null) {
      return
    }

    const interval = setInterval(() => {
      void savedCallback.current()
    }, delay)

    return () => {
      clearInterval(interval)
    }
  }, [delay])
}

export {
  useInterval
}
