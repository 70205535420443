import clsx from 'clsx'
import { type ReactNode, forwardRef } from 'react'

export interface BreadcrumbProps {
  /**
   * Additional class name(s) to give to the containing nav element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Additional class name(s) to give to the list `ol` element
   */
  listClassName?: string
}

/**
 * Breadcrumb nav to indicate the current page’s location within a navigational hierarchy that automatically adds separators via CSS.
 */
const Breadcrumb = forwardRef<HTMLDivElement, BreadcrumbProps>(({
  className,
  children,
  listClassName,
  ...otherAttributes
}, ref) => {
  const listClassNames = clsx('breadcrumb', listClassName)

  return (
    <nav
      className={className}
      aria-label='breadcrumb'
      ref={ref}
      {...otherAttributes}
    >
      <ol className={listClassNames}>
        {children}
      </ol>
    </nav>
  )
})
Breadcrumb.displayName = 'Breadcrumb'

export {
  Breadcrumb
}
