import { useCallback, useState } from 'react'

/**
 * Hook to make it easier to toggle an boolean
 */
function useToggle (initialToggled = false): [boolean, () => void, (toggled: boolean) => void] {
  const [toggled, setToggled] = useState(initialToggled)
  const toggle = useCallback(() => {
    setToggled((t) => !t)
  }, [])

  return [toggled, toggle, setToggled]
}

export {
  useToggle
}
