import { type ReactNode, forwardRef, type ElementType } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

export interface PaginationLinkProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * URL of the link
   */
  href?: string | undefined
  /**
   * Callback function to call when the link is clicked
   */
  onClick?: () => void
}

const PaginationLink = forwardRef<HTMLButtonElement | HTMLAnchorElement, PaginationLinkProps>(({
  className,
  children,
  href,
  onClick,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('page-link', className)

  const Tag = href != null && href !== '' ? Link : 'button' as ElementType

  return (
    <Tag className={classNames} href={href} ref={ref} onClick={onClick} {...otherAttributes}>
      {children}
    </Tag>
  )
})
PaginationLink.displayName = 'PaginationLink'

export {
  PaginationLink
}
