import { type ReactElement } from 'react'
import { ShowcasePricingSummarySectionArea } from './AddToCartPricingSummarySection'
import { type ShowcasePricingSummarySection } from './types'

interface ShowcasePricingSummaryMultipleComponentViewProps {
  sections: ShowcasePricingSummarySection[] | undefined | null
  baseTotal: string
  markedUpTotal: string
}

function ShowcasePricingSummaryMultipleComponentView ({ sections, baseTotal, markedUpTotal }: ShowcasePricingSummaryMultipleComponentViewProps): ReactElement | null {
  const showgrandTotal = (sections?.length ?? 0) > 1

  if (sections == null) {
    return null
  }

  return (
    <div>
      {sections.map((section, index) => <ShowcasePricingSummarySectionArea className={sectionMargin(sections.length, index)} key={index} section={section} />)}
      {showgrandTotal && (
        <>
          <div className='border-bottom border-3 mt-4 mb-2' />
          <div className='row'>
            <div className='col-4 fw-bold'>Grand Total</div>
            <div className='col-4 fw-bold text-end'>{baseTotal}</div>
            <div className='col-4 fw-bold text-end'>{markedUpTotal}</div>
          </div>
        </>
      )}
    </div>
  )
}

function sectionMargin (sectionsLength: number, index: number): string {
  if (sectionsLength > 1 && index + 1 === sectionsLength) {
    return 'mb-0'
  }

  return 'mb-4'
}

export { ShowcasePricingSummaryMultipleComponentView }
