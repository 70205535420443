import { type ChangeEvent, type ReactElement, useContext } from 'react'
import { Form, FormGroup, FormLabel, Input } from '@stuller/stullercom/ui'
import { MessagesPopoverContext } from './MessagesPopoverContext'

function MessagesFilters (): ReactElement {
  const {
    unreadCount,
    unreadImportantOnly,
    setUnreadImportantOnly
  } = useContext(MessagesPopoverContext)
  const unreadCountImportant = unreadCount.data?.viewer?.notificationsUnreadCount.important ?? 0

  function handleUnreadImportantOnly (event: ChangeEvent<HTMLInputElement>): void {
    setUnreadImportantOnly(event.target.checked)
    void unreadCount.refetch()
  }

  return (
    <Form>
      {unreadCountImportant > 0 && (
        <>
          <FormGroup
            check='switch'
            groupId='unread-important-only'
            inline
          >
            <Input type='checkbox' checked={unreadImportantOnly} onChange={handleUnreadImportantOnly} />
            <FormLabel check>
              Unread Alerts Only:
              <span className='text-danger fw-bold'>
                {' '}{unreadCountImportant} Unread Alert{unreadCountImportant > 1 && 's'}
              </span>
            </FormLabel>
          </FormGroup>
          {!unreadImportantOnly && <hr className='mt-0' />}
        </>
      )}
    </Form>
  )
}

export {
  MessagesFilters
}
