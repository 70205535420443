import React, { type ChangeEvent, useState, type ReactElement, useEffect } from 'react'
import { FormGroup, FormLabel, Input, InputGroup, InputGroupText, SelectDropdown, Zone } from '@stuller/stullercom/ui'
import type { CartLine } from '../shared/types'
import {
  type CartLineMessageInfoFragment,
  SalesType,
  useGetDotOptionsPermissionsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useCartContext } from '../shared/useCartContext'
import clamp from 'lodash/clamp'
import { isStringEmpty } from '@stuller/shared/util/core'

interface CartItemDOTOptionsProps {
  /**
   * The cart line object
   */
  cartLine: CartLine
  /**
   * Function to set the loading state
   */
  onLoading: (loading: boolean) => void
  /**
   * Function to set the error state
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * Function to set the success state
   */
  onSuccess: (success: boolean) => void
}

/**
 * Get the discount price based on the price and discount percent
 */
function getDiscountPrice (price: number, discountPercent: number): string {
  if (discountPercent === 0) {
    return ''
  }
  const discountPrice = price * (discountPercent / 100)

  return discountPrice.toFixed(2)
}

/**
 * Helper to get input as number
 */
function getInputAsNumber (input: string): number {
  return input === '' ? 0 : parseFloat(input)
}

const salesTypeOptions = [
  { value: SalesType.CrossSell, label: 'Cross Sell' },
  { value: SalesType.Upsell, label: 'Upsell' },
  { value: SalesType.Substitute, label: 'Substitute' }
]

/**
 * Options for DOT users to be able to change the cart line
 */
function CartItemDOTOptions ({
  cartLine,
  onLoading,
  onError,
  onSuccess
}: CartItemDOTOptionsProps): ReactElement | null {
  const { hasRole } = useAuth()
  const { cartArea, useCartContextUpdateCartLineMutation } = useCartContext()
  const cartLinePrice = cartLine?.price?.total?.value ?? 0
  const cartLineDiscount = cartLine?.options?.consultantDiscount ?? 0
  const currencyCode = cartLine?.price?.total?.exchangeRate?.code
  const formLabelClasses = 'align-content-center col-4 col-sm-3 col-md-4 col-lg-2 col-xl-12'
  const inputClasses = 'col-8 col-sm-9 col-md-8 col-lg-10 col-xl-12'
  const [consultantDiscountPercentInput, setConsultantDiscountPercentInput] = useState<string>(cartLine?.options?.consultantDiscount?.toString() ?? '')
  const [consultantDiscountPriceInput, setConsultantDiscountPriceInput] = useState<string>(getDiscountPrice(cartLinePrice, cartLineDiscount))
  const [matchingCodeInput, setMatchingCodeInput] = useState<string>(cartLine?.options?.matchingCode ?? '')
  const [salesTypeInput, setSalesTypeInput] = useState<SalesType | undefined>(cartLine?.options?.salesType ?? undefined)

  const { data: permissionsData, loading: permissionsLoading } = useGetDotOptionsPermissionsQuery({
    onError: (error) => {
      logger.error('Error getting DOT options permissions', {}, error)
    }
  })

  /**
   * Mutation to update the cart line
   */
  const [handleUpdateCartLine, { loading: updateCartLineLoading }] = useCartContextUpdateCartLineMutation({
    onError: (error) => {
      onError([{ code: 'CART_ERROR', message: 'There was an issue saving your options' }])
      logger.error('Error updating DOT options on cart line', {}, error)
      setConsultantDiscountPercentInput(cartLine?.options?.consultantDiscount?.toString() ?? '')
      setConsultantDiscountPriceInput(getDiscountPrice(cartLinePrice, cartLineDiscount))
      setMatchingCodeInput(cartLine?.options?.matchingCode ?? '')
      setSalesTypeInput(cartLine?.options?.salesType ?? undefined)
    },
    onCompleted: () => {
      onError([])
      onSuccess(true)
    }
  })

  const { canSetOrderLineConsultantDiscounts, canSetOrderLineMatching, canSetOrderLineSalesType } = permissionsData?.viewerPermissions ?? {}
  const hasProductDiscount = (cartLine?.price?.discount?.value?.value ?? 0) > 0
  const hasProductConfig = cartLine.item.__typename === 'Configuration'
  const canEditConsultantDiscount = canSetOrderLineConsultantDiscounts === true && !hasProductDiscount && !hasProductConfig

  function handleDiscountPriceChange (e: ChangeEvent<HTMLInputElement>): void {
    setConsultantDiscountPriceInput(e.target.value)
  }

  async function handleDiscountPriceBlur (): Promise<void> {
    const percentInputAsNumber = getInputAsNumber(consultantDiscountPercentInput)
    const priceInputAsNumber = getInputAsNumber(consultantDiscountPriceInput)
    const calculatedPrice = getDiscountPrice(cartLinePrice, percentInputAsNumber)
    const discountPercent = priceInputAsNumber / cartLinePrice * 100
    const shouldUpdate = (getInputAsNumber(calculatedPrice) !== priceInputAsNumber)
    setConsultantDiscountPercentInput(discountPercent === 0 ? '' : discountPercent?.toFixed(4))
    if (shouldUpdate) {
      await handleUpdateCartLine({
        variables: {
          input: {
            cartLineId: cartLine.id,
            fieldsToUpdate: ['CONSULTANT_DISCOUNT'],
            options: {
              consultantDiscount: consultantDiscountPriceInput === '' ? null : clamp(discountPercent, 0, 100)
            }
          }
        }
      })
    }
  }

  function handleDiscountPercentChange (e: ChangeEvent<HTMLInputElement>): void {
    setConsultantDiscountPercentInput(e.target.value)
  }

  async function handleDiscountPercentBlur (): Promise<void> {
    const inputAsNumber = getInputAsNumber(consultantDiscountPercentInput)
    if (inputAsNumber !== cartLineDiscount) {
      setConsultantDiscountPercentInput(inputAsNumber === 0 ? '' : inputAsNumber.toString())
      setConsultantDiscountPriceInput(getDiscountPrice(cartLinePrice, inputAsNumber))
      await handleUpdateCartLine({
        variables: {
          input: {
            cartLineId: cartLine.id,
            fieldsToUpdate: ['CONSULTANT_DISCOUNT'],
            options: {
              consultantDiscount: consultantDiscountPercentInput === '' ? null : clamp(inputAsNumber, 0, 100)
            }
          }
        }
      })
    }
  }

  function handleMatchingCodeChange (e: ChangeEvent<HTMLInputElement>): void {
    setMatchingCodeInput(e.target.value)
  }

  async function handleMatchingCodeBlur (): Promise<void> {
    await handleUpdateCartLine({
      variables: {
        input: {
          cartLineId: cartLine.id,
          fieldsToUpdate: ['MATCHING_CODE'],
          options: {
            matchingCode: isStringEmpty(matchingCodeInput) ? null : matchingCodeInput
          }
        }
      }
    })
  }

  async function handleSalesTypeChange (salesTypeOption: { value: SalesType } | null): Promise<void> {
    setSalesTypeInput(salesTypeOption?.value)
    await handleUpdateCartLine({
      variables: {
        input: {
          cartLineId: cartLine.id,
          fieldsToUpdate: ['SALES_TYPE'],
          options: {
            salesType: salesTypeOption?.value
          }
        }
      }
    })
  }

  /**
   * Reset state on change
   */
  useEffect(() => {
    setConsultantDiscountPercentInput(cartLine?.options?.consultantDiscount?.toString() ?? '')
    setConsultantDiscountPriceInput(getDiscountPrice(cartLinePrice, cartLineDiscount))
    setMatchingCodeInput(cartLine?.options?.matchingCode ?? '')
    setSalesTypeInput(cartLine?.options?.salesType ?? undefined)
  }, [
    cartLine?.options?.consultantDiscount,
    cartLinePrice,
    cartLineDiscount,
    cartLine?.options?.matchingCode,
    cartLine?.options?.salesType
  ])

  /**
   * Handle updating parent loading state
   */
  useEffect(() => {
    onLoading(updateCartLineLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCartLineLoading])

  if (
    !hasRole('Consultant Mode') ||
    cartArea !== 'cart-page' ||
    (canSetOrderLineConsultantDiscounts === false && canSetOrderLineMatching === false && canSetOrderLineSalesType === false) ||
    (!permissionsLoading && canSetOrderLineConsultantDiscounts == null && canSetOrderLineMatching == null && canSetOrderLineSalesType == null)
  ) {
    return null
  }

  return (
    <Zone color='consultant-mode' className='mt-3 p-4'>
      <div className='row gy-3'>
        {canEditConsultantDiscount && (
          <>
            <div className='col-xl'>
              <FormGroup groupId={`consultantDiscountPrice-${cartLine.id}`}>
                <div className='row'>
                  <FormLabel className={formLabelClasses}>Discount $ {currencyCode != null && `(${currencyCode})`}</FormLabel>
                  <InputGroup className={inputClasses}>
                    <InputGroupText>$</InputGroupText>
                    <Input
                      type='number'
                      value={consultantDiscountPriceInput}
                      onChange={handleDiscountPriceChange}
                      onBlur={handleDiscountPriceBlur}
                    />
                  </InputGroup>
                </div>
              </FormGroup>
            </div>
            <div className='col-xl'>
              <FormGroup groupId={`consultantDiscountPercent-${cartLine.id}`}>
                <div className='row'>
                  <FormLabel className={formLabelClasses}>Discount %</FormLabel>
                  <InputGroup className={inputClasses}>
                    <Input
                      type='number'
                      value={consultantDiscountPercentInput}
                      onChange={handleDiscountPercentChange}
                      onBlur={handleDiscountPercentBlur}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </div>
              </FormGroup>
            </div>
          </>
        )}
        {canSetOrderLineMatching === true && (
          <div className='col-xl'>
            <FormGroup groupId={`consultantMatchingCode-${cartLine.id}`}>
              <div className='row'>
                <FormLabel className={formLabelClasses}>Matching Code</FormLabel>
                <div className={inputClasses}>
                  <Input
                    type='text'
                    value={matchingCodeInput}
                    onChange={handleMatchingCodeChange}
                    onBlur={handleMatchingCodeBlur}
                  />
                </div>
              </div>
            </FormGroup>
          </div>
        )}
        {canSetOrderLineSalesType === true && (
          <div className='col-xl'>
            <FormGroup groupId={`consultantSalesType-${cartLine.id}`}>
              <div className='row'>
                <FormLabel className={formLabelClasses}>Sales Type</FormLabel>
                <div className={inputClasses}>
                  <SelectDropdown
                    options={salesTypeOptions}
                    value={salesTypeOptions.find((option) => option.value === salesTypeInput)}
                    onChange={handleSalesTypeChange}
                    isClearable
                    key={salesTypeInput}
                  />
                </div>
              </div>
            </FormGroup>
          </div>
        )}
      </div>
    </Zone>
  )
}

export {
  CartItemDOTOptions
}
