import { Button, FormLabel, Modal, ModalBody, ModalHeader, Input, FormGroup, ModalFooter } from '@stuller/stullercom/ui'
import { type ReactElement, useRef, type ChangeEvent, useState } from 'react'
import Cookies from 'js-cookie'
import type { FetchResult } from '@apollo/client'

interface ReleaseReservationModalProps {
  /**
   * If the modal is open
   */
  isOpen: boolean
  /**
   * Function to toggle the modal
   */
  onToggle: () => void
  /**
   * The type of action to be performed
   */
  actionType: 'remove' | 'save'
  /**
   * The mutation to be executed
   */
  action: () => Promise<FetchResult>
}

/**
 * This modal confirms if a user wants to remove an item from their scheduled cart
 */
function ReleaseReservationModal ({ isOpen, onToggle, actionType, action }: ReleaseReservationModalProps): ReactElement {
  const [dontShowAgainChecked, setDontShowAgainChecked] = useState(false)
  const ref = useRef(typeof window !== 'undefined' ? document.body : undefined)

  /**
   * Handler for the checkbox onChange event
   */
  function handleCheckboxOnChange (e: ChangeEvent<HTMLInputElement>): void {
    setDontShowAgainChecked(e.target.checked)
  }

  /**
   * Handler for the remove item / save for later button click event
   */
  async function handleRemoveButtonClick (): Promise<void> {
    // close modal (since cart item has loading state)
    onToggle()

    // run the mutation
    try {
      await action()

      // set cookie if checkbox is checked
      if (dontShowAgainChecked) {
        Cookies.set('cart-scheduled-release-reservation-alert-hide', 'true', { expires: 365 })
      }
    } catch (error) {}
  }

  return (
    <Modal isOpen={isOpen} onToggle={onToggle} portalRef={ref}>
      <ModalHeader tag='h1' className='pt-6' onToggle={onToggle}>
        Remove & Release Reservation
      </ModalHeader>
      <ModalBody>
        <p><b>Removing this item will release the reservation.</b> Removing items from a scheduled order will release the reservation, making it available to others.</p>
        <p className='mb-5'>Items removed from a scheduled order cannot be added back to the cart for a short period of time.</p>
        <FormGroup
          check
          groupId='dont-show-again'
          className='w-100 justify-content-end d-flex'
        >
          <Input type='checkbox' onChange={handleCheckboxOnChange} />
          <FormLabel check>
            Don't show this again
          </FormLabel>
        </FormGroup>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <div className='d-flex justify-content-end'>
          <Button color='link-dark' onClick={onToggle}>Keep Item in Cart</Button>
          <Button
            color={actionType === 'remove' ? 'danger' : 'primary'}
            outline={actionType === 'remove'}
            onClick={handleRemoveButtonClick}
            className='fw-bold'
          >
            {actionType === 'remove' ? 'Remove Item' : 'Save for Later'}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export {
  ReleaseReservationModal
}
