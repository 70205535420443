import { useContext } from 'react'
import { CartShipmentContext, type CartShipmentContextType } from './CartShipmentContext'

/**
 * Hook to fetch Cart shipment context information
 */
function useCartShipmentContext (): CartShipmentContextType {
  return useContext(CartShipmentContext)
}

export {
  useCartShipmentContext
}
