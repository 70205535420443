import { type ReactElement, useMemo } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useGetFooterShowcaseFooterMarketRatesQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { dayjs } from '@stuller/shared/util/core'
import {
  SHOWCASE_MARKET_RATES_GOLD_ICON,
  SHOWCASE_MARKET_RATES_PLATINUM_ICON,
  SHOWCASE_MARKET_RATES_SILVER_ICON
} from '@stuller/shared/util/constants'
import { ShowcaseMarketRate } from './ShowcaseMarketRate'

/**
 * Showcase market rates
 */
function ShowcaseMarketRates (): ReactElement | null {
  const { showcaseSettings } = useAuth()
  const showMetalMarkets = showcaseSettings?.showMetalMarkets === true
  const { data, error } = useGetFooterShowcaseFooterMarketRatesQuery({ skip: !showMetalMarkets, variables: { limit: 2 } })
  const hide = useMemo(() => !showMetalMarkets || data == null || error != null || data.marketRates.length !== 2, [showMetalMarkets, data, error])

  if (hide) {
    return null
  }

  const [currentRate, previousRate] = data?.marketRates ?? []

  return (
    <div className='container-xxxl mw-xl px-4 pt-5 pb-4'>
      <div className='row pb-3'>
        <div className='col-12 text-center'>
          Market prices as of {dayjs.utc(currentRate.date).format('l')}
        </div>
      </div>
      <div className='row justify-content-evenly'>
        <ShowcaseMarketRate
          name='GOLD'
          currentRate={currentRate.gold}
          previousRate={previousRate.gold}
          url={currentRate.url}
          icon={SHOWCASE_MARKET_RATES_GOLD_ICON}
        />
        <ShowcaseMarketRate
          name='PLATINUM'
          currentRate={currentRate.platinum}
          previousRate={previousRate.platinum}
          url={currentRate.url}
          icon={SHOWCASE_MARKET_RATES_PLATINUM_ICON}
        />
        <ShowcaseMarketRate
          name='SILVER'
          currentRate={currentRate.silver}
          previousRate={previousRate.silver}
          url={currentRate.url}
          icon={SHOWCASE_MARKET_RATES_SILVER_ICON}
        />
      </div>
    </div>
  )
}

export {
  ShowcaseMarketRates
}
