import { type ReactElement } from 'react'
import type { CartLine, Shipments } from '../../shared/types'
import { CartShipment } from './CartShipment'
import { useCartContext } from '../../shared/useCartContext'
import { CartItemList } from '../CartItemList'

interface CartShipmentListProps {
  /**
   * The items in the cart lines
   */
  cartLines: CartLine[]
  /**
   * The shipments in the cart
   */
  shipments: Shipments
}

/**
 * Shows the shipments in the cart
 */
function CartShipmentList ({ cartLines, shipments }: CartShipmentListProps): ReactElement | null {
  const { scheduledOrder } = useCartContext()

  // If not scheduled order and cart has lines, just render the lines
  if (scheduledOrder == null) {
    return <CartItemList cartLines={cartLines} />
  }

  return (
    <>
      {shipments.map((shipment, index) => (
        <CartShipment
          key={index}
          index={index}
          shipment={shipment}
          shipments={shipments}
        />
      ))}
    </>
  )
}

export {
  CartShipmentList
}
