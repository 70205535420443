import React, { type ReactElement, Fragment } from 'react'
import { CartItemConfigurationCard } from './CartItemConfigurationCard'
import { CartItemConfigurationProduct } from './CartItemConfigurationProduct'
import { type StoneSettingConfigurationDetail, type HeadConfigurationDetail } from '../../shared/types'
import { consolidateStoneSettings, returnGemstoneSetString } from './CartItemConfigurationSummary'

interface CartItemConfigurationHeadProps {
  /**
   * The head data
   */
  headData: HeadConfigurationDetail
}

function CartItemConfigurationHead ({
  headData
}: CartItemConfigurationHeadProps): ReactElement {
  return (
    <>
      {headData.head != null && (
        <CartItemConfigurationCard
          title='Peg Setting'
          description='To be soldered together with the shank'
        >
          <CartItemConfigurationProduct
            image={headData.head.product.image}
            title={headData.head.product.title}
            itemNumber={headData.head.product.itemNumber}
            id={headData.head.product.id}
            isActive={headData.head.product.isWebActive}
          />
        </CartItemConfigurationCard>
      )}
      {headData.head.details != null && headData.head.details.length > 0 && (
        <>
          {headData.head.details.map((headDetail, index) => (
            <Fragment key={index}>
              {headDetail.__typename === 'StoneSettingConfigurationDetail' && headDetail != null && (
                <>
                  {headDetail.stoneSettings != null && headDetail.stoneSettings.length > 0 && (
                    <CartItemConfigurationCard
                      title='Diamonds/Gemstones'
                      description={returnGemstoneSetString(headDetail as StoneSettingConfigurationDetail)}
                    >
                      {consolidateStoneSettings(headDetail as StoneSettingConfigurationDetail).map((stoneSetting, index) => (
                        <Fragment key={index}>
                          {stoneSetting.stone != null && stoneSetting.stone.__typename === 'Product' && (
                            <CartItemConfigurationProduct
                              key={index}
                              image={stoneSetting.stone.image}
                              title={stoneSetting.stone.title}
                              itemNumber={stoneSetting.stone.itemNumber}
                              id={stoneSetting.stone.id}
                              isActive={stoneSetting.stone.isWebActive}
                              quantity={stoneSetting.quantity}
                              paddingBottom={index !== headDetail.stoneSettings.length - 1}
                            />
                          )}
                        </Fragment>
                      ))}
                    </CartItemConfigurationCard>
                  )}
                </>
              )}
            </Fragment>
          ))}
        </>
      )}
    </>
  )
}

export {
  CartItemConfigurationHead
}
