import { type ReactNode, type ReactElement, forwardRef, useRef } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import { Button, Card, CardBody, Offcanvas, OffcanvasBody } from '@stuller/stullercom/ui'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'
import { ShippingCountdown } from '@stuller/stullercom/feat/shipping-countdown'
import { useCurrency, useToggle } from '@stuller/shared/util/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { CartSummaryAlerts } from './CartSummaryAlerts'
import { CartSummaryTotals } from './CartSummaryTotals'
import { CartSummaryButtons } from './CartSummaryButtons'
import type { Cart, CartLine } from '../shared/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CartSummaryEmptyScheduledCart } from './CartSummaryEmptyScheduledCart'
import { useCartContext } from '../shared/useCartContext'
import { ScheduledOrdersDisclaimer } from './ScheduledOrdersDisclaimer'

interface CartSummaryWrapperProps {
  /**
   * The children of the wrapper
   */
  children?: ReactNode
}

interface CartSummaryProps {
  /**
   * The full cart query
   */
  cart?: Cart
  /**
   * If showing the empty cart
   */
  showEmptyCart: boolean
  /**
   * The scheduled order cutoff time
   */
  scheduledOrderCutoffTime: string | null
  /**
   * The cart lines in the cart
   */
  cartLines: CartLine[]
}

type CartSummaryInnerProps = CartSummaryProps & {
  /**
   * Indicates the cart summary is being rendered in the offcanvas
   */
  offcanvas?: boolean
}

type CartSummaryOffcanvasProps = CartSummaryProps & {
  /**
   * Indicates that the desktop cart summary is visible
   */
  cartSummaryVisible: boolean
}

/**
 * A wrapper for the cart page summary for consistent width for the overlay/sticky summary
 */
function CartSummaryWrapper ({ children }: CartSummaryWrapperProps): ReactElement {
  return (
    <div>
      {children}

      <style jsx>{`
        @media (min-width: ${variables.breakpointMd}) {
          div {
            width: 350px;
          }
        }
      `}
      </style>
    </div>
  )
}

/**
 * The inner guts of the cart summary (without card or card body)
 */
const CartSummaryInner = forwardRef<HTMLDivElement, CartSummaryInnerProps>(({ cart, offcanvas = false, showEmptyCart, scheduledOrderCutoffTime, cartLines }, ref) => {
  const { isJewelerShowcase } = useAuth()
  const { scheduledOrder } = useCartContext()

  return (
    <>
      {showEmptyCart
        ? (
          <CartSummaryEmptyScheduledCart scheduledOrderCutoffTime={scheduledOrderCutoffTime} />
        )
        : (
          <>
            <CartSummaryAlerts shipments={cart?.shipments} cartLines={cartLines} />
            <h2 className='mb-2' data-test='cart-summary'>
              {scheduledOrder != null ? 'Estimated Charges' : `${isJewelerShowcase ? 'Cart' : 'Order'} Summary`}
            </h2>
            <CartSummaryTotals cart={cart} ref={ref} />
            <CartSummaryButtons cart={cart} offcanvas={offcanvas} cartIsEmpty={showEmptyCart} />
            {cart?.shippingCountdown != null && <ShippingCountdown countdown={cart.shippingCountdown} className='mt-5' portalBody />}
            {scheduledOrder != null && (
              <ScheduledOrdersDisclaimer />
            )}
          </>
        )}
    </>
  )
})
CartSummaryInner.displayName = 'CartSummaryInner'

/**
 * The offcanvas version of the cart page summary for mobile
 */
function CartSummaryOffcanvas ({ cart, cartSummaryVisible, showEmptyCart, scheduledOrderCutoffTime, cartLines }: CartSummaryOffcanvasProps): ReactElement {
  const { isJewelerShowcase } = useAuth()
  const [isOpen, toggleIsOpen] = useToggle()
  const [total] = useCurrency(cart?.price?.total.value, cart?.price?.total.exchangeRate)
  const portalRef = useRef(typeof window !== 'undefined' ? document.body : undefined)
  const classNames = clsx(
    'd-md-none position-sticky z-fixed bottom-0 w-100 bg-gray-100 border-top rounded-top-4 px-4 pt-4 pb-6 shadow',
    cartSummaryVisible && 'd-none'
  )

  return (
    <>
      {portalRef.current != null && (
        createPortal(
          (
            <div className={classNames}>
              <div className='row justify-content-center'>
                <div className='col-auto text-end'>
                  <div className='fs-6'>Est. Total</div>
                  <div className='fw-bold'>{total}</div>
                </div>
                <div className='col-8'>
                  <Button onClick={toggleIsOpen} color='secondary' outline='fill' className='w-100'>
                    Show {isJewelerShowcase ? 'Cart' : 'Order'} Summary
                  </Button>
                </div>
              </div>
            </div>
          ),
          portalRef.current
        ))}

      <Offcanvas
        isOpen={isOpen}
        onToggle={toggleIsOpen}
        placement='bottom'
        size='md'
        className='d-flex d-md-none border-top rounded-top-4 bg-gray-100'
      >
        <OffcanvasBody className='px-6 pb-6'>
          <div className='text-center pb-3'>
            <Button onClick={toggleIsOpen} color='anchor-dark' className='fs-6'>
              Collapse
              <FontAwesomeIcon icon={fas.faAngleDown} className='ps-1' size='xl' />
            </Button>
          </div>
          <CartSummaryInner
            cart={cart}
            cartLines={cartLines}
            offcanvas
            showEmptyCart={showEmptyCart}
            scheduledOrderCutoffTime={scheduledOrderCutoffTime}
          />
        </OffcanvasBody>
      </Offcanvas>
    </>
  )
}

/**
 * Shows the cart page summary with pricing, shipping, etc.
 */
const CartSummary = forwardRef<HTMLDivElement, CartSummaryProps>(({ cart, showEmptyCart, scheduledOrderCutoffTime, cartLines }, ref) => {
  return (
    <CartSummaryWrapper>
      <Card className='p-3 border-0 shadow-sm'>
        <CardBody data-test='cart-summary-section'>
          <CartSummaryInner
            cart={cart}
            cartLines={cartLines}
            ref={ref}
            showEmptyCart={showEmptyCart}
            scheduledOrderCutoffTime={scheduledOrderCutoffTime}
          />
        </CardBody>
      </Card>
    </CartSummaryWrapper>
  )
})
CartSummary.displayName = 'CartSummary'

export {
  CartSummaryWrapper,
  CartSummary,
  CartSummaryOffcanvas
}
