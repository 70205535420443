import { Nav, NavItem, NavLink } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'

interface AddToCartPricingSummaryTabsProps {
  currentTab: string | string[] | null
  onTabChange: (tab: string) => void
  className?: string
}

function AddToCartPricingSummaryTabs ({ currentTab, onTabChange, className }: AddToCartPricingSummaryTabsProps): ReactElement | null {
  return (
    <Nav tabs className={className}>
      <NavItem>
        <NavLink tag='button' active={currentTab === 'yourCost'} onClick={() => { onTabChange('yourCost') }}>
          <div>
            Your Cost
          </div>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag='button' active={currentTab === 'showcasePricing'} onClick={() => { onTabChange('showcasePricing') }}>
          <div>
            Showcase Pricing
          </div>
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export { AddToCartPricingSummaryTabs }
