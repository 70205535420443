import { type ReactElement } from 'react'
import { type InStockStatusInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { StatusTopSection } from './StatusTopSection'
import { EstimatedShipDateSection } from './EstimatedShipDateSection'

interface WhileSuppliesLastStatusProps {
  /**
   * in-stock status info, including backorder and onhand quantity
   */
  inStockStatus: InStockStatusInfoFragment['inStockStatus']
  /**
    * context-based human readable ship date
    */
  readableShipDate?: string
  /**
    * whether or not the product is returnable
    */
  isReturnable: boolean
  /**
    * whether or not the ship date is today
    */
  isShipDateToday: boolean
}

function WhileSuppliesLastStatus ({ inStockStatus, isReturnable, isShipDateToday, readableShipDate }: WhileSuppliesLastStatusProps): ReactElement {
  return (
    <>
      <StatusTopSection inStockStatusCode={inStockStatus.statusCode} isReturnable={isReturnable} color='blue'>
        {inStockStatus.status}
      </StatusTopSection>
      <EstimatedShipDateSection
        isShipDateToday={isShipDateToday}
        readableShipDate={readableShipDate}
      />
    </>
  )
}

export {
  WhileSuppliesLastStatus
}
