import { type ReactElement } from 'react'

interface AddToCartErrorToastProps {
  messages?: string[]
}

/**
 * Error toast notification after adding a product to cart
 */
function AddToCartErrorToast ({
  messages = ['There was an error adding this item to the cart. Refresh the page and try again.']
}: AddToCartErrorToastProps): ReactElement {
  return (
    <div className='text-body'>
      {messages.map((message, index) => (
        <div className='lh-sm' key={index}>{message}</div>
      ))}
    </div>
  )
}

export {
  AddToCartErrorToast
}
