import { useState } from 'react'
import {
  useCartPageCartItemDeleteCartLineWithCartListItemMutation,
  useGetConsultantCartsQuery,
  useCartPageCartItemUpdateCartLineWithCartListItemMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { CartPageLayout } from '../shared/CartPageLayout'
import { CartListCollapseList } from '../cart-list/CartListCollapseList'
import { Loading, UncontrolledPagination, type UncontrolledPaginationProps } from '@stuller/stullercom/ui'
import { CartEmptyState } from '../shared/CartEmptyState'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { CartContext } from '../shared/CartContext'
import { CartPageLoadError } from '../shared/CartPageLoadError'
import { type CustomNextPage } from '@stuller/stullercom/feat/layout-context'
import {
  GetCartPageDotCartsPagePermissionsDocument,
  type GetCartPageDotCartsPagePermissionsQuery
} from '@stuller/stullercom/data-access/apollo-queries'

/**
 * Number of items to display per page
 */
const pageLimit = 8

/**
 * The page that will show all the DOT carts
 */
const DotCartsPage: CustomNextPage = () => {
  const [pageNumber, setPageNumber] = useState(1)

  const { data, loading } = useGetConsultantCartsQuery({
    variables: {
      paging: {
        limit: pageLimit,
        page: pageNumber
      }
    },
    fetchPolicy: 'network-only'
  })
  const itemCount = data?.consultantCarts?.cartHeaders?.length ?? 0

  /**
   * Handles the pagination change and scrolls to the top of the page
   */
  function handleBottomPaginationChange (...args: Parameters<NonNullable<UncontrolledPaginationProps['onChange']>>): void {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
    setPageNumber(...args)
  }

  const dotCartHeaders = data?.consultantCarts.cartHeaders

  return (
    <CartPageLayout title='Other Open DOT Shopping Carts' activeNavigationLink='alldotcarts'>
      {itemCount != null && itemCount > pageLimit && (
        <div className='row'>
          <UncontrolledPagination
            className='w-auto ms-auto mb-5'
            page={pageNumber}
            perPage={pageLimit}
            total={itemCount}
            onChange={setPageNumber}
          />
        </div>
      )}
      <Loading loading={loading}>
        {dotCartHeaders == null && !loading && <CartPageLoadError cartName='DOT Carts' />}
        {dotCartHeaders != null && itemCount === 0 && !loading && (
          <CartEmptyState
            icon={fas.faCartShopping}
            title='No DOT Carts Found'
            description='You do not have any DOT carts at this time.'
          />
        )}
        <CartContext.Provider
          value={{
            cartArea: 'dot-carts-page',
            useCartContextUpdateCartLineMutation: useCartPageCartItemUpdateCartLineWithCartListItemMutation,
            useCartContextDeleteCartLineMutation: useCartPageCartItemDeleteCartLineWithCartListItemMutation
          }}
        >
          <CartListCollapseList cartHeaders={dotCartHeaders ?? null} />
        </CartContext.Provider>
      </Loading>
      {itemCount != null && itemCount > pageLimit && !loading && (
        <div className='row'>
          <UncontrolledPagination
            className='w-auto ms-auto mt-3'
            page={pageNumber}
            perPage={pageLimit}
            total={itemCount}
            onChange={handleBottomPaginationChange}
          />
        </div>
      )}
    </CartPageLayout>
  )
}

DotCartsPage.getInitialProps = async (context) => {
  const { apolloClient } = context

  const { data } = await apolloClient.query<GetCartPageDotCartsPagePermissionsQuery>({
    query: GetCartPageDotCartsPagePermissionsDocument
  })

  if (!data.viewerPermissions.canViewConsultantCarts) {
    return { statusCode: 403 }
  }

  return {}
}

DotCartsPage.layout = {
  title: {
    children: 'All DOT Carts'
  }
}

export {
  DotCartsPage
}
