import { type ReactElement } from 'react'
import { useAddToCartSectionContext } from '../useAddToCartSectionContext'
import { Button, Loading, ModalBody, ModalFooter, ModalHeader } from '@stuller/stullercom/ui'
import { AddToCartPricingSummaryTabs } from './AddToCartPricingSummaryTabs'
import { ShowcasePricingSummary } from './ShowcasePricingSummary'
import { YourCostPricingSummary } from './YourCostPricingSummary'
import {
  type ItemsInput,
  type ItemType,
  useGetAddToCartShowcasePriceSummaryQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { useToggleKey } from '@stuller/shared/util/react-hooks'

interface AddToCartPricingSummaryModalContentsProps {
  /**
   * function to close the modal
   */
  onToggle: () => void
  /**
   * id of the item
   */
  id: string
  /**
   * type of item
   */
  itemType: ItemType
  /**
   * account number
   */
  shipToAcountNumber?: string | null
}

/**
 * This component is responsible for rendering the pricing summary modal contents
 * It will show the user their cost and the showcase pricing
 * The user can toggle between the two tabs
 * Currently, the user can only see the showcase pricing
 */
function AddToCartPricingSummaryModalContents ({
  onToggle,
  id,
  itemType,
  shipToAcountNumber = null
}: AddToCartPricingSummaryModalContentsProps): ReactElement | null {
  const { options } = useAddToCartSectionContext()
  // tab 1 is your cost, 2 is showcase pricing
  const [open, handleToggle] = useToggleKey('showcasePricing')

  const input: ItemsInput = {
    items: [
      {
        id: {
          id,
          type: itemType
        },
        options: {
          isAnnealed: options?.isAnnealed ?? false,
          isForConsignment: options?.isForConsignment ?? false,
          isMatched: options?.isMatched ?? false,
          bagAndTagOptions: options?.bagAndTagOptions,
          specialInstructions: options?.specialInstructions,
          quantity: options?.quantity ?? 1,
          length: options?.length,
          width: options?.width
        }
      }
    ],
    shipToAccountId: shipToAcountNumber
  }

  // have to get the showcase pricing summary data to see if the user has markups
  const { data, loading } = useGetAddToCartShowcasePriceSummaryQuery({
    variables: {
      input
    }
  })

  // we will need to add logic to show tabs if the user has markups once we add the "your cost" section
  const showTabs = false

  return (
    <>
      <ModalHeader onToggle={onToggle}>
        Pricing Summary
        <h3 className='mb-0'>Showcase Pricing</h3>
      </ModalHeader>
      <ModalBody className='fs-6 fs-sm-5'>
        <Loading loading={loading}>
          {showTabs && (
            <div>
              <AddToCartPricingSummaryTabs currentTab={open} onTabChange={handleToggle} />
              <div className='d-none d-lg-block border-bottom mb-4' />
            </div>
          )}
          {open === 'yourCost' &&
            <YourCostPricingSummary />}
          {open === 'showcasePricing' && (
            <ShowcasePricingSummary
              data={data}
            />
          )}
        </Loading>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' outline className='m-0' onClick={onToggle}>Close</Button>
      </ModalFooter>
    </>
  )
}

export { AddToCartPricingSummaryModalContents }
