import { type ReactElement } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { MEMBERSHIP_CAD_CAM_ICON, MEMBERSHIP_LOCATE_A_JEWELER_ICON, MEMBERSHIP_STULLER_FIRST_ICON } from '@stuller/shared/util/constants'
import { Button, Loading } from '@stuller/stullercom/ui'
import {
  useGetHeaderUtilityNavMenuAccountMembershipsQuery,
  useGetHeaderUtilityNavMenuAccountLocateAJewelerListingCountQuery
} from '@stuller/stullercom/data-access/apollo-queries'

interface AccountMembershipProps {
  /**
   * Icon url for the membership
   */
  icon: string
  /**
   * Main title
   */
  title: string
  /**
   * Subtitle
   */
  description?: string
  /**
   * Url for manage button
   */
  url: string
}

/**
 * Rendered component that takes in user account membership information: Stuller First, Locate Jewler Listing, CADCAM Services
 */
function AccountMembership ({
  icon,
  title,
  description,
  url
}: AccountMembershipProps): ReactElement {
  return (
    <div className='row align-items-center border-bottom border-1 py-3'>
      <div className='col-auto py-1'>
        <img src={icon} alt={title} className='mb-2' width={50} />
      </div>
      <div className='col ps-1'>
        <div className='fw-medium'>{title}</div>
        {description != null && (
          <div className='fw-bold fs-7 text-uppercase'>{description}</div>
        )}
      </div>
      <div className='col-auto'>
        <Button href={url} tag='a' size='sm' pill>Manage</Button>
      </div>
    </div>
  )
}

/**
 * Displays the account memberships for the user
 */
function AccountMemberships (): ReactElement | null {
  const { authUser } = useAuth()
  const { data: accountMembershipsData, loading: accountMembershipLoading } = useGetHeaderUtilityNavMenuAccountMembershipsQuery()
  const { data: locateAJewelerListingData, loading: locateAJewelerListingLoading } = useGetHeaderUtilityNavMenuAccountLocateAJewelerListingCountQuery()
  // Combine loading states from both queries
  const loading = accountMembershipLoading || locateAJewelerListingLoading

  const [isStullerFirstEnrolled, locateAJewelerListingsCount, isCadCamServicesMember] = [
    authUser?.user?.isStullerFirstEnrolled ?? false,
    locateAJewelerListingData?.locateAJewelerListingSearch?.count ?? 0,
    accountMembershipsData?.viewer?.isCadCamServicesMember ?? false
  ]

  if (!loading && !isStullerFirstEnrolled && locateAJewelerListingsCount === 0 && !isCadCamServicesMember) {
    return null
  }

  return (
    <Loading loading={loading}>
      {!loading && (
        <div className='mb-4' data-gtm-click-subsection2='Memberships'>
          <div className='fw-bold'>Memberships</div>

          {isStullerFirstEnrolled && (
            <AccountMembership
              icon={MEMBERSHIP_STULLER_FIRST_ICON}
              title='Stuller First'
              description='Stuller First Membership'
              url='/myaccount/stullerfirst/managememberships'
            />
          )}

          {locateAJewelerListingsCount > 0 && (
            <AccountMembership
              icon={MEMBERSHIP_LOCATE_A_JEWELER_ICON}
              title='Locate a Jeweler'
              description={`${locateAJewelerListingsCount} Active Listing${locateAJewelerListingsCount > 1 ? 's' : ''}`}
              url='/myaccount/locateajeweler'
            />
          )}

          {isCadCamServicesMember && (
            <AccountMembership
              icon={MEMBERSHIP_CAD_CAM_ICON}
              title='CADCAM Services'
              url='/myaccount/cadcamservices/cadcamprojects'
            />
          )}
        </div>
      )}
    </Loading>
  )
}

export {
  AccountMemberships
}
