import { useEffect, useState, type ReactElement } from 'react'
import { getPilotCookie, type PilotCookieValue, togglePilotSegment } from './piloting'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Button } from '@stuller/stullercom/ui'

interface ReturnToLegacyPageBannerProps {
  /**
   * The title of the page
   */
  title: string
  /**
   * Page name for the button
   */
  buttonTitle: string
  /**
   * The pilot name
   */
  pilotName: string
}

function ReturnToLegacyPageBanner ({ title, buttonTitle, pilotName }: ReturnToLegacyPageBannerProps): ReactElement | null {
  const [pilotCookie, setPilotCookie] = useState<PilotCookieValue | undefined>(undefined)

  useEffect(() => {
    setPilotCookie(getPilotCookie(pilotName))
  }, [pilotName])

  function handlePilotToggle (): void {
    togglePilotSegment(pilotName)
    window.location.reload()
  }

  if (pilotCookie == null) {
    return null
  }

  return (
    <div
      className='bg-new-green-100 border-bottom border-new-green-200'
      data-gtm-click-section='section'
      data-gtm-click-subsection1='back_to_legacy'
    >
      <div className='container-xxl mw-xxl p-4'>
        <div className='row gy-3'>
          <div className='col text-new-green-400'>
            <p className='mb-0 fw-bold'>You're previewing the updated {title}</p>
            <p className='mb-0'>You can switch between the new and old pages for a limited time.</p>
          </div>
          <div className='col-12 col-sm-auto d-flex align-items-center'>
            <Button color='secondary' pill size='sm' outline='fill' onClick={handlePilotToggle}>
              <FontAwesomeIcon icon={fas.faRotateLeft} size='lg' className='me-2' />
              Back to Old {buttonTitle}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  ReturnToLegacyPageBanner
}
