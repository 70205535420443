import { createContext, type Dispatch, type SetStateAction } from 'react'
import {
  type GetHeaderUtilityNavMenuMessagesFeedQueryHookResult,
  type GetHeaderUtilityNavMenuMessagesUnreadCountQueryHookResult,
  type HeaderUtilityNavMenuMessagesUpdateFeedReadMutationHookResult
} from '@stuller/stullercom/data-access/apollo-queries'

export interface MessagesPopoverContextType {
  /**
   * Result of the notifications feed query
   */
  feed: GetHeaderUtilityNavMenuMessagesFeedQueryHookResult
  /**
   * Result of the notifications unread count query
   */
  unreadCount: GetHeaderUtilityNavMenuMessagesUnreadCountQueryHookResult
  /**
   * Mutation function to update the notification feed as read
   */
  updateFeedRead: HeaderUtilityNavMenuMessagesUpdateFeedReadMutationHookResult[0]
  /**
   * Result of the update notification feed as read mutation
   */
  feedReadUpdate: HeaderUtilityNavMenuMessagesUpdateFeedReadMutationHookResult[1]
  /**
   * Filter for unread important messages only
   */
  unreadImportantOnly: boolean
  setUnreadImportantOnly: Dispatch<SetStateAction<boolean>>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const MessagesPopoverContext = createContext<MessagesPopoverContextType>({} as MessagesPopoverContextType)

export {
  MessagesPopoverContext
}
