import { useMemo, type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { dayjs } from '@stuller/shared/util/core'

interface CartShipmentShipStatusProps {
  /**
   * The date the shipment will ship
   */
  shipDate?: string | null
}

/**
 * Calculate ship date formatted string
 */
function formatShipDate (date: string | undefined | null): string {
  if (date == null) {
    return ''
  }

  return dayjs(date).toReadableDateString()
}

/**
 * Displays the shipment ship status
 */
function CartShipmentShipStatus ({ shipDate }: CartShipmentShipStatusProps): ReactElement | null {
  const formattedShipDate = useMemo(() => formatShipDate(shipDate), [shipDate])

  if (shipDate == null) {
    return null
  }

  return (
    <h5 className='d-print-none'>
      {formattedShipDate === 'Today' && <FontAwesomeIcon icon={fas.faTruckFast} className='text-blue me-2' />}
      Ready to Ship {formattedShipDate}
    </h5>
  )
}

export {
  CartShipmentShipStatus
}
