import { type ReactElement } from 'react'
import { Modal, ModalBody, ModalHeader, Button } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { FormstackForm } from '@stuller/stullercom/feat/formstack'
import { useAddToCartSectionContext } from '../useAddToCartSectionContext'

/**
 * A component to show the shipping countdown modal with info about eligible products
 */
function RequestConsignmentStatusModal (): ReactElement {
  const [isOpen, toggleIsOpen] = useToggle()
  const { isDiamond } = useAddToCartSectionContext()
  const productType = isDiamond ? 'diamond' : 'gemstone'
  const formName = `request_serialized_${productType}_out_for_review_form`

  return (
    <>
      <Button onClick={toggleIsOpen} color='primary'>
        Request Status
      </Button>
      <Modal
        isOpen={isOpen}
        onToggle={toggleIsOpen}
        data-gtm-click-section=''
      >
        <ModalHeader onToggle={toggleIsOpen}>
          Request Status
        </ModalHeader>
        <ModalBody>
          <FormstackForm name={formName} />
        </ModalBody>
      </Modal>
    </>
  )
}

export {
  RequestConsignmentStatusModal
}
