import { type ChangeEvent, type ReactElement } from 'react'
import {
  FormGroup,
  FormLabel,
  Input
} from '@stuller/stullercom/ui'
import clsx from 'clsx'

interface ContentstackTargetingFieldsProps {
  /**
   * toggle override field
   */
  handleOverrideChange?: (e: ChangeEvent<HTMLInputElement>) => void
  /**
   * handles changing the preview targeting values
   */
  handleTargetingPreviewerChange?: (e: ChangeEvent<HTMLInputElement>) => void
  /**
   * value of targeting
   */
  value?: boolean | string
  /**
   * If overriding is checked
   */
  override: boolean
  /**
   * Id for fields
   */
  id?: string
  /**
   * label for fields
   */
  label?: string
}

/**
 * Contentstack targeting previewer fields
 */
function ContentstackTargetingFields ({ override, value, handleOverrideChange, handleTargetingPreviewerChange, label, id }: ContentstackTargetingFieldsProps): ReactElement {
  const groupItemClassNames = clsx(
    'list-group-item p-3',
    override && 'bg-blue-100 bg-opacity-50'
  )

  return (
    <div className={groupItemClassNames}>
      <FormGroup className='mb-0' groupId={`override-${id}`} check>
        <Input type='checkbox' name={id} checked={override} onChange={handleOverrideChange} />
        <FormLabel check>Override {label}</FormLabel>
      </FormGroup>
      {override && (
        <div className='mt-3 ms-5 ps-4 border-start'>
          {typeof value === 'boolean'
            ? (
              <FormGroup groupId={id} check>
                <Input type='checkbox' name={id} checked={value} onChange={handleTargetingPreviewerChange} />
                <FormLabel check>{label}</FormLabel>
              </FormGroup>
            )
            : (
              <FormGroup groupId={id}>
                <Input type='text' name={id} value={value} onChange={handleTargetingPreviewerChange} />
                <FormLabel>{label}</FormLabel>
              </FormGroup>
            )}
        </div>
      )}
    </div>
  )
}

export {
  ContentstackTargetingFields
}
