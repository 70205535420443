import { type ReactElement } from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { isStringEmpty } from '@stuller/shared/util/core'
import { useDurationCountdown } from '@stuller/shared/util/react-hooks'
import { bottomSectionClasses } from './helpers/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { InStockStatusCountdown } from './InStockStatusCountdown'
import { useAddToCartSectionContext } from '../useAddToCartSectionContext'
import { EligibleProductsModal } from '@stuller/stullercom/feat/shipping-countdown'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { AddToCartType, CustomShowcaseSettingDetailType } from '@stuller/stullercom/data-access/apollo-queries'

interface EstimatedShipDateSectionProps {
  /**
   * estimated ship date of the product
   */
  shipDate?: string
  /**
   * context-based human readable ship date
   */
  readableShipDate?: string
  /**
   * whether or not the ship date is today
   */
  isShipDateToday: boolean
}

function EstimatedShipDateSection ({ shipDate, isShipDateToday, readableShipDate }: EstimatedShipDateSectionProps): ReactElement | null {
  const { hasScheduledOrder, cutoffDate, countdownKind, addToCartType } = useAddToCartSectionContext()
  const { authUser, getCustomShowcaseSetting } = useAuth()
  const isQuoteIdCartType = addToCartType === AddToCartType.QuoteId
  const showCustomShipmentMessage = getCustomShowcaseSetting(CustomShowcaseSettingDetailType.ShowCustomShipmentMessage)?.[0]
  const cutoffDateDayJs = dayjs(cutoffDate)
  const duration = useDurationCountdown(cutoffDateDayJs, 1000)
  const hasCountdown = !isStringEmpty(cutoffDate) && !isStringEmpty(countdownKind) && !isQuoteIdCartType
  const showCountdown = isShipDateToday && hasCountdown && !hasScheduledOrder && duration.asMilliseconds() > 0
  const daysTillShip = dayjs(shipDate).diff(dayjs(), 'days')
  const shipDateMessageBeginning = `${daysTillShip >= 30 ? 'Estimated' : 'Ready'} to ship`
  const classes = clsx(
    bottomSectionClasses,
    isShipDateToday ? 'bg-blue-100 border-blue-200 text-blue-500' : 'bg-white'
  )

  if (authUser == null) {
    return null
  }

  let iconComponent: ReactElement | null = null
  if (hasScheduledOrder) {
    iconComponent = <FontAwesomeIcon icon={fas.faClock} className={clsx(isShipDateToday ? 'text-blue-300' : 'text-gray-400')} size='xl' />
  } else if (isShipDateToday) {
    iconComponent = <FontAwesomeIcon icon={fas.faTruckFast} className='text-blue-300' size='lg' />
  } else if (daysTillShip >= 30 || shipDate == null) {
    iconComponent = <FontAwesomeIcon icon={fas.faCircleInfo} className='text-gray-500' size='xl' />
  }

  return (
    <div className={classes}>
      <div className='d-flex align-items-center gap-3'>
        {iconComponent}
        <div className='lh-sm'>
          {readableShipDate != null && (
            <div className={clsx('d-flex flex-wrap gap-1', isShipDateToday && 'fw-bold')}>
              <div className='lh-sm'>{shipDateMessageBeginning}</div>
              <div className={clsx(!isShipDateToday && 'fw-bold')}>{readableShipDate}</div>
            </div>
          )}
          {!isStringEmpty(showCustomShipmentMessage) && (
            <div>{showCustomShipmentMessage}</div>
          )}
          {readableShipDate == null && (
            <div className='fw-bold'>Ship date unavailable</div>
          )}
          {showCountdown && (
            <div>
              <span className='me-2'>if you buy in the next</span>
              <InStockStatusCountdown duration={duration} />
              {countdownKind.toLowerCase() === 'previous_order' && (
                <span>and eligible items can ship with your previous order.</span>
              )}
            </div>
          )}
          {hasScheduledOrder && hasCountdown && (
            <ScheduledOrderShipDateMessage cutoffDate={cutoffDate} />
          )}
        </div>
      </div>
    </div>
  )
}

interface ScheduledOrderShipDateMessageProps {
  cutoffDate?: string
}

function ScheduledOrderShipDateMessage ({ cutoffDate }: ScheduledOrderShipDateMessageProps): ReactElement | null {
  const cutoffDateObj = dayjs(cutoffDate)
  const duration = useDurationCountdown(cutoffDateObj, 1000)

  return (
    <>
      <div>Add to your scheduled order within the next <InStockStatusCountdown duration={duration} /> and eligible items can ship together.</div>
      <div className='mt-3'>
        <EligibleProductsModal />
      </div>
    </>
  )
}

export {
  EstimatedShipDateSection
}
