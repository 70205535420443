import { type ReactElement } from 'react'
import { type ShowcasePricingSummarySection } from './types'
import { useCurrency } from '@stuller/shared/util/react-hooks'

interface ShowcasePricingSummarySingleComponentViewProps {
  section: ShowcasePricingSummarySection | undefined | null
}

function ShowcasePricingSummarySingleComponentView ({ section }: ShowcasePricingSummarySingleComponentViewProps): ReactElement | null {
  const {
    basePrice,
    markupPrice,
    markedUpTotal,
    minimumPrice,
    flatPrice,
    quantity,
    markupDescription,
    markupMultiplier
  } = section?.components?.[0] ?? {}

  const additionalCharge = section?.additionalCharge

  const [basePriceCurrency] = useCurrency(basePrice?.value, basePrice?.exchangeRate)
  const [markupPriceCurrency] = useCurrency(markupPrice?.value, markupPrice?.exchangeRate)
  const [markedUpTotalCurrency] = useCurrency(markedUpTotal?.value, markedUpTotal?.exchangeRate)
  const [minimumPriceCurrency] = useCurrency(minimumPrice?.value, minimumPrice?.exchangeRate)
  const [flatPriceCurrency] = useCurrency(flatPrice?.value, flatPrice?.exchangeRate)
  const [additionalChargeCurrency] = useCurrency(additionalCharge?.value, additionalCharge?.exchangeRate)

  if (section == null) {
    return null
  }

  const basePriceText = (quantity != null && quantity > 1)
    ? `Base Price (for ${quantity})`
    : 'Base Price'

  const showMinPrice = minimumPrice?.value != null && minimumPrice?.value > 0
  const showFlatPrice = flatPrice?.value != null && flatPrice?.value > 0

  return (
    <div className='row'>
      <div className='col-12'>
        <img alt={`image for ${section.title}`} width='100' src={section.item?.image ?? ''} />
      </div>
      <div className='col-12'>
        <div className='fw-bold'>{section.title}</div>
        <div className='row'>
          <div className='col-8'>{basePriceText}</div>
          <div className='col-4 text-end'>{basePriceCurrency}</div>
        </div>
        <div className='row'>
          <div className='col-8'>{markupDescription}</div>
          <div className='col-4 d-flex justify-content-between'>
            <div>x</div>
            <div>{markupMultiplier}</div>
          </div>
        </div>
        <div className='row justify-content-end'>
          <div className='col-4'>
            <hr />
          </div>
        </div>
        <div className='row'>
          <div className='col-8'>Base x Markup Price</div>
          <div className='col-4 text-end'>{markupPriceCurrency}</div>
        </div>
        {showMinPrice && (
          <div className='row'>
            <div className='col-8'>Minimum Price</div>
            <div className='col-4 text-end'>{minimumPriceCurrency}</div>
          </div>
        )}
        {showFlatPrice && (
          <div className='row'>
            <div className='col-8'>Flat Price</div>
            <div className='col-4 text-end'>{flatPriceCurrency}</div>
          </div>
        )}
        <div className='row'>
          <div className='col-8'>Additional Charge</div>
          <div className='col-4 text-end'>{additionalChargeCurrency}</div>
        </div>
        <div className='border-bottom border-3 mt-2 mb-3' />
        <div className='row fw-bold'>
          <div className='col-8'>Total</div>
          <div className='col-4 text-end'>{markedUpTotalCurrency}</div>
        </div>
      </div>
    </div>
  )
}

export { ShowcasePricingSummarySingleComponentView }
