import { type ChangeEvent, useState, type ReactElement } from 'react'
import { Form, FormGroup, FormLabel, Textarea } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'
import { isStringEmpty } from '@stuller/shared/util/core'

/**
 * Form Group for Special Instruction input fields
 */
function AddToCartSpecialInstructions (): ReactElement {
  const { isShowcase } = useAuth()
  const { onAddToCartDataChanged, options } = useAddToCartSectionContext()
  const [specialInstructions, setSpecialInstructions] = useState(options?.specialInstructions ?? '')

  function handleChange (event: ChangeEvent<HTMLTextAreaElement>): void {
    setSpecialInstructions(event.target.value)
  }

  function handleSubmit (): void {
    if ((isStringEmpty(specialInstructions) && isStringEmpty(options?.specialInstructions)) || specialInstructions === options?.specialInstructions) {
      return
    }
    onAddToCartDataChanged({ specialInstructions })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <FormLabel>
          Special Instructions
          {!isShowcase && (
            <span className='fw-normal ms-2'>
              (reviewed by Stuller)
            </span>
          )}
        </FormLabel>
        <Textarea rows={1} onChange={handleChange} onBlur={handleSubmit} value={specialInstructions} />
      </FormGroup>
    </Form>
  )
}

export {
  AddToCartSpecialInstructions
}
