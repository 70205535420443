import { forwardRef, useMemo, type CSSProperties } from 'react'
import { type CarrierType, CARRIER_TYPE_INFO } from '../../util/constants/carrierTypes'
import clsx from 'clsx'

export interface CarrierIconProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Additional styles to apply to the containing element
   */
  style?: CSSProperties
  /**
   * The type of carrier to display
   */
  type?: CarrierType
  /**
   * The width of the icon
   */
  width?: number
}

/**
 * Component for rendering a carrier icon.
 */
const CarrierIcon = forwardRef<HTMLSpanElement, CarrierIconProps>(({
  id,
  className,
  style,
  type: typeIn,
  width = 16,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('form-carrier-icon', className)
  const matchingCarrrierType = useMemo(() => {
    let carrierType = typeIn != null ? CARRIER_TYPE_INFO[typeIn] : undefined
    carrierType ??= CARRIER_TYPE_INFO.other

    return carrierType
  }, [typeIn])
  const iconStyles = useMemo(() => ({
    width: `${width}px`
  }), [width])

  if (matchingCarrrierType.icon == null) {
    return null
  }

  return (
    <span id={id} className={classNames} style={style} ref={ref} {...otherAttributes}>
      <img src={matchingCarrrierType.icon} alt={matchingCarrrierType.name} style={iconStyles} className='mt-n1' />
    </span>
  )
})
CarrierIcon.displayName = 'CarrierIcon'

export { CarrierIcon }
