import { useRouter } from 'next/router'
import { Alert, Button } from '@stuller/stullercom/ui'
import { useMemo, type ReactElement } from 'react'
import type { CartLine, Shipments } from '../shared/types'
import { useCartContext } from '../shared/useCartContext'
import { useCounter } from '@stuller/shared/util/react-hooks'

interface CartSummaryAlertsProps {
  /**
   * The lines in the cart
   */
  cartLines: CartLine[]
  /**
   * Cart shipments
   */
  shipments?: Shipments
}

interface CartSummaryAlertInfo {
  cartLineId: string
  shipmentIndex?: number
}

interface CartSummaryAlertProps {
  /**
   * The type of alert
   */
  type: 'danger' | 'warning'
  /**
   * The number of alerts
   */
  alerts: CartSummaryAlertInfo[]
}

/**
 * Component for the alerts in cart summary
 */
function CartSummaryAlert ({
  type,
  alerts
}: CartSummaryAlertProps): ReactElement {
  const router = useRouter()
  const [alertIndex, , , handleNext] = useCounter(0, {
    max: alerts.length - 1
  })
  const { setOpenShipments } = useCartContext()
  const typeName = type === 'danger' ? 'Error' : 'Alert'

  /**
   * Handles clicking to view the first alert
   */
  async function handleNextAlert (): Promise<void> {
    const alertToShow = alerts[alertIndex]
    const shipmentIndex = alertToShow.shipmentIndex
    if (shipmentIndex != null) {
      setOpenShipments?.((shipmentsOpen) => [...(shipmentsOpen ?? []), shipmentIndex.toString()])
    }
    handleNext()
    await router.push({ hash: `#cart-item-${alertToShow.cartLineId}` })
  }

  /**
   * Handles clicking to view the first alert
   */
  async function handleViewFirstAlert (): Promise<void> {
    const alertToShow = alerts[0]
    const shipmentIndex = alertToShow.shipmentIndex
    if (shipmentIndex != null) {
      setOpenShipments?.((shipmentsOpen) => [...(shipmentsOpen ?? []), shipmentIndex.toString()])
    }
    await router.push({ hash: `#cart-item-${alertToShow.cartLineId}` })
  }

  return (
    <Alert
      size='sm'
      color={type}
      className='mb-3'
      icon
      button={(
        <>
          <Button onClick={handleNextAlert} color='anchor' className='alert-link d-none d-md-block'>
            View {alertIndex === 0 ? 'First' : 'Next'} {typeName}
          </Button>
          <Button onClick={handleViewFirstAlert} color='anchor' className='alert-link d-block d-md-none'>
            View First {typeName}
          </Button>
        </>
      )}
    >
      {alerts.length} {typeName}{alerts.length > 1 && 's'}
    </Alert>
  )
}

/**
 * Shows cart summary alerts in cart summary
 */
function CartSummaryAlerts ({ shipments, cartLines }: CartSummaryAlertsProps): ReactElement | null {
  const { scheduledOrder } = useCartContext()
  const { errorsAlert, notesAlert } = useMemo(() => {
    const errorsAlert: CartSummaryAlertInfo[] = []
    const notesAlert: CartSummaryAlertInfo[] = []
    // If not scheduled order, push alerts in order of items in cart. Otherwise, push alerts in order of shipments
    if (scheduledOrder == null) {
      for (const line of cartLines) {
        if (line.errors.length > 0) {
          errorsAlert.push({ cartLineId: line.id })
        }
        if (line.notes.length > 0) {
          notesAlert.push({ cartLineId: line.id })
        }
      }
    } else {
      for (const [index, shipment] of (shipments ?? []).entries()) {
        for (const line of shipment.lines) {
          if (line.errors.length > 0) {
            errorsAlert.push({ shipmentIndex: index, cartLineId: line.id })
          }
          if (line.notes.length > 0) {
            notesAlert.push({ shipmentIndex: index, cartLineId: line.id })
          }
        }
      }
    }

    return { errorsAlert, notesAlert }
  }, [shipments, cartLines, scheduledOrder])

  if (errorsAlert.length === 0 && notesAlert.length === 0) {
    return null
  }

  return (
    <div className='pb-3'>
      {errorsAlert.length > 0 && <CartSummaryAlert type='danger' alerts={errorsAlert} />}
      {notesAlert.length > 0 && <CartSummaryAlert type='warning' alerts={notesAlert} />}
    </div>
  )
}

export {
  CartSummaryAlerts
}
