import {
  CARRIER_FEDEX_ICON,
  CARRIER_UPS_ICON,
  CARRIER_USPS_ICON,
  CARRIER_PUROLATOR_ICON,
  CARRIER_OTHER_ICON,
  CARRIER_MOTOR_FREIGHT_ICON,
  CARRIER_PICK_UP_ICON,
  CARRIER_OCEAN_FREIGHT_ICON,
  CARRIER_DHL_ICON
} from './media'

export type CarrierType =
  'fedex'
  | 'FedEx'
  | 'FEDEX'
  | 'ups'
  | 'UPS'
  | 'usps'
  | 'USPS'
  | 'purolator'
  | 'PUROLATOR'
  | 'dhl'
  | 'DHL'
  | 'mf'
  | 'MF'
  | 'ocean-freight'
  | 'Ocean Freight'
  | 'OCEAN_FREIGHT'
  | 'pick-up'
  | 'Pick Up'
  | 'PICK_UP'
  | 'other'
  | 'OTHER'

/**
 * Mapping of carrier types and their info
 */
const CARRIER_TYPE_INFO: Record<CarrierType, { name: string, icon: string | null }> = {
  fedex: {
    name: 'FedEx',
    icon: CARRIER_FEDEX_ICON
  },
  FedEx: {
    name: 'FedEx',
    icon: CARRIER_FEDEX_ICON
  },
  FEDEX: {
    name: 'FedEx',
    icon: CARRIER_FEDEX_ICON
  },
  ups: {
    name: 'UPS',
    icon: CARRIER_UPS_ICON
  },
  UPS: {
    name: 'UPS',
    icon: CARRIER_UPS_ICON
  },
  usps: {
    name: 'USPS',
    icon: CARRIER_USPS_ICON
  },
  USPS: {
    name: 'USPS',
    icon: CARRIER_USPS_ICON
  },
  purolator: {
    name: 'Purolator',
    icon: CARRIER_PUROLATOR_ICON
  },
  PUROLATOR: {
    name: 'Purolator',
    icon: CARRIER_PUROLATOR_ICON
  },
  dhl: {
    name: 'DHL',
    icon: CARRIER_DHL_ICON
  },
  DHL: {
    name: 'DHL',
    icon: CARRIER_DHL_ICON
  },
  mf: {
    name: 'Motor Freight',
    icon: CARRIER_MOTOR_FREIGHT_ICON
  },
  MF: {
    name: 'Motor Freight',
    icon: CARRIER_MOTOR_FREIGHT_ICON
  },
  'ocean-freight': {
    name: 'Ocean Freight',
    icon: CARRIER_OCEAN_FREIGHT_ICON
  },
  'Ocean Freight': {
    name: 'Ocean Freight',
    icon: CARRIER_OCEAN_FREIGHT_ICON
  },
  OCEAN_FREIGHT: {
    name: 'Ocean Freight',
    icon: CARRIER_OCEAN_FREIGHT_ICON
  },
  'pick-up': {
    name: 'Pick Up',
    icon: CARRIER_PICK_UP_ICON
  },
  'Pick Up': {
    name: 'Pick Up',
    icon: CARRIER_PICK_UP_ICON
  },
  PICK_UP: {
    name: 'Pick Up',
    icon: CARRIER_PICK_UP_ICON
  },
  other: {
    name: 'Other',
    icon: CARRIER_OTHER_ICON
  },
  OTHER: {
    name: 'Other',
    icon: CARRIER_OTHER_ICON
  }
}

export {
  CARRIER_TYPE_INFO
}
