import { type ReactElement } from 'react'
import { fab } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@stuller/stullercom/ui'

interface ItemShareXProps {
  /**
   * Callback to execute when the item is shared
   */
  onShare: () => void
}

/**
 * Share item via X
 */
function ItemShareX ({ onShare }: ItemShareXProps): ReactElement {
  return (
    <Button
      tag='a'
      href={`https://twitter.com/intent/tweet?url=${encodeURI(window.location.href)}`}
      target='_blank'
      color='anchor-dark'
      className='fw-normal'
      onClick={onShare}
    >
      <FontAwesomeIcon icon={fab.faXTwitter} className='me-2' color='#000000' />
      X
    </Button>
  )
}

export {
  ItemShareX
}
