import { type PdpImageCarouselMediaInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { createContext } from 'react'

export interface PdpImageCarouselContextType {
  /**
   * Array of media fragments for the carousel
   */
  media?: PdpImageCarouselMediaInfoFragment[]
  /**
   * For Serialized Products: If the stone is lab-grown
   */
  isLabGrown: boolean
  /**
   * For Serialized Products: If the item is a gemstone
   */
  isGemstone: boolean
  /**
   * For Serialized Products: The serial number of the item being displayed
   */
  serialNumber?: string
  /**
   * Indicates if the modal is open
   */
  isModalOpen?: boolean
  /**
   * Function to toggle the modal state
   */
  onToggleModal?: () => void
}

const PdpImageCarouselContext = createContext<PdpImageCarouselContextType>({
  media: [],
  isLabGrown: false,
  isModalOpen: false,
  isGemstone: false,
  onToggleModal: () => {}
})

export {
  PdpImageCarouselContext
}
