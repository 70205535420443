import type { ReactElement } from 'react'
import type { CartLine } from '../shared/types'
import Link from 'next/link'

interface CartItemReportProps {
  /**
   * The cart line to update
   */
  cartLine: CartLine
}

/**
 * Shows the report link for serialized products
 */
function CartItemReport ({ cartLine }: CartItemReportProps): ReactElement | null {
  if (cartLine.item.__typename !== 'SerializedProduct' || cartLine.item.report?.url == null || cartLine.item.report?.provider == null) {
    return null
  }

  return (
    <>
      &nbsp;<span className='text-gray-300'>|</span>&nbsp;<Link href={cartLine.item.report.url} target='_blank'>{cartLine.item.report.provider} Report</Link>
    </>
  )
}

export {
  CartItemReport
}
