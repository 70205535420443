import { useEffect, type ReactElement } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Button } from '@stuller/stullercom/ui'
import type { CartLine } from '../shared/types'
import { trackCartLineAddToCart, trackCartLineRemoveFromCart } from '@stuller/stullercom/feat/google-tag-manager'
import {
  type CartLineMessageInfoFragment,
  useCartPageCartItemMoveSavedCartLineToCartMutation,
  useCartPageCartItemMoveCartLineToCartMutation,
  useCartPageCartItemSaveCartLineForLaterMutation,
  GetSavedCartLinesDocument,
  GetCustomersWithCartsDocument
} from '@stuller/stullercom/data-access/apollo-queries'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { useCartContext } from '../shared/useCartContext'
import Cookies from 'js-cookie'
import { ReleaseReservationModal } from './ReleaseReservationModal'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { getClientValidationErrors } from '@stuller/stullercom/data-access/apollo-client'

interface CartItemMoveFromButtonProps {
  /**
   * The cart line data
   */
  cartLine: CartLine
  /**
   * Function to set the loading state
   */
  onLoading: (loading: boolean) => void
  /**
   * Function to set the error state
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * Whether to disable the button
   */
  disabled?: boolean
  /**
   * If the product is serialized
   */
  isSerializedProduct: boolean
}

interface MoveFromButtonProps {
  /**
   * The onClick promise function that takes in the mutation to be executed
   */
  onClick: (() => void) | (() => Promise<any>)
  /**
   * Whether to disable the button
   */
  disabled: boolean
}

/**
 * The move from button that renders inside the main component and gets the mutation passed through to it
 */
function MoveFromButton ({ onClick, disabled = false }: MoveFromButtonProps): ReactElement {
  const { cartArea } = useCartContext()

  return (
    <Button
      color='anchor-dark'
      onClick={onClick}
      disabled={disabled}
      data-test='move-to-cart-button'
    >
      <FontAwesomeIcon
        className='me-2'
        icon={cartArea === 'cart-page' || cartArea === 'dot-carts-page' || cartArea === 'cart-search-page' ? fas.faPlus : fas.faCartShopping}
      />
      <span className='fw-normal'>
        {cartArea === 'cart-page'
          ? 'Save for Later'
          : cartArea === 'dot-carts-page'
            ? 'Move to My Dot Cart'
            : cartArea === 'cart-search-page'
              ? 'Move to Consultant Cart'
              : 'Move to Cart'}
      </span>
    </Button>
  )
}

/**
 * This component is a button that allows the user to move an item to the cart or saved for later
 */
function CartItemMoveFromButton ({
  cartLine,
  onError,
  onLoading,
  disabled = false,
  isSerializedProduct
}: CartItemMoveFromButtonProps): ReactElement | null {
  const [releaseReservationModalIsOpen, toggleReleaseReservationModalIsOpen] = useToggle(false)
  const { cartArea, scheduledOrder } = useCartContext()
  const auth = useAuth()
  const { isJewelerShowcase } = auth
  const dontShowReleaseReservationModal = Cookies.get('cart-scheduled-release-reservation-alert-hide')
  const showReleaseReservationModal = (cartLine.item.isLimitedAvailability || isSerializedProduct) && scheduledOrder != null && dontShowReleaseReservationModal !== 'true'

  /**
   * Mutation to move a saved cart line to the cart
   */
  const [moveSavedCartLineToCart, { loading: moveSavedCartLineLoading }] = useCartPageCartItemMoveSavedCartLineToCartMutation({
    variables: { input: { savedCartLineId: cartLine.id } },
    onError: (error) => {
      const errorStrings = getClientValidationErrors(error).map((e) => e.message)
      const errorMessage = errorStrings.length > 0 ? errorStrings : 'There was an issue moving this item to cart'
      onLoading(false)
      onError([{ code: 'CART_ERROR', message: errorMessage.toString() }])
      logger.error('Could not move item to cart: ', { code: 'CART_ERROR', message: error.message }, error)
    },
    onCompleted: () => {
      onError([])
      trackCartLineAddToCart(cartLine, auth)
    },
    update: (cache) => {
      // Evict the saved cart line and cartItemCount from the cache
      cache.evict({ id: `SavedCartLine:${cartLine.id}` })
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'cartItemCount' })
      cache.gc()
    },
    refetchQueries: [GetSavedCartLinesDocument]
  })

  /**
   * Mutation to move a cart line to the cart
   */
  const [moveCartLineToCart, { loading: moveCartLineLoading }] = useCartPageCartItemMoveCartLineToCartMutation({
    variables: {
      input: {
        cartLineId: cartLine.id
      }
    },
    onError: (error) => {
      const errorStrings = getClientValidationErrors(error).map((e) => e.message)
      const errorMessage = errorStrings.length > 0 ? errorStrings : 'There was an issue moving this item to cart'
      onLoading(false)
      onError([{ code: 'CART_ERROR', message: errorMessage.toString() }])
      logger.error('Could not move item to cart: ', { code: 'CART_ERROR', message: error.message }, error)
    },
    onCompleted: () => {
      onError([])
      trackCartLineAddToCart(cartLine, auth)
    },
    update: (cache) => {
      // Evict the cart count from the cache
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'cartItemCount' })
      cache.gc()
    },
    refetchQueries: [GetCustomersWithCartsDocument]
  })

  /**
   * Mutation to save the item for later
   */
  const [saveForLater, { loading: saveForLaterLoading }] = useCartPageCartItemSaveCartLineForLaterMutation({
    variables: {
      cartLineId: cartLine.id
    },
    onError: (error: Error) => {
      onLoading(false)
      onError([{ code: 'CART_ERROR', message: 'There was an issue moving this item to saved items' }])
      logger.error('Could not move item to cart: ', { code: 'CART_ERROR', message: error.message }, error)
    },
    onCompleted: () => {
      onError([])
      trackCartLineRemoveFromCart(cartLine, auth)
    },
    update: (cache) => {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'cartItemCount' })
      cache.gc()
    }
  })

  /**
   * Handle loading and resetting the error states
   */
  useEffect(() => {
    onLoading(moveSavedCartLineLoading || moveCartLineLoading || saveForLaterLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveSavedCartLineLoading, moveCartLineLoading, saveForLaterLoading])

  if (isJewelerShowcase) {
    return null
  }

  return (
    <>
      <div className='me-xl-4 order-2 order-xl-3'>
        <MoveFromButton
          onClick={(cartArea === 'cart-page' && showReleaseReservationModal) ?
            toggleReleaseReservationModalIsOpen :
            cartArea === 'cart-page'
              ? saveForLater
              : cartArea === 'saved-items-page'
                ? moveSavedCartLineToCart
                : moveCartLineToCart}
          disabled={disabled}
        />
      </div>
      <ReleaseReservationModal
        isOpen={releaseReservationModalIsOpen}
        onToggle={toggleReleaseReservationModalIsOpen}
        actionType='save'
        action={saveForLater}
      />
    </>
  )
}

export {
  CartItemMoveFromButton
}
