import { type ReactElement, type ReactNode } from 'react'
import { Badge, type BadgeProps, Nav, NavItem, NavLink } from '@stuller/stullercom/ui'
import { useGetCartPageNavigationQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, type IconDefinition } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'

/**
 * Cart page navigation links
 */
export type CartPageNavigationLinks =
  'cart' |
  'jewelershowcasecarts' |
  'saveditems' |
  'subcarts' |
  'cartsearch' |
  'alldotcarts'

interface CartPageNavigationProps {
  /**
   * The active cart page navigation link
   */
  activeNavigationLink: CartPageNavigationLinks
}

interface CartPageNavLinkProps {
  /**
   * The link type based on enum
   */
  linkType: CartPageNavigationLinks
  /**
   * The active cart page navigation link
   */
  activeNavigationLink: CartPageNavigationLinks
  /**
   * The link href
   */
  href: string
  /**
   * The link disabled
   */
  disabled?: boolean
  /**
   * The link children
   */
  children: ReactNode
  /**
   * The badge props
   */
  badge?: BadgeProps
  /**
   * The icon to be displayed before the link
   */
  icon?: IconDefinition | null
}

/**
 * Build a navigation link component
 */
function CartPageNavLink ({
  linkType,
  activeNavigationLink,
  href,
  disabled = false,
  children,
  badge,
  icon,
  ...otherAttributes
}: CartPageNavLinkProps): ReactElement {
  const badgeColor = badge?.children === null || badge?.children === 0 ? 'gray-500' : badge?.color
  const childrenContainerClassName = clsx(
    'col ps-0 pe-0',
    icon != null ? 'ps-2' : 'ps-0 ps-lg-3'
  )

  return (
    <NavLink
      active={!disabled && (linkType === activeNavigationLink)}
      href={href}
      disabled={disabled}
    >
      <div className='row' {...otherAttributes}>
        {icon != null && (
          <div className='col-auto ps-0 ps-lg-3 pe-0 d-flex align-items-center'>
            <FontAwesomeIcon icon={icon} className='fs-4' />
          </div>
        )}
        <div className={childrenContainerClassName}>
          {children}
        </div>
        <div className='col-auto order-first order-lg-last ps-2 pe-2 pe-lg-3 d-flex align-items-center'>
          <Badge color={badgeColor} data-test='cart-item-count-on-tab'>
            {badge?.children}
          </Badge>
        </div>
      </div>
    </NavLink>
  )
}

/**
 * Cart page navigation
 */
function CartPageNavigation ({
  activeNavigationLink
}: CartPageNavigationProps): ReactElement {
  const { data } = useGetCartPageNavigationQuery()
  const canViewJewelerShowcaseRequests = data?.viewerPermissions.canViewJewelerShowcaseRequests
  const canSaveCartItems = data?.viewerPermissions.canSaveCartItems
  const canViewShowcaseOnlyUsers = data?.viewerPermissions.canViewShowcaseOnlyUsers
  const canViewConsultantCarts = data?.viewerPermissions.canViewConsultantCarts
  const canViewCart = data?.viewerPermissions.canViewCart
  const cartItemCount = data?.cartItemCount
  const savedItemCount = canSaveCartItems === true ? data?.savedCartLines?.count : 0
  const jshowcaseItemCount = canViewJewelerShowcaseRequests === true ? data?.pendingJewelerShowcaseCallbackRequests?.count ?? 0 : 0
  const consultantCartItemCounts = canViewConsultantCarts === true ? data?.consultantCarts.cartLineCount : 0
  const showcaseOnlyCartItemCount = canViewShowcaseOnlyUsers === true ? data?.showcaseOnlyCarts.count : 0
  const hasScheduledOrder = data?.viewer?.scheduledOrder != null
  const customersWithCart = data?.customersWithCart.length ?? 0

  return (
    <Nav tabs className='mb-5 mb-lg-0 d-print-none'>
      {canViewCart === true && (
        <NavItem>
          <CartPageNavLink
            linkType='cart'
            activeNavigationLink={activeNavigationLink}
            href='/cart'
            badge={{ color: 'primary', children: cartItemCount }}
            icon={hasScheduledOrder ? fas.faClock : null}
          >
            Cart Items
          </CartPageNavLink>
        </NavItem>
      )}
      {canSaveCartItems === true && (
        <NavItem>
          <CartPageNavLink
            linkType='saveditems'
            activeNavigationLink={activeNavigationLink}
            href='/cart/saveditems'
            badge={{ color: 'primary', children: savedItemCount }}
            data-test='saved-items-tab'
          >
            Saved For Later
          </CartPageNavLink>
        </NavItem>
      )}
      {canViewShowcaseOnlyUsers === true && (
        <NavItem>
          <CartPageNavLink
            linkType='subcarts'
            activeNavigationLink={activeNavigationLink}
            href='/cart/subcarts'
            badge={{ color: 'primary', children: showcaseOnlyCartItemCount }}
          >
            Showcase Only Carts
          </CartPageNavLink>
        </NavItem>
      )}
      {canViewJewelerShowcaseRequests === true && (
        <NavItem>
          <CartPageNavLink
            linkType='jewelershowcasecarts'
            activeNavigationLink={activeNavigationLink}
            href='/cart/jewelershowcasecarts'
            badge={{ color: 'danger', children: jshowcaseItemCount }}
          >
            Jeweler Showcase Requests
          </CartPageNavLink>
        </NavItem>
      )}
      {canViewConsultantCarts === true && (
        <NavItem>
          <CartPageNavLink
            linkType='alldotcarts'
            activeNavigationLink={activeNavigationLink}
            href='/cart/alldotcarts'
            badge={{ color: 'primary', children: consultantCartItemCounts }}
          >
            DOT Carts
          </CartPageNavLink>
        </NavItem>
      )}
      {canViewConsultantCarts === true && (
        <NavItem>
          <CartPageNavLink
            linkType='cartsearch'
            activeNavigationLink={activeNavigationLink}
            href='/cart/cartsearch'
            badge={{ color: 'primary', children: customersWithCart }}
          >
            Search Customer Carts
          </CartPageNavLink>
        </NavItem>
      )}
    </Nav>
  )
}

export {
  CartPageNavigation
}
