import { type AddToCartSectionInfoFragment, SalesType, useGetDotOptionsPermissionsQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { Select, Zone } from '@stuller/stullercom/ui'
import { useState, type ChangeEvent, type ReactElement } from 'react'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'

interface AddToCartDotSectionProps {
  addToCartSectionInfo?: AddToCartSectionInfoFragment | null
}

const SalesTypes = [
  { key: SalesType.CrossSell, value: 'Cross Sell' },
  { key: SalesType.Upsell, value: 'Upsell' },
  { key: SalesType.Substitute, value: 'Substitute' }
]

const mapSalesTypeToDisplayString = (salesType: SalesType): string => {
  return SalesTypes.find((type) => type.key === salesType)?.value ?? ''
}

const mapStringToSalesType = (salesType: string): SalesType | undefined => {
  return SalesTypes.find((type) => type.value === salesType)?.key ?? undefined
}

function AddToCartDotSection ({ addToCartSectionInfo }: AddToCartDotSectionProps): ReactElement {
  const { salesType, setSalesType } = useAddToCartSectionContext()
  const salesTypeDisplayString = mapSalesTypeToDisplayString(salesType?.toString() as SalesType)
  const [salesTypeForSelect, setSalesTypeForSelect] = useState<string>(salesTypeDisplayString)

  const { data: permissionsData, loading: permissionsLoading } = useGetDotOptionsPermissionsQuery({
    onError: (error) => {
      logger.error('Error getting DOT options permissions', {}, error)
    }
  })

  const { canSetOrderLineSalesType } = permissionsData?.viewerPermissions ?? { canSetOrderLineSalesType: false }

  const { planningGroup, planningCode, itemStatus, hasCadFile } = addToCartSectionInfo ?? {}

  function handleSalesTypeChange (event: ChangeEvent<HTMLSelectElement>): void {
    setSalesTypeForSelect(event.target.value)
    const salesTypeConverted = mapStringToSalesType(event.target.value)
    setSalesType(salesTypeConverted)
  }

  return (
    <Zone color='consultant-mode' className='bg-red-100 p-3 rounded'>
      {!permissionsLoading && canSetOrderLineSalesType && (
        <div>
          <div className='mb-2 fw-bold'>Sales Type</div>
          <Select value={salesTypeForSelect} onChange={handleSalesTypeChange}>
            <option value=''>None</option>
            {SalesTypes.map((salesType) => (
              <option key={salesType.key} value={salesType.value}>
                {salesType.value}
              </option>
            ))}
          </Select>
        </div>
      )}
      <div className='my-3'>
        <div className='row'>
          <div className='col-6 col-lg-4 fw-bold'>Planning Code:</div>
          <div className='col-6 col-lg-8'>{planningCode}</div>
        </div>
        <div className='row'>
          <div className='col-6 col-lg-4 fw-bold'>Item Status:</div>
          <div className='col-6 col-lg-8'>{itemStatus}</div>
        </div>
        <div className='row'>
          <div className='col-6 col-lg-4 fw-bold'>Planning Group:</div>
          <div className='col-6 col-lg-8'>{planningGroup}</div>
        </div>
        <div className='row'>
          <div className='col-6 col-lg-4 fw-bold'>Has CAD File:</div>
          <div className='col-6 col-lg-8'>{(hasCadFile ?? false) ? 'Yes' : 'No'}</div>
        </div>
      </div>
    </Zone>
  )
}

export { AddToCartDotSection }
