import { type ReactElement } from 'react'
import { Card, CardBody } from '@stuller/stullercom/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import Link from 'next/link'
import { isStringEmpty } from '@stuller/shared/util/core'

interface AddToCartToastProps {
  image?: string | null
  title: string
}

/**
 * Success toast notification after adding a product to cart
 */
function AddToCartSuccessToast ({ title, image }: AddToCartToastProps): ReactElement {
  const hasImage = !isStringEmpty(image)

  return (
    <>
      <FontAwesomeIcon
        icon={fas.faCheckCircle}
        size='lg'
        className='text-success me-2'
      />
      Added
      <span className='mx-2'>|</span>
      <Link href='/cart' className='text-body fw-normal'>Go to Cart</Link>
      <Card className='mt-3'>
        <CardBody className='container'>
          <div className='row'>
            {hasImage && (
              <div className='col-auto'>
                <img src={image} style={{ maxWidth: 70 }} />
              </div>
            )}
            <div className='col'>
              {title}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export {
  AddToCartSuccessToast
}
