import {
  forwardRef,
  type ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
  type CSSProperties
} from 'react'
import clsx from 'clsx'
import { useEventListener } from '@stuller/shared/util/react-hooks'
import { siteConfig } from '@stuller/stullercom/util/site-config'

const BootstrapCollapseImport = typeof window !== 'undefined' && (siteConfig.NODE_ENV === 'test' ? require('bootstrap/js/dist/collapse') : import('bootstrap/js/dist/collapse'))

export interface CollapseProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Indicates the collapse is open
   */
  isOpen?: boolean
  /**
   * Indicates to show the collapse as horizontal (requires `width` on child element)
   */
  horizontal?: boolean
  /**
   * Handler called when collapse is opening
   */
  onShow?: (event: Event) => void
  /**
   * Handler called once the collapse is shown
   */
  onShown?: (event: Event) => void
  /**
   * Handler called when the collapse is closing
   */
  onHide?: (event: Event) => void
  /**
   * Handler called once the collapse is hidden
   */
  onHidden?: (event: Event) => void
}

/**
 * Toggle the visibility of content with a collapse.
 *
 * For easy collapse state management use `useToggle`.
 */
const Collapse = forwardRef<HTMLDivElement, CollapseProps>(({
  className,
  children,
  isOpen = false,
  horizontal = false,
  onShow = () => {},
  onShown = () => {},
  onHide = () => {},
  onHidden = () => {},
  ...otherAttributes
}, ref) => {
  const innerRef = useRef<HTMLDivElement>(null)
  // Expose innerRef, must use non-null assertion because signature requires it and conditional is not allowed
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => innerRef.current!, [])
  const [collapse, setCollapse] = useState<any>()
  const [collapsing, setCollapsing] = useState(false)
  const classNames = clsx(
    'collapse',
    horizontal && 'collapse-horizontal',
    className,
    isOpen && (typeof window === 'undefined' || (typeof window !== 'undefined' && collapse == null) || (collapse == null && collapsing)) && 'show' // fixing SSR bug
  )

  // Init collapse
  // TODO: remove once SSR is safe https://github.com/twbs/bootstrap/pull/34989
  useEffect(() => {
    if (innerRef?.current != null && collapse == null && BootstrapCollapseImport !== false) {
      if (siteConfig.NODE_ENV !== 'test') {
        if (isOpen) {
          innerRef.current?.classList.add('show')
        }
        // eslint-disable-next-line promise/prefer-await-to-then
        BootstrapCollapseImport?.then((bootstrapModal: any) => {
          const BootstrapCollapse = bootstrapModal.default
          setCollapse(new BootstrapCollapse(innerRef.current as Element, { show: isOpen, toggle: false }))
        })
      } else {
        setCollapse(new BootstrapCollapseImport(innerRef.current as Element, { show: isOpen, toggle: false }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerRef.current, collapse, isOpen])

  // fixing SSR bug
  useEffect(() => {
    if (collapse !== 'undefined' && innerRef.current !== null && isOpen) {
      innerRef.current?.classList.add('show')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse])

  // Handle show
  const handleShow = useCallback((event: Event) => {
    setCollapsing(true)
    onShow(event)
  }, [setCollapsing, onShow])

  // Handle shown
  const handleShown = useCallback((event: Event) => {
    setCollapsing(false)
    onShown(event)
  }, [setCollapsing, onShown])

  // Handle hide
  const handleHide = useCallback((event: Event) => {
    setCollapsing(true)
    onHide(event)
  }, [setCollapsing, onHide])

  // Handle hidden
  const handleHidden = useCallback((event: Event) => {
    setCollapsing(false)
    onHidden(event)
  }, [setCollapsing, onHidden])

  // Assign event listeners
  useEventListener('show.bs.collapse', handleShow, innerRef)
  useEventListener('shown.bs.collapse', handleShown, innerRef)
  useEventListener('hide.bs.collapse', handleHide, innerRef)
  useEventListener('hidden.bs.collapse', handleHidden, innerRef)

  // Dispose collapse
  useEffect(() => {
    return () => {
      collapse?.dispose?.()
    }
  }, [collapse])

  // Toggle collapse
  useEffect(() => {
    if (collapse != null) {
      if (isOpen) {
        collapse.show()
      } else {
        collapse.hide()
      }
    }
  }, [collapse, isOpen, collapsing])

  return (
    <div
      className={classNames}
      ref={innerRef}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
Collapse.displayName = 'Collapse'

export {
  Collapse
}
