import { type ReactElement } from 'react'
import { fab } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@stuller/stullercom/ui'

interface ItemSharePinterestProps {
  /**
   * Callback to execute when the item is shared
   */
  onShare: () => void
}

/**
 * Share item via Pinterest
 */
function ItemSharePinterest ({ onShare }: ItemSharePinterestProps): ReactElement {
  return (
    <Button
      tag='a'
      href={`http://pinterest.com/pin/create/button/?url=${encodeURI(window.location.href)}`}
      target='_blank'
      color='anchor-dark'
      className='fw-normal'
      onClick={onShare}
    >
      <FontAwesomeIcon icon={fab.faPinterest} className='me-2' color='#f0002a' />
      Pinterest
    </Button>
  )
}

export {
  ItemSharePinterest
}
