import {
  CustomShowcaseSettingDetailType,
  useGetHeaderMainNavCategoriesQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { type Auth } from '@stuller/stullercom/feat/auth'
import { useMemo } from 'react'

export interface MainLink {
  /**
   * Text to show for the main nav item
   */
  label: string
  /**
   * Relative path to navigate to
   */
  url: string
  /**
   * CMS content container id to get and show in the popover
   */
  contentContainerId?: string | null
  /**
   * CMS content container name to get and show in the popover
   */
  contentContainerName?: string | null
  /**
   * Function to get sublinks for the main nav item (can use Apollo hooks)
   */
  sublinks?: () => {
    loading: boolean
    links: MainLink[]
    featured: MainLink[]
  }
}

export interface QuickLink {
  /**
   * Text to show for the quicklink nav item
   */
  label: string
  /**
   * Relative path to navigate to
   */
  url: string
  /**
   * Indicates if the quicklink should be shown at all
   */
  show: (auth: Auth) => boolean
  /**
   * Indicates if the quicklink should be shown in main nav bar (only applied if `show` returns `true`)
   */
  showInNavBar: boolean
}

/**
 * Main nav links
 */
const mainLinks: MainLink[] = [
  {
    label: 'Products',
    url: '/browse',
    contentContainerName: null,
    sublinks: function useSubLinks () {
      const { data, loading } = useGetHeaderMainNavCategoriesQuery()
      const [links, featured] = useMemo(() => {
        const links: MainLink[] = []
        const featured: MainLink[] = []

        if (data?.mainNavCategories != null) {
          const categories = [...data.mainNavCategories]
            .sort((a, b) => {
              return (a.mainNavSortOrder ?? Infinity) - (b.mainNavSortOrder ?? Infinity)
            })

          for (const category of categories) {
            const link = {
              label: category.mainNavDisplayName,
              url: category.url,
              contentContainerId: category.mainNavContentContainerId
            }
            if (category.mainNavIsFeatured) {
              featured.push(link)
            } else {
              links.push(link)
            }
          }
        }

        return [links, featured]
      }, [data])

      return {
        loading,
        links,
        featured
      }
    }
  },
  {
    label: 'Services',
    url: '/services',
    contentContainerName: 'services-navbar-dropdown-main-content'
  },
  {
    label: 'Customization',
    url: '/customization',
    contentContainerName: 'customization-navbar-dropdown-main-content'
  },
  {
    label: 'Events & Training',
    url: '/events',
    contentContainerName: 'events-training-navbar-dropdown-main-content'
  },
  {
    label: 'Educational Resources',
    url: '/help-center',
    contentContainerName: 'educational-resources-navbar-dropdown-main-content'
  }
]

/**
 * Main nav quicklinks
 */
const quickLinks: QuickLink[] = [
  {
    label: 'Quick Order',
    url: '/quickorder',
    show: () => true,
    showInNavBar: true
  },
  {
    label: 'Quick Shops',
    url: '/quickshops',
    show: () => true,
    showInNavBar: true
  },
  {
    label: 'Deals',
    url: '/deals',
    show: () => true,
    showInNavBar: true
  },
  {
    label: 'Stuller Blog',
    url: 'https://blog.stuller.com',
    show: () => true,
    showInNavBar: false
  },
  {
    label: 'Catalogs + Publications',
    url: '/catalogs',
    show: () => true,
    showInNavBar: false
  },
  {
    label: 'Contact Us',
    url: '/contactus',
    show: () => true,
    showInNavBar: false
  }
]

/**
 * Main nav quicklinks for showcase
 */
const showcaseQuickLinks: QuickLink[] = [
  {
    label: 'Shop Favorites',
    url: '/favorite',
    show: (auth) => {
      const { isJewelerShowcase, getCustomShowcaseSetting } = auth
      const disableFavorites = getCustomShowcaseSetting(CustomShowcaseSettingDetailType.DisableFavorites)?.[0] === 'true'

      return !isJewelerShowcase && !disableFavorites
    },
    showInNavBar: true
  }
]

export {
  mainLinks,
  quickLinks,
  showcaseQuickLinks
}
