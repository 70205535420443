import { type ApolloClient } from '@apollo/client'
import { type GetAuth } from '@stuller/stullercom/feat/auth'
import {
  GetAppShowcaseSettingsDocument,
  type GetAppShowcaseSettingsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { type CustomAppProps } from './types'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

/**
 * Get showcase settings according to domain/user info
 *
 * jewelerShowcaseDomainInfo: search by `subdomain`
 * jewelerShowcasePunchoutInfo: search by `memberId`
 * authenticated user: search by `userId`
 */
async function getShowcaseSettings (apolloClient: ApolloClient<unknown>, auth: GetAuth): Promise<CustomAppProps['showcaseSettings'] | null> {
  const { isShowcase, isStullerComShowcaseMode } = auth
  let showcaseSettings = null

  if (isShowcase) {
    try {
      const results = await apolloClient.query<GetAppShowcaseSettingsQuery>({ query: GetAppShowcaseSettingsDocument })
      showcaseSettings = results?.data?.showcaseSettings ?? null

      // Throw only if in stullercom mode & no showcaseSettings. For jewelershowcase a 403 is handled downstream by isInvalidShowcase.ts
      if (isStullerComShowcaseMode && showcaseSettings == null) {
        throw new Error('In showcase but no showcase settings found.')
      }
    } catch (error) {
      logger.error('Error getting showcase settings', {}, error)
    }
  }

  return showcaseSettings
}

export {
  getShowcaseSettings
}
