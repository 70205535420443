import { Form, Input, FormLabel, FormGroup, Textarea, Button, FormFeedback, Recaptcha, Loading, Alert } from '@stuller/stullercom/ui'
import { type ReactElement, useRef, useState } from 'react'
import { validateForm, type ValidateFormErrors } from '@stuller/shared/util/schema-validation'
import { z } from 'zod'
import { siteConfig } from '@stuller/stullercom/util/site-config'
import { type ItemType, useSerializedPdpPagesSendProductShareEmailMutation } from '@stuller/stullercom/data-access/apollo-queries'
import { getClientValidationErrors, type ValidationError } from '@stuller/stullercom/data-access/apollo-client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ItemShareEmailFormProps {
  /**
   * The id of the item
   */
  itemId: string
  /**
   * The type of the item
   */
  itemType: ItemType
  /**
   * The image of the product to share
   */
  image?: string | null
  /**
   * Function to toggle the modal
   */
  onToggle: () => void
}

/**
 * Schema to validate form fields
 */
const schema = z.object({
  senderName: z.string().min(1, { message: 'Required' }),
  senderEmail: z.string().email({ message: 'Please enter a valid email' }).min(1, { message: 'Required' }),
  recipientName: z.string().min(1, { message: 'Required' }),
  recipientEmail: z.string().email({ message: 'Please enter a valid email' }).min(1, { message: 'Required' }),
  message: z.string().min(1, { message: 'Required' }),
  recaptchaResponseToken: z.string().min(1, { message: 'Required' })
})

/**
 * A form to share a product via email
 */
function ItemShareEmailForm ({ itemId, itemType, image, onToggle }: ItemShareEmailFormProps): ReactElement {
  const formRef = useRef<HTMLFormElement>(null)
  const [success, setSuccess] = useState<boolean>(false)
  const [formInput, setFormInput] = useState<Record<string, string>>({
    senderName: '',
    senderEmail: '',
    recipientName: '',
    recipientEmail: '',
    message: '',
    recaptchaResponseToken: ''
  })
  const [formErrors, setFormErrors] = useState<ValidateFormErrors>({})
  const [serverFormErrors, setServerFormErrors] = useState<ValidationError[] | null>(null)
  const [sendProductShareEmail, { loading }] = useSerializedPdpPagesSendProductShareEmailMutation({
    variables: {
      input: {
        senderName: formInput.senderName,
        senderEmail: formInput.senderEmail,
        recipientName: formInput.recipientName,
        recipientEmail: formInput.recipientEmail,
        message: formInput.message,
        recaptchaResponseToken: formInput.recaptchaResponseToken,
        itemId: {
          id: itemId,
          type: itemType
        }
      }
    },
    onCompleted: () => {
      setSuccess(true)
    },
    onError: (error) => {
      const validationErrors = getClientValidationErrors(error)
      const errorMessages = validationErrors.length > 0 ? validationErrors : [{ message: 'There was an issue sharing via email', propertyNames: [] }]
      setServerFormErrors(errorMessages)
      logger.error('Error sending product share email', {}, error)
    }
  })

  /**
   * Function to handle the schema validation and run the mutation or display errors
   */
  async function handleSubmit (event: React.FormEvent): Promise<void> {
    event.preventDefault()
    event.stopPropagation()
    setFormErrors({})
    setServerFormErrors(null)
    const result = validateForm(schema, formInput)
    if (result.success) {
      try {
        await sendProductShareEmail()
      } catch (e) {
        console.error(e)
      }
    } else {
      setFormErrors(result.errors)
    }
  }

  /**
   * Handle the input change from all inputs
   */
  function handleInputChange (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setFormInput((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  /**
   * Handle the recaptcha token change
   */
  function handleRecaptchaChange (token: string | null): void {
    setFormInput((prev) => ({
      ...prev,
      recaptchaResponseToken: token ?? ''
    }))
  }

  return (
    <Loading loading={loading}>
      {!success
        ? (
          <>
            {!isStringEmpty(image) && (
              <img src={image} alt='Product Image' style={{ width: 150 }} className='mb-5' />
            )}
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div className='row'>
                <FormGroup groupId='sender-name' className='col-sm'>
                  <FormLabel>Sender's Name</FormLabel>
                  <Input
                    type='text'
                    required
                    onChange={handleInputChange}
                    name='senderName'
                    invalid={formErrors.senderName != null}
                    placeholder='Full Name'
                  />
                  <FormFeedback valid={formErrors.senderName == null}>{formErrors.senderName}</FormFeedback>
                </FormGroup>
                <FormGroup groupId='sender-email' className='mt-5 mt-sm-0 col-sm'>
                  <FormLabel>Sender's Email</FormLabel>
                  <Input
                    type='email'
                    required
                    onChange={handleInputChange}
                    name='senderEmail'
                    invalid={formErrors.senderEmail != null}
                    placeholder='Email'
                  />
                  <FormFeedback valid={formErrors.senderEmail == null}>{formErrors.senderEmail}</FormFeedback>
                </FormGroup>
              </div>
              <div className='row mt-sm-5'>
                <FormGroup groupId='recipient-name' className='mt-5 mt-sm-0 col-sm'>
                  <FormLabel>Recipient's Name</FormLabel>
                  <Input
                    type='text'
                    required
                    onChange={handleInputChange}
                    name='recipientName'
                    invalid={formErrors.recipientName != null}
                    placeholder='Full Name'
                  />
                  <FormFeedback valid={formErrors.recipientName == null}>{formErrors.recipientName}</FormFeedback>
                </FormGroup>
                <FormGroup groupId='recipient-email' className='mt-5 mt-sm-0 col-sm'>
                  <FormLabel>Recipient's Email</FormLabel>
                  <Input
                    type='email'
                    required
                    onChange={handleInputChange}
                    name='recipientEmail'
                    invalid={formErrors.recipientEmail != null}
                    placeholder='Email'
                  />
                  <FormFeedback valid={formErrors.recipientEmail == null}>{formErrors.recipientEmail}</FormFeedback>
                </FormGroup>
              </div>
              <FormGroup groupId='message' className='mt-5'>
                <FormLabel>Message</FormLabel>
                <Textarea
                  onChange={handleInputChange}
                  name='message'
                  invalid={formErrors.message != null}
                  style={{ minHeight: 80 }}
                />
                <FormFeedback valid={formErrors.message == null}>{formErrors.message}</FormFeedback>
              </FormGroup>
              <FormGroup id='recaptcha' className='mt-5'>
                <Recaptcha
                  onChange={handleRecaptchaChange}
                  siteKey={siteConfig.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? ''}
                  invalid={formErrors.recaptchaResponseToken != null}
                />
                <FormFeedback className='d-block w-100' valid={false}>{formErrors.recaptchaResponseToken}</FormFeedback>
              </FormGroup>
              <Button type='submit' color='primary' className='mt-5'>Send</Button>
              {serverFormErrors !== null && (
                <Alert color='danger' icon className='mt-4'>
                  <div className='row align-items-center gy-3'>
                    <div className='col'>
                      {serverFormErrors?.map((error, i) => (
                        <p key={i} className='m-0'>{error.message}</p>
                      ))}
                    </div>
                  </div>
                </Alert>
              )}
            </Form>
          </>
        )
        : (
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <FontAwesomeIcon icon={fas.faCircleCheck} size='7x' className='text-success' />
            <p className='mt-4 fs-4'>Your product has been shared</p>
            {onToggle != null && (
              <Button color='primary' onClick={onToggle} className='mt-3'>Close</Button>
            )}
          </div>
        )}
    </Loading>
  )
}

export {
  ItemShareEmailForm
}
