import { type ReactElement } from 'react'
import { CartItemConfigurationCard } from './CartItemConfigurationCard'
import { IMAGE_COMING_SOON } from '@stuller/shared/util/constants'
import { type ImprintingConfigurationDetail, type ImprintingLocationColorConfiguration } from '../../shared/types'
import clsx from 'clsx'

/**
 * Props for the imprinting component
 */
interface CartItemConfigurationImprintingProps {
  /**
   * The imprinting data
   */
  imprintingData: ImprintingConfigurationDetail
}

/**
 * Props for the color description component
 */
interface ColorDescriptionProps {
  color: ImprintingLocationColorConfiguration
  index: number
  paddingBottom?: boolean
}

/**
 * Component for the imprinting item in the cart item configuration
 */
function CartItemConfigurationImprinting ({
  imprintingData
}: CartItemConfigurationImprintingProps): ReactElement {
  return (
    <>
      {imprintingData.imprintings != null && imprintingData.imprintings.length > 0 && (
        <>
          {imprintingData.imprintings.map((imprinting, index) => (
            <CartItemConfigurationCard key={index}>
              <div className='row'>
                <div className='col-2 col-sm-auto'>
                  <img className='w-100' style={{ maxWidth: 65 }} src={imprinting.locationImage ?? IMAGE_COMING_SOON} />
                </div>
                <div className='col d-flex align-items-center'>
                  <p className='mb-0 fw-bold'>{imprinting.locationDescription}</p>
                </div>
              </div>
              <div className='my-3 border-bottom' />
              <div className='row'>
                <div className='col-2 col-sm-auto'>
                  <img className='w-100' style={{ maxWidth: 65 }} src={imprinting.logo.imagePreview ?? IMAGE_COMING_SOON} />
                </div>
                <div className='col d-flex flex-column justify-content-center'>
                  {imprinting.colors.map((color, index) => (
                    <ColorDescription
                      key={index}
                      index={index}
                      color={color}
                      paddingBottom={index !== imprinting.colors.length - 1}
                    />
                  ))}
                </div>
              </div>
            </CartItemConfigurationCard>
          ))}
        </>
      )}
    </>
  )
}

/**
 * Component for the color description in the imprinting item
 */
function ColorDescription ({
  color,
  index,
  paddingBottom = false
}: ColorDescriptionProps): ReactElement {
  const classNames = clsx(
    'd-flex flex-column',
    paddingBottom && 'mb-3'
  )

  return (
    <div className={classNames}>
      <p className='mb-0 text-gray-600 fs-7 text-uppercase'>Color #{index + 1}</p>
      <div className='d-flex flex-column'>
        <div className='d-flex align-items-center'>
          {color.hex != null && (
            <div
              className='rounded-circle me-2'
              style={{
                backgroundColor: color?.hex,
                width: 15,
                height: 15
              }}
            />
          )}
          <p className='mb-0 fw-light'>{color.name}</p>
        </div>
        {color.instructions != null && (
          <>
            <p className='mb-0 text-gray-600 fs-7 text-uppercase'>Notes For Color #{index + 1}</p>
            <p className='mb-0 fw-light'>{color.instructions}</p>
          </>
        )}
      </div>
    </div>
  )
}

export {
  CartItemConfigurationImprinting
}
