import { type ReactElement } from 'react'
import { type InStockStatusInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { StatusTopSection } from './StatusTopSection'
import { EstimatedShipDateSection } from './EstimatedShipDateSection'

interface GenericInStockStatusProps {
  /**
   * in-stock status info, including backorder and onhand quantity
   */
  inStockStatus: InStockStatusInfoFragment['inStockStatus']
  /**
   * context-based human readable ship date
   */
  readableShipDate?: string
  /**
   * whether or not the product is returnable
   */
  isReturnable: boolean
  /**
   * whether or not the ship date is today
   */
  isShipDateToday: boolean
}

function GenericInStockStatus ({ inStockStatus, readableShipDate, isReturnable, isShipDateToday }: GenericInStockStatusProps): ReactElement {
  return (
    <>
      <StatusTopSection isReturnable={isReturnable} inStockStatusCode={inStockStatus.statusCode} color='black'>{inStockStatus.status}</StatusTopSection>
      <EstimatedShipDateSection isShipDateToday={isShipDateToday} readableShipDate={readableShipDate} />
    </>
  )
}

export {
  GenericInStockStatus
}
