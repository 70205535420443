import { type ReactElement } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { Title } from '@stuller/stullercom/feat/layout'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { ErrorContact } from './ErrorContact'
import { ERROR_500_IMAGE } from '@stuller/shared/util/constants'

/**
 * 500 error page (no layout)
 */
function Error500 (): ReactElement | null {
  const { authUser, isShowcase } = useAuth()

  return (
    <div className='container-xxxl mw-xxl px-4 py-4'>
      <Title>Something Went Wrong! - 500</Title>
      <div>
        <h2 className='text-center'>Something Went Wrong! (500 Error)</h2>
        <div className='row'>
          <div
            className={clsx('pb-6', authUser != null ? 'col-md-6' : 'col')}
            style={{ background: `url(${ERROR_500_IMAGE}) no-repeat center center` }}
          >
            <p>It looks as though something in our system has broken.</p>
            <p>
              Don't panic - an email has been sent to the web development team that something went wrong.
            </p>
            <p>
              {isShowcase
                ? <>Try visiting one of these pages.</>
                : <>Use the form to the right to tell us what you were doing so we can fix this problem, then try visiting one of these pages.</>}
            </p>

            <ul>
              <li><Link href='/'>Return to the Homepage</Link></li>
              <li><Link href='/browse'>Products</Link></li>
              {isShowcase && (
                <>
                  <li><Link href='/services'>Services</Link></li>
                  <li><Link href='/events'>Events</Link></li>
                  <li><Link href='/education'>Education</Link></li>
                </>
              )}
            </ul>

            <ErrorContact />
          </div>

          {authUser != null && (
            <div className='col-md-6'>
              <div className='border text-break my-2 p-3'>
                <CmsContent contentContainerName='500-error-form' />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {
  Error500
}
