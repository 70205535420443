import { type Auth } from '@stuller/stullercom/feat/auth'
import { trackEvent } from '../track'
import type { GtmItemTracking, ItemOrProductGroupTracking } from './types'

type ProductGroupTrackingInfo = Extract<ItemOrProductGroupTracking, { __typename?: 'ProductGroup' }>
type ItemTrackingInfo = Extract<ItemOrProductGroupTracking, { __typename?: 'Product' | 'SerializedProduct' | 'Configuration' }>

/**
 * Map Product data to ItemTracking object
 */
function productGroupTrackingMapper (tracking: ProductGroupTrackingInfo): GtmItemTracking {
  return {
    item_id: tracking.code,
    item_variant: null,
    item_name: tracking.title,
    item_brand: null,
    item_category: null,
    item_category2: null,
    item_category3: null,
    item_category4: null,
    item_category5: null,
    currency: null,
    discount: null,
    price: null,
    quantity: 1,
    config_id: null,
    serial_number: null,
    coupon: null,
    index: tracking.index ?? 0,
    item_list_id: tracking.item_list_id ?? null,
    item_list_name: null
  }
}

/**
 * Map Product data to ItemTracking object
 */
function itemTrackingMapper (tracking: ItemTrackingInfo, auth: Auth): GtmItemTracking {
  const { isShowcase } = auth
  const price = isShowcase
    ? null
    : tracking.price?.roundedPerUnit.discount?.originalPrice.valueInUsd ??
      tracking.price?.roundedPerUnit.total.valueInUsd ??
      null

  return {
    item_id: tracking.defaultProductGroup?.code ?? tracking.series,
    item_variant: tracking.itemNumber,
    item_name: tracking.title,
    item_brand: tracking.brand ?? null,
    item_category: tracking.merchandiseHierarchy?.segment2 ?? null,
    item_category2: tracking.merchandiseHierarchy?.segment3 ?? null,
    item_category3: tracking.merchandiseHierarchy?.segment4 ?? null,
    item_category4: tracking.merchandiseHierarchy?.segment5 ?? null,
    item_category5: tracking.merchandiseHierarchy?.segment6 ?? null,
    currency: price != null ? 'USD' : null,
    discount: price != null ? tracking.price?.roundedPerUnit.discount?.value.valueInUsd ?? 0 : null,
    price,
    quantity: tracking.options?.quantity ?? 1,
    config_id: tracking.__typename === 'Configuration' ? parseInt(tracking.id) : null,
    serial_number: tracking.__typename === 'SerializedProduct' ? parseInt(tracking.id) : null,
    coupon: null,
    index: tracking.index ?? 0,
    item_list_id: tracking.item_list_id ?? null,
    item_list_name: null
  }
}

/**
 * Helper Method to map Product and ProductGroup data to ItemTracking object
*/
function productOrGroupTrackingMapper (tracking: ItemOrProductGroupTracking, auth: Auth): GtmItemTracking | null {
  if (tracking.__typename === 'ProductGroup') {
    return productGroupTrackingMapper(tracking)
  }

  if (tracking.__typename === 'Product' || tracking.__typename === 'SerializedProduct' || tracking.__typename === 'Configuration') {
    return itemTrackingMapper(tracking, auth)
  }

  return null
}

/**
 * Track view item list event
 */
function trackItemListView (trackings: ItemOrProductGroupTracking[], auth: Auth): void {
  trackEvent('view_item_list', {
    items: trackings.map((tracking) => productOrGroupTrackingMapper(tracking, auth))
      .filter((i) => i != null)
  })
}

/**
 * Track select item event
 */
function trackItemSelect (tracking: ItemOrProductGroupTracking, auth: Auth): void {
  trackEvent('select_item', {
    items: [productOrGroupTrackingMapper(tracking, auth)]
      .filter((i) => i != null)
  })
}

/**
 * Track adding an item to cart
 */
function trackItemAddToWishlist (tracking: ItemTrackingInfo, auth: Auth): void {
  trackEvent('add_to_wishlist', {
    items: [productOrGroupTrackingMapper(tracking, auth)]
      .filter((i) => i != null)
  })
}

export {
  productOrGroupTrackingMapper,
  trackItemListView,
  trackItemSelect,
  trackItemAddToWishlist
}
