import { Alert } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'
import { type CartLineMessageInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface CartPageAlertProps {
  /**
   * The type of alert
   */
  type: 'danger' | 'warning'
  /**
   * The message of the alert
   */
  notes?: CartLineMessageInfoFragment[]
  /**
   * The message of the errors
   */
  errors?: CartLineMessageInfoFragment[]
}

/**
 * Component for the alerts on the cart page
 */
function CartPageAlert ({
  type,
  notes,
  errors
}: CartPageAlertProps): ReactElement {
  return (
    <Alert color={type} icon>
      {notes != null && notes.length > 0 && (
        <ul className='mb-0 list-unstyled'>
          {notes.map((note, index) => (
            <li key={index} className='mb-0 pb-0'>{note.message}</li>
          ))}
        </ul>
      )}
      {errors != null && errors.length > 0 && (
        <>
          {errors.map((error, index) => (
            <p key={index} className='mb-0 pb-0'>{error.message}</p>
          ))}
        </>
      )}
    </Alert>
  )
}

export {
  CartPageAlert
}
