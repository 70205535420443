import { type ReactElement, useRef, useMemo } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@stuller/stullercom/ui'
import { useCartContext } from '../shared/useCartContext'
import type { CartLine } from '../shared/types'

interface RemoveAllConfirmationModalProps {
  /**
   * Whether the modal is open
   */
  isOpen: boolean
  /**
   * Function to toggle the modal
   */
  onToggle: () => void
  /**
   * Function to run after confirming the removal of all items
   */
  onConfirm: () => void
  /**
   * The shipments in the cart
   */
  lines?: CartLine[]
}

/**
 * A confirmation modal shown before removing all items from the cart
 */
function RemoveAllConfirmationModal ({
  isOpen,
  onToggle,
  onConfirm,
  lines
}: RemoveAllConfirmationModalProps): ReactElement {
  const ref = useRef(typeof window !== 'undefined' ? document.body : undefined)
  const { cartArea, scheduledOrder } = useCartContext()

  /**
   * Handle "Remove All Items" button click
   */
  function handleConfirm (): void {
    onConfirm()
    onToggle()
  }

  /**
   * Whether the cart has limited or serialized products
   */
  const hasLimitedOrSerializedProducts = useMemo(() => {
    if (lines != null) {
      return lines.some((cartLine) => cartLine.item.isLimitedAvailability || cartLine.item.__typename === 'SerializedProduct')
    }

    return false
  }, [lines])

  const showReleaseReservationText = cartArea === 'cart-page' && scheduledOrder != null && hasLimitedOrSerializedProducts

  return (
    <Modal
      isOpen={isOpen}
      onToggle={onToggle}
      portalRef={ref}
    >
      <ModalHeader>Remove All Items</ModalHeader>
      <ModalBody className='pb-4'>
        {showReleaseReservationText && (
          <>
            <p><b>Removing this item will release the reservation.</b> Removing items from a scheduled order will release the reservation, making it available to others.</p>
            <p>Items removed from a scheduled order cannot be added back to the cart for a short period of time.</p>
          </>
        )}
        {cartArea === 'cart-page' && (
          <p className='mb-0'>Are you sure you want to delete all the items from your cart?</p>
        )}
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='danger' outline='fill' onClick={handleConfirm} data-test='remove-all-items'>Remove All Items</Button>
        <Button color='link-dark' onClick={onToggle} className='fw-normal'>Keep Items In Cart</Button>
      </ModalFooter>
    </Modal>
  )
}

export {
  RemoveAllConfirmationModal
}
