import { type ReactElement } from 'react'
import { type InStockStatusInfoFragment, ProductInStockStatusCode } from '@stuller/stullercom/data-access/apollo-queries'
import { InStockStatus } from './InStockStatus'
import { MadeToOrderStatus } from './MadeToOrderStatus'
import { OutOnConsignmentStatus } from './OutOnConsignmentStatus'
import { BackorderedStatus } from './BackorderedStatus'
import { LimitedAvailabilityStatus } from './LimitedAvailabilityStatus'
import { SoldStatus } from './SoldStatus'
import { PreorderStatus } from './PreorderStatus'
import { GenericInStockStatus } from './GenericInStockStatus'
import { WhileSuppliesLastStatus } from './WhileSuppliesLastStatus'
import { PartiallyAvailableStatus } from './PartiallyAvailableStatus'
import dayjs from 'dayjs'

interface InStockStatusProps {
  /**
   * in-stock status info, including backorder and onhand quantity
   */
  inStockStatus: InStockStatusInfoFragment['inStockStatus']
  /**
   * estimated ship date of the product
   */
  shipDate?: string
  /**
   * whether or not the product is returnable
   */
  isReturnable?: boolean
  /**
   * whether or not the product is a serialized product
   */
  isSerializedProduct: boolean
  /**
   * whether or not the serialized product is on consignment (aka on memo)
   */
  isOnConsignment: boolean
  /**
   * whether or not the serialized product is active
   */
  isActive: boolean
  /**
   * whether or not the product has limited availability
   */
  isLimitedAvailability: boolean
  /**
   * Requested quantity for the item being added to cart
   */
  requestedQuantity: number
}

/**
 * Renders the In-Stock Status section
 */
function InStockStatusSection ({
  inStockStatus,
  shipDate,
  isSerializedProduct,
  isOnConsignment,
  isLimitedAvailability,
  isActive,
  isReturnable = false,
  requestedQuantity = 1
}: InStockStatusProps): ReactElement {
  const { statusCode, backOrderInfo } = inStockStatus
  const { canBackOrder, quantityOnHand } = backOrderInfo
  const isInStock = statusCode === ProductInStockStatusCode.InStock
  const isMadeToOrder = statusCode === ProductInStockStatusCode.MadeToOrder
  const isPartiallyAvailable = statusCode === ProductInStockStatusCode.PartiallyAvailable
  const isPreorder = statusCode === ProductInStockStatusCode.PreOrder
  const isWhileSuppliesLast = statusCode === ProductInStockStatusCode.WhileSuppliesLast
  const isBackordered = canBackOrder && requestedQuantity > quantityOnHand
  const isSold = isSerializedProduct && !isActive && !isOnConsignment

  const dayJsShipDate = shipDate != null ? dayjs(shipDate) : null
  const isShipDateToday = dayJsShipDate?.isToday() ?? false
  const readableShipDate = dayJsShipDate?.toReadableDateString()

  const getComponent = (): ReactElement => {
    if (isInStock) {
      return (
        <InStockStatus
          isShipDateToday={isShipDateToday}
          readableShipDate={readableShipDate}
          inStockStatus={inStockStatus}
          isReturnable={isReturnable}
        />
      )
    }
    if (isMadeToOrder) {
      return (
        <MadeToOrderStatus
          isShipDateToday={isShipDateToday}
          readableShipDate={readableShipDate}
          inStockStatus={inStockStatus}
          isReturnable={isReturnable}
        />
      )
    }
    if (isLimitedAvailability) {
      return (
        <LimitedAvailabilityStatus
          isShipDateToday={isShipDateToday}
          readableShipDate={readableShipDate}
          inStockStatus={inStockStatus}
          isReturnable={isReturnable}
        />
      )
    }
    if (isPartiallyAvailable) {
      return (
        <PartiallyAvailableStatus
          isShipDateToday={isShipDateToday}
          readableShipDate={readableShipDate}
          inStockStatus={inStockStatus}
          isReturnable={isReturnable}
        />
      )
    }
    if (isPreorder) {
      return (
        <PreorderStatus
          inStockStatus={inStockStatus}
          readableShipDate={readableShipDate}
          isReturnable={isReturnable}
          isShipDateToday={isShipDateToday}
        />
      )
    }
    if (isBackordered) {
      return (
        <BackorderedStatus
          inStockStatusCode={inStockStatus.statusCode}
          readableShipDate={readableShipDate}
          isReturnable={isReturnable}
          isShipDateToday={isShipDateToday}
        />
      )
    }
    if (isWhileSuppliesLast) {
      return (
        <WhileSuppliesLastStatus
          inStockStatus={inStockStatus}
          isReturnable={isReturnable}
          readableShipDate={readableShipDate}
          isShipDateToday={isShipDateToday}
        />
      )
    }
    if (isOnConsignment) {
      return <OutOnConsignmentStatus />
    }
    if (isSold) {
      return <SoldStatus />
    }

    return (
      <GenericInStockStatus
        inStockStatus={inStockStatus}
        readableShipDate={readableShipDate}
        isReturnable={isReturnable}
        isShipDateToday={isShipDateToday}
      />
    )
  }

  const component = getComponent()

  return <div>{component}</div>
}

export {
  InStockStatusSection
}
