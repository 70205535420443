import { useEffect } from 'react'

/**
 * Interface to allow window.bootstrap (for this file only)
 */
declare global {
  interface Window {
    /**
     * Bootstrap classes to allow use from CMS
     */
    bootstrap: {
      Modal: object
      Carousel: object
    }
  }
}

/**
 * Imports Bootstrap JS needed via useEffect to prevent server errors
 * These are mostly just needed for things that might be done via CMS
 * Prefer the `import` route for React stuff (see Modal)
 *
 * Also adds some of the Bootstrap classes to `window.bootstrap.X` to allow use from CMS
 */
function useBootstrap (): void {
  /* eslint-disable promise/prefer-await-to-then */
  useEffect(() => {
    window.bootstrap = window.bootstrap ?? {}

    void import('bootstrap/js/dist/alert')
    void import('bootstrap/js/dist/button')
    import('bootstrap/js/dist/carousel')
      .then((i) => { window.bootstrap.Carousel = i.default })
      .catch((error: unknown) => {
        console.error(error)
      })

    void import('bootstrap/js/dist/collapse')
    // import('bootstrap/js/dist/dropdown')
    import('bootstrap/js/dist/modal')
      .then((i) => { window.bootstrap.Modal = i.default })
      .catch((error: unknown) => {
        console.error(error)
      })
    // import('bootstrap/js/dist/offcanvas')
    // import('bootstrap/js/dist/popover')
    // import('bootstrap/js/dist/scrollspy')
    // import('bootstrap/js/dist/tab')
    // import('bootstrap/js/dist/toast')
    // import('bootstrap/js/dist/tooltip')
  }, [])
  /* eslint-enable promise/prefer-await-to-then */
}

export {
  useBootstrap
}
