import { type ReactElement } from 'react'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { ItemShareEmailModal } from './ItemShareEmailModal'
import type { ItemType } from '@stuller/stullercom/data-access/apollo-queries'

interface ItemShareEmailProps {
  /**
   * Callback to execute when the item is shared
   */
  onShare: () => void
  /**
   * The id of the item
   */
  itemId: string
  /**
   * The type of the item
   */
  itemType: ItemType
  /**
   * The image of the product to share
   */
  image?: string | null
}

/**
 * Share item via email
 */
function ItemShareEmail ({ onShare, itemId, itemType, image }: ItemShareEmailProps): ReactElement {
  const [open, handleToggle] = useToggle(false)

  function handleToggleModal (): void {
    handleToggle()
    onShare()
  }

  return (
    <>
      <Button color='anchor-dark' className='fw-normal' onClick={handleToggle}>
        <FontAwesomeIcon icon={fas.faEnvelope} className='me-2' />
        Email
      </Button>
      <ItemShareEmailModal
        open={open}
        onToggle={handleToggleModal}
        itemId={itemId}
        itemType={itemType}
        image={image}
      />
    </>
  )
}

export {
  ItemShareEmail
}
