import { type AddToCartInput, type AddToCartSectionInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'
import { RequestItemsForm } from '@stuller/stullercom/feat/request-items-page'
import { useAddToCartSectionContext } from '../useAddToCartSectionContext'
import { OneClickQuoteProductCard } from './OneClickQuoteProductCard'

interface OneClickQuoteModalProps {
  addToCartSectionInfo: AddToCartSectionInfoFragment
  modalOpen: boolean
  onToggle: () => void
}

/*
* This component is a modal that allows the user to request a quote for an item. It will display a form to request a quote, and then display a confirmation message after the form is submitted.
*/
function OneClickQuoteModal ({ addToCartSectionInfo, modalOpen, onToggle }: OneClickQuoteModalProps): ReactElement {
  function handleToggle (): void {
    onToggle()
  }

  const { itemId, itemType, trackingParams, customerNotes, options } = useAddToCartSectionContext()

  const {
    recommendationIndex,
    recommendationSource,
    recommendationId,
    recommendationType
  } = trackingParams ?? {}
  const numericRecommendationIndex = recommendationIndex != null ? parseInt(recommendationIndex) : undefined
  const numericRecommendationId = recommendationId != null ? parseInt(recommendationId) : undefined

  const addToCarts: AddToCartInput[] = [{
    itemId: {
      id: itemId,
      type: itemType
    },
    options: {
      isAnnealed: options?.isAnnealed ?? false,
      isForConsignment: options?.isForConsignment ?? false,
      isMatched: options?.isMatched ?? false,
      bagAndTagOptions: options?.bagAndTagOptions,
      customerNotes,
      specialInstructions: options?.specialInstructions,
      quantity: options?.quantity ?? 1,
      length: options?.length,
      width: options?.width,
      matchingCode: undefined, // TODO: add options?.matchingCode,
      consultantDiscount: undefined, // TODO: add options?.consultantDiscount,
      salesType: undefined // TODO: add options?.salesType,
    },
    tracking: {
      index: numericRecommendationIndex,
      source: recommendationSource ?? '',
      sourceId: numericRecommendationId,
      type: recommendationType
    }
  }]

  return (
    <Modal isOpen={modalOpen} onToggle={handleToggle} data-gtm-click-section=''>
      <ModalHeader onToggle={handleToggle} />
      <ModalBody>
        <RequestItemsForm
          title='Request This Item'
          addToCarts={addToCarts}
          onCancel={handleToggle}
          onContinue={handleToggle}
        >
          <OneClickQuoteProductCard addToCartSectionInfo={addToCartSectionInfo} />
        </RequestItemsForm>
      </ModalBody>
      <ModalFooter />
    </Modal>
  )
}

export { OneClickQuoteModal }
