import { type ReactElement } from 'react'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type CartMessages } from '../shared/types'
import { CartPageAlert } from './CartPageAlert'

interface CartErrorsNotesProps {
  /**
   * The type of alert
   */
  type: 'danger' | 'warning'
  /**
   * The cart errors or notes
   */
  messages?: CartMessages
}

/**
 * Shows the top level cart errors and notes
 */
function CartErrorsNotes ({ type, messages }: CartErrorsNotesProps): ReactElement | null {
  if (isArrayEmpty(messages)) {
    return null
  }

  return (
    <div className='bg-gray-100'>
      <div className='container-xxl mw-xxl px-4'>
        <div className='row'>
          <div className='col'>
            <CartPageAlert type={type} notes={messages} />
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  CartErrorsNotes
}
