export const formEvents = [
  'calculations-complete',
  'change',
  'change-page',
  'error',
  'next-page',
  'prev-page',
  'ready',
  'submit',
  'validation-complete'
] as const

export type FormEvent = typeof formEvents[number]

export interface FormstackEventDetailsData {
  'calculations-complete': any
  change: any
  'change-page': any
  error: {
    /**
     * The errors on the form
     */
    errors: Record<string, Record<string, Record<string, boolean>>>
    /**
     * The page that errors start on
     */
    page: number
  }
  'next-page': {
    /**
     * The page number that the user went to
     */
    page: number
  }
  'prev-page': any
  ready: {
    /**
     * The form submission id
     */
    formSubmissionId: string
    /**
     * The form rendered data
     */
    formRendered?: any
    /**
     * Indicates if the form should generate a lead for GTM
     */
    formGenerateLead: boolean
  }
  submit: any
  'validation-complete': any
}

export interface FormstackEventDetails<T extends FormEvent> {
  /**
   * Data sent from the form like type, subField, fieldId, etc.
   */
  data?: FormstackEventDetailsData[T]
  /**
   * The form id
   */
  formId: string
  /**
   * The type of Formstack form event
   */
  type: T
}

export interface FormstackEventListener<T extends FormEvent> {
  /**
   * Indicates the form has changed
   */
  type: T
  /**
   * The event data
   */
  event: FormstackEventDetails<T>
}

export interface FormResizeListener {
  /**
   * Indicates the form iframe has resized
   */
  type: 'resize'
  /**
   * The height of the iframe
   */
  height: number
}

export interface FormEventData extends Event {
  /**
   * Data expected sent from the iframe
   */
  data: {
    /**
     * The message source to identify the message as the current form iframe
     */
    source: string
  } & (
    FormResizeListener |
    FormstackEventListener<'calculations-complete'> |
    FormstackEventListener<'change'> |
    FormstackEventListener<'change-page'> |
    FormstackEventListener<'error'> |
    FormstackEventListener<'next-page'> |
    FormstackEventListener<'prev-page'> |
    FormstackEventListener<'ready'> |
    FormstackEventListener<'submit'> |
    FormstackEventListener<'validation-complete'>
  )
}
