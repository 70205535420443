import { type ReactElement } from 'react'
import { Button } from '@stuller/stullercom/ui'
import { useAddToExternalCartPostMessageAPI } from './useAddToExternalCartPostMessageAPI'

/**
 * Add to Cart Button that will post a message to the iframed jshow site's parent window to add the product to the external cart
 */
function AddToExternalCartButton (): ReactElement | null {
  const { postAddToExternalCartMessage } = useAddToExternalCartPostMessageAPI()

  return (
    <Button color='success' onClick={postAddToExternalCartMessage}>Add To Cart</Button>
  )
}

export {
  AddToExternalCartButton
}
