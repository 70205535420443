import { type ReactElement, useContext } from 'react'
import { Button } from '@stuller/stullercom/ui'
import { MenuCartContext } from './MenuCartContext'

/**
 * The button that links to the cart page
 */
function CartLinkButton (): ReactElement {
  const { title, hasScheduledOrder } = useContext(MenuCartContext)

  return (
    <div className='px-6 mb-4 pt-6'>
      <Button
        href='/cart'
        tag='a'
        color={hasScheduledOrder ? 'primary' : 'success'}
        outline={hasScheduledOrder ? false : 'fill'}
        pill
        className='w-100'
        data-test='go-to-cart-button'
      >
        Go to {title}
        {hasScheduledOrder && (
          <div className='fs-6 fw-normal lh-1'>
            Edit Items and Shipping Options
          </div>
        )}
      </Button>
    </div>
  )
}

export {
  CartLinkButton
}
