import { type ReactElement } from 'react'
import { Alert, Button } from '@stuller/stullercom/ui'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

/**
 * Renders the View In Cart Section
 */
function ViewInCartSection (): ReactElement | null {
  const router = useRouter()

  async function handleClick (): Promise<void> {
    await router.push('/cart')
  }

  return (
    <>
      <Alert
        color='warning'
        icon
        className='mb-0'
      >
        <div className='lh-sm'>
          <div className='text-yellow-600'>
            <div className='lh-sm fw-bold'>Stone not reserved</div>
            <div>This stone is not reserved until you checkout or schedule your order.</div>
          </div>
        </div>
      </Alert>
      <Button color='success' onClick={handleClick}>
        View In Cart
        <FontAwesomeIcon icon={fas.faAngleRight} size='xl' className='ms-1' />
      </Button>
    </>

  )
}

export {
  ViewInCartSection
}
