import { type ReactElement, useState, useEffect } from 'react'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useToggle, useCurrency } from '@stuller/shared/util/react-hooks'
import {
  type CartListItemInfoFragment,
  type CartLineMessageInfoFragment,
  useCartPageCartListDeleteAllCartLinesMutation,
  useCartPageCartListMoveAllLinesToCartMutation,
  useGetCartByIdQuery,
  type JewelerShowcaseCallbackRequestInfoFragment
} from '@stuller/stullercom/data-access/apollo-queries'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { trackCartListItemAddToCart } from '@stuller/stullercom/feat/google-tag-manager'
import { Button } from '@stuller/stullercom/ui'
import { RemoveAllConfirmationModal } from '../cart-page/RemoveAllConfirmationModal'
import { CartAlert } from '../shared/CartAlert'
import { CartPageLoadError } from '../shared/CartPageLoadError'
import { useCartContext } from '../shared/useCartContext'
import { CartItemList } from './CartItemList'
import { JewelerShowcaseCustomerContactInformation } from './JewelerShowcaseCustomerContactInformation'

interface CartListCollapseInnerProps {
  /**
   * The cart ID
   */
  cartId: string
  /**
   * The jeweler showcase request data
   */
  jewelerShowcaseCallbackData: JewelerShowcaseCallbackRequestInfoFragment | null
  /**
   * Handler for when the jeweler showcase print button is clicked
   */
  onJewelerShowcasePrint: (forCustomer: boolean) => void
  /**
   * Handler for updating the loading state
   */
  onLoading: (loading: boolean) => void
  /**
   * Handler used after completing a removeAllItems or moveAllLinesToCart mutation
   */
  onMutationCompleted: () => void
  /**
   * Whether the collapse has been opened at least once
   */
  hasBeenOpened: boolean
}

interface PricingTotalsProps {
  /**
   * The price of the cart
   */
  price: CartListItemInfoFragment['price'] | null
  /**
   * The quoted price of the cart
   */
  quotedPrice: CartListItemInfoFragment['quotedPrice'] | null
}

interface CartListCollapseButtonProps {
  /**
   * The function to call when the button is clicked
   */
  onClick: () => Promise<any>
  /**
   * Whether to disable the button
   */
  disabled?: boolean
}
interface RemoveAllItemsButtonProps {
  /**
   * The cart lines
   */
  lines: CartListItemInfoFragment['items']
  /**
 * The function to call when the button is clicked
 */
  onRemoveAll: () => Promise<any>
}

/**
 * This component is a button that allows the user to move all items to the cart.
 */
function MoveAllToCartButton ({ onClick, disabled = false }: CartListCollapseButtonProps): ReactElement {
  return (
    <Button className='w-100' color='success' onClick={onClick} disabled={disabled} data-test='move-all-to-my-cart'>
      Move All to My Cart
    </Button>
  )
}

/**
 * This component is a button that allows the user to remove all items from the cart.
 */
function RemoveAllItemsButton ({ lines, onRemoveAll }: RemoveAllItemsButtonProps): ReactElement {
  const [isRemoveAllModalOpen, toggleRemoveAllModal] = useToggle()

  return (
    <>
      <Button className='w-100' color='danger' outline='fill' onClick={toggleRemoveAllModal} data-test='remove-all-items-button'>
        Remove All Items
      </Button>
      <RemoveAllConfirmationModal
        isOpen={isRemoveAllModalOpen}
        onToggle={toggleRemoveAllModal}
        onConfirm={onRemoveAll}
        lines={lines}
      />
    </>
  )
}

/**
 * This component is a button that allows the user to move all items to consultant cart.
 */
function MoveAllToConsultantCart ({ onClick, disabled = false }: CartListCollapseButtonProps): ReactElement {
  return (
    <Button className='w-100' color='success' onClick={onClick} disabled={disabled} data-test='move-all-to-consultant-cart-button'>
      <FontAwesomeIcon icon={fas.faCartShopping} className='me-2' />
      Move All to Consultant Cart
    </Button>
  )
}

/**
 * This component displays the pricing totals for a cart.
 */
function PricingTotals ({
  price,
  quotedPrice
}: PricingTotalsProps): ReactElement {
  const { cartArea } = useCartContext()
  const [subtotal] = useCurrency(price?.subtotal.value, price?.subtotal.exchangeRate)
  const showSubtotal = price?.subtotal.value != null && price.subtotal.value > 0
  const [quotedSubtotal] = useCurrency(quotedPrice?.subtotal.value, quotedPrice?.subtotal.exchangeRate)
  const showQuotedSubtotal = (cartArea === 'sub-carts-page' || cartArea === 'jeweler-showcase-carts-page')
    && quotedPrice?.subtotal.value != null && quotedPrice.subtotal.value > 0
  const [salesTax] = useCurrency(price?.salesTaxV2?.value ?? 0, price?.salesTaxV2?.exchangeRate)
  const showSalesTax = price?.salesTaxV2?.value != null && price.salesTaxV2.value > 0
  const [quotedSalesTax] = useCurrency(quotedPrice?.salesTaxV2?.value ?? 0, quotedPrice?.salesTaxV2?.exchangeRate)
  const showQuotedSalesTax = (cartArea === 'sub-carts-page' || cartArea === 'jeweler-showcase-carts-page')
    && quotedPrice?.salesTaxV2?.value != null && quotedPrice.salesTaxV2.value > 0

  return (
    <>
      {(showSubtotal || showQuotedSubtotal) && (
        <div className='row px-5'>
          <div className='col-auto'>
            <p className='mb-0'>Subtotal:</p>
          </div>
          <div className='col'>
            {showQuotedSubtotal && (
              <div className='col text-end'>
                <p className='mb-0 fw-bold text-green-300'>Quote: {quotedSubtotal}</p>
              </div>
            )}
            {showSubtotal && (
              <div className='col-12 text-end d-print-none-active-child'>
                <p className='mb-0 fw-bold'>{subtotal}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {(showSalesTax || showQuotedSalesTax) && (
        <div className='row px-5'>
          <div className='col-auto'>
            <p className='mb-0'>Est. Sales Tax: </p>
          </div>
          <div className='col'>
            {showQuotedSalesTax && (
              <div className='col text-end'>
                <p className='mb-0 fw-bold text-green-300'>Quote: {quotedSalesTax}</p>
              </div>
            )}
            {showSalesTax && (
              <div className='col-12 text-end d-print-none-active-child'>
                <p className='mb-0 fw-bold'>{salesTax}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

/**
 * Body of the cart list collapse that displays the items in the cart
 */
function CartListCollapseInner ({
  cartId,
  jewelerShowcaseCallbackData,
  hasBeenOpened,
  onJewelerShowcasePrint,
  onLoading,
  onMutationCompleted
}: CartListCollapseInnerProps): ReactElement | null {
  const [actionError, setActionError] = useState<CartLineMessageInfoFragment>({ code: '', message: '' })
  const { cartArea } = useCartContext()
  const auth = useAuth()

  // Get the cart from the cart ID
  const { data: getCartData, loading: getCartLoading, error: getCartError } = useGetCartByIdQuery({
    variables: {
      cartId
    },
    skip: !hasBeenOpened
  })
  const cartListItem = getCartData?.cartById
  const cartLineErrors = cartListItem?.items?.flatMap((line) => line.errors) ?? []

  /**
   * Mutation to remove all items from the cart list item
   */
  const [removeAllItems, { loading: removeAllItemsLoading }] = useCartPageCartListDeleteAllCartLinesMutation({
    variables: { input: { cartId } },
    onError: (error: Error) => {
      onLoading(false)
      setActionError({ code: 'REMOVE_ALL_ERROR', message: 'We weren\'t able to remove the items from your cart. Refresh the page and try again.' })
      logger.error('Could not remove all items from the cart: ', { code: 'REMOVE_ALL_ERROR', message: 'We weren\'t able to remove the items from your cart. Refresh the page and try again.' }, error)
    },
    update: (cache) => {
      // evict the cart and cartItemCount from the cache
      cache.evict({ id: `Cart:${cartId}` })
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'cartItemCount' })
      if (cartArea === 'jeweler-showcase-carts-page') {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'pendingJewelerShowcaseCallbackRequests', args: { onlyRecent: true } })
      }
      cache.gc()
    },
    onCompleted: () => {
      onMutationCompleted()
    }
  })

  /**
   * Mutation to move all items to the cart from the cart list item
   */
  const [moveAllLinesToCart, { loading: moveAllLinesToCartLoading }] = useCartPageCartListMoveAllLinesToCartMutation({
    variables: {
      input: {
        sourceCartId: cartId
      }
    },
    onError: (error) => {
      onLoading(false)
      setActionError({ code: 'MOVE_ALL_ERROR', message: 'We weren\'t able to move the items to your cart. Refresh the page and try again.' })
      logger.error('Could not remove all items from the cart: ', { code: 'MOVE_ALL_ERROR', message: 'We weren\'t able to move the items to your cart. Refresh the page and try again.' }, error)
    },
    onCompleted: () => {
      onMutationCompleted()
      if (cartListItem != null) {
        trackCartListItemAddToCart(cartListItem, auth)
      }
    },
    update: (cache) => {
      // evict the cart and cartItemCount from the cache
      cache.evict({ id: `Cart:${cartId}` })
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'cartItemCount' })
      if (cartArea === 'jeweler-showcase-carts-page') {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'pendingJewelerShowcaseCallbackRequests', args: { onlyRecent: true } })
      }
      cache.gc()
    }
  })

  /**
   * Handles the loading state
   */
  useEffect(() => {
    if (getCartLoading || moveAllLinesToCartLoading || removeAllItemsLoading) {
      setActionError({ code: '', message: '' })
    }
    onLoading(getCartLoading || moveAllLinesToCartLoading || removeAllItemsLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCartLoading, moveAllLinesToCartLoading, removeAllItemsLoading])

  if (getCartError != null) {
    return (
      <div className='p-5'>
        <CartPageLoadError cartName='Cart' />
      </div>
    )
  }

  if (cartListItem == null) {
    return null
  }

  return (
    <>
      {cartArea === 'jeweler-showcase-carts-page' && jewelerShowcaseCallbackData != null && (
        <div className='px-4 px-md-5 mt-5'>
          <JewelerShowcaseCustomerContactInformation jewelerShowcaseCallbackRequestInfo={jewelerShowcaseCallbackData} onPrint={onJewelerShowcasePrint} />
        </div>
      )}
      <div className='px-4 px-md-5 p-5'>
        <CartItemList cartLines={cartListItem.items} />
      </div>
      <div className='pb-4'>
        <div className='row'>
          <div className='col-12 col-md-8 col-lg-6 ms-0 ms-md-auto'>
            <PricingTotals
              price={cartListItem.price ?? null}
              quotedPrice={cartListItem.quotedPrice ?? null}
            />
            <div className='row px-5'>
              <div className='col-12'>
                <div className='my-3 border-top border-1 border-gray-300' />
              </div>
              <div className='col-12'>
                <p className='text-end'>Subtotal does not include freight charges</p>
              </div>
              <div className='d-print-none'>
                {actionError.code !== '' && (
                  <div className='col-sm-12 mt-4'>
                    <CartAlert
                      type='danger'
                      messages={[actionError.message]}
                      title={actionError.code === 'REMOVE_ALL_ERROR' ? 'Error Removing Items' : actionError.code === 'MOVE_ALL_ERROR' ? 'Error Moving Items' : ''}
                    />
                  </div>
                )}
              </div>
              {(cartArea === 'sub-carts-page' || cartArea === 'jeweler-showcase-carts-page') && (
                <>
                  <div className='col-12 order-2 order-sm-1 col-sm-6 mt-3 mt-sm-0  d-print-none'>
                    <RemoveAllItemsButton lines={cartListItem.items} onRemoveAll={removeAllItems} />
                  </div>
                  <div className='col-12 col-sm-6 order-1 order-sm-2 mt-2 mt-sm-0  d-print-none'>
                    <MoveAllToCartButton onClick={moveAllLinesToCart} disabled={cartLineErrors.length > 0} />
                  </div>
                </>
              )}
              {cartArea === 'dot-carts-page' && (
                <div className='col-12 mt-3  d-print-none'>
                  <MoveAllToConsultantCart onClick={moveAllLinesToCart} disabled={cartLineErrors.length > 0} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {
  CartListCollapseInner
}
