import { type ReactElement, useRef } from 'react'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import {
  useCartPageCartSummaryCancelScheduledOrderMutation,
  useGetCartItemCountQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { Button, Modal, ModalBody, ModalHeader, useToast } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'

interface CancelOrderButtonProps {
  /**
   * The function to call when the button is clicked
   */
  onClick: (() => void) | (() => Promise<any>)
  /**
   * If the button is in a loading state
   */
  loading: boolean
  /**
   * Additional class names for the button
   */
  className?: string
}

interface ConfirmCancelScheduledOrderModalProps {
  /**
   * Indicates if the modal is open
   */
  isOpen: boolean
  /**
   * Function to handle toggling the modal
   */
  handleToggle: () => void
}

interface CancelScheduledOrderButtonsProps {
  /**
   * Indicates if the cart is empty
   */
  cartIsEmpty: boolean
}

/**
 * The actionable button to cancel a scheduled order
 */
function CancelOrderButton ({ onClick, loading, className }: CancelOrderButtonProps): ReactElement {
  return (
    <Button
      color='danger'
      outline
      onClick={onClick}
      loading={loading}
      className={className}
      data-test='cancel-schedule-order-button'
    >
      Cancel Scheduled Order
    </Button>
  )
}

function ConfirmCancelScheduledOrderModal ({ isOpen, handleToggle }: ConfirmCancelScheduledOrderModalProps): ReactElement {
  const toast = useToast()
  const ref = useRef(typeof window !== 'undefined' ? document.body : undefined)

  /**
   * fetch the cart item count
   */
  const cartItemCount = useGetCartItemCountQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  /**
   * Mutation to cancel the scheduled order
   */
  const [cancelScheduledOrder, { loading }] = useCartPageCartSummaryCancelScheduledOrderMutation({
    onError: (error) => {
      logger.error('Could not cancel scheduled order', {}, error)
      toast('There was an error canceling your scheduled order. Please try again.', { color: 'danger' })
    },
    onCompleted: () => {
      handleToggle()
      void cartItemCount.refetch()
    }
  })

  return (
    <Modal isOpen={isOpen} onToggle={handleToggle} portalRef={ref}>
      <ModalHeader>
        Are you sure?
      </ModalHeader>
      <ModalBody>
        <p className='fw-bold mb-0'>Inventory will no longer be reserved</p>
        <p>All items will remain in your cart, but inventory will no longer be reserved.</p>
        <div className='d-flex flex-column flex-sm-row justify-content-end mt-3 mt-sm-5'>
          <Button color='link-dark' onClick={handleToggle}>Keep Scheduled Order</Button>
          <CancelOrderButton onClick={cancelScheduledOrder} loading={loading} />
        </div>
      </ModalBody>
    </Modal>
  )
}

/** Cancel Scheduled Order Button */
function CancelScheduledOrderButton ({ cartIsEmpty }: CancelScheduledOrderButtonsProps): ReactElement {
  const [isOpen, toggleIsOpen] = useToggle()
  const toast = useToast()

  /**
   * fetch the cart item count
   */
  const cartItemCount = useGetCartItemCountQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  /**
   * Mutation to cancel the scheduled order
   */
  const [cancelScheduledOrder, { loading }] = useCartPageCartSummaryCancelScheduledOrderMutation({
    onError: (error) => {
      logger.error('Could not cancel scheduled order', {}, error)
      toast('There was an error canceling your scheduled order. Please try again.', { color: 'danger' })
    },
    onCompleted: () => {
      void cartItemCount.refetch()
    }
  })

  return (
    <>
      <CancelOrderButton onClick={cartIsEmpty ? cancelScheduledOrder : toggleIsOpen} loading={loading} className='w-100' />
      <ConfirmCancelScheduledOrderModal isOpen={isOpen} handleToggle={toggleIsOpen} />
    </>
  )
}

export {
  CancelScheduledOrderButton
}
