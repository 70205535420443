import { useEffect, useMemo, useState, type ReactElement } from 'react'
import type {
  ActionMeta,
  GroupHeadingProps,
  OptionProps,
  SingleValueProps
} from 'react-select'
import {
  Alert,
  CARRIER_TYPE_INFO,
  CarrierIcon,
  type CarrierType,
  FormGroup,
  FormLabel,
  SelectDropdown,
  selectDropdownComponents
} from '@stuller/stullercom/ui'
import { dayjs } from '@stuller/shared/util/core'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import { type CartPageCartListUpdateScheduledCartShippingMethodMutationVariables } from '@stuller/stullercom/data-access/apollo-queries'
import type { Estimate, ShippingMethodSelectionId } from '../../shared/types'

const SATURDAY_DELIVERY_LABEL = 'Saturday Delivery Options'
const OTHER_LABEL = 'Other Options'
const UNAVAILABLE_DELIVERY_DATE_LABEL = 'N/A'

interface CartShipmentMethodSelectProps {
  /**
   * The currently selected shipping method id
   */
  shippingMethodSelectionId: ShippingMethodSelectionId
  /**
   * The shipment estimate options
   */
  estimates: Estimate[] | null
  /**
   * The function to handle the shipping method selection
   */
  onUpdateShippingMethod: (input: Omit<CartPageCartListUpdateScheduledCartShippingMethodMutationVariables['input'], 'shipSetCode'>) => Promise<void>
  /**
   * The function to handle when the menu is opened
   */
  onMenuOpen: () => void
  /**
   * Loading state of the shipping estimates
   */
  isLoading: boolean
  /**
   * The error messages if any
   */
  error: string[]
}

interface OptionType {
  /**
   * The value of the option as method id
   */
  value: string
  /**
   * The estimate data
   */
  estimate: Estimate
}

interface GroupOptionType {
  /**
   * The carrier of the group
   */
  label: string
  /**
   * The options in the group
   */
  options: OptionType[]
}

interface OptionValueInnerProps {
  /**
   * The estimate info
   */
  estimate: Estimate
  /**
   * If the value is the single value selected
   */
  singleValue?: boolean
}

/**
 * Renders the inner option or value
 */
function OptionValueInner ({ estimate, singleValue = false }: OptionValueInnerProps): ReactElement {
  const { shortDate, longDate } = useMemo(() => {
    if (estimate.delivery != null) {
      return {
        shortDate: dayjs(estimate.delivery).format('l'),
        longDate: dayjs(estimate.delivery).format('dddd, MMMM D, YYYY')
      }
    }
    if (estimate.method.carrier != null) {
      return {
        shortDate: UNAVAILABLE_DELIVERY_DATE_LABEL,
        longDate: UNAVAILABLE_DELIVERY_DATE_LABEL
      }
    }

    return { shortDate: null, longDate: null }
  }, [estimate])
  const priceDecimal = estimate.freight?.value
  const [price] = useCurrency(priceDecimal, estimate.freight?.exchangeRate)

  return (
    <div className='row gx-1'>
      <div className='col-5 col-lg-5'>
        <div className='row gx-2 flex-nowrap'>
          {CARRIER_TYPE_INFO[estimate.method.carrier as CarrierType]?.icon != null && (
            <div className='col-auto'>
              <CarrierIcon type={estimate.method.carrier as CarrierType} />
            </div>
          )}
          <div className='col text-wrap'>
            {estimate.method.description}
          </div>
        </div>
      </div>
      <div className='col-3 col-lg-2 text-end'>
        <div>{priceDecimal != null && (priceDecimal === 0 ? 'Free' : price)}</div>
      </div>
      <div className='col-4 col-lg-5 text-end'>
        <span className='d-none d-lg-block'>{longDate}</span>
        <span className='d-lg-none'>{shortDate}</span>
      </div>
    </div>
  )
}

/**
 * Renders an estimate value selected in the dropdown
 */
function SingleValue ({ children, ...props }: SingleValueProps<OptionType>): ReactElement {
  return (
    <selectDropdownComponents.SingleValue {...props}>
      <OptionValueInner estimate={props.data.estimate} singleValue />
    </selectDropdownComponents.SingleValue>
  )
}

/**
 * Renders an estimate option in the dropdown
 */
function Option ({ children, ...props }: OptionProps<OptionType>): ReactElement {
  return (
    <selectDropdownComponents.Option {...props} key={props.data.value} className='cursor-pointer border-bottom px-3'>
      <OptionValueInner estimate={props.data.estimate} />
    </selectDropdownComponents.Option>
  )
}

/**
 * Renders an estimate group header in the dropdown
 */
function GroupHeading ({ children, ...props }: GroupHeadingProps<GroupOptionType>): ReactElement {
  const carrier = props.data.label

  return (
    <selectDropdownComponents.GroupHeading {...props} key={carrier} className='border-bottom bg-gray-100 fs-6 fw-bold py-2 px-3'>
      <CarrierIcon type={carrier as CarrierType} className='pe-2' />
      {carrier}
    </selectDropdownComponents.GroupHeading>
  )
}

/**
 * Shows the shipment method select for shipment
 */
function CartShipmentMethodSelect ({
  shippingMethodSelectionId,
  estimates,
  onUpdateShippingMethod,
  onMenuOpen,
  isLoading,
  error
}: CartShipmentMethodSelectProps): ReactElement {
  const groups = useMemo(() => {
    const groupMap: Record<string, GroupOptionType> = {}

    // Build group map
    for (const estimate of estimates ?? []) {
      const option = {
        value: `${estimate.method.id}:${estimate.isSaturdayDelivery}`,
        estimate
      }
      const label = estimate.isSaturdayDelivery === true ? SATURDAY_DELIVERY_LABEL : estimate.method.carrier ?? OTHER_LABEL
      if (groupMap[label] == null) {
        groupMap[label] = { label, options: [option] }
      } else {
        groupMap[label].options.push(option)
      }
    }

    // Order groups
    return [
      groupMap[SATURDAY_DELIVERY_LABEL],
      ...Object.values(groupMap)
        .filter((group) => ![SATURDAY_DELIVERY_LABEL, OTHER_LABEL].includes(group.label)),
      groupMap[OTHER_LABEL]
    ]
      .filter((option) => option != null)
  }, [estimates])
  const [value, setValue] = useState<OptionType | undefined>()

  /**
   * Handler for when the shipping method is changed
   */
  async function handleOnChange (option: OptionType, actionMeta: ActionMeta<OptionType>): Promise<void> {
    setValue(option)
    await onUpdateShippingMethod({
      isSaturdayDelivery: option.estimate.isSaturdayDelivery ?? false,
      shippingMethodId: option.estimate.method.id
    })
  }

  /**
   * Updates the value when the shipping method changes
   */
  useEffect(() => {
    setValue(groups
      .flatMap((group) => group.options)
      .find((option) => option.value === shippingMethodSelectionId))
  }, [shippingMethodSelectionId, groups])

  if (groups.length === 0) {
    return (
      <Alert size='sm' color='danger' icon>
        There was an issue getting shipping estimates. Try refreshing the page.
      </Alert>
    )
  }

  return (
    <FormGroup className='mb-4'>
      <FormLabel className='d-flex justify-content-between'>
        <div>Shipping Method</div>
        <div>Est. Delivery Date</div>
      </FormLabel>
      <SelectDropdown
        options={isLoading ? [] : groups}
        value={value}
        onChange={handleOnChange}
        onMenuOpen={onMenuOpen}
        isSearchable={false}
        isLoading={isLoading}
        components={{ Option, SingleValue, GroupHeading }}
      />
      {error.length > 0 && (
        <Alert color='danger' size='sm' icon className='mt-3'>
          {error.map((e, index) => (
            <div key={index}>{e}</div>
          ))}
        </Alert>
      )}
    </FormGroup>
  )
}

export {
  CartShipmentMethodSelect
}
