import {
  type ReactElement,
  memo,
  useEffect,
  useState
} from 'react'
import Script from 'next/script'
import { useLayout, type LiveChatProps } from '@stuller/stullercom/feat/layout-context'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useUnsupported } from '@stuller/stullercom/feat/unsupported-banner'
import {
  useGetUnsupportedBannerMinimumSupportedBrowsersQuery,
  useGetLiveChatEmbeddedChatSettingsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { dispatchStullerEvent, useStullerEventListener } from '@stuller/stullercom/feat/stuller-events'

/**
 * Interface to allow window.embeddedservice_bootstrap Salesforce embedded (for this file only)
 */
declare global {
  interface Window {
    /**
     * New salesforce embedded chat
     */
    embeddedservice_bootstrap?: any
  }
}

interface EmbeddedChatEventHandlers {
  /**
   * Handler for when embedded messaging is ready
   */
  handleEmbeddedMessagingReady: () => void
  /**
   * Handler for when the embedded messaging window is maximized
   */
  handleEmbeddedMessagingWindowMaximized: (embeddedService: any) => void
  /**
   * Handler for when the embedded messaging window is minimized
   */
  handleEmbeddedMessagingWindowMinimized: () => void
  /**
   * Handler for when the live chat button is clicked
   */
  handleEmbeddedMessagingButtonClicked: () => void
}

let embeddedChatObserver: MutationObserver | null = null

const LiveChat = memo(({
  chatUnavailable = false
}: LiveChatProps): ReactElement | null => {
  const { authUser, isShowcase, isSterling } = useAuth()
  const chatDisabled = authUser == null || isShowcase || isSterling
  const { layoutState: { marketRatesFooterShown } } = useLayout()
  const [chatEnabled, setChatEnabled] = useState<boolean>(false)
  const [chatReady, setChatReady] = useState<boolean>(false)
  const browserData = useGetUnsupportedBannerMinimumSupportedBrowsersQuery()
  const browser = useUnsupported(browserData?.data?.cmsLookupsByType ?? [])
  const unsupportedBrowser = browser != null
  const [modalOpen, toggleModalOpen] = useToggle(false)
  const { data: allEmbeddedChatSettings } = useGetLiveChatEmbeddedChatSettingsQuery()
  const embeddedChatSettings = allEmbeddedChatSettings?.embeddedChatSettings?.primaryEmbeddedChatSettings ?? null

  const useIsMobile = (): boolean => {
    const mobileBreakpoint = 559
    const [isMobile, setIsMobile] = useState(
      typeof window !== 'undefined' ? window.innerWidth <= mobileBreakpoint : false
    )

    useEffect(() => {
      const mediaQuery = window.matchMedia(`(max-width: ${mobileBreakpoint}px)`)
      const handleResize = (e: MediaQueryListEvent): void => {
        setIsMobile(e.matches)
      }

      setIsMobile(mediaQuery.matches)
      mediaQuery.addEventListener('change', handleResize)

      return () => {
        mediaQuery.removeEventListener('change', handleResize)
      }
    }, [])

    return isMobile
  }

  const isMobile = useIsMobile()

  useEffect(() => {
    setChatEnabled(embeddedChatSettings?.chatEnabled ?? false)
  }, [embeddedChatSettings])

  function prepopulateInputFields (embeddedService: any): void {
    try {
      embeddedService.prechatAPI.setVisiblePrechatFields({
        _firstName: {
          value: authUser?.firstName,
          isEditableByEndUser: true
        },
        _lastName: {
          value: authUser?.lastName,
          isEditableByEndUser: true
        },
        _email: {
          value: authUser?.email,
          isEditableByEndUser: true
        }
      })
      embeddedService.prechatAPI.setHiddenPrechatFields({
        Account_Number: authUser?.user?.defaultActiveShipToAccount?.id
      })
    } catch (error) {
      console.error('Error prepopulating input fields', error)
    }
  }

  /**
   * This function hides the salesforce embedded messaging button for mobile screens
   */
  function hideSalesforceButton (): void {
    const embeddedMessaging = document.querySelector<HTMLDivElement>('#embedded-messaging')
    if (embeddedMessaging != null) {
      embeddedMessaging.style.display = 'none'
    }
  }

  function showSalesforceChatWindow (): void {
    const embeddedMessaging = document.querySelector<HTMLDivElement>('#embedded-messaging')
    if (embeddedMessaging != null) {
      embeddedMessaging.style.display = 'block'
    }
  }

  async function handleButtonClick (): Promise<void> {
    dispatchStullerEvent('track-universal-event', {
      category: 'Live Chat',
      action: 'Button',
      label: 'Click'
    })

    if (chatUnavailable || !chatEnabled || !chatReady) {
      toggleModalOpen()

      return
    }

    // Launch salesforce live chat
    const { embeddedservice_bootstrap: embeddedService } = window
    try {
      await embeddedService.utilAPI.launchChat()
      showSalesforceChatWindow()
    } catch (error) {
      setChatEnabled(false)
    }
  }

  const embeddedChatEventHandlers: EmbeddedChatEventHandlers = {
    handleEmbeddedMessagingReady: () => {
      setEmbeddedMessagingObserver()
    },
    handleEmbeddedMessagingWindowMaximized: (embeddedService: any) => {
      prepopulateInputFields(embeddedService)

      if (isMobile) {
        showSalesforceChatWindow()
      }
    },
    handleEmbeddedMessagingWindowMinimized: () => {
      if (isMobile) {
        hideSalesforceButton()
      }
    },
    handleEmbeddedMessagingButtonClicked: () => {
      dispatchStullerEvent('track-universal-event', {
        category: 'Live Chat',
        action: 'Button',
        label: 'Click'
      })
    }
  }

  function initEmbeddedMessaging (): void {
    const { embeddedservice_bootstrap: embeddedService } = window

    try {
      embeddedService.settings.language = 'en-US'
      embeddedService.settings.omitSandbox = true

      window.addEventListener('onEmbeddedMessagingReady', embeddedChatEventHandlers.handleEmbeddedMessagingReady)
      window.addEventListener('onEmbeddedMessagingWindowMaximized', () => {
        embeddedChatEventHandlers.handleEmbeddedMessagingWindowMaximized(embeddedService)
      })
      window.addEventListener('onEmbeddedMessagingWindowMinimized', embeddedChatEventHandlers.handleEmbeddedMessagingWindowMinimized)
      window.addEventListener('onEmbeddedMessagingButtonClicked', embeddedChatEventHandlers.handleEmbeddedMessagingButtonClicked)

      embeddedService.init(
        embeddedChatSettings?.orgId,
        embeddedChatSettings?.messagingChannel,
        embeddedChatSettings?.experienceSiteUrl,
        {
          scrt2URL: embeddedChatSettings?.telephonyUrl
        }
      )
    } catch (error) {
      setChatEnabled(false)
      console.error('Error initializing embedded messaging', error)
    }
  }

  function setEmbeddedMessagingObserver (): void {
    if (typeof MutationObserver !== 'undefined') {
      embeddedChatObserver = new MutationObserver((mutationsList, observer) => {
        const salesforceButton = document.querySelector<HTMLButtonElement>('.embeddedMessagingConversationButton')
        if (salesforceButton != null) {
          observer.disconnect()

          salesforceButton.classList.add('animation-fade-in-up', 'delay-1s')

          const liveChatButton = document.querySelector<HTMLButtonElement>('.live-chat-button')
          liveChatButton?.addEventListener('click', () => {
            void handleButtonClick()
          })
        }
      })

      embeddedChatObserver.observe(document.body, {
        childList: true,
        subtree: true
      })
    }
  }

  function handleChatReady (): void {
    setEmbeddedMessagingObserver()
    initEmbeddedMessaging()
    setChatReady(true)
  }

  /**
   * Setup event listener to open live chat from other links
   */
  useStullerEventListener('live-chat-open', handleButtonClick)

  return (
    <>
      {!unsupportedBrowser &&
        !chatDisabled &&
        embeddedChatSettings?.srcAttribute != null && (
          <Script
            id='live-chat-script'
            src={embeddedChatSettings?.srcAttribute}
            strategy='lazyOnload'
            onLoad={handleChatReady}
          />
      )}
      <Modal id='live-chat-disabled-modal' isOpen={modalOpen} onToggle={toggleModalOpen}>
        <ModalHeader>
          {unsupportedBrowser ? 'Browser Not Supported' : 'Live Chat is Temporarily Disabled'}
        </ModalHeader>
        <ModalBody>
          {unsupportedBrowser && 'This browser is not supported with our Live Chat feature.'}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={toggleModalOpen}>Close</Button>
        </ModalFooter>
      </Modal>

      <style jsx global>{`
        .embedded-messaging {
          position: relative;
          z-index: 16600;
        }

        .embeddedMessagingFrame.isMaximized {
          margin-bottom: 0 !important;
        }

        .embeddedMessagingFrame.isMinimized {
          margin-bottom: ${marketRatesFooterShown ? 15 : 0}px !important;
        }

        .embeddedMessagingConversationButton {
          margin-bottom: ${marketRatesFooterShown ? 15 : 0}px !important;
        }

        /* Media Queries */
        @media (max-width: 559px) {
          .embedded-messaging {
            display: none;
          }
          #live-chat-disabled-modal {
            z-index: 1070;
          }
        }

        @media (min-width: 560px) {
          .embeddedMessagingFrame.isMaximized {
            height: 670px !important;
          }
        }
      `}
      </style>
    </>
  )
})
LiveChat.displayName = 'LiveChat'

export {
  LiveChat
}
