import { type StrictTypedTypePolicies } from '@stuller/stullercom/data-access/apollo-queries'

// The type polices for the Apollo Client cache
// See https://www.apollographql.com/docs/react/pagination/core-api
// See https://www.apollographql.com/docs/react/caching/cache-field-behavior
const typePolicies: StrictTypedTypePolicies = {
  User: {
    fields: {
      notificationsFeed: {
        keyArgs: ['app', 'types', 'important', 'status', 'limit'],
        merge (existing, incoming, { args }) {
          if (args?.cursor == null) {
            return incoming
          }

          return {
            ...existing,
            ...incoming,
            notifications: [...(existing?.notifications ?? []), ...incoming.notifications]
          }
        }
      }
    }
  },
  Cart: {
    fields: {
      items: {
        merge (_existing, incoming) {
          return incoming
        }
      }
    }
  },
  CartHeader: {
    keyFields: ['cartId']
  },
  SavedCartLinesResult: {
    merge: true
  },
  JewelerShowcaseCallbackRequestsResult: {
    merge: true
  },
  ShowcaseOnlyCartsResult: {
    merge: true
  },
  ShipToAccountSearchResult: {
    merge: true
  },
  ShipToAccount: {
    fields: {
      address: {
        merge: true
      }
    }
  },
  ConsultantCartsResult: {
    merge: true
  },
  Query: {
    fields: {
      contactSearch: {
        merge: true
      }
    }
  },
  TaskRunsSearchResult: {
    fields: {
      taskRuns: {
        keyArgs: ['domain', 'taskKey', 'limit'],
        merge (existing, incoming, { args }) {
          if (args?.cursor == null) {
            return incoming
          }

          return [...existing, ...incoming]
        }
      }
    }
  }
}

export {
  typePolicies
}
