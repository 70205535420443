import React, { type ReactElement } from 'react'
import { Input, InputGroup, InputGroupText } from '@stuller/stullercom/ui'

interface ItemQuantityInputGroupWrapperProps {
  /**
   * The current quantity of the cart item
   */
  quantity: number | string
  /**
   * The function run when the quantity is changed
   */
  onChangeQuantity: (event: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Whether the input is disabled
   */
  isDisabled: boolean
  /**
   * The quantity description
   */
  soldByDescription: string
  /**
   * The class name for the input group element
   */
  inputGroupClassName: string
  /**
   * The class name for the input element
   */
  inputClassName: string
  /**
   * The class name for the input group text element
   */
  inputGroupTextClassName: string
  /**
   * The max width of the entire input group
   */
  inputGroupMaxWidth?: number
}

function ItemQuantityInputGroupWrapper ({
  quantity,
  onChangeQuantity,
  isDisabled,
  soldByDescription,
  inputGroupClassName,
  inputClassName,
  inputGroupTextClassName,
  inputGroupMaxWidth: maxWidth
}: ItemQuantityInputGroupWrapperProps): ReactElement {
  const style = maxWidth != null ? { maxWidth } : {}

  return (
    <InputGroup className={inputGroupClassName} style={style}>
      <Input
        value={quantity}
        onChange={onChangeQuantity}
        type='tel'
        maxLength={5}
        className={inputClassName}
        disabled={isDisabled}
        data-test='item-quantity'
      />
      <InputGroupText style={{ minWidth: 110 }} className={inputGroupTextClassName}>{soldByDescription}</InputGroupText>
    </InputGroup>
  )
}

export {
  ItemQuantityInputGroupWrapper
}
