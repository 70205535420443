import { type ReactElement, useState } from 'react'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover, PopoverBody } from '@stuller/stullercom/ui'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

/**
 * Share item via copy link
 */
function ItemShareCopyLink (): ReactElement {
  const [showSuccess, setShowSuccess] = useState(false)
  const [reference, setReference] = useState<HTMLElement | null>(null)

  async function handleClick (): Promise<void> {
    try {
      await navigator.clipboard.writeText(window.location.href)
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 3000)
    } catch (e) {
      logger.error('Error copying url number to clipboard', {}, e)
    }
  }

  return (
    <>
      <Button color='anchor-dark' className='fw-normal' onClick={handleClick} ref={setReference}>
        <FontAwesomeIcon icon={fas.faFiles} className='me-2' />
        Copy Link
      </Button>
      <Popover open={showSuccess} reference={reference} placement='top'>
        <PopoverBody>
          <p className='mb-0'>Copied to clipboard</p>
        </PopoverBody>
      </Popover>
    </>
  )
}

export {
  ItemShareCopyLink
}
