import React, { type ReactElement } from 'react'
import {
  useGetQuoteDetailsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import dayjs from 'dayjs'

interface AddToCartQuoteDetailsProps {
  /**
   * quote id
   */
  quoteId: string
}

/**
 * Small component to grab the quote details and show them
 */
function AddToCartQuoteDetails ({ quoteId }: AddToCartQuoteDetailsProps): ReactElement | null {
  const { data, error } = useGetQuoteDetailsQuery({
    variables: { id: quoteId }
  })
  const { quote, date } = data?.publicQuoteLineById ?? {}
  const [quotedPrice] = useCurrency(quote?.value, quote?.exchangeRate)
  const shipDate = date != null ? dayjs(date).format('MM/DD/YYYY') : 'N/A'

  if (quote == null || error != null) {
    return null
  }

  return (
    <div className='text-end'>
      <div>Showcase Quoted Price</div>
      <div>
        <span className='fw-bold'>{quotedPrice}</span> on <span className='fw-bold'>{shipDate}</span>
      </div>
    </div>
  )
}

export default AddToCartQuoteDetails
