import { type ReactElement, type ReactNode } from 'react'

interface SchemaMarkupPriceWrapperProps {
  /**
   * The children to wrap with schema.org markup for price.
   */
  children: ReactNode
  /**
   * The price to display.
   */
  schemaPrice: string
  /**
   * The currency code for the price.
   */
  schemaPriceCurrency: string
  /**
   * The type of price.
   */
  schemaPriceType?: string
  /**
   * The unit code for the price, used when a product is sold in units of measure.
   */
  schemaUnitCode?: string
}

/**
 * This component is used to wrap the price display components with schema.org markup for price.
 */
function SchemaMarkupPriceWrapper ({
  children,
  schemaPrice,
  schemaPriceCurrency,
  schemaPriceType,
  schemaUnitCode
}: SchemaMarkupPriceWrapperProps): ReactElement {
  return (
    <div itemProp='priceSpecification' itemScope itemType='https://schema.org/UnitPriceSpecification'>
      <meta itemProp='priceCurrency' content={schemaPriceCurrency} />
      <meta itemProp='price' content={schemaPrice} />
      {schemaPriceType != null &&
        <meta itemProp='priceType' content={schemaPriceType} />}
      {schemaUnitCode != null &&
        <meta itemProp='unitCode' content={schemaUnitCode} />}
      {children}
    </div>
  )
}

export { SchemaMarkupPriceWrapper }
