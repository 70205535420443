import { useCurrency } from '@stuller/shared/util/react-hooks'
import { type GetAddToCartShowcasePriceSummaryQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { type ReactElement } from 'react'
import { ShowcasePricingSummaryMultipleComponentView } from './ShowcasePricingSummaryMultipleComponentView'
import { ShowcasePricingSummarySingleComponentView } from './ShowcasePricingSummarySingleComponentView'

interface ShowcasePricingSummaryProps {
  data: GetAddToCartShowcasePriceSummaryQuery | undefined
}

function ShowcasePricingSummary ({ data }: ShowcasePricingSummaryProps): ReactElement | null {
  const showcasePriceSummary = data?.items?.[0]?.showcasePriceSummary
  const { sections, baseTotal: baseTotalData, markedUpTotal: markedUpTotalData } = showcasePriceSummary ?? {}
  const [baseTotal] = useCurrency(baseTotalData?.value, baseTotalData?.exchangeRate)
  const [markedUpTotal] = useCurrency(markedUpTotalData?.value, markedUpTotalData?.exchangeRate)

  if (showcasePriceSummary == null) {
    return null
  }

  // we show the markup data differently if there is only 1 section with only 1 component
  const showMultipleComponentView = (sections?.length ?? 0) > 1 || (sections?.[0]?.components?.length ?? 0) > 1

  return (
    <>
      {showMultipleComponentView
        ? (
          <ShowcasePricingSummaryMultipleComponentView sections={sections} baseTotal={baseTotal} markedUpTotal={markedUpTotal} />
        )
        : (
          <ShowcasePricingSummarySingleComponentView section={sections?.[0]} />
        )}
    </>

  )
}

export { ShowcasePricingSummary }
