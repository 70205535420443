import { type ReactElement, type ReactNode, useLayoutEffect, useState } from 'react'
import { Header } from '@stuller/stullercom/feat/header'
import { Footer } from '@stuller/stullercom/feat/footer'
import { MarketRatesFooter } from '@stuller/stullercom/feat/market-rates-footer'
import { RatePage } from '@stuller/stullercom/feat/rate-page'
import { UnsupportedBanner } from '@stuller/stullercom/feat/unsupported-banner'
import { GoogleTagManager } from '@stuller/stullercom/feat/google-tag-manager'
import { RemoveTargetBlankWhenFramed } from '@stuller/stullercom/feat/remove-target-blank-when-framed'
import { Title } from './Title'
import { Meta } from './Meta'
import { MetaDescription } from './MetaDescription'
import { MetaImage } from './MetaImage'
import { PageProgress } from './PageProgress'
import { Main } from './Main'
import { McAfeeTrustmark } from './McAfeeTrustmark'
import { BarcodeInput } from '@stuller/stullercom/feat/barcode-input'
import { BootstrapSizeHelper } from '@stuller/stullercom/feat/bootstrap-size-helper'
import {
  LayoutContext,
  type LayoutContextProps,
  type LayoutContextState,
  layoutContextPropsDefault,
  layoutContextStateDefault
} from '@stuller/stullercom/feat/layout-context'
import { AuthExpired } from '@stuller/stullercom/feat/auth-expired'
import { CanonicalUrl } from './CanonicalUrl'
import { RecaptchaInclude } from '@stuller/stullercom/ui'
import { IframeHostDomainManager } from './IframeHostDomainManager'

export interface LayoutProps {
  /**
   * Children of layout
   */
  children?: ReactNode
  /**
   * Layout props to send to children
   */
  layoutProps?: LayoutContextProps
}

/**
 * Main layout for web
 *
 * Set layout props statically via top level pages in `pages/`:
 *   - Use the `CustomNextPage` type on the page component
 *   - Set layout props via `[PageComponent].layout`.
 * Get/set layout props dynamically via the `useLayout` `layoutProps` and `setLayoutProps`.
 *
 * Get/set layout state dynamically via the `useLayout` `layoutState` and `setLayoutState`.
 */
function Layout ({
  children,
  layoutProps: layoutPropsIn = layoutContextPropsDefault
}: LayoutProps): ReactElement {
  const [layoutProps, setLayoutProps] = useState<LayoutContextProps>(layoutPropsIn)
  const [layoutState, setLayoutState] = useState<LayoutContextState>(layoutContextStateDefault)

  // Update layout when it changes
  useLayoutEffect(() => {
    setLayoutProps(layoutPropsIn)
  }, [layoutPropsIn])

  if (layoutProps.disabled === true) {
    return children as ReactElement
  }

  return (
    <LayoutContext.Provider value={{ layoutProps, setLayoutProps, layoutState, setLayoutState }}>
      <IframeHostDomainManager />
      <PageProgress />
      <AuthExpired />
      <RecaptchaInclude />
      <GoogleTagManager />
      <RemoveTargetBlankWhenFramed />
      <Title {...layoutProps.title} />
      <Meta {...layoutProps.meta} />
      <MetaDescription {...layoutProps.metaDescription} />
      <MetaImage {...layoutProps.metaImage} />
      <CanonicalUrl {...layoutProps.canonicalUrl} />
      <UnsupportedBanner {...layoutProps.unsupportedBanner} />
      <Header {...layoutProps.header} />
      <Main>{children}</Main>
      <RatePage {...layoutProps.ratePage} />
      <Footer {...layoutProps.footer} />
      <MarketRatesFooter {...layoutProps.marketRatesFooter} />
      <BarcodeInput {...layoutProps.barcodeInput} />
      <McAfeeTrustmark />
      <BootstrapSizeHelper />
    </LayoutContext.Provider>
  )
}

export {
  Layout
}
