import { useMemo, useCallback } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CustomShowcaseSettingDetailType, ItemType } from '@stuller/stullercom/data-access/apollo-queries'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type AddToCartSectionContextType } from './AddToCartSectionContext'
import { useAddToCartSectionContext } from './useAddToCartSectionContext'

export interface AddToExternalCartProps {
  /**
   * The item id
   */
  itemId: number | null
  /**
   * The serial number
   */
  serialNumber: number | null
  /**
   * The quantity
   */
  quantity: number
  /**
   * The configuration id
   */
  configurationId: number | null
  /**
   * The price
   */
  price: number
  /**
   * The stone type
   */
  certifiedStoneType: string | null
}
export interface ExternalCartPostMessageAPI {
  /**
   * Function to post the add to external cart message
   */
  postAddToExternalCartMessage: () => void
  /**
   * Flag to determine if the add to external cart message should be posted
   */
  shouldPostAddToExternalCartMessage: boolean
}

/**
 * Helper to determine the certified stone type.
 */
function getCertifiedStone (cartContext: AddToCartSectionContextType): string | null {
  if (cartContext.isGemstone) {
    return 'gemstone'
  }
  if (cartContext.isDiamond && !cartContext.isLabGrown) {
    return 'diamond'
  }
  if (cartContext.isDiamond && cartContext.isLabGrown) {
    return 'labgrowndiamond'
  }

  return null
}

/**
 * Hook to use the postMessage API to communicate with the parent window.
 */
function useAddToExternalCartPostMessageAPI (): ExternalCartPostMessageAPI {
  const { getCustomShowcaseSetting, isJewelerShowcase } = useAuth()
  const cartContext = useAddToCartSectionContext()
  const { itemId: rawItemId, itemType, options, itemPrice } = cartContext
  const parsedId = parseInt(rawItemId)
  const itemId = itemType === ItemType.Product ? parsedId : null
  const serialNumber = itemType === ItemType.SerializedProduct ? parsedId : null
  const configurationId = itemType === ItemType.Configuration ? parsedId : null
  const certifiedStoneType = getCertifiedStone(cartContext)

  const externalCartPayload: AddToExternalCartProps = useMemo(
    () => ({
      itemId,
      serialNumber,
      quantity: options?.quantity ?? 1,
      configurationId,
      price: itemPrice,
      certifiedStoneType
    }),
    [
      itemId,
      serialNumber,
      options?.quantity,
      itemPrice,
      configurationId,
      certifiedStoneType
    ]
  )

  const allowIframeHostApi: boolean =
    getCustomShowcaseSetting(CustomShowcaseSettingDetailType.AllowIframeHostApi)?.[0] === 'true'
  const allowedDomainsCSV: string =
    getCustomShowcaseSetting(CustomShowcaseSettingDetailType.AllowIframeHostApiDomainsCsvList)?.[0] ?? ''

  const parentDomain = useMemo((): string | null => {
    // check for browser global window object as nextjs does not have window object in server side rendering.
    if (typeof window === 'undefined') {
      return null
    }
    const domain = window.sessionStorage.getItem('IframeHostDomain')
    if (!allowIframeHostApi || isStringEmpty(domain) || isStringEmpty(allowedDomainsCSV)) {
      return null
    }

    return domain
  }, [allowIframeHostApi, allowedDomainsCSV])

  const postAddToExternalCartMessage = useCallback((): void => {
    if (typeof window === 'undefined') {
      return
    }
    const parentWindow = window.parent
    if (parentDomain != null && parentWindow != null) { // parentWindow can rarely be null e.g. for contentWindow of an iframe that is already removed from the parent.
      try {
        parentWindow.postMessage(externalCartPayload, parentDomain)
        console.log('Message posted to parent window:', externalCartPayload, parentDomain)
      } catch (error) {
        console.error('Failed to post message to parent window:', error)
      }
    } else {
      console.warn('Parent window or domain is not available to post message:', parentWindow, parentDomain)
    }
  }, [externalCartPayload, parentDomain])

  const shouldPostAddToExternalCartMessage = isJewelerShowcase && parentDomain != null

  return { shouldPostAddToExternalCartMessage, postAddToExternalCartMessage }
}

export { useAddToExternalCartPostMessageAPI }
