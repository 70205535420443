import { useState, useEffect, type ReactElement, useMemo, useRef } from 'react'
import {
  type CartLineMessageInfoFragment,
  useCartPageCartItemUpdateSavedCartLineMutation,
  useGetCanSubmitOrderForApprovalBypassCheckoutQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { getClientValidationErrors } from '@stuller/stullercom/data-access/apollo-client'
import { type CartLine } from '../shared/types'
import { isStringEmpty } from '@stuller/shared/util/core'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { FormGroup, FormLabel, Textarea } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useCartContext } from '../shared/useCartContext'

/**
 * Props for the CartItemInstructionsNotes component
 */
export interface CartItemInstructionsNotesProps {
  /**
   * Special instructions for the item
   */
  specialInstructions?: string | null
  /**
   * Customer notes for the item
   */
  customerNotes?: string | null
  /**
   * Function to set the loading state
   */
  onLoading: (loading: boolean) => void
  /**
   * Function to set the error state
   */
  onError: (error: CartLineMessageInfoFragment[]) => void
  /**
   * The cart line to update
   */
  cartLine: CartLine
  /**
   * The callback to call when the call has been successful
   */
  onSuccess: (success: boolean) => void
  /**
   * Whether the notes are disabled
   */
  disabled?: boolean
}

/**
 * This component is a card that allows the user to add special instructions and notes to an item in the cart.
 */
function CartItemInstructionsNotes ({
  specialInstructions,
  customerNotes,
  onLoading,
  onError,
  cartLine,
  onSuccess,
  disabled = false
}: CartItemInstructionsNotesProps): ReactElement | null {
  const { isJewelerShowcase } = useAuth()
  const { useCartContextUpdateCartLineMutation } = useCartContext()
  const { data: canSubmitOrderApproval } = useGetCanSubmitOrderForApprovalBypassCheckoutQuery()
  const canViewCheckoutInfo = useMemo(() => {
    return !isJewelerShowcase
      || canSubmitOrderApproval?.viewerPermissions.canSubmitOrderForApprovalBypassCheckout === true
  }, [isJewelerShowcase, canSubmitOrderApproval])
  const [updatedSpecialInstructions, setUpdatedSpecialInstructions] = useState<string | null | undefined>(specialInstructions)
  const [updatedCustomerNotes, setUpdatedCustomerNotes] = useState<string | null | undefined>(customerNotes)
  const specialInstructionsRef = useRef<HTMLTextAreaElement>(null)
  const customerNotesRef = useRef<HTMLTextAreaElement>(null)

  /**
   * Function to handle the change of the special instructions
   */
  function handleSpecialInstructionsOnChange (event: React.ChangeEvent<HTMLTextAreaElement> | null): void {
    setUpdatedSpecialInstructions(isStringEmpty(event?.target.value) ? null : event.target.value)
  }

  /**
   * Function to handle the change of the customer notes
   */
  function handleCustomerNotesOnChange (event: React.ChangeEvent<HTMLTextAreaElement> | null): void {
    setUpdatedCustomerNotes(isStringEmpty(event?.target.value) ? null : event.target.value)
  }

  /**
   * Function to scroll to top of the special instructions and customer notes text area
   */
  function scrollToTop (): void {
    if (specialInstructionsRef.current != null) {
      specialInstructionsRef.current.scrollTop = 0
    }
    if (customerNotesRef.current != null) {
      customerNotesRef.current.scrollTop = 0
    }
  }

  /**
   * Mutation to update the cart line
   */
  const [handleUpdateCartLine, { loading: updateCartLineLoading }] = useCartContextUpdateCartLineMutation({
    variables: {
      input: {
        cartLineId: cartLine.id,
        fieldsToUpdate: ['CUSTOMER_NOTES', 'SPECIAL_INSTRUCTIONS'],
        options: {
          specialInstructions: updatedSpecialInstructions,
          customerNotes: updatedCustomerNotes
        }
      }
    },
    onError: (error) => {
      const validationErrors = getClientValidationErrors(error).map((e) => e.message)
      const errorMessages = validationErrors.length > 0 ? validationErrors.map((error) => ({ code: 'CART_ERROR', message: error })) : [{ code: 'CART_ERROR', message: 'There was an issue saving your options' }]
      onError(errorMessages)
      setUpdatedCustomerNotes(customerNotes)
      setUpdatedSpecialInstructions(specialInstructions)
    },
    onCompleted: () => {
      onError([])
      onSuccess(true)
      scrollToTop()
    }
  })

  /**
   * Mutation to update the saved cart line
   */
  const [handleUpdateSavedCartLine, { loading: updateSavedCartLineLoading }] = useCartPageCartItemUpdateSavedCartLineMutation({
    variables: {
      input: {
        savedCartLineId: cartLine.id,
        fieldsToUpdate: ['CUSTOMER_NOTES', 'SPECIAL_INSTRUCTIONS'],
        options: {
          specialInstructions: updatedSpecialInstructions,
          customerNotes: updatedCustomerNotes
        }
      }
    },
    onError: (error: Error) => {
      onError([{ code: 'CART_ERROR', message: 'There was an issue saving your options' }])
      logger.error('Error updating saved cart item options', {}, error)
      setUpdatedCustomerNotes(customerNotes)
      setUpdatedSpecialInstructions(specialInstructions)
    },
    onCompleted: () => {
      onError([])
      onSuccess(true)
      scrollToTop()
    }
  })

  /**
   * Function to handle the onBlur event and update the notes & instructions in child components
   */
  function handleOnBlur (): void {
    if (specialInstructions !== updatedSpecialInstructions || customerNotes !== updatedCustomerNotes) {
      if (cartLine.__typename === 'CartLine') {
        void handleUpdateCartLine()
      } else {
        void handleUpdateSavedCartLine()
      }
    }
  }

  /**
   * Reset state on change
   */
  useEffect(() => {
    setUpdatedSpecialInstructions(specialInstructions)
    setUpdatedCustomerNotes(customerNotes)
  }, [specialInstructions, customerNotes])

  /**
   * Handle updating parent loading state
   */
  useEffect(() => {
    onLoading(updateSavedCartLineLoading || updateCartLineLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSavedCartLineLoading, updateCartLineLoading])

  if (!canViewCheckoutInfo) {
    return null
  }

  return (
    <div className='mt-4'>
      <div className='row gy-4'>
        <FormGroup className='col-lg-6'>
          <FormLabel>Special Instructions<span className='fw-normal'> (reviewed by Stuller)</span></FormLabel>
          <Textarea
            rows={1}
            onChange={handleSpecialInstructionsOnChange}
            value={updatedSpecialInstructions ?? ''}
            onBlur={handleOnBlur}
            maxLength={250}
            data-test='special-instructions'
            disabled={disabled}
            ref={specialInstructionsRef}
          />
        </FormGroup>
        <FormGroup className='col-lg-6'>
          <FormLabel>Customer Notes<span className='fw-normal'> (for your records)</span></FormLabel>
          <Textarea
            rows={1}
            onChange={handleCustomerNotesOnChange}
            value={updatedCustomerNotes ?? ''}
            onBlur={handleOnBlur}
            maxLength={250}
            data-test='customer-notes'
            disabled={disabled}
            ref={customerNotesRef}
          />
        </FormGroup>
      </div>
    </div>
  )
}

export {
  CartItemInstructionsNotes
}
