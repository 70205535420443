import { useContext } from 'react'
import { CartContext, type CartContextType } from './CartContext'

/**
 * Hook to fetch Cart context information
 */
function useCartContext (): CartContextType {
  return useContext(CartContext)
}

export {
  useCartContext
}
