import { createContext } from 'react'
import type { Shipment, Shipments } from './types'

export interface CartShipmentContextType {
  /**
   * The current shipment
   */
  shipment?: Shipment
  /**
   * All shipments
   */
  shipments?: Shipments
}

const CartShipmentContext = createContext<CartShipmentContextType>({})

export {
  CartShipmentContext
}
