import { GetRequestItemsPagePermissionsDocument, type GetRequestItemsPagePermissionsQuery, type GetRequestItemsPageCartCountQuery, GetRequestItemsPageCartCountDocument } from '@stuller/stullercom/data-access/apollo-queries'
import { type CustomNextPage } from '@stuller/stullercom/feat/layout-context'
import { RequestItemsForm } from './RequestItemsForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useRouter } from 'next/router'
import { Button } from '@stuller/stullercom/ui'

/**
 * RequestItemsPage
 */
const RequestItemsPage: CustomNextPage = () => {
  const router = useRouter()

  function handleBackToCart (): void {
    void router.push('/cart')
  }

  return (
    <div className='container mw-sm py-8'>
      <Button
        className='mb-5 fw-normal text-decoration-none'
        color='anchor-dark'
        tag='a'
        href='/cart'
      >
        <FontAwesomeIcon icon={fas.faChevronLeft} className='me-2 text-gray-500' size='xl' />
        Back To Cart
      </Button>
      <RequestItemsForm
        title='Request Items'
        onContinue={handleBackToCart}
      />
    </div>
  )
}

RequestItemsPage.getInitialProps = async (context) => {
  const { apolloClient } = context

  const { data: permissionsData } = await apolloClient.query<GetRequestItemsPagePermissionsQuery>({
    query: GetRequestItemsPagePermissionsDocument
  })

  if (!permissionsData.viewerPermissions.canSubmitQuote) {
    return { statusCode: 403 }
  }

  const { data: cartData } = await apolloClient.query<GetRequestItemsPageCartCountQuery>({
    query: GetRequestItemsPageCartCountDocument
  })

  // If the cart is empty, redirect to the cart page
  if (cartData.cart?.itemCount == null || cartData.cart.itemCount === 0) {
    return { statusCode: 307, location: '/cart' }
  }

  return {}
}

RequestItemsPage.layout = {
  title: {
    children: 'Request Items'
  }
}

export {
  RequestItemsPage
}
