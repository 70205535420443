import { getSerializedGemstonePageInitialProps } from './getSerializedGemstoneInitialProps'
import type { SerializedGemstonePageType } from '../types'
import { PdpImageCarousel } from '@stuller/stullercom/feat/pdp-image-carousel'

/**
 * The Page that will render the content entries with the Content Page content type
 */
const SerializedGemstonePage: SerializedGemstonePageType = ({ serializedGemstone, serialNumber }) => {
  if (serializedGemstone == null) {
    return null
  }

  const pageTitle = `${serializedGemstone.stoneOrigin !== 'Natural' ? serializedGemstone.stoneOrigin : ''} ${serializedGemstone.stoneFamily} #${serializedGemstone?.id}`

  console.log('Serialized Gemstone:', serializedGemstone)

  return (
    <div className='container-xxxl mw-xxl px-4'>
      {pageTitle}
      <div style={{ maxWidth: '500px' }}>
        <PdpImageCarousel item={serializedGemstone} title={pageTitle} />
      </div>
    </div>
  )
}

SerializedGemstonePage.getInitialProps = getSerializedGemstonePageInitialProps

export {
  SerializedGemstonePage
}
